import { Injectable } from '@angular/core';
import { stat } from 'fs';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { SettingsService } from '../services/settings.service';

@Injectable()

export class DataService {
    constructor(private settingsService: SettingsService) {}
    public userSettings;
    public themeSubscriber = new BehaviorSubject<any>(this.getInitialThemePath());
    _variable_images = this.themeSubscriber.asObservable();

    setTheme(themePath) {
        this.themeSubscriber.next(themePath);
    }
    getInitialThemePath() {
        //   const interval = setInterval(() => {
        //     if (this.settingsService.activitySettings) {
        //         debugger;
        //         clearInterval(interval);
        //         this.userSettings = this.settingsService.activitySettings;
      
        //         if (!this.userSettings.themeHighlightColor) {
        //           this.userSettings.themeHighlightColor = 'theme1';
        //           return 'assets/img/' + 'theme1' + '/';
        //         } 
        //         return 'assets/img/' + this.userSettings.themeHighlightColor + '/';
        //     }
        //   }, 300);
      
      
        if (localStorage.getItem('themehighlightcolor')) {
        if ((localStorage.getItem('themehighlightcolor') !== null) || (localStorage.getItem('themehighlightcolor') !== undefined)) {
            return 'assets/img/' + localStorage.getItem('themehighlightcolor') + '/';
        }
    }
        localStorage.setItem('themehighlightcolor', 'theme1');
        return 'assets/img/' + 'theme1' + '/';
    }

    getMode() {
        const state = new Subject<any>();
        this._variable_images.pipe(take(1)).subscribe((path) => {
            let stateObj = {
                light: false,
                balanced: false,
                dark: false

            };
            switch (path) {
                case 'assets/img/theme1/':
                    stateObj.balanced = true;
                    break;
                case 'assets/img/theme-dark/':
                    stateObj.dark = true;
                    break;
                case 'assets/img/theme-focus/':
                    stateObj.light = true;
                    break;
            }
            state.next(stateObj) ;
        });
        return state.asObservable();
    }
}
