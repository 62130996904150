import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import { selectPaymentAccount } from "../store";
import { GetPaymentAccount } from "../store/account/account.actions";
import { GetAllPlans } from "../store/plan/plan.actions";

@Injectable()
export class AccountPaymentResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
    return this.store.pipe(
      select(selectPaymentAccount),
      tap(isLoaded => {
        const customerId = JSON.parse(localStorage.profile).user_metadata.cusID;
        this.store.dispatch(new GetPaymentAccount(customerId));

        if (!isLoaded) {
        }
      }),
      filter(isLoaded => !!isLoaded),
      first(),
    );
  }
}
