import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import { EditGetAllLessonSteps } from "../store/edit-lesson/edit-lesson.actions";
import { selectGetLessonPlan, selectGetLessonStepsLoaded } from "../store";

@Injectable()
export class AllLessonStepsResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const lessonPlanId = route.params.lessonPlanId;

    return this.store.pipe(
      select(selectGetLessonStepsLoaded, { id: lessonPlanId}),
      tap((isLoaded) => {
        // if (!isLoaded) {
          this.store.dispatch(new EditGetAllLessonSteps(lessonPlanId));
        // }
      }),
      filter(isLoaded => !!isLoaded),
      first(),
    );
  }
}
