import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import { selectGetPlan, selectGetPlanSuccess, selectPaymentAccount, selectGetAllPlansSuccess} from "../store";
import { GetPaymentAccount } from "../store/account/account.actions";
import { GetAllPlans } from "../store/plan/plan.actions";

@Injectable()
export class AccountPlanResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(selectGetAllPlansSuccess),
      tap(isLoaded => {
        this.store.dispatch(new GetAllPlans(''));

        if (!isLoaded) {
        }
      }),
      filter(isLoaded => !!isLoaded),
      first(),
    );
  }
}
