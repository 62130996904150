import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import { GetManyWorkbookPassages } from "../store/passage/passage.actions";
import * as selectors from "../store";

@Injectable()
export class WorkbookPassagesResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const workbookId = route.params.workbookId;
    return this.store.pipe(
      select(selectors.isWorkbookPassagesLoaded, { id: workbookId}),
      tap(isLoaded => {
        if (!isLoaded) {
          this.store.dispatch(new GetManyWorkbookPassages(workbookId));
        }
      }),
      filter(isLoaded => {
        if (workbookId === 'new') { return true; }
        return isLoaded;
      }),
      first(),
    );
  }
}

