import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {LessonPlansService} from '../core/services/lesson-plans.service';
import {CourseService} from '../core/services/courses.service';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap, take, takeUntil} from 'rxjs/operators';
import {
  faChevronUp,
  faChevronDown,
  faArrowCircleUp,
  faArrowCircleDown,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import {UtilityService} from '../core/services/utility.service';
import { isSetCourseLoaded, selectGetNonOrSubscribedCoursesById } from '../core/store';
import { combineLatest, Subject } from 'rxjs';
import { SetCurriculumCourse } from '../core/store/set-course/set-course.actions';

@Component({
  selector: 'app-lesson-plans',
  templateUrl: './html/lesson-plans.html',
  styleUrls: ['../assets/css/main.css']
})

export class LessonPlansComponent implements OnInit, OnDestroy {
  course: any;
  lessonPlans: any[] = [];
  lessonPlanOpen: any[] = [];
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faArrowCircleUp = faArrowCircleUp;
  faArrowCircleDown = faArrowCircleDown;
  faPlusCircle = faPlusCircle;
  private unsubscribe$: Subject<void> = new Subject();


  constructor(
    private courseService: CourseService,
    private lessonPlansService: LessonPlansService,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store
  ) {

  }

  ngOnInit() {
    const courseId = this.route.url['value'][1].path;

    this.courseService
      .get(courseId)
      .pipe(
        switchMap((course: any) => {
          this.course = course;
          return this.lessonPlansService.getAllWithSteps(courseId);
        })
      )
      .subscribe((lessonPlans: any[]) => {
        this.lessonPlans = lessonPlans;
       this.lessonPlanOpen = lessonPlans.map(() => ({open: false}));
      });
    
    combineLatest([
      this.store.select(isSetCourseLoaded),
      this.store.select(selectGetNonOrSubscribedCoursesById, {id: courseId})

    ])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([isSetCourseLoaded, course]) => {
      if (!isSetCourseLoaded && course) {
        this.store.dispatch(new SetCurriculumCourse({
          course: course,
          redirect: false
        }));
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleOpen(index: any) {
    this.lessonPlanOpen[index].open = !this.lessonPlanOpen[index].open;
  }

  edit($event: any, lessonPlanId: string) {
    $event.stopPropagation();

    const order = this.lessonPlans.length === 0 ? 0 : this.sortBy('order')[this.lessonPlans.length - 1].order + 1;
    this.router.navigate([`edit-lesson-plan/${lessonPlanId}/${this.course._id}/${order}`]);
  }

  begin($event: any, lessonPlan: any) {
    $event.stopPropagation();

    this.lessonPlansService.getFirstStep(lessonPlan._id)
      .pipe(
        take(1)
      )
      .subscribe((firstStep: any) => {
        const url = this.utilityService.getLessonPlanStepUrl(
          firstStep.activityName,
          firstStep.courseWorkbookId,
          lessonPlan._id,
          firstStep._id
        );
        this.router.navigate([url]);
      });

  }

  copy($event: any, lessonPlanId: string) {
    $event.stopPropagation();
    this.lessonPlansService
      .copy(lessonPlanId)
      .subscribe(
        (copiedLesson: any) => {
          copiedLesson.order = this.lessonPlans[this.lessonPlans.length - 1].order + 1;
          this.lessonPlans.push(copiedLesson);
          this.lessonPlanOpen.push({open: false});
        },
        error => {
        }
      );
  }

  delete($event: any, lessonPlanId: string) {
    $event.stopPropagation();
    this.lessonPlansService
      .delete(lessonPlanId)
      .pipe(
        take(1)
      )
      .subscribe(
        () => {
          const matchedIndex = this.lessonPlans.findIndex(lessonPlan => lessonPlan._id === lessonPlanId);
          this.lessonPlans.splice(matchedIndex, 1);
          this.lessonPlanOpen.splice(matchedIndex, 1);
        },
        error => {
        }
      );
  }

  move($event: any, index: number, isUp: boolean) {
    $event.stopPropagation();
    if ((isUp && index === 0) || (!isUp && index === this.lessonPlans.length - 1)) {
      return;
    }

    const nextIndex = isUp ? index - 1 : index + 1;
    const tempOrder = this.lessonPlans[index].order;
    this.lessonPlans[index].order = this.lessonPlans[nextIndex].order;
    this.lessonPlans[nextIndex].order = tempOrder;
  }

  sortBy(prop: string): any[] {
    return this.lessonPlans.sort((a, b) => {
      if (a[prop] < b[prop]) {
        return -1;
      }

      if (a[prop] > b[prop]) {
        return 1;
      }

      return 0;
    });

  }

  createNew() {
    const order = this.lessonPlans.length === 0 ? 0 : this.sortBy('order')[this.lessonPlans.length - 1].order + 1;
    this.router.navigate([`edit-lesson-plan/new/${this.course._id}/${order}`]);
  }
}
