import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import { Component, EventEmitter, Input, Output, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-successful-update',
  templateUrl: './html/successful-update.html',
  styleUrls: [
    '../../assets/css/main.css',
    '../../assets/scss/fontawesome.scss',
    '../../assets/scss/brands.scss',
    '../../assets/scss/regular.scss',
    '../../assets/scss/solid.scss'
  ]
})
export class SuccessfulComponent {
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  faHandshake = faHandshake;
  @Input() text: string;

  constructor(
    public dialogRef: MatDialogRef<SuccessfulComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {
   }

  close() {
    this.closeEvent.emit();
  }
  onCancel() {
    this.dialogRef.close();
}
}
