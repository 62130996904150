import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import * as ActivityActions from "../store/activity/activity.actions";
import * as reducers from "../store";
import { SetUpdatingWorkbookActivityPending } from '../store/workbook-activity/workbook-activity.actions';
import { SetActivityOrLessonFixStatus } from '../store/user-interface/user-interface.actions';

@Injectable()
export class ActivityInitResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const courseWorkbookId = route.params.courseWorkbookId;
    const lessonPlanStepId = route.params.lessonPlanStepId;
    const customerId = JSON.parse(localStorage.profile).user_metadata.cusID;
    const userId = JSON.parse(localStorage.profile).user_metadata.uid;
    const activityType = route.url[0].path;
    const actionObj = {
      courseWorkbookId,
      lessonPlanStepId,
      userId,
      customerId
    };

    let initObj: {selector: any; action: any} = {
      selector: reducers.isLoadedBlackboardInit,
      action: new ActivityActions.BlackboardInit(actionObj)
    };
    this.store.dispatch(new ActivityActions.ClearActivityInitData());

    if (activityType === 'mini-tiles') {
      initObj.selector = reducers.isLoadedMiniTilesInit;
      initObj.action = new ActivityActions.MiniTilesInit(actionObj);
    } else if (activityType === 'workbook-tiles') {
      initObj.selector = reducers.isLoadedWorkbookTilesInit;
      initObj.action = new ActivityActions.WorkbookTilesInit(actionObj);
    }

    return this.store.pipe(
      select(initObj.selector, { id: courseWorkbookId, type: lessonPlanStepId ? 'lessonPlanStep' : 'courseWorkbookActivity'}),
      tap(isLoaded => {
        this.store.dispatch(new SetActivityOrLessonFixStatus('outdated'));
        if (!isLoaded) {
          this.store.dispatch(initObj.action);
        }
      }),
      filter(isLoaded => {
        return  !!isLoaded;
      }),
      first(),
    );
  }
}
