import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewerNewComponent } from './pdf-viewer-new.component';



@NgModule({
  declarations: [PdfViewerNewComponent],
  exports: [PdfViewerNewComponent],
  imports: [
    CommonModule
  ]
})
export class PdfViewerNewModule { }
