import { AllCoursesResolver } from '../core/resolvers/all-courses.resolver';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CurriculumComponent } from './curriculum.component';
import { AuthGuard } from '../core/services/auth-guard.service';
import { AllSubscribedCoursesResolver } from '../core/resolvers/all-subscribed-courses.resolver';
import { AllMyLibraryWorkbooksResolver } from '../core/resolvers/all-my-library-workbooks.resolver';
import { LibraryWorkbooksResolver } from '../core/resolvers/library-workbooks.resolver';
import { PlanResolver } from '../core/resolvers/plan.resolver';
import { SetCourseCourseResolver } from '../core/resolvers/set-course.resolver';
import { AllCourseWorkbooksResolver } from '../core/resolvers/all-course-workbooks.resolver';

const curriculumRoutes: Routes = [
  {
    path: 'my-curriculum',
    canActivate: [AuthGuard],
    component: CurriculumComponent,
    // loadChildren: () => import('../Curriculum/curriculum.module').then(m => m.CurriculumModule),
    data: {},
    resolve: {
      allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
      allCoursesLoaded: AllCoursesResolver,
      allMyLibraryWorkbooksLoaded: AllMyLibraryWorkbooksResolver,
      planLoaded: PlanResolver,
      // setCourseLoaded: SetCourseCourseResolver,
      // loadCourseworkbook: AllCourseWorkbooksResolver
    }
  },
  {
      path: 'library',
      canActivate: [AuthGuard],
      loadChildren: () => import('../whizzimo-library/whizzimo-library.module').then(m => m.WhizzimoLibraryModule),
      resolve: {
        allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
        allCoursesLoaded: AllCoursesResolver,
        allLibraryWorkbooksLoaded: LibraryWorkbooksResolver,
        allMyLibraryWorkbooksLoaded: AllMyLibraryWorkbooksResolver,
      }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(curriculumRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class CurriculumRoutingModule {}
