import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import { faEdit, faTrash, faCopy } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-workbooks-element',
  templateUrl: './html/workbooks.html',
  styleUrls: [
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss'

  ]
})

export class WorkBooksComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  filteredWorkbooks: any[] = null;
  @Input() workbooks: any[] = [];
  @Output() deleteWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() addWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() copyWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() editWorkbook: EventEmitter<any> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  openDialog(currentWorkbook): void {
    const dialogRef = this.dialog.open  (WorkbookMenuComponent, {
      data: {workbook: currentWorkbook},
      width: '350px'
    });

    dialogRef.componentInstance.functions = {
      deleteWorkbook: (workbookId) => {
        dialogRef.close();
        this.deleteWorkbook.emit(workbookId);
      },
      addCourseWorkbook: (workbook) => {
        dialogRef.close();
        this.addWorkbook.emit(workbook);
      },
      copyWorkbook: (workbookId) => {
        dialogRef.close();
        this.copyWorkbook.emit(workbookId);
      },
      editWorkbook: (workbookId) => {
        dialogRef.close();
        this.editWorkbook.emit(workbookId);
      }
    };
  }

  search($event) {
    if ($event.target.value === '' || !this.filteredWorkbooks || this.filteredWorkbooks.length === 0) {
      return this.filteredWorkbooks = this.workbooks.slice();
    }

    this.filteredWorkbooks = this.workbooks.filter(workbook => workbook.name.toLowerCase().indexOf($event.target.value.toLowerCase()) >= 0);
  }
}

@Component({
  selector: 'app-workbook-menu',
  templateUrl: './html/workbook-menu.html'
})
export class WorkbookMenuComponent {
  public faEdit = faEdit;
  public faTrash = faTrash;
  public faCopy = faCopy;
  functions;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  deleteWorkbook(workbookId) {
    this.functions.deleteWorkbook(workbookId);
  }

  addCourseWorkbook(workbook) {
    this.functions.addCourseWorkbook(workbook);
  }

  copyWorkbook(workbookId) {
    this.functions.copyWorkbook(workbookId);
  }

  editWorkbook(workbookId) {
    this.functions.editWorkbook(workbookId);
  }
}
