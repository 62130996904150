import { Store } from '@ngrx/store';
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { delay, map, switchMap, tap } from "rxjs/operators";
import { CourseWorkbookActivityService } from "../../services/course-workbook-activity.service";
import * as actions from './workbook-activity.actions';
import { BlackboardInit, MiniTilesInit, UpdateInitDataCourseWorkbookActivitySuccess, WorkbookTilesInit } from '../activity/activity.actions';
import { SetActivityOrLessonFixStatus } from '../user-interface/user-interface.actions';


@Injectable()

export class WorkbookActivityEffect {
  constructor(
      private actions$: Actions,
      private courseWorkbookActivityService: CourseWorkbookActivityService,
      private store: Store
  ) {}

  UpdateWorkbookActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.WorkbookActivityActionTypes.UpdateWorkbookActivity),
      map((action: actions.UpdateWorkbookActivity) => action.payload),
      switchMap((payload: any) => {
          return this.courseWorkbookActivityService.update(payload.courseWorkbookActivity)
            .pipe(
              // tap(res => {
              //   this.store.dispatch(new SetActivityOrLessonFixStatus('updated'))
              // }),
              // delay(1000),
              switchMap(res => {
                if (payload.initData.activityType === 'blackboard') {
                  const data = JSON.parse(JSON.stringify(payload.initData));
                  delete data.activityType;
                  this.store.dispatch(new BlackboardInit(data));
                } else if (payload.initData.activityType === 'workbook-tiles') {
                  const data = JSON.parse(JSON.stringify(payload.initData));
                  delete data.activityType;
                  this.store.dispatch(new WorkbookTilesInit(data));
                } else if (payload.initData.activityType === 'mini-tiles') {
                  const data = JSON.parse(JSON.stringify(payload.initData));
                  delete data.activityType;
                  this.store.dispatch(new MiniTilesInit(data));
                }
                return [
                  new SetActivityOrLessonFixStatus('updated'),
                  // new UpdateInitDataCourseWorkbookActivitySuccess(payload.courseWorkbookActivity),
                  new actions.UpdateWorkbookActivitySuccess(payload.courseWorkbookActivity),
                ];
              })
            );
      })
    );
  });

  GetWorkbookActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.WorkbookActivityActionTypes.GetWorkbookActivity),
      map((action: actions.GetWorkbookActivity) => action.payload),
      switchMap((payload: any) => {
          return this.courseWorkbookActivityService.get(payload.courseWorkbookId, payload.activityTypeId)
            .pipe(
              map(res => new actions.GetWorkbookActivitySuccess(res))
            );
      })
    );
  });
}
