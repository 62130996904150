import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class CurriculumService {

  constructor(private http: HttpClient) {}
  activeTab: string;
}
