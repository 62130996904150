import * as setCourseActions from './set-course.actions';

export interface State {
  course: any;
  pending: boolean;
  succeeded: boolean;
  error: any;
}

const initialState: State = {
  course: null,
  pending: false,
  succeeded: false,
  error: null,
};

export function reducer(state = initialState, action: setCourseActions.SetCourseActions): State {
  switch (action.type) {
    case setCourseActions.SetCourseActionTypes.SetCurriculumCourse: {
        return {
            ...state,
            pending: true,
            succeeded: false,
            error: null
        };
    }

    case setCourseActions.SetCourseActionTypes.SetCurriculumCourseSuccess: {
      return {
          ...state,
          course: action.payload.course,
          pending: false,
          succeeded: true,
          error: null
      };
    }

    case setCourseActions.SetCourseActionTypes.SetCurriculumCourseFail: {
      return {
          ...state,
          course: null,
          pending: false,
          succeeded: false,
          error: action.payload
      };
    }

    case setCourseActions.SetCourseActionTypes.ResetCurriculumCourse: {
      return {
          ...state,
          ...initialState
      };
    }

    case setCourseActions.SetCourseActionTypes.SetCurriculumCourseById: {
      return {
          ...state,
          course: null,
          pending: true,
          succeeded: false,
          error: null
      };
    }

    case setCourseActions.SetCourseActionTypes.SetCurriculumCourseByIdSuccess: {
      return {
          ...state,
          course: action.payload.course,
          pending: false,
          succeeded: true,
      };
    }


    default:
      return state;
  }
}
