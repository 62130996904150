import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { create } from 'domain';
import * as Words from './words/words.reducer';
import * as EditLesson from './edit-lesson/edit-lesson.reducer';
import * as Lessons from './lesson/lesson.reducer';
import * as fromDummy from './dummy/dummy-reducer';
import * as Plan from './plan/plan.reducer';
import * as Courses from './courses/courses.reducer';
import * as Subscriptions from './subscriptions/subscriptions.reducer';
import * as CourseWorkbook from './course-workbook/course-workbook.reducer';
import * as Settings from './settings/settings.reducer';
import * as Workbooks from './workbook/workbook.reducer';
import * as SetCourse from './set-course/set-course.reducer';
import * as Activity from './activity/activity.reducer';
import * as Account from './account/account.reducer';
import * as Sentences from './sentence/sentence.reduce';
import * as Passages from './passage/passage.reducer';
import * as Phrases from './phrase/phrase.reducer';
import * as Files from './file/file.reducer';
import * as Sharing from './sharing/sharing.reducer';
import * as WorkbookActivity from './workbook-activity/workbook-activity.reducer';
import * as UserInterface from './user-interface/user-interface.reducer';
import * as Feedback from './feedback/feedback.reducer';


export interface WhizzimoStates {
    dummyData: fromDummy.State;
    Plan: Plan.State;
    Courses: Courses.State;
    Subscriptions: Subscriptions.State;
    CourseWorkbook: CourseWorkbook.State;
    Settings: Settings.State;
    Workbooks: Workbooks.State;
    SetCourse: SetCourse.State;
    Activity: Activity.State;
    Account: Account.State;
    Sentences: Sentences.State;
    Passages: Passages.State;
    Phrases: Phrases.State;
    Files: Files.State;
    Words: Words.State;
    Lessons: Lessons.State;
    EditLesson: EditLesson.State;
    Sharing: Sharing.State;
    WorkbookActivity: WorkbookActivity.State;
    UserInterface: UserInterface.State;
    Feedback: Feedback.State;
}

export interface State {
whizzimoStates: WhizzimoStates;
}

export const reducers: ActionReducerMap<WhizzimoStates> = {
    dummyData: fromDummy.reducer,
    Plan: Plan.reducer,
    Courses: Courses.reducer,
    Subscriptions: Subscriptions.reducer,
    CourseWorkbook: CourseWorkbook.reducer,
    Settings: Settings.reducer,
    Workbooks: Workbooks.reducer,
    SetCourse: SetCourse.reducer,
    Activity: Activity.reducer,
    Account: Account.reducer,
    Sentences: Sentences.reducer,
    Passages: Passages.reducer,
    Phrases: Phrases.reducer,
    Files: Files.reducer,
    Words: Words.reducer,
    Lessons: Lessons.reducer,
    EditLesson: EditLesson.reducer,
    Sharing: Sharing.reducer,
    WorkbookActivity: WorkbookActivity.reducer,
    UserInterface: UserInterface.reducer,
    Feedback: Feedback.reducer
};

export const selectWhizzimoState = createFeatureSelector<WhizzimoStates>('whizzimo');

// dummy data state selectors
export const selectDummyDataState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.dummyData
);

export const selectDummyDataError = createSelector(
    selectDummyDataState,
    fromDummy.getError
);
export const selectDummyDataPending = createSelector(
    selectDummyDataState,
    fromDummy.getPending
);
export const selectDummyDataSuccess = createSelector(
    selectDummyDataState,
    fromDummy.getSucceeded
);

export const selectDummyData = createSelector(
    selectDummyDataState,
    fromDummy.getDummyData
);


//  plan selectors
export const selectGetPlanState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.Plan
);

export const selectGetPlanError = createSelector(
    selectGetPlanState,
    Plan.getError
);

export const selectGetPlanSuccess = createSelector(
    selectGetPlanState,
    Plan.getSucceeded
);

export const selectIsPlanLoaded = createSelector(
    selectGetPlanState,
    state => !!(state.succeeded && state.id)

);
export const selectGetPlan = createSelector(
    selectGetPlanState,
    Plan.getPlan
);
export const selectGetAllPlans = createSelector(
    selectGetPlanState,
    Plan.getAllPlans
);

export const selectGetAllPlansSuccess = createSelector(
    selectGetPlanState,
    Plan.getAllSucceeded
);

export const selectGetPlanData = createSelector(
    selectGetPlanState,
    state => state.plan
);

// courses selectors

export const selectCoursesState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.Courses
);

export const selectGetCourse = createSelector(
    selectCoursesState,
    Courses.getCourse
);

export const selectGetCourseSuccess = createSelector(
    selectCoursesState,
    // Courses.getSucceeded
    state => !!(state.succeeded && state.id)
);

export const selectGetCourseError = createSelector(
    selectCoursesState,
    Courses.getError
);

export const selectGetAllCourses = createSelector(
    selectCoursesState,
    Courses.getAllCourses
);

export const selectAllCoursesSuccess = createSelector(
    selectCoursesState,
    Courses.getAllSucceeded
);

export const selectCourseData = createSelector(
    selectCoursesState,
    (state, props) => state.entities[props.id]
);

// settings selectors

export const selectSettingsState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.Settings
);

// export const getCurrentSetting = createSelector(
//     selectSettingsState,
//     Settings.getSetting
// );

export const selectGetSettingSuccess = createSelector(
    selectSettingsState,
    Settings.getSucceeded
);

export const selectGetSettingError = createSelector(
    selectSettingsState,
    Settings.getError
);

export const selectGetAllSettings = createSelector(
    selectSettingsState,
    Settings.getAllSettings
);

export const selectAllSettingsSuccess = createSelector(
    selectSettingsState,
    Settings.getAllSucceeded
);

export const selectGetSettingPending = createSelector(
    selectSettingsState,
    Settings.getPending
);

export const getCurrentSetting = createSelector(
    selectSettingsState,
    // state => state.allSettings.find((setting: any) => setting.isCurrent === true)
    state => state.allSettings.find((setting: any) => setting._id === state.selectedId)
);

export const selectCurrentSettingId = createSelector(
    selectSettingsState,
    state => state.selectedId
);

export const selectCurrentTheme = createSelector(
    getCurrentSetting,
    setting => setting?.themeHighlightColor
);

// subscriptions selectors

export const selectSubscriptionsState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.Subscriptions
);

export const selectGetSubscription = createSelector(
    selectSubscriptionsState,
    Subscriptions.getSubscription
);

export const selectGetSubscriptionSuccess = createSelector(
    selectSubscriptionsState,
    Subscriptions.getSucceeded
);

export const selectGetSubscriptionError = createSelector(
    selectSubscriptionsState,
    Subscriptions.getError
);

export const selectGetAllSubscription = createSelector(
    selectSubscriptionsState,
    Subscriptions.getAllSubscriptions
);

export const selectAllSubscriptionSuccess = createSelector(
    selectSubscriptionsState,
    Subscriptions.getAllSucceeded
);

// course workbook selectors

export const selectCourseWorkbookState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.CourseWorkbook
);

export const getCourseWorkbook = createSelector(
    selectCourseWorkbookState,
    // CourseWorkbook.getCourseWorkbook
    (state, props) => state.entities[props.id]
);

export const selectGetCourseWorkbookPending = createSelector(
    selectCourseWorkbookState,
    CourseWorkbook.getPending
);

export const selectGetCourseWorkbookSuccess = createSelector(
    selectCourseWorkbookState,
    CourseWorkbook.getSucceeded
);

export const selectGetCourseWorkbookError = createSelector(
    selectCourseWorkbookState,
    CourseWorkbook.getError
);

export const selectGetAllCourseWorkbook = createSelector(
    selectCourseWorkbookState ,
    CourseWorkbook.getAllCourseWorkbook
);

export const selectAllCourseWorkbookSuccess = createSelector(
    selectCourseWorkbookState ,
    CourseWorkbook.getAllSucceeded
);

export const isCourseWorkbookLoaded = createSelector(
    selectCourseWorkbookState ,
    (state, props) => {
        return !!(
            (state.entities[props.id] && state.entities[props.id].workbook)
            || (state.selectedId === props.id && Object.values(state.entities).length)
            );
    }
);

export const isAllCourseWorkbooksLoaded = createSelector(
    selectCourseWorkbookState ,
    (state, props) => {
        return state.selectedId === props.id;
    }
);


// workbooks selectors
export const selectWorkbooksState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.Workbooks
);

export const selectAllWorkbooksSuccess = createSelector(
    selectWorkbooksState,
    Workbooks.getAllSucceeded
);

export const selectGetMyLibraryWorkbooks = createSelector(
    selectWorkbooksState,
    Workbooks.getAllWorkbooks
);

export const getWorkbook = createSelector(
    selectWorkbooksState,
    (state, props) => {
        const allMyWorkbooks = state.entities;
        const workbookId = props.id;
        const workbookKey = Object.keys(allMyWorkbooks).find(key => {
            const workbook = allMyWorkbooks[key];
            if (key === workbookId || workbook.oldId === workbookId || workbook.workbookId === workbookId) {
                return true;
            }
            return false;
        });

        if (workbookKey) {
            return allMyWorkbooks[workbookKey];
        }
        return null;
    }
);
export const getSelectedWorkbook = createSelector(
    selectWorkbooksState,
    Workbooks.getWorkbook
);


export const selectGetMyWhizzimoWorkbooks = createSelector(
    selectWorkbooksState,
    state => {
        if (state.libraryWorkbooks) {
            const libraryWorkbooks = JSON.parse(JSON.stringify(state.libraryWorkbooks));
            return libraryWorkbooks.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        }
    }
);

// set-course selectors
export const selectSetCourseState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.SetCourse
);

export const isSetCourseLoaded = createSelector(
    selectSetCourseState,
    state => !!(state.course)
);

export const getSetCourseData = createSelector(
    selectSetCourseState,
    state => state?.course
);

export const isSetCourseResetted = createSelector(
    selectSetCourseState,
    state => !state.course
);

export const checkCourseLoadedById = createSelector(
    selectSetCourseState,
    (state, props) => state.course?._id === props?.id
);

export const isCurrentCourseSubscribed = createSelector(
  getSetCourseData,
  course => !!course?.subscriptionId
);

// activity selectors
export const selectActivityState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.Activity
);

export const selectActivitySelectedTool = createSelector(
    selectActivityState,
    state => ({
      tool:  state.selectedTool,
      cardSize: state.selectedCardSize,
    })
);

export const selectBlackboardInit = createSelector(
    selectActivityState,
    Activity.blackboardInit
);

export const selectMiniTilesInit = createSelector(
    selectActivityState,
    Activity.miniTilesInit
);
export const selectWorkbookTilesInit = createSelector(
    selectActivityState,
    Activity.workbookTilesInit
);
export const selectActivityTypes = createSelector(
    selectActivityState,
    state => state.activityTypes
);

export const isLoadedBlackboardInit = createSelector(
    selectBlackboardInit,
    (state, props) => state?.courseWorkbook?._id === props?.id && state[props.type]?.courseWorkbookId === props?.id
);

export const isLoadedMiniTilesInit = createSelector(
    selectMiniTilesInit,
    (state, props) => state?.courseWorkbook?._id === props?.id && state[props.type]?.courseWorkbookId === props?.id
);

export const isLoadedWorkbookTilesInit = createSelector(
    selectWorkbookTilesInit,
    (state, props) => state?.courseWorkbook?._id === props?.id && state[props.type]?.courseWorkbookId === props?.id
);


// Account state selectors
export const selectAccountState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.Account
);

export const selectAccountError = createSelector(
    selectAccountState,
    Account.getError
);
export const selectAccountPending = createSelector(
    selectAccountState,
    Account.getPending
);
export const selectAccountSuccess = createSelector(
    selectAccountState,
    Account.getSucceeded
);

export const selectPromo = createSelector(
    selectAccountState,
    Account.getPromo
);

export const selectPaymentAccount = createSelector(
    selectAccountState,
    Account.getPaymentAccount
);
export const selectCardInfo = createSelector(
    selectAccountState,
    Account.getCardInfo
);
export const selectAllSubscriptions = createSelector(
    selectAccountState,
    Account.getAllSubscriptions
);

// Sentences state selectors
export const selectSentenceState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.Sentences
);

export const selectSentenceError = createSelector(
    selectSentenceState,
    Sentences.getError
);
export const selectSentencePending = createSelector(
    selectSentenceState,
    Sentences.getPending
);
export const selectSentenceSuccess = createSelector(
    selectSentenceState,
    Sentences.getSucceeded
);

export const selectGetSentences = createSelector(
    selectSentenceState,
    Sentences.getSentences
);

export const selectGetWorkbookSentences = createSelector(
    selectSentenceState,
    Sentences.getWorkbookSentences
);

export const isWorkbookSentencesLoaded = createSelector(
    selectSentenceState,
    (state, props) => state.workbookId === props?.id
);


// Passages state selectors
export const selectPassageState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.Passages
);

export const selectPassageError = createSelector(
    selectPassageState,
    Passages.getError
);
export const selectPassagePending = createSelector(
    selectPassageState,
    Passages.getPending
);
export const selectPassageSuccess = createSelector(
    selectPassageState,
    Passages.getSucceeded
);

export const selectGetPassages = createSelector(
    selectPassageState,
    Passages.getPassages
);

export const selectGetWorkbookPassages = createSelector(
    selectPassageState,
    Passages.getWorkbookPassages
);

export const isWorkbookPassagesLoaded = createSelector(
    selectPassageState,
    (state, props) => state.workbookId === props?.id
);

// Sentences state selectors
export const selectPhraseState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.Phrases
);

export const selectPhraseError = createSelector(
    selectPhraseState,
    Phrases.getError
);
export const selectPhrasePending = createSelector(
    selectPhraseState,
    Phrases.getPending
);
export const selectPhraseSuccess = createSelector(
    selectPhraseState,
    Phrases.getSucceeded
);

export const selectGetPhrases = createSelector(
    selectPhraseState,
    Phrases.getPhrases
);

export const selectGetWorkbookPhrases = createSelector(
    selectPhraseState,
    Phrases.getWorkbookPhrases
);

export const isWorkbookPhrasesLoaded = createSelector(
    selectPhraseState,
    (state, props) => state.workbookId === props?.id
);

// Files state selectors
export const selectFileState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.Files
);

export const selectFileError = createSelector(
    selectFileState,
    Files.getError
);
export const selectFilePending = createSelector(
    selectFileState,
    Files.getPending
);
export const selectFileSuccess = createSelector(
    selectFileState,
    Files.getSucceeded
);
export const selectGetFile = createSelector(
    selectFileState,
    Files.getFile
);

export const selectGetFiles = createSelector(
    selectFileState,
    Files.getFiles
);

export const selectGetWorkbookFiles = createSelector(
    selectFileState,
    Files.getWorkbookFiles
);

// Words state selectors
export const selectWordsState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.Words
);

export const selectWordsError = createSelector(
    selectWordsState,
    Words.getError
);
export const selectWordsPending = createSelector(
    selectWordsState,
    Words.getPending
);
export const selectWordsSuccess = createSelector(
    selectWordsState,
    Words.getSucceeded
);
export const selectGetPairings = createSelector(
    selectWordsState,
    Words.getPairings
);

export const selectGetWordsByWordIds = createSelector(
    selectWordsState,
    Words.getWordsByWordIds
);

export const selectGetTilesByWordIds = createSelector(
    selectWordsState,
    Words.getTilesByWordIds
);
export const selectGetPostResponse = createSelector(
    selectWordsState,
    Words.getPostResponse
);

export const selectGetPostResponseByWorkbookId = createSelector(
    selectWordsState,
    (state, props) => state?.postResponse[props?.id]
);

// Lessons state selectors
export const selectLessonState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.Lessons
);

export const selectLessonError = createSelector(
    selectLessonState,
    Lessons.getError
);
export const selectLessonPending = createSelector(
    selectLessonState,
    Lessons.getPending
);
export const selectLessonSuccess = createSelector(
    selectLessonState,
    Lessons.getSucceeded
);
export const selectGetLesson = createSelector(
    selectLessonState,
    Lessons.getLesson
);

export const selectGetAllLessons = createSelector(
    selectLessonState,
    Lessons.GetAllLessons
);

export const selectGetFirstLessonStep = createSelector(
    selectLessonState,
    Lessons.getFirstLessonStep
);
export const selectGetAllLessonSteps = createSelector(
    selectLessonState,
    Lessons.getAllLessonSteps
);
export const selectGetAllLessonStepsSuccess = createSelector(
    selectLessonState,
    Lessons.getAllLessonStepsSuccess
);

export const selectGetLessonPlan = createSelector(
    selectGetAllLessons,
    (allLessons, props) => {
        return allLessons?.find(lesson => lesson?._id === props?.id);
    }
);

export const selectGetAllLessonsLoaded = createSelector(
    selectLessonState,
    state => state.courseId
);

// EditLesson state selectors
export const selectEditLessonState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.EditLesson
);

export const selectEditLessonError = createSelector(
    selectEditLessonState,
    EditLesson.getError
);
export const selectEditLessonPending = createSelector(
    selectEditLessonState,
    EditLesson.getPending
);
export const selectEditLessonSuccess = createSelector(
    selectEditLessonState,
    EditLesson.getSucceeded
);
export const selectGetLessonStep = createSelector(
    selectEditLessonState,
    EditLesson.getLessonStep
);

export const selectIsExistLessonStepById = createSelector(
    selectGetLessonStep,
    (lessonStep, param) => lessonStep?._id === param?.id
);

export const selectGetAllLessonEditedSteps = createSelector(
    selectEditLessonState,
    EditLesson.GetAllLessonSteps
);

export const selectGetCountByCourseWorkbookId = createSelector(
    selectEditLessonState,
    EditLesson.getCourseWorkbookIdCount
);

export const selectGetCountByWorkbookId = createSelector(
    selectEditLessonState,
    EditLesson.getWorkbookIdCount
);

export const selectGetLessonStepsLoaded = createSelector(
    selectEditLessonState,
    (state, props) => state.lessonId === props.id
);

export const isWorkbookFilesLoaded = createSelector(
    selectFileState,
    (state, props) => state.workbookId === props?.id
);

// Sharing state selectors
export const selectSharingState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.Sharing
);

export const selectSharingError = createSelector(
    selectSharingState,
    Sharing.getError
);
export const selectSharingPending = createSelector(
    selectSharingState,
    Sharing.getPending
);
export const selectSharingSuccess = createSelector(
    selectSharingState,
    Sharing.getSucceeded
);

export const selectGetSharingData = createSelector(
    selectSharingState,
    Sharing.GetSharingData
);

export const selectGetAppliedSuccess = createSelector(
    selectSharingState,
    Sharing.GetApplied
);

export const selectGetAllCollections = createSelector(
    selectSharingState,
    state => state.sharingData
);

export const selectGetAllCollectionsSuccess = createSelector(
    selectSharingState,
    state => state.succeeded
);

// Workbook Activity state selectors
export const selectWorkbookActivityState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.WorkbookActivity
);

export const selectGetWorkbookActivity = createSelector(
    selectWorkbookActivityState,
    state => state.workbookActivity
);

export const selectGetWorkbookActivityByParams = createSelector(
    selectWorkbookActivityState,
    (state, params) => {
        if (state.workbookActivity?.courseWorkbookId === params?.courseWorkbookId && state.workbookActivity?.activityTypeId === params?.activityTypeId) {
            return state.workbookActivity;
        }
    }
);

export const selectWorkbookActivityUpdated = createSelector(
  selectWorkbookActivityState,
  state => state.updated
);


// Getting all Non and Subscription Courses
export const getAllNonAndSubscriptionCourses = createSelector(
    selectGetAllCourses,
    selectGetAllSubscription,
    (courses, subscriptions) => [...subscriptions, ...courses]
);

export const isSetCourseInit = createSelector(
    selectSetCourseState,
    getAllNonAndSubscriptionCourses,
    (state, allCourses) => {
        return {
            isInit: !!(!state.course && !state.pending && !state.succeeded && !state.error),
            course: allCourses[0]
        };
    }
);

export const selectGetNonOrSubscribedCoursesById = createSelector(
    getAllNonAndSubscriptionCourses,
    (courses, props) => {
        return courses.find(course => course._id === props.id);
    }
);


export const selectUserInterfaceState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.UserInterface
);

export const selectActivityOrLessonFixStatus = createSelector(
  selectUserInterfaceState,
  state => state.activityOrLessonFixStatus
);

export const selectFeedbackState = createSelector(
    selectWhizzimoState,
    (state: WhizzimoStates) => state.Feedback
);

export const selectFeedbackError = createSelector(
    selectFeedbackState,
    Feedback.getError
);

export const selectFeedbackPending = createSelector(
    selectFeedbackState,
    Feedback.getPending
);

export const selectFeedbackSuccess = createSelector(
    selectFeedbackState,
    Feedback.getSucceeded
);