import { Store } from '@ngrx/store';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Inject, ChangeDetectionStrategy} from '@angular/core';
import {TilesService} from '../../../../services/word-filter-tiles.service';
import {take, takeUntil} from 'rxjs/operators';
import {SettingsService} from '../../../../services/new-settings.service';
import {UserService} from '../../../../services/user.service';
import {WordsService} from '../../../../services/words.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BehaviorSubject, Subject} from 'rxjs';
import {UtilityService} from '../../../../services/utility.service';
import { GetPairings } from '../../../../store/words/words.actions';
import { getPairings } from '../../../../store/words/words.reducer';
import { getCurrentSetting, selectGetPairings } from '../../../../store';
import { AsyncPipe } from '@angular/common';
import {uniqBy} from 'lodash';

@Component({
  selector: 'app-word-tiles-filter',
  templateUrl: './html/word-filter-tiles.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
    './word-filter.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush

})

export class WordFilterTilesComponent implements OnInit, OnDestroy {
  @Input() filter: any;
  @Input() newWorkbook: boolean;
  @Output() updateQuery: EventEmitter<any> = new EventEmitter<any>();

  tilesToSelect: any[] = [];
  userSettings: any;
  inputText: string;
  pairings: any;
  newTiles: any[] = [];
  tilesUpdatedSubject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  newTiles$ = this.tilesUpdatedSubject.asObservable();
  private unsubscribe$: Subject<void> = new Subject();


  constructor(
    private tilesService: TilesService,
    private userService: UserService,
    private settingsService: SettingsService,
    private wordService: WordsService,
    private _tileModal: MatDialog,
    private utilityService: UtilityService,
    private store: Store,
    private async: AsyncPipe

  ) {

  }

  ngOnInit() {
    // this.wordService
    //   .getPairings()
    //   .pipe(take(1))
    //   .subscribe(pairings => this.pairings = pairings);
    this.store.select(selectGetPairings)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(pairings => {
      if (!pairings) {
        this.pairings = null;
        return;
      }
      this.pairings = JSON.parse(JSON.stringify(pairings));
    });

    // if (localStorage.userSettings) {
    //   this.userSettings = JSON.parse(localStorage.userSettings);
    // } else {
    //   this.settingsService
    //     .get(JSON.parse(localStorage.profile).user_metadata.uid)
    //     .pipe(
    //       take(1)
    //     )
    //     .subscribe((userSettings: any) => {
    //       localStorage.setItem('userSettings', JSON.stringify(userSettings));
    //       this.userSettings = userSettings;
    //     });
    // }
    this.store.select(getCurrentSetting).pipe(takeUntil(this.unsubscribe$)).subscribe(setting => {
      if (!setting) {
        this.userSettings = null;
        return;
      }
      this.userSettings = JSON.parse(JSON.stringify(setting));
    });
    if (!this.filter.currentVal || this.filter.currentVal && this.filter.currentVal.length === 0) {
        this.filter.currentVal = JSON.parse(JSON.stringify(
          Object.values(this.userSettings.bank)
          .map(row => Object.values(row))
          .reduce((prev, next) => prev.concat(next))
          .filter(val => val !== '\'+tilekey+\'')
        )
        );

        this.waitForPairings(() => this.createTiles(this.filter.currentVal, this.pairings));
        this.updateQueryEvent();
    } else {
      this.waitForPairings(() => this.createTiles(this.filter.currentVal, this.pairings));
      this.updateQueryEvent();
    }
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next()
      this.unsubscribe$.complete();
  }

  waitForPairings(callback: Function) {
    if (typeof this.filter.currentVal[0] === 'object') {
      // this.newTiles = JSON.parse(JSON.stringify(this.filter.currentVal));
      this.tilesUpdatedSubject.next(JSON.parse(JSON.stringify(this.filter.currentVal)));
    } else {
      const interval = setInterval(() => {
        if (this.pairings) {
          clearInterval(interval);
          callback();
        }
      }, 500);
    }

  }

  addTile(tile) {
    let newTiles: any = this.async.transform(this.newTiles$).slice();
    this.inputText = '';
    this.tilesToSelect = [];
    const tiles = [tile];
    const pairing = this.pairings.find((pairing) => pairing.letter === tile.split('.')[2]);
    const type = tile.substring(tile.indexOf('.') + 1, tile.lastIndexOf('.'));
    let matchingPhonemes = pairing ? pairing.examples.filter(example => example.type === type) : [];
    const letter = pairing ? pairing.letter : tile.substring(tile.lastIndexOf('.') + 1);

    if (newTiles.every((newTile) => newTile.tile !== tile)) {
      this.newTiles = newTiles;
      this.createTiles(tiles, this.pairings);
      newTiles.push({
        letter: letter,
        tile: tile,
        allChecked: true,
        partialChecked: false,
        examples: matchingPhonemes
      });
      this.tilesUpdatedSubject.next(newTiles);
      // this.updateQueryEvent(newTiles);
    }
  }

  compareTileLists(): void {
    let newTiles: any = this.async.transform(this.newTiles$);

    newTiles.forEach((newTile) => {
      this.tilesToSelect = JSON.parse(JSON.stringify(this.tilesToSelect.filter((tile) => tile !== newTile.tile)));
    });
  }

  removeTile(newTiles) {
    // this.newTiles = newTiles;
    this.tilesUpdatedSubject.next(newTiles);
    this.updateQueryEvent();
  }

  updateQueryEvent(event?: any) {
    let newTiles: any = this.async.transform(this.newTiles$);
    if (event) {
      this.updateQuery.emit({[this.filter.name]: event});
      this.tilesUpdatedSubject.next(event);
    } else {
      this.updateQuery.emit({[this.filter.name]: newTiles});
    }
  }

  tileOptions(tile) {
    const tileIndex = this.filter.currentVal.indexOf(tile);
  }

  getTiles($event: any) {
    if ($event.target.value == null || $event.target.value === '') {
      this.tilesToSelect = [];
    }
    if ($event.target.value.length > 0) {
      this.tilesService
      .searchTiles($event.target.value)
      .pipe(
        take(1)
      )
      .subscribe((tiles: any[]) => {
        this.tilesToSelect = JSON.parse(JSON.stringify(tiles.filter(tile => this.filter.currentVal.indexOf(tile.letter) < 0)));
        this.compareTileLists();
        // for some reason the tilesToSelect input in selectable-letter-tiles will not update until something chages in regards to the input
        // this code make sure that happens as you type
        const el = document.getElementById('tilesearch');
        el.blur();
        el.focus();
      });
    }
  }

  createTiles(tiles, pairings) {
    // tiles = Array.from(new Set(tiles.map((tile) => tile)));
    tiles.forEach( tile => {
      let matchingPhonemes: any[] = [];
      const letter = tile.substring(tile.lastIndexOf('.') + 1);
      const type = tile.split(".")[1];

      pairings.forEach( pairing => {
        if (pairing.letter === letter) {
          pairing.examples.forEach( example => {
            if (example.type === type && example.type !== 'blank') {
              matchingPhonemes.push(example);
            }
          });
        }
      });
      matchingPhonemes = matchingPhonemes.map(phoneme => {
        const p = {...{}, ...phoneme};
        p.checked = true;
        return p;
      });
      this.newTiles.push(
        {letter: letter,
        tile: tile,
        allChecked: true,
        partialChecked: false,
        examples: matchingPhonemes
        });
    });

    this.newTiles = uniqBy(this.newTiles, 'tile');

    this.utilityService
      .sortDroppedTiles(this.newTiles)
      .pipe(take(1))
      .subscribe((tiles: any[]) => {
        this.tilesUpdatedSubject.next(tiles);
        this.updateQueryEvent();
      });

  }

  clearTiles() {
    this.newTiles = [];
    this.tilesUpdatedSubject.next([]);
  }

  openTileModal(tile) {
    if (tile.examples) {
      const tileModal = this._tileModal.open(TilePairingModalComponent,
        {
          data: tile
        }
      );
      tileModal.componentInstance.removeTileEvent.pipe(takeUntil(this.unsubscribe$)).subscribe(($event) => {
        this.removeTile($event);
      });
    }
  }
}

@Component({
  selector: 'app-tile-pairing-modal',
  templateUrl: '../tile-pairing-modal/html/tile-pairing-modal.html',
  styleUrls: ['../tile-pairing-modal/tile-pairing-modal.scss']
})
export class TilePairingModalComponent {

  @Output() removeTileEvent: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _TilePairingRef: MatDialogRef<TilePairingModalComponent>) {}

    removeTile(tile) {
      this.removeTileEvent.emit(tile);
    }
}
