import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum WorkbookActivityActionTypes {
  GetWorkbookActivity = '[WorkbookActivityService] Get Course Workbook Activity',
  GetWorkbookActivitySuccess = '[WorkbookActivityService] Get Course Workbook ActivitySuccess',
  UpdateWorkbookActivity = '[WorkbookActivityService] Update Course Workbook Activity',
  UpdateWorkbookActivitySuccess = '[WorkbookActivityService] Update Course Workbook ActivitySuccess',

  SetUpdatingWorkbookActivityPending = 'Updating Workbook Activity still pending',
  SetUpdatingWorkbookActivitySuccess = 'Updating Workbook Activity is finish',

  ClearWorkbookActivity = 'Clear Workbook Activity Value'


}

export class GetWorkbookActivity implements Action {
  readonly type = WorkbookActivityActionTypes.GetWorkbookActivity;

  constructor(public payload: any) {}
}

export class GetWorkbookActivitySuccess implements Action {
  readonly type = WorkbookActivityActionTypes.GetWorkbookActivitySuccess;

  constructor(public payload: any) {}
}

export class UpdateWorkbookActivity implements Action {
  readonly type = WorkbookActivityActionTypes.UpdateWorkbookActivity;

  constructor(public payload: any) {}
}

export class UpdateWorkbookActivitySuccess implements Action {
  readonly type = WorkbookActivityActionTypes.UpdateWorkbookActivitySuccess;

  constructor(public payload: any) {}
}

export class SetUpdatingWorkbookActivityPending implements Action {
  readonly type = WorkbookActivityActionTypes.SetUpdatingWorkbookActivityPending;

  constructor(public payload: any) {}
}

export class SetUpdatingWorkbookActivitySuccess implements Action {
  readonly type = WorkbookActivityActionTypes.SetUpdatingWorkbookActivitySuccess;

  constructor(public payload: any) {}
}

export class ClearWorkbookActivity implements Action {
  readonly type = WorkbookActivityActionTypes.ClearWorkbookActivity;

  constructor() {}
}

export type WorkbookActivityActions
= UpdateWorkbookActivity
| UpdateWorkbookActivitySuccess
| GetWorkbookActivity
| GetWorkbookActivitySuccess
| SetUpdatingWorkbookActivityPending
| SetUpdatingWorkbookActivitySuccess
| ClearWorkbookActivity;
