import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "./auth.service";
import { Subject, Observable, BehaviorSubject} from 'rxjs';

@Injectable()
export class AuthGuard  {
  public restricted: any = [];
  constructor(private _auth: AuthService, private router: Router) {}
  public  = false;
  private loggedIn = new BehaviorSubject<any>(false);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    // this.restricted = route.data["restrict"];
    return this.checkLogin();
  }
  public loginStatusListener() {
    return this.loggedIn.asObservable();
  }
  public updateLoginStatus(status: boolean) {
    return this.loggedIn.next(status);
  }
  public checkLogin(): boolean {

    if (this._auth.isAuthenticated()) {
      this.loggedIn.next(true);
      return true;
    } else {
      if (window.location.hash.indexOf("access_token") >= 0) {
        this.loggedIn.next(false);
        return false;
      }

      this.router.navigate(['/login']);
      // this.loggedIn.next(true);
      this.loggedIn.next(false);
      return false;
    }
  }
}
