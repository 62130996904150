import {Injectable, OnInit} from '@angular/core';
import { Subject, Observable} from 'rxjs';
import { ApiService } from './api.service';
import {HttpClient} from '@angular/common/http';
import { API_DIR } from '../../environments/environment';
import {take} from 'rxjs/operators';

@Injectable()

export class UserService implements OnInit {

  public auth0UserInfo: any;
  public userInfo: any;

  constructor(private _api: ApiService, private http: HttpClient) {
    this.auth0UserInfo = JSON.parse(localStorage.getItem('profile'));
  }

  ngOnInit(): void {
  }

  get(userId: string) {
    if (this.userInfo) {
      return new Observable(observer => observer.next(this.userInfo));
    }

    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/user/${userId}`)
        .pipe(
          take(1)
        )
        .subscribe(
          (user: any) => {
            this.userInfo = user.data;
            observer.next(user.data);
          },
          error => {
            throw error;
          }
        );
    });
  }

  getUserInfo() {
    if (!this.auth0UserInfo) {
      this.auth0UserInfo = JSON.parse(localStorage.getItem('profile'));
    }
    return this.auth0UserInfo;
  }

  setTermsOfService(userId, auth0Id) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/user/${userId}/termsOfService`, {auth0Id})
        .subscribe(
          (user: any) => {
            this.userInfo = user.data;
            observer.next(user.data);
          },
          error => {
            throw error;
          }
        );
    });
  }
}
