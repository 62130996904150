import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum CourseActionTypes {
    GetCourse = '[CoursesService] Get Course',
    GetCourseSuccess = '[CoursesService] Get Course Success',
    GetCourseFail = '[CoursesService] Get Course Fail',

    GetAllCourse = '[CoursesService] Get All Course',
    GetAllCourseSuccess = '[CoursesService] Get All Course Success',
    GetAllCourseFail = '[CoursesService] Get All Course Fail',

    ClearCourses = '[CoursesService] Clear Courses',

    CreateCourse = '[CoursesService] Create Course',
    CreateCourseSuccess = '[CoursesService] Create Course Success',
    CreateCourseFail = '[CoursesService] Create Course Fail',

    UpdateCourse = '[CoursesService] Update Course',
    UpdateCourseSuccess = '[CoursesService] Update Course Success',
    UpdateCourseFail = '[CoursesService] Update Course Fail',

    DeleteCourse = '[CoursesService] Delete Course',
    DeleteCourseSuccess = '[CoursesService] Delete Course Success',
    DeleteCourseFail = '[CoursesService] Delete Course Fail',

    CopyCourse = '[CoursesService] Copy Course',
    CopyCourseSuccess = '[CoursesService] Copy Course Success',
    CopyCourseFail = '[CoursesService] Copy Course Fail',

    GetAllCourses = '[CoursesService] Get All Subscribed and Non-Subscribed Courses',
    GetAllCoursesSuccess = '[CoursesService] Get All Subscribed and Non-Subscribed Courses Success',
    GetAllCoursesFail = '[CoursesService] Get All Subscribed and Non-Subscribed Courses Fail',

    ReorderCourses = '[CoursesService] Reorder Course',
}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class GetCourse implements Action {
    readonly type = CourseActionTypes.GetCourse;

    constructor(public payload: any) {}
}

export class GetCourseSuccess implements Action {
    readonly type = CourseActionTypes.GetCourseSuccess;

    constructor(public payload: any) {}
}

export class GetCourseFail implements Action {
    readonly type = CourseActionTypes.GetCourseFail;

    constructor(public payload: any) {}
}

export class GetAllCourse implements Action {
    readonly type = CourseActionTypes.GetAllCourse;

    constructor(public payload: any) {}
}

export class GetAllCourseSuccess implements Action {
    readonly type = CourseActionTypes.GetAllCourseSuccess;

    constructor(public payload: any) {}
}

export class GetAllCourseFail implements Action {
    readonly type = CourseActionTypes.GetAllCourseFail;

    constructor(public payload: any) {}
}



export class CreateCourse implements Action {
    readonly type = CourseActionTypes.CreateCourse;

    constructor(public payload: any) {}
}
export class CreateCourseSuccess implements Action {
    readonly type = CourseActionTypes.CreateCourseSuccess;

    constructor(public payload: any) {}
}
export class CreateCourseFail implements Action {
    readonly type = CourseActionTypes.CreateCourseFail;

    constructor(public payload: any) {}
}

export class UpdateCourse implements Action {
    readonly type = CourseActionTypes.UpdateCourse;

    constructor(public payload: any) {}
}
export class UpdateCourseSuccess implements Action {
    readonly type = CourseActionTypes.UpdateCourseSuccess;

    constructor(public payload: any) {}
}
export class UpdateCourseFail implements Action {
    readonly type = CourseActionTypes.UpdateCourseFail;

    constructor(public payload: any) {}
}
export class DeleteCourse implements Action {
    readonly type = CourseActionTypes.DeleteCourse;

    constructor(public payload: any) {}
}
export class DeleteCourseSuccess implements Action {
    readonly type = CourseActionTypes.DeleteCourseSuccess;

    constructor(public payload: any) {}
}
export class DeleteCourseFail implements Action {
    readonly type = CourseActionTypes.DeleteCourseFail;

    constructor(public payload: any) {}
}
export class CopyCourse implements Action {
    readonly type = CourseActionTypes.CopyCourse;

    constructor(public payload: any) {}
}
export class CopyCourseSuccess implements Action {
    readonly type = CourseActionTypes.CopyCourseSuccess;

    constructor(public payload: any) {}
}
export class CopyCourseFail implements Action {
    readonly type = CourseActionTypes.CopyCourseFail;

    constructor(public payload: any) {}
}

export class ClearCourses implements Action {
    readonly type = CourseActionTypes.ClearCourses;
}

export class GetAllCourses implements Action {
    readonly type = CourseActionTypes.GetAllCourses;

    constructor(public payload: any) {}
}
export class GetAllCoursesSuccess implements Action {
    readonly type = CourseActionTypes.GetAllCoursesSuccess;

    constructor(public payload: any) {}
}
export class GetAllCoursesFail implements Action {
    readonly type = CourseActionTypes.GetAllCoursesFail;

    constructor(public payload: any) {}
}

export class ReorderCourses implements Action {
    readonly type = CourseActionTypes.ReorderCourses;

    constructor(public payload: any) {}
}


export type CoursesActions
= GetCourse
| GetCourseSuccess
| GetCourseFail
| GetAllCourse
| GetAllCourseSuccess
| GetAllCourseFail
| ClearCourses
| CreateCourse
| CreateCourseSuccess
| CreateCourseFail
| UpdateCourse
| UpdateCourseSuccess
| UpdateCourseFail
| DeleteCourse
| DeleteCourseSuccess
| DeleteCourseFail
| CopyCourse
| CopyCourseSuccess
| CopyCourseFail
| GetAllCourses
| GetAllCoursesSuccess
| GetAllCoursesFail
| ReorderCourses;
