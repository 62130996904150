import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe  implements PipeTransform {
  transform(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    if (!field) {
      return array;
    }
    array.sort((a: any, b: any) => {
      if ((field && a[field] < b[field]) || (!field && a < b)) {
        return -1;
      } else if ((field && a[field] > b[field]) || (!field && a > b)) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
