// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.activity-box {
  flex-grow: 1;
}

.mat-tab-links {
  float: left !important;
}

.tab {
  display: block;
  height: 75px;
  min-width: 175px;
  padding: 0px 10px;
  max-width: 250px;
  line-height: 75px;
  text-align: center;
  color: #4c5878 !important;
  font-weight: 500;
}
.tab.active {
  color: #FF7E0D !important;
  font-weight: bold;
}
.tab span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activity-content {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
  background: #e4e2ed;
}`, "",{"version":3,"sources":["webpack://./src/activities/activity.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,cAAA;EACA,YAAA;EACA,gBAAA;EACA,iBAAA;EACD,gBAAA;EAEC,iBAAA;EACA,kBAAA;EAEA,yBAAA;EACA,gBAAA;AADF;AAGE;EACE,yBAAA;EACA,iBAAA;AADJ;AAIE;EACE,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAFJ;;AAMA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;AAHF","sourcesContent":[":host {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.activity-box {\n  flex-grow: 1;\n}\n\n.mat-tab-links {\n  float: left !important;\n}\n\n.tab {\n  display: block;\n  height: 75px;\n  min-width: 175px;\n  padding: 0px 10px;\n\tmax-width: 250px;\n\n  line-height: 75px;\n  text-align: center;\n\n  color: #4c5878 !important;\n  font-weight: 500;\n\n  &.active {\n    color: #FF7E0D !important;\n    font-weight: bold;\n  }\n\n  span {\n    display: block;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n}\n\n.activity-content {\n  display: flex;\n  flex-direction: column;\n  overflow-y: hidden;\n  overflow-x: hidden;\n  background: #e4e2ed;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
