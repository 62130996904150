import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of,  Observable} from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { SettingsService } from '../../services/new-settings.service';
import * as actions from './settings.actions';
import { selectGetAllSettings } from '../index';

@Injectable()

export class SetttingsEffect {
    constructor(
        private actions$: Actions,
        private store$: Store,
        private settingsService: SettingsService
    ) {}
    getSetting$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SettingsActionTypes.GetSetting),
            map((action: actions.GetSetting) => action.payload),
            switchMap((payload: any) => {
                return this.settingsService.get(payload).pipe(
                    map((res: any) => new actions.GetSettingSuccess(res)),
                    catchError((error) => of(new actions.GetSettingFail(error.message)))
                );
            })
        );
    });

    getAllSettings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SettingsActionTypes.GetAllSettings),
            map((action: actions.GetAllSettings) => action.payload),
            switchMap((payload: any) => {
                return this.settingsService.getAll(payload).pipe(
                    map((res: any) => {
                        const allSettings = res.sort((a, b) => a.name.localeCompare(b.name));
                        allSettings.forEach(item => {
                            item.tilefont = item.tilefont ?? 'ff-arial';
                            item.textfont = item.textfont ?? 'ff-arial';
                        });
                        this.store$.dispatch(new actions.GetAllSettingsSuccess(allSettings));

                        if (res && res.length) {
                            const currentSetting = res.find(setting => setting.isCurrent) || res[0];
                            this.settingsService.activitySettings = currentSetting;
                            return new actions.SetCurrentSettingSuccess(currentSetting);
                        }
                    }),
                    catchError((error) => of(new actions.GetAllSettingsFail(error.message)))
                );
            })
        );
    });

    // getAllSettingsSuccess$ = createEffect(() => {
    //     return this.actions$.pipe(
    //         ofType(actions.SettingsActionTypes.GetAllSettingsSuccess),
    //         map((action: actions.GetAllSettingsSuccess) => action.payload),
    //         // switchMap((payload: any) => {
    //         //     return this.settingsService.getAll(payload).pipe(
    //         //         map((res: any) => new actions.GetAllSettingsSuccess(res)),
    //         //         catchError((error) => of(new actions.GetAllSettingsFail(error.message)))
    //         //     );
    //         // })
    //     );
    // });

    createSetting$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SettingsActionTypes.CreateSetting),
            map((action: actions.CreateSetting) => action.payload),
            switchMap((payload: any) => {
                return this.settingsService.create(payload.teacherKey, payload).pipe(
                    switchMap((res: any) => {
                        return [
                            new actions.CreateSettingSuccess(res.data),
                            new actions.SetCurrentSetting(res.data)
                        ];
                    }),
                    catchError((error) => of(new actions.CreateSettingFail(error.message)))
                );
            })
        );
    });
    updateSetting$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SettingsActionTypes.UpdateSetting),
            map((action: actions.UpdateSetting) => action.payload),
            switchMap((payload: any) => {
                return this.settingsService.update(payload._id, {...payload, isCurrent: true}).pipe(
                    map((res: any) => new actions.UpdateSettingSuccess(payload)),
                    catchError((error) => of(new actions.UpdateSettingFail(error.message)))
                );
            })
        );
    });
    updateCurrentSetting$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SettingsActionTypes.SetCurrentSetting),
            map((action: actions.SetCurrentSetting) => action.payload),
            switchMap((payload: any) => {
                return this.settingsService.updateCurrent(payload._id, payload.ownerKey).pipe(
                    map((res: any) => new actions.SetCurrentSettingSuccess(payload)),
                    catchError((error) => of(new actions.SetCurrentSettingFail(error.message)))
                );
            })
        );
    });

    copySetting$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SettingsActionTypes.CopySetting),
            map((action: actions.CopySetting) => action.payload),
            switchMap((payload: any) => {
                return this.settingsService.copy(payload.id, payload.key).pipe(
                    switchMap((res: any) => {
                        return [
                            new actions.CopySettingSuccess(res.data),
                            new actions.SetCurrentSettingSuccess(res.data)
                    ]; }),
                    catchError((error) => of(new actions.CopySettingFail(error.message)))
                );
            })
        );
    });

    updateCompoundSounds$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SettingsActionTypes.UpdateCompoundSounds),
            map((action: actions.UpdateCompoundSounds) => action.payload),
            switchMap((payload: any) => {
                return this.settingsService.updateCompoundSounds(payload.compoundSounds, payload.id).pipe(
                    map((res: any) =>
                         new actions.GetAllSettings(JSON.parse(localStorage.profile).user_metadata.uid)
                     ),
                    catchError((error) => of(new actions.UpdateCompoundSoundsFail(error.message)))
                );
            })
        );
    });



    deleteSetting$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SettingsActionTypes.DeleteSetting),
            map((action: actions.DeleteSetting) => action.payload),
            switchMap((payload: any) => {
                return this.settingsService.delete(payload).pipe(
                    switchMap((res: any) => {
                        return [
                            new actions.DeleteSettingSuccess(payload)
                        ];
                    }),
                    catchError((error) => of(new actions.DeleteSettingFail(error.message)))
                );
            })
        );
    });

}
