import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoursesComponent } from './courses.component';

const CoursesRoutes: Routes = [
  {
    path: 'courses', component: CoursesComponent, data: {}
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(CoursesRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class CoursesRoutingModule {}
