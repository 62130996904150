import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-simultaneous-login-dialog',
  templateUrl: './simultaneous-login-dialog.html'
})
export class SimultaneousLoginDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SimultaneousLoginDialogComponent>) { }

  ngOnInit() {
  }

  logout(): void {
    this.dialogRef.close();
  }
}
