import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import Auth0Lock from 'auth0-lock';
import { AppConfig as environment } from '../../environments/environment';
import { filter, take } from 'rxjs/operators';
import * as auth0 from 'auth0-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_DIR, POSTHOG_API_KEY } from '../../environments/environment';
import posthog from 'posthog-js'
import { ApiService } from './api.service';
import { ListenerService } from './listener.service';
import {Socket} from 'ngx-socket-io';
import { GetAllActivityTypes } from '../store/activity/activity.actions';
import {getDummyData} from '../store/dummy/dummy-reducer';
import { AmplitudeService } from './amplitude.service';
// import {PosthogService} from './posthog.service';

(window as any).global = window;

@Injectable()
export class AuthService {

  constructor(
    public router: Router,
    private http: HttpClient,
    private _listener: ListenerService,
    private _api: ApiService,
    private socket: Socket,
    private store: Store,
    private amplitudeService: AmplitudeService,
    // private posthogService: PosthogService,
  ) {

  }
  public accessToken: any;
  public user_id: string = null;
  lock = new Auth0Lock(
    environment.auth0.clientId,
    environment.auth0.domain,
    environment.auth0.Options
  );

  private auth0 = new auth0.WebAuth({
        domain:       environment.auth0.domain,
        clientID:     environment.auth0.clientId,
        scope: environment.auth0.Options.auth.params.scope,
        responseType: 'token id_token',
        audience: 'https://whizzimoapp.auth0.com/userinfo'
  });

  public static logout(): void {
    // Remove tokens and expiry time from localStorage
    const appversion = localStorage.getItem('appversion');
    localStorage.clear();
    localStorage.setItem('appversion', appversion);

    // posthog.reset();

    window.location.href = '/';
  }

  // For path based routing
  public handleAuthentication(): void {
    this.lock.on('authenticated', (authResult: AuthResult) => {
      this.auth0.client.userInfo(authResult.accessToken, (error, profile) => {
        if (error) {
          console.error(error);
        } else {
          this.setSession(authResult, profile);
        }
      });
      this.lock.hide();
    });
    this.lock.on('authorization_error', err => {
    });
  }

  // For login from Clever
  public handleAuthenticationClever(authResult): void {
      this.auth0.client.userInfo(authResult.accessToken, (error, profile) => {
        if (error) {
          console.error(error);
        } else {
          this.lock.hide();
          this.setSession(authResult, profile);
        }
      });
  }

  // for hash based routing
  public handleAuthenticationWithHash(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        filter((event: NavigationStart) => /access_token|id_token|error/.test(event.url)),
        take(1)
      )
      .subscribe(() => {
        this.lock.resumeAuth(
          window.location.hash,
          (err: auth0.Auth0Error, authResult: AuthResult) => {
            if (err) {
            } else {
              this.lock.getUserInfo(authResult.accessToken, (error, profile) => {
                if (error) {
                  console.error(error);
                } else {
                  this.setSession(authResult, profile);
                }
              });
            }
          }
        );
      });
  }

  private setSession(
    authResult: AuthResult,
    profile?: any
  ): void {
    const expiresAt = JSON.stringify(authResult.expiresIn * 10000 + new Date().getTime());

    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    if (profile) {
      this.http.get(`${API_DIR}/version`)
        .pipe(take(1))
        .subscribe((version: any) => {
          if (!localStorage.getItem('appversion')) {
            localStorage.setItem('appversion', version.data);
            localStorage.setItem('refresh', 'true');
            return;
          }

          if (localStorage.getItem('appversion') != version.data) {
            localStorage.setItem('appversion', version.data.toString());
            localStorage.setItem('refresh', 'true');
          }
        });
      this.http.get(API_DIR + '/account/auth0/user/' + profile.sub  )
        .subscribe(
          (result: any) => {
            localStorage.setItem('profile', JSON.stringify(result.data));
            this.user_id = result.data.user_metadata.uid;

            // this.router.navigate(['/my-curriculum']);

            const email = result?.data?.email || 'no-email@whizzimo.com';

            // this.posthogService.identify(this.user_id, email, result.data.user_metadata.name);
            this.amplitudeService.setUserId(this.user_id);
            this.amplitudeService.identifyUser({
              email,
              name: result.data.user_metadata.name,
              // appVersion: localStorage.getItem('appversion') ? localStorage.getItem('appversion') : 'n/a'
            })

            // @ts-ignore
            // window.checksumai?.init(POSTHOG_API_KEY, {api_host: 'https://app.posthog.com'});

            // @ts-ignore
            // window.checksumai?.identify(email);


            this._listener.callback('get Courses');
            this.store.dispatch(new GetAllActivityTypes(''));
            window.location.href = '/';
          },
          error => {
            console.log(error);
            // if the error status is 418 then refresh
            if (error.status === 418) {
              // refresh the page with javascript if on login, otherwise use the router
              if (window.location.hash.indexOf('login') >= 0) {
                window.location.reload();
              } else {
                this.router.navigate(['/login']);
              }

            }
          });
    }
  }

  public login(): void {
    this.lock.show();
  }
  public closepopup(): void {
    this.lock.hide();
  }

  logout() {
    AuthService.logout();
    // this.posthogService.reset();
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at') || '{}');
    return new Date().getTime() < expiresAt;
  }
}
