import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of,  Observable} from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ActivityTypeService } from '../../services/activity-type.service';
import { ActivityService } from '../../services/activity.service';

import * as actions from './activity.actions';

@Injectable()

export class ActivityEffect {
    constructor(
        private actions$: Actions,
        private activityService: ActivityService,
        private activityTypesService: ActivityTypeService
    ) {}
    BlackboardInit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.ActivityActionTypes.BlackboardInit),
            map((action: actions.BlackboardInit) => action.payload),
            switchMap((payload: any) => {
                return this.activityService.blackboardInit(payload).pipe(
                    map((res: string) => new actions.BlackboardInitSuccess(res)),
                    catchError((error) => of(new actions.BlackboardInitFail(error.message)))
                );
            })
        );
    });

    MiniTilesInit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.ActivityActionTypes.MiniTilesInit),
            map((action: actions.MiniTilesInit) => action.payload),
            switchMap((payload: any) => {
                return this.activityService.miniTilesInit(payload).pipe(
                    map((res: string) => new actions.MiniTilesInitSuccess(res)),
                    catchError((error) => of(new actions.MiniTilesInitFail(error.message)))
                );
            })
        );
    });

    WorkbookTilesInit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.ActivityActionTypes.WorkbookTilesInit),
            map((action: actions.WorkbookTilesInit) => action.payload),
            switchMap((payload: any) => {
                return this.activityService.workbookTilesInit(payload).pipe(
                    map((res: string) => new actions.WorkbookTilesInitSuccess(res)),
                    catchError((error) => of(new actions.WorkbookTilesInitFail(error.message)))
                );
            })
        );
    });

    getAllActivityTypes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.ActivityActionTypes.GetAllActivityTypes),
            map((action: actions.GetAllActivityTypes) => action.payload),
            switchMap((payload: any) => {
                return this.activityTypesService.getAll().pipe(
                    map((res: string) => new actions.GetAllActivityTypesSuccess(res))
                );
            })
        );
    });
}
