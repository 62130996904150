import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_DIR } from '../../environments/environment';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};


@Injectable()

export class PasswordresetService {
  authData: any;

  constructor(private http: HttpClient, private router: Router, private _api: ApiService) {}

  public iscleveruser(email) {

     return this.http.get(API_DIR + '/account/auth0/user/clever/' + email, httpOptions);
  
  }

  public sendpasswordreset(email) {
    return this.http.post(API_DIR + '/account/auth0/user/resetPassword/' + email, httpOptions);
  }
}
