import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_DIR } from '../../environments/environment';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()

export class PassageService {

  constructor(private http: HttpClient) {}

  getManyPassages(userId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/passage/user/${userId}`)
        .subscribe(
          (passages: any) => observer.next(passages.data),
          error => {
            throw error;
          }
        );
    });
  }

  getManyWorkbookPassages(workbookId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/workbookPassage/workbook/${workbookId}`)
        .subscribe(
          (workbookPassages: any) => observer.next(workbookPassages.data),
          error => {
            throw error;
          }
        );
    });
  }

  createPassage(newPassage: any) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/passage`, newPassage)
        .subscribe(
          (passage: any) => observer.next(passage.data),
          error => {
            throw error;
          }
        );
    });
  }

  createWorkbookPassage(newWorkbookPassage: any) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/workbookPassage`, newWorkbookPassage)
        .subscribe(
          (workbookPassage: any) => observer.next(workbookPassage.data),
          error => {
            throw error;
          }
        );
    });
  }

  updatePassage(passageId: string, updatedPassage: any) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/passage/${passageId}`, {passage: updatedPassage})
        .subscribe(
          (passage: any) => observer.next(passage.data),
          error => {
            throw error;
          }
        );
    });
  }

  updateWorkbookPassage(passageId: string, data: any) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/workbookPassage/${passageId}`, data)
        .subscribe(
          (passage: any) => observer.next(passage.data),
          error => {
            throw error;
          }
        );
    });
  }

  deletePassage(passageId: string) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/passage/${passageId}`)
        .subscribe(
          () => observer.next(),
          error => {
            throw error;
          }
        );
    });
  }

  deleteWorkbookPassage(collectionId: string, itemId: string) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/workbookPassage/${collectionId}/${itemId}`)
        .subscribe(
          () => observer.next(),
          error => {
            throw error;
          }
        );
    });
  }

  deleteManyWorkbookPassages(passageId: string) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/workbookPassage/passage/${passageId}`)
        .subscribe(
          () => observer.next(),
          error => {
            throw error;
          }
        );
    });
  }
}
