import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import { selectAllSubscriptions, selectGetPlan, selectGetPlanSuccess, selectPaymentAccount } from "../store";
import { GetAllSubscriptions, GetPaymentAccount } from "../store/account/account.actions";
import { GetAllPlans } from "../store/plan/plan.actions";

@Injectable()
export class AccountSubscriptionResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const custId = JSON.parse(localStorage.profile).user_metadata.cusID;
    return this.store.pipe(
      select(selectAllSubscriptions),
      tap(isLoaded => {
        if (!isLoaded) {
          this.store.dispatch(new GetAllSubscriptions(custId));
        }
      }),
      filter(isLoaded => !!isLoaded),
      first(),
    );
  }
}
