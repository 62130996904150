import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of,  Observable} from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { PlanService } from '../../services/plan.service';
import * as actions from './plan.actions';

@Injectable()

export class PlanEffect {
  getAllPlans$;
  getPlan$;
    constructor(
        private actions$: Actions,
        private planService: PlanService
    ) {
      this.getAllPlans$ = createEffect(() => this.actions$.pipe(
        ofType(actions.PlanActionTypes.GetAllPlans),
        map((action: actions.GetAllPlans) => action.payload),
        switchMap((payload: any) => {
          return this.planService.getAllPlans().pipe(
            map((res: any) => new actions.GetAllPlansSuccess(res)),
            catchError((error) => of(new actions.GetAllPlansFail(error.message)))
          );
        })
      ));

    this.getPlan$ = createEffect(() => this.actions$.pipe(
        ofType(actions.PlanActionTypes.GetPlan),
        map((action: actions.GetPlan) => action.payload),
        switchMap((payload: any) => {
          return this.planService.getPlan(payload).pipe(
            map((res: any) => new actions.GetPlanSuccess(res)),
            catchError((error) => of(new actions.GetPlanFail(error.message)))
          );
        })
      ));
    }
}
