import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RegistrationComponent } from "./registration.component";

const RegistrationRoutes: Routes = [
  {
    path: "registration",
    component: RegistrationComponent,
    data: {}
  }
];

@NgModule({
  imports: [RouterModule.forChild(RegistrationRoutes)],
  exports: [RouterModule]
})
export class RegistrationRoutingModule {}
