import { Component, ErrorHandler, Injectable, Injector } from '@angular/core';
// import { Component, OnInit, HostBinding, HostListener, Inject, ViewChild, ViewContainerRef, AfterViewInit, ChangeDetectorRef, AfterContentChecked, OnDestroy, ElementRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../core/services/auth.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { throwError } from 'rxjs';

// @Component({
//   selector: 'app-inactivity-modal',
//   templateUrl: './inactivity-modal/inactivity-modal.html',
//   styleUrls: ['./inactivity-modal/inactivity-modal.scss']
// })
// export class InactivityModalComponent {
//   constructor (private _auth: AuthService) {}

//   logOut () {
//     this._auth.logout();
//   }
// }

@Injectable()
export class  GlobalErrorHandlerService implements ErrorHandler {
  constructor (private injector: Injector, private dialog: MatDialog) {}

  handleError (error: HttpErrorResponse) {
    let router = this.injector.get(Router);
    switch (error?.status) {
      // case 401: // login
      //   // this.router.navigateByUrl("/login");
      //   const dialogRef = this.dialog.open(InactivityModalComponent, {
      //     // InactivityModalComponent
      //     width: '350px',
      //     panelClass: 'modal-container'
      //   });
      //   break;
      case 403: // forbidden
        console.log(`403 error`);
        break;
      case 418: // teapot
        console.log(`You're a teapot, not an alt user`);
        router.navigateByUrl("/login");

    }
    console.error('An error occurred:', error.message);
    console.error(error);
  }
}
