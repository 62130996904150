import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { tap, filter, first } from "rxjs/operators";
import { isSetCourseLoaded, checkCourseLoadedById } from "../store";
import { SetCurriculumCourseById } from "../store/set-course/set-course.actions";

@Injectable()
export class SetCourseCourseResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(isSetCourseLoaded),
      filter(isLoaded => !!isLoaded),
      first()
    );
  }
}


@Injectable()
export class SetCourseResolver  {
  constructor(private store: Store<any>) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const courseId = route.params.courseId;
    // localStorage.setItem('currentCourseId', courseId);
    return this.store.pipe(
      select(checkCourseLoadedById, {id: courseId }),
      tap(isLoaded => {
        if (!isLoaded) {
          this.store.dispatch(new SetCurriculumCourseById(courseId));
        }
      }),
      filter(isLoaded => !!isLoaded),
      first()
    );
  }
}
