import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { FeedbackService } from "../../services/feedback.service";
import * as actions from "./feedback.actions";
import { catchError, map, of, switchMap } from "rxjs";

@Injectable()
export class FeedbackEffect {
    constructor(
        private actions$: Actions,
        private store$: Store,
        private feedbackService: FeedbackService,
    ) {}

    createFeedback$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.FeedbackActionTypes.createFeedback),
            map((action: actions.CreateFeedback) => action.payload),
            switchMap((payload: any) => {
                return this.feedbackService.createFeedback(payload).pipe(
                    map((res: any) => new actions.CreateFeedbackSuccess(res)),
                    catchError((error) => of(new actions.CreateFeedbackFailure(error.message)))
                );
            })
        );
    });
}