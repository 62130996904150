// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 17px;
  max-width: 200px;
}
:host .button span {
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/core/components/header/header-activities.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,iBAAA;EAEA,gBAAA;AAJF;AAME;EACE,eAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAJJ","sourcesContent":["// @import \"../../../styles.scss\";\n// @import \"../../../variable/_variable.scss\";\n// @import \"../../../variable/_variable-v1.scss\";\n\n:host .button {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding: 0px 17px;\n\n  max-width: 200px;\n\n  span {\n    font-size: 16px;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n  }\n\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
