import {Component, Inject, OnInit} from '@angular/core';
import { PasswordresetService } from '../core/services/passwordreset.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {UtilityService} from '../core/services/utility.service';
import { AuthService } from './../core/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-passwordreset',
  templateUrl: './html/passwordreset.html',
  styleUrls: ['../assets/css/main.css',
              './passwordreset.component.scss',
              '../assets/scss/fontawesome.scss',
              '../assets/scss/brands.scss',
              '../assets/scss/regular.scss',
              '../assets/scss/solid.scss']
})


export class PasswordresetComponent implements OnInit {
  public errMsg = '';
  public email: string;
  public submitValue = 'Submit';
  public showMsg = false;
  public showcleverMsg = false;
  public showunRegMsg = false;
  public showInvalidMsg = false;
  public CleverUser = false;
  public new: any;
  public isLoading = false;
  infoDialog: any;

  constructor(private _reg: PasswordresetService, private router: Router, private dialog: MatDialog, private utilityService: UtilityService, public _auth: AuthService) {}

  keyEmail(event: any, form: any) { 
    const reg = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
  }
  ngOnInit() {
  this._auth.closepopup();
  }
  submit() {
    this.isLoading = true;
    this.submitValue = 'Please wait...';
    // clever_user
    this._reg.iscleveruser(this.email)
    .subscribe(
      (data: any) => {
        // Check if user is from Clever.
        if (data.data === true) {
          this.showunRegMsg = false;
          this.showcleverMsg = true;
          this.showInvalidMsg = false;
          this.showMsg = false;
          this.submitValue = 'Submit';
        } else if (data.data === false) {
            // Send password reset email via API.
            this._reg.sendpasswordreset(this.email)
              .subscribe(
                (data: any) => {
                if (data.data === "W") {
                  this.submitValue = 'Submit';
                  this.showMsg = true;
                  this.showcleverMsg = false;
                  this.showInvalidMsg = false;
                  this.showunRegMsg = false;
                  } else {
                  }
                },
                (error: any) => {
                  this.errMsg = "";
                  this.submitValue = 'Submit';
                }
              );
        } else {
          this.submitValue = 'Submit';
          this.showMsg = false;
          this.showcleverMsg = false;
          this.showInvalidMsg = true;
          this.showunRegMsg = false;
        }
      },
      (error: any) => {
        if (error.error.error === "Cannot read property 'app_metadata' of undefined") {
          this.showInvalidMsg = true;
          this.showMsg = false;
          this.showcleverMsg = false;
          this.showunRegMsg = false;
        } else {
          this.showInvalidMsg = false;
          this.errMsg = error.error.error;
        }
          this.submitValue = 'Submit';
    });
    this.isLoading = false;
  }
}

@Component({
  selector: 'app-registration-info-menu',
  template: `
    <div>
      <h2 mat-dialog-title>{{data.header}}</h2>
    </div>
    <mat-dialog-content class="mat-typography">
      <div style="margin: 20px" [innerHTML]="data.body"></div>
    </mat-dialog-content>
  `
})
export class PasswordresetInfoDialogComponent {
  functions;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) {}

  closeActivityDialog(activity, data) {
    this.functions.closeDialog();
  }
}
