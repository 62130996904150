import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_DIR } from '../../environments/environment';
import {Observable, of, Subject} from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CourseWorkbookService {
  courseWorkbooks: any = [];
  coursesSubject: Subject<any> = new Subject();

  constructor(private http: HttpClient) { }

  get(courseWorkbookId: string) {
    return new Observable(observer => {
      if (!courseWorkbookId) {
        return observer.next({});
      }
      this.http
        .get(`${API_DIR}/courseWorkbook/${courseWorkbookId}`)
        .pipe(
          take(1)
        )
        .subscribe(
          (courseWorkbook: any) => {
            observer.next(courseWorkbook.data);
          },
          error => {
            throw error;
          });
    });
  }

  getAll(courseId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/courseWorkbook/course/${courseId}`)
        .pipe(
          take(1)
        )
        .subscribe((courseWorkbooks: any) => {
          this.courseWorkbooks = courseWorkbooks.data;
          observer.next(this.courseWorkbooks);
        });
    });
  }
}
