import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_DIR } from '../../environments/environment';
import {Observable, Subject} from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class CourseWorkbookActivityService {
  constructor(private http: HttpClient) { }

  get(courseWorkbookId, activityTypeId) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/courseWorkbookActivity/courseWorkbook/${courseWorkbookId}/activityType/${activityTypeId}`)
        .pipe(
          take(1)
        )
        .subscribe(
          (courseWorkbookActivity: any) => {
            observer.next(courseWorkbookActivity.data);
          },
        error => {
          throw error;
          }
        );
    });
  }

  update(courseWorkbookActivity) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/courseWorkbookActivity/${courseWorkbookActivity._id}`, courseWorkbookActivity)
        .pipe(
          take(1)
        )
        .subscribe(
          (updatedCourseWorkbookActivity: any) => {
            observer.next(updatedCourseWorkbookActivity.data);
          },
          error => {
            throw error;
          }
        );
    });
  }
}
