import { UtilityService } from './../../services/utility.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of,  Observable} from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { LessonPlansService } from '../../services/lesson-plans.service';
import * as actions from './lesson.actions';

@Injectable()

export class LessonPlansEffect {
    constructor(
        private actions$: Actions,
        private store$: Store,
        private lessonPlansService: LessonPlansService,
        private router: Router,
        private utilityService: UtilityService
    ) {}
    getLessonPlan$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.LessonActionTypes.GetLesson),
            map((action: actions.GetLesson) => action.payload),
            switchMap((payload: any) => {
                return this.lessonPlansService.get(payload).pipe(
                    map((res: any) => new actions.GetLessonSuccess(res)),
                    catchError((error) => of(new actions.GetLessonFail(error.message)))
                );
            })
        );
    });

    getAllLessonPlans$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.LessonActionTypes.GetAllLessons),
            map((action: actions.GetAllLessons) => action.payload),
            switchMap((payload: any) => {
                return this.lessonPlansService.getAll(payload).pipe(
                    map((res: any) => new actions.GetAllLessonsSuccess({
                        id: payload,
                        items: res
                    })),
                    catchError((error) => of(new actions.GetAllLessonsFail(error.message)))
                );
            })
        );
    });
    getFirstLessonStep$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.LessonActionTypes.GetFirstLessonStep),
            map((action: actions.GetFirstLessonStep) => action.payload),
            switchMap((payload: any) => {
                return this.lessonPlansService.getFirstStep(payload).pipe(
                    tap((firstStep: any) => {
                        // new actions.GetFirstLessonStepSuccess(res)
                        const url = this.utilityService.getLessonPlanStepUrl(
                            firstStep.activityName,
                            firstStep.courseWorkbookId,
                            payload,
                            firstStep._id
                        );
                        this.router.navigate([url]);
                    }),
                    catchError((error) => of(new actions.GetFirstLessonStepFail(error.message)))
                );
            })
        );
    }, {dispatch: false});

    getAllLessonSteps$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.LessonActionTypes.GetAllLessonSteps),
            map((action: actions.GetAllLessonSteps) => action.payload),
            switchMap((payload: any) => {
                return this.lessonPlansService.getAllWithSteps(payload).pipe(
                    map((res: any) => new actions.GetAllLessonStepsSuccess({
                        id: payload,
                        items: res
                    })),
                    catchError((error) => of(new actions.GetAllLessonStepsFail(error.message)))
                );
            })
        );
    });

    createLessonPlans$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.LessonActionTypes.CreateLesson),
            map((action: actions.CreateLesson) => action.payload),
            switchMap((payload: any) => {
                return this.lessonPlansService.create(payload).pipe(
                    map((res: any) => new actions.CreateLessonSuccess(res)),
                    catchError((error) => of(new actions.CreateLessonFail(error.message)))
                );
            })
        );
    });
    copyLessonPlans$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.LessonActionTypes.CopyLesson),
            map((action: actions.CopyLesson) => action.payload),
            switchMap((payload: any) => {
                return this.lessonPlansService.copy(payload).pipe(
                    map((res: any) => new actions.CopyLessonSuccess(res)),
                    catchError((error) => of(new actions.CopyLessonFail(error.message)))
                );
            })
        );
    });
    updateLessonPlan$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.LessonActionTypes.UpdateLesson),
            map((action: actions.UpdateLesson) => action.payload),
            switchMap((payload: any) => {
                return this.lessonPlansService.update(payload.id, payload.lessonPlan).pipe(
                    map((res: any) => new actions.UpdateLessonSuccess(payload.lessonPlan)),
                    catchError((error) => of(new actions.UpdateLessonFail(error.message)))
                );
            })
        );
    });
    deleteLessonPlans$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.LessonActionTypes.DeleteLesson),
            map((action: actions.DeleteLesson) => action.payload),
            switchMap((payload: any) => {
                return this.lessonPlansService.delete(payload).pipe(
                    map((res: any) => new actions.DeleteLessonSuccess(payload)),
                    catchError((error) => of(new actions.DeleteLessonFail(error.message)))
                );
            })
        );
    });


}
