import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of,  Observable} from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { PassageService } from '../../services/passage.service';

import * as actions from './passage.actions';

@Injectable()

export class PassagesEffect {
    constructor(
        private actions$: Actions,
        private store$: Store,
        private passageService: PassageService
    ) {}
    getManyPassages$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PassageActionTypes.GetManyPassages),
            map((action: actions.GetManyPassages) => action.payload),
            switchMap((payload: any) => {
                return this.passageService.getManyPassages(payload).pipe(
                    map((res: any) => new actions.GetManyPassagesSuccess(res)),
                    catchError((error) => of(new actions.GetManyPassagesFail(error.message)))
                );
            })
        );
    });

    getManyWorkbookPassages$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PassageActionTypes.GetManyWorkbookPassages),
            map((action: actions.GetManyWorkbookPassages) => action.payload),
            switchMap((payload: any) => {
                return this.passageService.getManyWorkbookPassages(payload).pipe(
                    map((res: any) => new actions.GetManyWorkbookPassagesSuccess({
                        id: payload,
                        data: res
                    })),
                    catchError((error) => of(new actions.GetManyWorkbookPassagesFail(error.message)))
                );
            })
        );
    });

    createPassage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PassageActionTypes.CreatePassage),
            map((action: actions.CreatePassage) => action.payload),
            switchMap((payload: any) => {
                return this.passageService.createPassage(payload.passageData).pipe(
                    switchMap((res: any) => {
                        const workbookPassage = {
                            workbookId: payload.workbookId,
                            passageId: res._id,
                            ownerKey: payload.passageData.ownerKey
                        };
                        return [
                            new actions.CreatePassageSuccess(res),
                            new actions.CreateWorkbookPassage(workbookPassage)
                        ];
                    }),
                    catchError((error) => of(new actions.CreatePassageFail(error.message)))
                );
            })
        );
    });

    createWorkbookPassage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PassageActionTypes.CreateWorkbookPassage),
            map((action: actions.CreateWorkbookPassage) => action.payload),
            switchMap((payload: any) => {
                return this.passageService.createWorkbookPassage(payload).pipe(
                    map((res: any) => new actions.CreateWorkbookPassageSuccess(res)),
                    catchError((error) => of(new actions.CreateWorkbookPassageFail(error.message)))
                );
            })
        );
    });

    updatePassage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PassageActionTypes.UpdatePassage),
            map((action: actions.UpdatePassage) => action.payload),
            switchMap((payload: any) => {
                return this.passageService.updatePassage(payload.id, payload.Passage).pipe(
                    map((res: any) => new actions.UpdatePassageSuccess(payload)),
                    catchError((error) => of(new actions.UpdatePassageFail(error.message)))
                );
            })
        );
    });

    updateWorkbookPassage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PassageActionTypes.UpdateWorkbookPassage),
            map((action: actions.UpdateWorkbookPassage) => action.payload),
            switchMap((payload: any) => {
                return this.passageService.updateWorkbookPassage(payload.id, payload.passages).pipe(
                    switchMap((res: any) => [
                        new actions.UpdateWorkbookPassageSuccess(payload.passages),
                        // new actions.ClearWorkbookPassages({}),
                        // new actions.GetManyWorkbookPassages(payload.id)
                    ]),
                    catchError((error) => of(new actions.UpdateWorkbookPassageFail(error.message)))
                );
            })
        );
    });

    deletePassage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PassageActionTypes.DeletePassage),
            map((action: actions.DeletePassage) => action.payload),
            switchMap((payload: any) => {
                return this.passageService.deletePassage(payload).pipe(
                    map((res: any) => new actions.DeletePassageSuccess(payload)),
                    catchError((error) => of(new actions.DeletePassageFail(error.message)))
                );
            })
        );
    });

    deleteWorkbookPassage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PassageActionTypes.DeleteWorkbookPassage),
            map((action: actions.DeleteWorkbookPassage) => action.payload),
            switchMap((payload: any) => {
                return this.passageService.deleteWorkbookPassage(payload.collectionId, payload.itemId).pipe(
                    map((res: any) => new actions.DeleteWorkbookPassageSuccess(payload)),
                    catchError((error) => of(new actions.DeleteWorkbookPassageFail(error.message)))
                );
            })
        );
    });

    deleteManyWorkbookPassage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PassageActionTypes.DeleteManyWorkbookPassage),
            map((action: actions.DeleteManyWorkbookPassage) => action.payload),
            switchMap((payload: any) => {
                return this.passageService.deleteManyWorkbookPassages(payload).pipe(
                    map((res: any) => new actions.DeleteManyWorkbookPassageSuccess(res)),
                    catchError((error) => of(new actions.DeleteManyWorkbookPassageFail(error.message)))
                );
            })
        );
    });

}
