import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum SettingsActionTypes {
    GetSetting = '[SettingsService] Get Setting',
    GetSettingSuccess = '[SettingsService] Get Setting Success',
    GetSettingFail = '[SettingsService] Get Setting Fail',

    GetAllSettings = '[SettingsService] Get All Settings',
    GetAllSettingsSuccess = '[SettingsService] Get All Settings Success',
    GetAllSettingsFail = '[SettingsService] Get All Settings Fail',

    CreateSetting = '[SettingsService] Create Settings',
    CreateSettingSuccess = '[SettingsService] Create Settings Success',
    CreateSettingFail = '[SettingsService] Create Settings Fail',

    UpdateSetting = '[SettingsService] Update Settings',
    UpdateSettingSuccess = '[SettingsService] Update Settings Success',
    UpdateSettingFail = '[SettingsService] Update Settings Fail',

    SetCurrentSetting = '[SettingsService] Set Current Settings',
    SetCurrentSettingSuccess = '[SettingsService] Set Current Settings Success',
    SetCurrentSettingFail = '[SettingsService] Set Current Settings Fail',

    CopySetting = '[SettingsService] Copy Settings',
    CopySettingSuccess = '[SettingsService] Copy Settings Success',
    CopySettingFail = '[SettingsService] Copy Settings Fail',

    UpdateCompoundSounds = '[SettingsService] Update Compound Sounds',
    UpdateCompoundSoundsSuccess = '[SettingsService] Update Compound Sounds',
    UpdateCompoundSoundsFail = '[SettingsService] Update Compound Sounds',

    DeleteSetting = '[SettingsService] Delete Settings',
    DeleteSettingSuccess = '[SettingsService] Delete Settings Success',
    DeleteSettingFail = '[SettingsService] Delete Settings Fail'

}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class GetSetting implements Action {
    readonly type = SettingsActionTypes.GetSetting;

    constructor(public payload: any) {}
}
export class GetSettingSuccess implements Action {
    readonly type = SettingsActionTypes.GetSettingSuccess;

    constructor(public payload: any) {}
}
export class GetSettingFail implements Action {
    readonly type = SettingsActionTypes.GetSettingFail;

    constructor(public payload: any) {}
}

export class GetAllSettings implements Action {
    readonly type = SettingsActionTypes.GetAllSettings;

    constructor(public payload: any) {}
}

export class GetAllSettingsSuccess implements Action {
    readonly type = SettingsActionTypes.GetAllSettingsSuccess;

    constructor(public payload: any) {}
}
export class GetAllSettingsFail implements Action {
    readonly type = SettingsActionTypes.GetAllSettingsFail;

    constructor(public payload: any) {}
}
export class CreateSetting implements Action {
    readonly type = SettingsActionTypes.CreateSetting;

    constructor(public payload: any) {}
}

export class CreateSettingSuccess implements Action {
    readonly type = SettingsActionTypes.CreateSettingSuccess;

    constructor(public payload: any) {}
}
export class CreateSettingFail implements Action {
    readonly type = SettingsActionTypes.CreateSettingFail;

    constructor(public payload: any) {}
}

export class UpdateSetting implements Action {
    readonly type = SettingsActionTypes.UpdateSetting;

    constructor(public payload: any) {}
}
export class UpdateSettingSuccess implements Action {
    readonly type = SettingsActionTypes.UpdateSettingSuccess;

    constructor(public payload: any) {}
}
export class UpdateSettingFail implements Action {
    readonly type = SettingsActionTypes.UpdateSettingFail;

    constructor(public payload: any) {}
}

export class SetCurrentSetting implements Action {
    readonly type = SettingsActionTypes.SetCurrentSetting;

    constructor(public payload: any) {}
}
export class SetCurrentSettingSuccess implements Action {
    readonly type = SettingsActionTypes.SetCurrentSettingSuccess;

    constructor(public payload: any) {}
}
export class SetCurrentSettingFail implements Action {
    readonly type = SettingsActionTypes.SetCurrentSettingFail;

    constructor(public payload: any) {}
}

export class DeleteSetting implements Action {
    readonly type = SettingsActionTypes.DeleteSetting;

    constructor(public payload: any) {}
}
export class DeleteSettingSuccess implements Action {
    readonly type = SettingsActionTypes.DeleteSettingSuccess;

    constructor(public payload: any) {}
}
export class DeleteSettingFail implements Action {
    readonly type = SettingsActionTypes.DeleteSettingFail;

    constructor(public payload: any) {}
}

export class CopySetting implements Action {
    readonly type = SettingsActionTypes.CopySetting;

    constructor(public payload: any) {}
}
export class CopySettingSuccess implements Action {
    readonly type = SettingsActionTypes.CopySettingSuccess;

    constructor(public payload: any) {}
}
export class CopySettingFail implements Action {
    readonly type = SettingsActionTypes.CopySettingFail;

    constructor(public payload: any) {}
}

export class UpdateCompoundSounds implements Action {
    readonly type = SettingsActionTypes.UpdateCompoundSounds;

    constructor(public payload: any) {}
}
export class UpdateCompoundSoundsSuccess implements Action {
    readonly type = SettingsActionTypes.UpdateCompoundSoundsSuccess;

    constructor(public payload: any) {}
}
export class UpdateCompoundSoundsFail implements Action {
    readonly type = SettingsActionTypes.UpdateCompoundSoundsFail;

    constructor(public payload: any) {}
}


export type SettingsActions
= GetSetting
| GetSettingSuccess
| GetSettingFail
| GetAllSettings
| GetAllSettingsSuccess
| GetAllSettingsFail
| CreateSetting
| CreateSettingSuccess
| CreateSettingFail
| UpdateSetting
| UpdateSettingSuccess
| UpdateSettingFail
| UpdateCompoundSounds
| UpdateCompoundSoundsSuccess
| UpdateCompoundSoundsFail
| CopySetting
| CopySettingSuccess
| CopySettingFail
| SetCurrentSetting
| SetCurrentSettingSuccess
| SetCurrentSettingFail
| DeleteSetting
| DeleteSettingSuccess
| DeleteSettingFail;
