import {Component, Input, OnInit} from '@angular/core';
import { faListOl, faAlignLeft, faFileAlt } from '@fortawesome/free-solid-svg-icons';



@Component({
  selector: 'app-header-workbook-builder',
  templateUrl: './html/header-workbook-builder.html',
  styleUrls: [
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss'
  ]
})

export class HeaderWorkbookBuilderComponent implements OnInit {
  @Input() workbookId: string;
  public faListOl = faListOl;
  public faAlignLeft = faAlignLeft;
  public faFileAlt = faFileAlt;

  constructor() {}

  ngOnInit() {}

}
