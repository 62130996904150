// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      :host ::ng-deep {
        .mat-mdc-checkbox .mdc-form-field {
          width: 20px;
          height: 20px;
          position: relative;
          left: -10px;
        }
      }
    `, "",{"version":3,"sources":["webpack://./src/Settings/Account/account.component.ts"],"names":[],"mappings":";MACM;QACE;UACE,WAAW;UACX,YAAY;UACZ,kBAAkB;UAClB,WAAW;QACb;MACF","sourcesContent":["\n      :host ::ng-deep {\n        .mat-mdc-checkbox .mdc-form-field {\n          width: 20px;\n          height: 20px;\n          position: relative;\n          left: -10px;\n        }\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
