import {
  faArrowUp,
  faArrowDown,
  faSync,
  faCheck,
  faTimes,
  faCopy
} from '@fortawesome/free-solid-svg-icons';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UpdateLessonStep, CreateLessonStep, CreateLessonStepSuccess, DeleteLessonStep, DeleteLessonStepSuccess, EditGetAllLessonSteps, GetLessonStep, ClearLessonStep, UpdateLessonStepSuccess } from '../../../core/store/edit-lesson/edit-lesson.actions';
import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, OnChanges, Output, OnDestroy, ElementRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import {CourseWorkbooksService} from '../../services/course-workbooks.service';
import {map, switchMap, take, takeUntil, takeWhile} from 'rxjs/operators';
import {WorkbooksService} from '../../services/workbooks.service';
import {BehaviorSubject, combineLatest, Observable, of, Subject, Subscription} from 'rxjs';
import {UtilityService} from '../../services/utility.service';
import { _variable_images } from '../../../environments/environment';
import {SettingsService} from '../../services/new-settings.service';
import { ListenerService } from '../../services/listener.service';
import { PlanService } from '../../services/plan.service';
import {TilesService} from '../../services/word-filter-tiles.service';
import { DataService } from '../../services/data.service';
import { getCourseWorkbook, getCurrentSetting, getWorkbook, selectGetPlanData, selectGetPostResponseByWorkbookId, selectGetWorkbookActivity, selectPaymentAccount } from '../../store';
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import { GetPaymentAccount } from '../../store/account/account.actions';
import { Router } from '@angular/router';
import { UpdateWorkbookActivity } from '../../store/workbook-activity/workbook-activity.actions';
import { WordsService } from '../../services/words.service';
import { WorkbookActivityLessonStepService } from '../../services/workbook-activity-lesson-step.service';
import { CourseWorkbookEditWordsComponent } from './course-workbook-edit-words.component';
import { CourseWorkbookEditWordTilesComponent } from './course-workbook-edit-word-tiles.component';


export enum WordsUpdatingOptionEnum {
  Manual = 1,
  RemoveWords,
  ReplaceWords,
  UseWords
}

export enum TilesUpdatingOptionEnum {
  Manual = 1,
  ActiveWordsBased,
  ExactTilesFilterBased,
  TilesFromTileLayout
}


@Component({
  selector: 'app-course-workbook-edit',
  templateUrl: './html/course-workbook-edit.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host ::ng-deep {
        .mat-mdc-form-field-subscript-wrapper {
          display: none;
        }
      }
    `
  ]
})

export class CourseWorkbookEditComponent implements OnInit, OnDestroy {
  @Input() set setlessonPlanStep(lessonPlanStep: any) {
    if (lessonPlanStep) {
      this.lessonPlanStepSubject.next(lessonPlanStep);
    }
  }

  @Input() set setLessonPlanSteps(lessonPlanSteps: any) {
    if (lessonPlanSteps.length > 0) {
      // this.lessonPlanSteps = lessonPlanSteps;
      this.lessonPlanStepsSubject.next(lessonPlanSteps);
    }
  }

  @Input() set setActivityTypes(activityTypes: any) {
    if (activityTypes.length > 0) {
      this.activityTypesSubject.next(activityTypes);
    }
  }

  @Input() set setCourseWorkbooks(courseWorkbooks: any) {
    if ( courseWorkbooks !== null && courseWorkbooks.length > 0) {
      this.courseWorkbooks = courseWorkbooks;
      this.courseWorkbooksSubject.next(courseWorkbooks);
    }
  }
  @Input() displayMoreWordsText = false;
  @Input() isOpenedFromActivity = false;
  @Output() updatedLessonPlanStepsEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  public  updatedLessons: any = {};
  activeTab = 'Words';

  constructor(
    private tilesService: TilesService,
    private workbooksService: WorkbooksService,
    private settingsService: SettingsService,
    private listenerService: ListenerService,
    private planService: PlanService,
    private courseWorkbooksService: CourseWorkbooksService,
    private dataService: DataService,
    private store: Store,
    private async: AsyncPipe,
    private router: Router,
    private wordsService: WordsService,
    private utiltiyService: UtilityService,
    private workbookActivityLessonStepService: WorkbookActivityLessonStepService

    ) {
      const path = localStorage.getItem('navigatePath');
      if (path.includes('activity')) {
        this.dropdownButtonName = path.split("/")[2];
      }
      // const interval = setInterval(() => {
      //   if (this.settingsService.activitySettings) {
      //     this.activitySettings = this.settingsService.activitySettings;

      //     clearInterval(interval);
      //   }
      // }, 300);
      this.subscriptions.add(
        this.store.select(getCurrentSetting)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(setting => {
          if (!setting) {
            this.activitySettings = null;
            return;
          }
          this.activitySettings = JSON.parse(JSON.stringify(setting));
        })
      );

    const initInterval = setInterval(() => {
      if (this.lessonPlanStep$ && this.activityTypes$ && this.courseWorkbooks$) {
        clearInterval(initInterval);
        this.subscriptions.add(
          this.lessonPlanStep$.subscribe((lessonPlanStep: any) => {
            this.lessonPlanStep = JSON.parse(JSON.stringify(lessonPlanStep));

          })
        );
        this.init();
      }
    }, 100);

  }
  @ViewChild('stepNameInput') stepNameInput: ElementRef;
  @ViewChild('workbookEditTiles') workbookEditTiles: ElementRef;
  @ViewChild('workbookEditWords') workbookEditWords: CourseWorkbookEditWordsComponent;
  @ViewChild(CourseWorkbookEditWordTilesComponent) workbookEditWordsTiles: CourseWorkbookEditWordTilesComponent;


  @Input() index: number;
  @Input() courseWorkbookActivity: any;
  @Input() userSettings: any;
  @Output() changeLessonPlanSteps: EventEmitter<any> = new EventEmitter<any>();
  @Output() copyLessonPlanStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() moveEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() moveDoubleEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateCourseWorkbookActivityEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() savingEvent: EventEmitter<any> = new EventEmitter<any>();
  activityType: any;
  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;
  faSync = faSync;
  faCheck = faCheck;
  faTimes = faTimes;
  faCopy = faCopy;
  selectedCourseWorkbook: any;
  workbook: any;
  courseWorkbook: any;
  UtilityService: any = UtilityService;
  dropdownButtonName: any;
  plan: any;
  courseWorkbookChangeSubject: Subject<any> = new Subject<any>();
  changeWorkbookActivitySubject: Subject<any> = new Subject<any>();
  shuffleSubject: Subject<any> = new Subject<any>();
  updateLessonPlanStepSubject: Subject<any> = new Subject<any>();

  arrowImage: any;
  checkImage: any;
  timesImage: any;
  timeImage: any;
  arrowDownImage: any;
  arrowUpImage: any;
  arrowDoubleDownImage: any;
  arrowDoubleUpImage: any;
  copyImage: any;
  isStepAdd = false;
  selectedIndex = -1;
  public courseWorkbooks: any[] = [];
  public lessonPlanSteps: any[] = [];
  public courseWorkbookId: any = '';
  public lessonPlanStep: any = null;
  public activityTypes: any[] = [];

  public lessonPlanStepSubject: any = new BehaviorSubject<any>(this.lessonPlanStep);
  public courseWorkbooksSubject: any = new BehaviorSubject<any>(this.courseWorkbooks);
  public lessonPlanStepsSubject: any = new BehaviorSubject<any>(this.lessonPlanSteps);
  public courseWorkbookIdSubject: any = new BehaviorSubject<any>(this.courseWorkbookId);
  public activityTypesSubject: any = new BehaviorSubject<any>(this.activityTypes);

  public lessonPlanStep$: any = this.lessonPlanStepSubject.asObservable();
  public courseWorkbooks$: any = this.courseWorkbooksSubject.asObservable();
  public lessonPlanSteps$: any = this.lessonPlanStepsSubject.asObservable();
  public courseWorkbookId$: any = this.courseWorkbookIdSubject.asObservable();
  public activityTypes$: any = this.activityTypesSubject.asObservable();

  public numWords = 0;
  private subscriptions = new Subscription();
  tiles: any[];
  public activitySettings: any;
  public maxlengthName: boolean;
  private unsubscribe$: Subject<void> = new Subject();
  words: any;
  public accountSubscriptionPlanName: any = '';
  public accountSubscriptionPlanNameSubject: any = new BehaviorSubject<any>(this.accountSubscriptionPlanName);
  public accountSubscriptionPlanName$: any = this.accountSubscriptionPlanNameSubject.asObservable();

  wordNumSubject: Subject<any> = new Subject<any>();

  editWorkbookForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.maxLength(60)]),
      description: new UntypedFormControl('', [Validators.maxLength(80)])

  });
  // activityTypeId: string
  tabType: 'both' | 'onlyWords' | 'onlyTiles' = 'both';
  // modelChange;
  init() {
    this.store.pipe(
      select(selectPaymentAccount),
      tap(isLoaded => {
        if (!isLoaded) {
          const customerId = JSON.parse(localStorage.profile).user_metadata.cusID;
          this.store.dispatch(new GetPaymentAccount(customerId));
        }
      }),
      filter(isLoaded => !!isLoaded),
      first()
      ).subscribe(account => {
        if (account?.subscriptions?.data) {
          this.accountSubscriptionPlanNameSubject.next(account.subscriptions.data[0].plan?.name);
        }
      });

    this.lessonPlanStep = this.async.transform(this.lessonPlanStep$);
    let courseWorkbooks: any = this.async.transform(this.courseWorkbooks$);
    let activityTypes: any  = this.async.transform(this.activityTypes$);
    courseWorkbooks = courseWorkbooks.sort((a, b) => a.order - b.order );
    if (this.lessonPlanStep) {
      this.lessonPlanStep = JSON.parse(JSON.stringify(this.lessonPlanStep));
      this.lessonPlanStep.words = this.lessonPlanStep?.words?.filter((word) => word !== null);
      this.selectedCourseWorkbook = courseWorkbooks.find(courseWorkbook => courseWorkbook._id === this.lessonPlanStep.courseWorkbookId);
      this.courseWorkbookIdSubject.next(this.lessonPlanStep.courseWorkbookId);
      // this.activityTypeId = this.lessonPlanStep?.activityId;
    } else {
      this.courseWorkbookActivity = JSON.parse(JSON.stringify(this.courseWorkbookActivity));

      // this.courseWorkbookActivity = JSON.parse(JSON.stringify(this.courseWorkbookActivity));
      this.selectedCourseWorkbook = courseWorkbooks.find(courseWorkbook => courseWorkbook._id === this.courseWorkbookActivity.courseWorkbookId);
      // this.activityTypeId = this.courseWorkbookActivity?.activityTypeId
    }
    if (this.activityTypeId === 'lettercards') {
      this.tabType = 'onlyTiles';
      this.activeTab = 'Tiles';
    } else if (
      this.activityTypeId === 'blackboard' || this.activityTypeId === 'wordcards' || this.activityTypeId === 'timedReading' ||
      this.activityTypeId === 'flashcards'
    ) {
      this.tabType = 'onlyWords';
      this.activeTab = 'Words';
    }

    this.activityType = activityTypes.find(activityType => {
      return activityType._id === (this.lessonPlanStep
        ? this.lessonPlanStep.activityId
        : this.courseWorkbookActivity.activityTypeId);
    });

    if (this.courseWorkbookActivity && this.courseWorkbookActivity.numWords === null) {
      this.courseWorkbookActivity.numWords = 15;
    }
    if (this.lessonPlanStep && this.lessonPlanStep.numWords === null) {
      this.lessonPlanStep.numWords = 15;
      this.numWords = 15;
    }
    if (this.lessonPlanStep && this.lessonPlanStep.numWords > this.lessonPlanStep.words.length) {
      this.lessonPlanStep.numWords = this.lessonPlanStep.words.length;
      this.numWords = this.lessonPlanStep.words.length;
    }
    // if(this.lessonPlanStep && this.lessonPlanStep.numWords !== this.lessonPlanStep.words.length){
    //   this.lessonPlanStep.numWords = this.lessonPlanStep.words.length
    // }

    if (this.lessonPlanStep && (this.lessonPlanStep.tiles || this.lessonPlanStep.miniTiles)) {
      this.tiles = this.lessonPlanStep.tiles.flat();
      this.subscriptions.add(
        this.store.select(selectGetPostResponseByWorkbookId, {id: this.lessonPlanStep.workbookId})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(words => {
          this.words = words;
        })
      );
    } else {
      let workbookId = this.lessonPlanStep ? this.lessonPlanStep.workbookId : this.courseWorkbookActivity.workbookId;
      let courseWorkbookId = this.lessonPlanStep ? this.lessonPlanStep.courseWorkbookId : this.courseWorkbookActivity.courseWorkbookId;

      this.subscriptions.add(
        combineLatest([
          // this.workbooksService.get(this.courseWorkbookActivity.workbookId),
          // this.courseWorkbooksService.get(this.courseWorkbookActivity.courseWorkbookId)
          // this.store.select(getWorkbook, {id: workbookId}),
          this.store.select(getCourseWorkbook, {id: courseWorkbookId}),
          // this.store.select(selectGetPostResponseByWorkbookId, {id: workbookId})
        ])
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((courseWorkbook: any) => {
            if (!courseWorkbook) {
              this.workbook = null;
              this.courseWorkbook = null;
              return;
            }
            this.workbook = JSON.parse(JSON.stringify(courseWorkbook[0].workbook));
            this.courseWorkbook = JSON.parse(JSON.stringify(courseWorkbook[0]));
            this.selectedCourseWorkbook = this.courseWorkbook;
            this.courseWorkbookChangeSubject.next(courseWorkbook);
            if (courseWorkbook[0].fullWords) {
              this.words = JSON.parse(JSON.stringify(courseWorkbook[0].fullWords));
            }

            if (this.courseWorkbookActivity && (this.courseWorkbookActivity.tiles || this.courseWorkbookActivity.miniTiles)) {
              this.tiles = this.courseWorkbookActivity.tiles.flat();
            } else if (this.courseWorkbook && (this.courseWorkbook.tiles || this.courseWorkbook.miniTiles)) {
              this.tiles = this.courseWorkbook.tiles.flat();
            } else {
              this.tiles = this.workbook.tiles.flat();
            }
          })
      );

    }
    // this.planService.getPlan(JSON.parse(localStorage.profile).user_metadata.cusID).subscribe((plan: any) => {
    //   this.plan = plan.name;
    // });
    this.subscriptions.add(
      this.store.select(selectGetPlanData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plan: any) => {
        if (!plan) {
          this.plan = null;
          return;
        }
        this.plan = plan.name;
    })

    );

    this.lessonPlanStepSubject.next(this.lessonPlanStep);
    setTimeout(() => {
      this.courseWorkbookChangeSubject.next(this.selectedCourseWorkbook);
    }, 100);

    // if (this.lessonPlanStep) {
    //   this.fixLessonPlanStepData();

    // }
  }
  ngOnInit(): void {
    this.subscriptions.add(
      this.dataService._variable_images.subscribe(path => {
        this.arrowImage = path + 'arrow-shuffle.png';
        this.checkImage = path + 'check.png';
        this.timesImage = path + 'x.png';

        this.timeImage = path + 'x-mat-card.png';
        this.arrowDownImage = path + 'down-mat-card.png';
        this.arrowUpImage = path + 'up-mat-card.png';
        this.copyImage = path + 'duplicate-mat-card.png';
        this.arrowDoubleDownImage = path + 'down-double-mat-card.png';
        this.arrowDoubleUpImage = path + 'up-double-mat-card.png';
        })
    );


    // this.arrowImage = _variable_images + 'arrow-shuffle.png';
    // this.checkImage = _variable_images + 'check.png';
    // this.timesImage = _variable_images + 'x.png';

    // this.timeImage = _variable_images + 'x-mat-card.png';
    // this.arrowDownImage = _variable_images + 'down-mat-card.png';
    // this.arrowUpImage = _variable_images + 'up-mat-card.png';
    // this.copyImage = _variable_images + 'duplicate-mat-card.png';
  }

  // ngOnChanges(changes: any) {

  //   if (changes.lessonPlanStep) {

  //   }
  //   // if(changes.setCourseWorkbook){
  //   //   this.courseWorkbooks = changes.setCourseWorkbook.currentValue;
  //   // }
  // }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  serveCourseWorkbook($event) {
    setTimeout(() => {
      this.selectedCourseWorkbook = $event ? $event : this.selectedCourseWorkbook;
      this.courseWorkbookChangeSubject.next(this.selectedCourseWorkbook);
  
    }, 100);
}
  onkeyName(event: any): void {
    let lessonPlanStep: any = this.async.transform(this.lessonPlanStep$);
    const name = this.editWorkbookForm.get('name');
    this.maxlengthName = name.status === "INVALID"  ? true : false;
    lessonPlanStep.name = name.value;
    this.lessonPlanStepSubject.next(lessonPlanStep);
  }

  fisherYatesShuffle(array) {

    // const lessonPlanStep: any = this.async.transform(this.lessonPlanStep$);
    // const obj = JSON.parse(JSON.stringify(lessonPlanStep)) || JSON.parse(JSON.stringify(this.courseWorkbookActivity));
    const obj = JSON.parse(JSON.stringify(this.lessonPlanStep ? this.lessonPlanStep : this.courseWorkbookActivity));
    // let getRandomInt = (max: number) => {
    //   return Math.floor(Math.random() * max);
    // };
    // let newArray = [];
    // for (let i = 0; i < obj.numWords; i++) {
    //   let index = getRandomInt(obj.words.length);
    //   newArray.push(this.words[index]);
    // }
    // const arr = array.slice(0, obj.numWords);
    // const remainderArray = array.slice(obj.numWords);
    // for (let i = arr.length - 1; i > 0; i--) {
    //   const j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
    //   [arr[i], arr[j]] = [arr[j], arr[i]];
    // }

    obj.words = this.shuffle(obj.words);
    this.words = [...obj.words];
    // this.shuffleSubject.next(newArray);
    if (this.lessonPlanStep) {
      this.updateLessonPlanStep(obj);
    } else {
      this.updateCourseWorkbookActivityEvent.emit(obj);
    }
  }

  shuffleTiles() {
    const obj = JSON.parse(JSON.stringify(this.lessonPlanStep ? this.lessonPlanStep : this.courseWorkbookActivity));
    obj.tiles = this.shuffle(obj.tiles);
    this.tiles = [...obj.tiles];
    if (this.lessonPlanStep) {
      this.updateLessonPlanStep(obj);
    } else {
      this.updateCourseWorkbookActivityEvent.emit(obj);
    }
  }

  shuffle(array) {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  toggleWhizzimap() {
    let lessonPlanStep: any = this.async.transform(this.lessonPlanStep$);
    // let obj = lessonPlanStep || this.courseWorkbookActivity;

    // for some reason when in the activity this object is read only, so
    // copying to be able to update the value of ignoreWhizzimap


    if (lessonPlanStep) {
      lessonPlanStep = { ...lessonPlanStep };
      lessonPlanStep.ignoreWhizzimap = !lessonPlanStep.ignoreWhizzimap;
      this.lessonPlanStep = lessonPlanStep;
      this.updateLessonPlanStep(this.lessonPlanStep);
    } else {
      this.courseWorkbookActivity = JSON.parse(JSON.stringify(this.courseWorkbookActivity));
      this.courseWorkbookActivity.ignoreWhizzimap = !this.courseWorkbookActivity.ignoreWhizzimap;
      this.updateCourseWorkbookActivityEvent.emit(this.courseWorkbookActivity);
    }
  }

  updateLessonPlanSteps(lessonPlanStep) {
    this.changeLessonPlanSteps.emit(lessonPlanStep);
  }

  updateLessonPlanStep($event: any) {
    let lessonPlanStep: any = this.async.transform(this.lessonPlanStep$);
    let obj = this.courseWorkbookActivity || lessonPlanStep;

    // this.lessonPlanStep = $event;
    if ($event) {
      // this.lessonPlanStep = {...JSON.parse(JSON.stringify($event)), _id: this.lessonPlanStep._id};
      obj = JSON.parse(JSON.stringify($event));
      delete obj.lessonPlan;
    }

    // if (obj.numWords > obj.words.length) {
    //   for (let i = obj.words.length; i < obj.numWords; i++) {
    //     obj.words.push(this.words[i]);
    //   }
    // }

    if (obj.numWords > obj.words.length) {
      for (let i = obj.words.length; i < obj.numWords; i++) {
        obj.words.push(this.words[i]);
      }
    }

    if (this.lessonPlanStep) {
      // if (JSON.stringify($event) === JSON.stringify(lessonPlanStep)) {
      //   return;
      // }
      // this.lessonPlanStep
      // const index = this.lessonPlanSteps.findIndex(item => item._id === $event._id)
      // if (index > -1) {
      //   this.lessonPlanSteps[index] = obj;
      // }
      if (obj.activityId !== "blankTiles") {
        this.lessonPlanStepSubject.next(obj);
      }
      // let obj = {
      //   id: lessonPlanStep._id,
      //   lessonPlanStep: lessonPlanStep
      // };

      // this.updatedLessons[lessonPlanStep._id] = lessonPlanStep;
      if (obj.isNewStep) {
        delete obj.isNewStep;
      }
      this.updatedLessonPlanStepsEventEmitter.emit(obj);
      this.courseWorkbookChangeSubject.next(this.selectedCourseWorkbook);

      // this.store.dispatch(new UpdateLessonStep(obj));

      this.updateLessonPlanSteps(obj);
      // this.tiles = obj.tiles;

    } else {
      this.lessonPlanStepSubject.next(obj);
      this.updateCourseWorkbookActivityEvent.emit(obj);
    }
  }

  updateCourseWorkbookActivity($event: any) {
    this.courseWorkbookActivity = JSON.parse(JSON.stringify($event));
    this.updateCourseWorkbookActivityEvent.emit($event);
  }

  changeNumWords(isIncrease: boolean) {
      let lessonPlanStep: any = this.async.transform(this.lessonPlanStep$);
      let obj: any = {...{}, ...(lessonPlanStep || this.courseWorkbookActivity)};
      // const obj = JSON.parse(JSON.stringify(this.lessonPlanStep)) || JSON.parse(JSON.stringify(this.courseWorkbookActivity));
      // obj.numWords = obj.numWords > obj.words.length ? obj.words.length : obj.numWords;

      if ((isIncrease && obj.numWords >= 50) || (!isIncrease && obj.numWords <= 0)) {
        return;
      }
      if (isIncrease) {
        // if (obj.numWords === this.words?.length) {
        //   return;
        // }
        obj.numWords += 1;
        this.selectedCourseWorkbook.workbook.preview.some(item1 => {
          const found = obj.words.find(item2 => item2.wordid === item1.wordid);
          if (!found) {
            const dataFound = this.selectedCourseWorkbook.fullWords.find(word => word.wordid === item1.wordid);
            obj.words = [...obj.words, dataFound];
          }
          if (obj.words.length === obj.numWords) {
            return true;
          }
        });

      }

      if (!isIncrease && obj.numWords > 0) {
        obj.numWords -= 1;
        // obj.words.pop();
        obj.words = [...obj.words].splice(0, obj.words.length - 1);
      }

      this.words = [...obj.words];

      if (lessonPlanStep) {
        // this.lessonPlanStepSubject.next(obj);
        // this.numWords = obj.numWords;
        this.updateLessonPlanStep(obj);
      } else {
        // this.numWords = obj.numWords;
        // this.wordNumSubject.next(obj.numWords);
        this.updateCourseWorkbookActivityEvent.emit(obj);

        // const updated = this.updateWorkbookActivity(obj);
        // this.updateCourseWorkbookActivityEvent.emit(updated);
      }
      (this.workbookEditWordsTiles as any)?.workbookEditWords?.topTableScrollBottom();
      this.workbookEditWords?.topTableScrollBottom();
  }

  isWordsActivity(id: string) {
    let activityTypes: any = this.async.transform(this.activityTypes$);
    const activity = activityTypes.find(activityType => activityType._id === id);

    if (!activity) {
      return false;
    }

    return activity.name.toLowerCase() === 'wordcards'
      || activity.name.toLowerCase() === 'timed reading'
      || activity.name.toLowerCase() === 'flashcards'
      || activity.name.toLowerCase() === 'blackboard';
  }

  isWordsTilesActivity(id: any) {
    let activityTypes: any = this.async.transform(this.activityTypes$);

    const activity = activityTypes.find(activityType => activityType._id === id);

    if (!activity) {
      return false;
    }

    return activity.name.toLowerCase() === 'workbook tiles' || activity.name.toLowerCase() === 'spelling';
  }

  isSpellingActivity(id: any) {
    let activityTypes: any = this.async.transform(this.activityTypes$);

    const activity = activityTypes.find(activityType => activityType._id === id);

    if (!activity) {
      return false;
    }

    return activity.name.toLowerCase() === 'spelling';
  }

  isPhrasesActivity(id: any) {
    let activityTypes: any = this.async.transform(this.activityTypes$);

    const activity = activityTypes.find(activityType => activityType._id === id);

    if (!activity) {
      return false;
    }

    return activity.name === 'Sentence Parts';
  }

  isSentencesActivity(id: any) {
    let activityTypes: any = this.async.transform(this.activityTypes$);

    const activity = activityTypes.find(activityType => activityType._id === id);

    if (!activity) {
      return false;
    }

    return activity.name === 'Sentences';
  }

  isPassagesActivity(id: any) {
    let activityTypes: any = this.async.transform(this.activityTypes$);
    const activity = activityTypes.find(activityType => activityType._id === id);

    if (!activity) {
      return false;
    }

    return activity.name === 'Passages';
  }

  isFilesActivity(id: any) {
    let activityTypes: any = this.async.transform(this.activityTypes$);
    const activity = activityTypes.find(activityType => activityType._id === id);

    if (!activity) {
      return false;
    }

    return activity.name === 'PDF Viewer';
  }

  courseWorkbookChanged($event?: any) {
    let lessonPlanStep: any = JSON.parse(JSON.stringify(this.async.transform(this.lessonPlanStep$)));
    if ($event) {
      this.selectedCourseWorkbook = this.courseWorkbooks.find(courseWorkbook => courseWorkbook._id === $event);
    }
    const activityTypeId = lessonPlanStep.activityId || lessonPlanStep.activityTypeId;

    // lessonPlanStep.numWords = this.userSettings[activityTypeId]?.wordNum || 15;

    lessonPlanStep.words = this.selectedCourseWorkbook.workbook.preview.slice(0, lessonPlanStep.numWords);
    this.numWords = lessonPlanStep.words.length;

    // lessonPlanStep.tiles = this.selectedCourseWorkbook.workbook.tiles.filter(tile => tile.indexOf('+tilekey+') < 0);
    lessonPlanStep.courseWorkbookId = this.selectedCourseWorkbook._id;
    lessonPlanStep.workbookId = this.selectedCourseWorkbook.workbookId;

    // if (lessonPlanStep && lessonPlanStep.numWords > lessonPlanStep.words.length) {
    //   lessonPlanStep.numWords = lessonPlanStep.words.length;
    //   this.numWords = lessonPlanStep.words.length;
    // }


    // lessonPlanStep.words = this.selectedCourseWorkbook.fullWords
    //   .map(word => ({word: word.word, wordid: word.wordid}))
    //   .slice(0, lessonPlanStep.numWords);
    lessonPlanStep.files = [];
    lessonPlanStep.sentences = [];
    lessonPlanStep.passages = [];
    lessonPlanStep.phrases = [];

    this.lessonPlanStep = lessonPlanStep;
    this.lessonPlanStepSubject.next(lessonPlanStep);
    this.courseWorkbookChangeSubject.next(this.selectedCourseWorkbook);

    // let observable;
    // if (lessonPlanStep.words.find(word => !word?.displayWord)) {
    //   observable = this.workbookActivityLessonStepService.fixWordsData(lessonPlanStep, this.courseWorkbook)
    // } else {
    //   observable = of(null)
    // }
    // observable.subscribe(updatedWords => {
    //   if (updatedWords) {
    //     lessonPlanStep.words = updatedWords;
    //   }
    //   this.lessonPlanStepSubject.next(lessonPlanStep);
    //   this.courseWorkbookChangeSubject.next(this.selectedCourseWorkbook);

    //   const lessonPlanSteps: any = this.async.transform(this.lessonPlanSteps$);
    //   const lpIndex = lessonPlanSteps.findIndex(lps => lps._id === lessonPlanStep._id);
    //   lessonPlanSteps[lpIndex] = lessonPlanStep;
    //   this.lessonPlanStepsSubject.next(lessonPlanSteps);

    //   this.updateLessonPlanStep(lessonPlanStep);
    // })
    this.workbookActivityLessonStepService.fixActivityOrStepWords(lessonPlanStep, this.selectedCourseWorkbook)
      .pipe(
        switchMap(words => {
          lessonPlanStep.words = words;
          lessonPlanStep.numWords = words.length;
          // TODO: apply to other activity with only words tab on it.
          if (activityTypeId === 'blackboard') {
            return of(lessonPlanStep.tiles);
          } else {
            if (lessonPlanStep.tileUpdating === TilesUpdatingOptionEnum.ActiveWordsBased ||
              lessonPlanStep.tileUpdating === TilesUpdatingOptionEnum.TilesFromTileLayout) {
              lessonPlanStep.tiles = [];
            }
            return this.workbookActivityLessonStepService.fixActivityOrStepTiles(lessonPlanStep, this.selectedCourseWorkbook);
          }
        }),
        take(1)
      )
      .subscribe(tiles => {
        lessonPlanStep.tiles = tiles;
        this.lessonPlanStepSubject.next(lessonPlanStep);
        this.courseWorkbookChangeSubject.next(this.selectedCourseWorkbook);
        const lessonPlanSteps: any = this.async.transform(this.lessonPlanSteps$);
        const lpIndex = lessonPlanSteps.findIndex(lps => lps._id === lessonPlanStep._id);
        lessonPlanSteps[lpIndex] = lessonPlanStep;
        this.lessonPlanStepsSubject.next(lessonPlanSteps);

        this.updateLessonPlanStep(lessonPlanStep);
      });
  }

  updateStartingView($event) {
    let lessonPlanStep: any = this.async.transform(this.lessonPlanStep$);
    if (lessonPlanStep) {
      lessonPlanStep.selectedView = $event;
      this.updateLessonPlanStep(lessonPlanStep);
    } else {
      const obj = JSON.parse(JSON.stringify(this.courseWorkbookActivity));
      obj.selectedView = $event;
      if (JSON.stringify(obj) !== JSON.stringify(this.courseWorkbookActivity)) {
        this.updateCourseWorkbookActivityEvent.emit(obj);
      }
    }
  }

  isTilesActivity(id: any) {
    let activityTypes: any = this.async.transform(this.activityTypes$);

    const activity = activityTypes.find(activityType => activityType._id === id);

    if (!activity) {
      return false;
    }

    return activity.name.toLowerCase() === 'lettercards';

  }

  isBlankTilesActivity(id: any) {
    let activityTypes: any = this.async.transform(this.activityTypes$);

    const activity = activityTypes.find(activityType => activityType._id === id);

    if (!activity) {
      return false;
    }

    return activity.name.toLowerCase() === 'blank tiles';
  }

  isMiniTilesActivity(id: any) {
    let activityTypes: any = this.async.transform(this.activityTypes$);

    const activity = activityTypes.find(activityType => activityType._id === id);

    if (!activity) {
      return false;
    }

    return activity.name.toLowerCase() === 'mini tile board';

  }

  isNumWordsActivity(id: any) {
    let activityTypes: any = this.async.transform(this.activityTypes$);

    const activity = activityTypes.find(activityType => activityType._id === id);

    if (!activity) {
      return false;
    }

    return activity.name.toLowerCase() !== 'whiteboard'
      && activity.name.toLowerCase() !== 'lettercards'
      && activity.name.toLowerCase() !== 'pdf viewer'
      && activity.name.toLowerCase() !== 'sentence parts'
      && activity.name.toLowerCase() !== 'blank tiles'
      && activity.name.toLowerCase() !== 'sentences'
      && activity.name.toLowerCase() !== 'passages';
  }

  isShuffleWordsActivity(id: any) {
    let activityTypes: any = this.async.transform(this.activityTypes$);

    const activity = activityTypes.find(activityType => activityType._id === id);

    if (!activity) {
      return false;
    }

    return activity.name.toLowerCase() !== 'whiteboard'
      && activity.name.toLowerCase() !== 'lettercards'
      && activity.name.toLowerCase() !== 'pdf viewer'
      && activity.name.toLowerCase() !== 'sentence parts'
      && activity.name.toLowerCase() !== 'blank tiles'
      && activity.name.toLowerCase() !== 'sentences'
      && activity.name.toLowerCase() !== 'passages';
  }

  isMissingTilesActivity(id: any) {
    let activityTypes: any = this.async.transform(this.activityTypes$);

    const activity = activityTypes.find(activityType => activityType._id === id);

    if (!activity) {
      return false;
    }

    return activity.name.toLowerCase() === 'workbook tiles'
      || activity.name.toLowerCase() === 'blackboard'
      || activity.name.toLowerCase() === 'spelling';
  }

  isLinedActivity(id: any) {
    let activityTypes: any = this.async.transform(this.activityTypes$);

    const activity = activityTypes.find(activityType => activityType._id === id);

    if (!activity) {
      return false;
    }

    return activity.name.toLowerCase() === 'whiteboard';
  }

  isStartingViewActivity(id: string) {
    let activityTypes: any = this.async.transform(this.activityTypes$);
    let lessonPlanStep: any = this.async.transform(this.lessonPlanStep$);
    const activity = activityTypes.find(activityType => activityType._id === id);

    if (!activity) {
      return false;
    }

    if (!lessonPlanStep && !this.workbook) {
      return false;
    }

    return activity.name.toLowerCase() === 'flashcards';
  }

  isActivity(id: any) {
    let activityTypes: any = this.async.transform(this.activityTypes$);
    const activity = activityTypes.find(activityType => activityType._id === id);

    if (!activity) {
      return false;
    }

    return activity.name.toLowerCase() !== 'whiteboard';
  }

  copyLessonPlanStep() {
    let lessonPlanStep: any = this.async.transform(this.lessonPlanStep$);
    this.copyLessonPlanStepEvent.emit(lessonPlanStep);
  }

  move(step: any, index: number, isUp) {
    this.moveEvent.emit({step, index: index, isUp: isUp});
  }

  moveDouble(step: any, index: number, isUp) {
    this.moveDoubleEvent.emit({step, index: index, isUp: isUp});
  }

  delete(index: number) {
    this.deleteEvent.emit(index);
  }

  activityChanged($event: any) {
    let lessonPlanStep: any = JSON.parse(JSON.stringify(this.async.transform(this.lessonPlanStep$)));
    let activityTypes: any = this.async.transform(this.activityTypes$);
    const selectedActivityType = activityTypes.find(activityType => activityType._id === $event);
    lessonPlanStep.activityName = selectedActivityType.name;
    lessonPlanStep.activityId = selectedActivityType._id;
    this.activityType = selectedActivityType;

    switch (lessonPlanStep.activityId) {
      case 'blackboard':
      case 'wordcards':
      case 'timedReading':
      case 'flashcards':
        this.tabType = 'onlyWords';
        this.activeTab = 'Words';
        this.utiltiyService.setActiveLessonStepTab('Words');
        break;

      case 'lettercards':
        this.tabType = 'onlyTiles';
        this.activeTab = 'Tiles';
        this.utiltiyService.setActiveLessonStepTab('Tiles');
        break;


      case 'workbookTiles':
      case 'miniTiles':
      case 'spelling':
        this.tabType = 'both';
        this.utiltiyService.setActiveLessonStepTab(this.activeTab === 'Tiles' ? 'Tiles' : 'Words');
        break;
    }

    if (selectedActivityType.name === 'Lettercards') {
      this.lessonPlanStepSubject.next(lessonPlanStep);
      this.tiles = Object.values(this.userSettings.bank).map(row => Object.values(row)).flat();
    } else {
      this.lessonPlanStepSubject.next(lessonPlanStep);
      // this.courseWorkbookChangeSubject.next(this.selectedCourseWorkbook);
      this.updateLessonPlanStep(lessonPlanStep);
    }

    setTimeout(() => {
      this.courseWorkbookChanged();
    }, 10);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));


  }

  lessonPlanStepValueChanged($event: any, fieldName: string) {
    let lessonPlanStep: any = this.async.transform(this.lessonPlanStep$);
    lessonPlanStep[fieldName] = $event;
    this.lessonPlanStepSubject.next(lessonPlanStep);
    this.updateLessonPlanStep(lessonPlanStep);
  }
  stepAddLesson(index) {
    this.selectedIndex = index + 1;
    this.isStepAdd = true;
    if (this.isStepAdd) {
      setTimeout(() => {
        this.stepNameInput.nativeElement.focus();
      }, 100);
    }
  }
  stepAddLessonOutsideClick(index) {
    this.selectedIndex = index + 1;
    this.isStepAdd = false;
  }

  flashcardsStartingViewPredicate() {
    let lessonPlanStep: any = this.async.transform(this.lessonPlanStep$);
    return this.activityType.name === 'Flashcards'
      && (lessonPlanStep
          ? !this.selectedCourseWorkbook?.workbook.filters.isPhonetic
          : !this.workbook.filters.isPhonetic
      );
  }

  toggleAffixTiles() {
    let lessonPlanStep: any = this.async.transform(this.lessonPlanStep$);
    const obj = { ...(lessonPlanStep || this.courseWorkbookActivity) };
    obj.affixTiles = !obj.affixTiles;

    if (lessonPlanStep) {
      this.updateLessonPlanStep(lessonPlanStep);
    } else {
      this.updateCourseWorkbookActivityEvent.emit(obj);
    }
  }

  isaffixTilesActivity(id: string) {
    if (!this.activityTypes$) {
      return false;
    }
    let activityTypes: any[] = this.async.transform(this.activityTypes$);
    const activity = activityTypes.find(activityType => activityType._id === id);

    if (!activity) {
      return false;
    }

    return activity.name.toLowerCase() === 'mini tile board'
      || activity.name.toLowerCase() === 'workbook tiles'
      || activity.name.toLowerCase() === 'blackboard';
  }

  updateWorkbookActivity(obj: any) {
    let courseWorkbookActivity = JSON.parse(JSON.stringify(obj));
    if (courseWorkbookActivity.numWords > courseWorkbookActivity.words.length) {
      // for (let i = courseWorkbookActivity.words.length; i < courseWorkbookActivity.numWords; i++) {
      //   if (this.words) {
      //     courseWorkbookActivity.words.push(this.words[i]);
      //   } else {
      //     courseWorkbookActivity.words.push(this.workbook.preview[i]);
      //   }
      // }


      let words = this.words ? this.words : this.workbook.preview;
      words.some(item1 => {
        const found = courseWorkbookActivity.words.find(item2 => item2.wordid === item1.wordid);
        if (!found) {
          courseWorkbookActivity.words.push(item1);
        }
      });


    } else {
      courseWorkbookActivity.words = this.courseWorkbookActivity.words.slice(0, this.courseWorkbookActivity.numWords + 1);
    }
    this.courseWorkbookActivity = { ...courseWorkbookActivity };
    return this.courseWorkbookActivity;
  }

  updateLessonPlanStepName($event) {
    let lessonPlanStep: any = this.async.transform(this.lessonPlanStep$);
    lessonPlanStep.name = $event;
    this.lessonPlanStepSubject.next(lessonPlanStep);
    this.updateLessonPlanStep(lessonPlanStep);
  }

  resetDefaultTiles() {
    if (this.lessonPlanStep) {
      // this.lessonPlanStep = JSON.parse(JSON.stringify(this.lessonPlanStep));
      // this.lessonPlanStep.tiles = this.selectedCourseWorkbook.workbook.filters.exactTiles.map(item => item.tile);
      // this.tiles = this.lessonPlanStep.tiles;
      // // this.lessonPlanStepSubject.next(this.lessonPlanStep);
      // this.updateLessonPlanStep(this.lessonPlanStep)
    } else {
      // this.courseWorkbookActivity = JSON.parse(JSON.stringify(this.courseWorkbookActivity));
      // this.courseWorkbookActivity.tiles = this.courseWorkbook.workbook.filters.exactTiles.map(item => item.tile);
      // this.tiles = this.courseWorkbookActivity.tiles;
      // this.updateCourseWorkbookActivityEvent.emit(this.courseWorkbookActivity);
    }
  }

  // addTilesForActiveWords() {
  //   const userId = JSON.parse(localStorage.profile).user_metadata.uid;
  //   // const courseWorkbookActivity = JSON.parse(JSON.stringify(this.courseWorkbookActivity));
  //   const item = this.lessonPlanStep ? JSON.parse(JSON.stringify(this.lessonPlanStep)) : JSON.parse(JSON.stringify(this.courseWorkbookActivity))
  //   const ids = item.words.map(word => word.wordid);
  //   return this.wordsService
  //   .getTilesByWordIds(userId, ids)
  //   .pipe(
  //     take(1),
  //     map((tiles: any) => {
  //       const newTiles = [...new Set([...tiles.flat()])].filter(tile => tile);
  //       this.tiles = newTiles;
  //       return newTiles;
  //     })
  //   )
  // }

  changeWordUpdating(value) {
    let data = JSON.parse(JSON.stringify(this.lessonPlanStep ? this.lessonPlanStep : this.courseWorkbookActivity));
    const courseWorkbook = this.courseWorkbooks.find(item => item._id === data.courseWorkbookId);
    data.wordUpdating = value;
    // if (data.wordUpdating === WordsUpdatingOptionEnum.RemoveWords) {
    //   data.words = this.workbookActivityLessonStepService.applyRemoveWordsOption(data, courseWorkbook)
    // } else if (data.wordUpdating === WordsUpdatingOptionEnum.ReplaceWords) {
    //   data.words = this.workbookActivityLessonStepService.applyReplaceWordsOption(data, courseWorkbook)
    // } else if (data.wordUpdating === WordsUpdatingOptionEnum.UseWords) {
    //   data.words = this.workbookActivityLessonStepService.applyUseWordsOption(courseWorkbook)
    // }
    // data.numWords = data.words.length;
    if (data.wordUpdating === WordsUpdatingOptionEnum.UseWords) {
      data.numWords = 50;
    }

    let obs$;
    if (this.tabType === 'onlyWords' || this.tabType === 'both') {
      obs$ = this.workbookActivityLessonStepService.fixActivityOrStepWords(data, courseWorkbook);
    } else {
      obs$ = of([]);
    }

    obs$
      .pipe(
        switchMap((words: any) => {
          data.words = words;
          data.numWords = data.words.length;
          this.words = [...words];

          if ((this.tabType === 'onlyTiles' || this.tabType === 'both') && data.tileUpdating === TilesUpdatingOptionEnum.ActiveWordsBased) {
            return this.workbookActivityLessonStepService.applyActiveWordsTilesOption(data);
          } else {
            return of(Array.isArray(data.tiles) ? data.tiles : []);
          }
        }),
        take(1)
      )
      .subscribe(tiles => {
        data.tiles = tiles;
        this.tiles = tiles;
        if (this.lessonPlanStep) {
          this.updateLessonPlanStep(data);
        } else {
          this.updateCourseWorkbookActivityEvent.emit(data);
        }

      });
    //
    // if (this.lessonPlanStep) {
      // const courseWorkbook = this.courseWorkbooks.find(item => item._id === this.lessonPlanStep.courseWorkbookId)
      // this.lessonPlanStep = JSON.parse(JSON.stringify(this.lessonPlanStep));
      // this.lessonPlanStep.wordUpdating = value;

      // if (this.lessonPlanStep.wordUpdating === WordsUpdatingOptionEnum.RemoveWords) {
      //   this.lessonPlanStep.words = this.workbookActivityLessonStepService.applyRemoveWordsOption(this.lessonPlanStep, this.selectedCourseWorkbook)
      // } else if (this.lessonPlanStep.wordUpdating === WordsUpdatingOptionEnum.ReplaceWords) {
      //   this.lessonPlanStep.words = this.workbookActivityLessonStepService.applyReplaceWordsOption(this.lessonPlanStep, this.selectedCourseWorkbook)
      // } else if (this.lessonPlanStep.wordUpdating === WordsUpdatingOptionEnum.UseWords) {
      //   this.lessonPlanStep.words = this.workbookActivityLessonStepService.applyUseWordsOption(this.selectedCourseWorkbook)
      // }
      // this.lessonPlanStep.numWords = this.lessonPlanStep.words.length;
      // this.words = [...this.lessonPlanStep.words]

      // if (this.lessonPlanStep.tileUpdating === TilesUpdatingOptionEnum.ActiveWordsBased) {
      //   const item = this.lessonPlanStep ? JSON.parse(JSON.stringify(this.lessonPlanStep)) : JSON.parse(JSON.stringify(this.courseWorkbookActivity))
      //   this.workbookActivityLessonStepService.applyActiveWordsTilesOption(item)
      //     .subscribe(tiles => {
      //       this.lessonPlanStep.tiles = tiles;
      //       this.tiles = tiles;
      //       this.updateLessonPlanStep(this.lessonPlanStep);
      //     })
      //   return;
      // }
      // this.updateLessonPlanStep(this.lessonPlanStep);
    // } else {
      // this.courseWorkbookActivity = JSON.parse(JSON.stringify(this.courseWorkbookActivity));
      // this.courseWorkbookActivity.wordUpdating = value;
      // if (this.courseWorkbookActivity.wordUpdating === WordsUpdatingOptionEnum.RemoveWords) {
      //   this.courseWorkbookActivity.words = this.workbookActivityLessonStepService.applyRemoveWordsOption(this.courseWorkbookActivity, this.courseWorkbook)
      // } else if (this.courseWorkbookActivity.wordUpdating === WordsUpdatingOptionEnum.ReplaceWords) {
      //   this.courseWorkbookActivity.words = this.workbookActivityLessonStepService.applyReplaceWordsOption(this.courseWorkbookActivity, this.courseWorkbook)
      // } else if (this.courseWorkbookActivity.wordUpdating === WordsUpdatingOptionEnum.UseWords) {
      //   this.courseWorkbookActivity.words = this.workbookActivityLessonStepService.applyUseWordsOption(this.courseWorkbook)
      // }
      // this.courseWorkbookActivity.numWords = this.courseWorkbookActivity.words.length;
      // this.words = [...this.courseWorkbookActivity.words]

      // if (this.courseWorkbookActivity.tileUpdating === TilesUpdatingOptionEnum.ActiveWordsBased) {
      //   this.workbookActivityLessonStepService.applyActiveWordsTilesOption(this.courseWorkbookActivity)
      //     .subscribe(tiles => {
      //       this.courseWorkbookActivity.tiles = tiles;
      //       this.tiles = tiles;
      //       this.updateCourseWorkbookActivityEvent.emit(this.courseWorkbookActivity);
      //     })

      //   return;
      // }
      // this.updateCourseWorkbookActivityEvent.emit(this.courseWorkbookActivity);
    // }
  }

  changeTileUpdating(value) {
    if (this.lessonPlanStep) {
      // this.lessonPlanStep.tileUpdating
      this.lessonPlanStep = JSON.parse(JSON.stringify(this.lessonPlanStep));
      this.lessonPlanStep.isTilesSorted = false;
      this.lessonPlanStep.tileUpdating = value;
      if (this.lessonPlanStep.activityId === 'miniTiles') {
        this.lessonPlanStep.tiles = this.lessonPlanStep.miniTiles;
      }

      if (this.lessonPlanStep.tileUpdating === TilesUpdatingOptionEnum.ActiveWordsBased) {
        // this.addTilesForActiveWords().subscribe(tiles => {
        //   this.lessonPlanStep.tiles = tiles;
        //   this.updateLessonPlanStep(this.lessonPlanStep);
        //   return
        // })
        this.workbookActivityLessonStepService.applyActiveWordsTilesOption(this.lessonPlanStep)
          .pipe(take(1))
          .subscribe(tiles => {
            this.lessonPlanStep.tiles = tiles;
            if (this.lessonPlanStep.activityId === 'miniTiles') {
              this.lessonPlanStep.miniTiles = this.lessonPlanStep.tiles;
            }
            this.tiles = tiles;
            this.updateLessonPlanStep(this.lessonPlanStep);
          });

        return;
      } else if (this.lessonPlanStep.tileUpdating === TilesUpdatingOptionEnum.ExactTilesFilterBased) {
        // this.hasExactTilesFilter();
        // if (!(this.selectedCourseWorkbook.workbook.filters.exactTiles?.length)) {
        //   this.lessonPlanStep = JSON.parse(JSON.stringify(this.lessonPlanStep));
        //   this.lessonPlanStep.tileUpdating = TilesUpdatingOptionEnum.Manual;
        //   this.updateLessonPlanStep(this.lessonPlanStep)
        // } else {
        //   // this.resetDefaultTiles();
        //   this.lessonPlanStep = JSON.parse(JSON.stringify(this.lessonPlanStep));
        //   this.lessonPlanStep.tiles = this.selectedCourseWorkbook.workbook.filters.exactTiles.map(item => item.tile);
        //   this.tiles = this.lessonPlanStep.tiles;
        //   // this.lessonPlanStepSubject.next(this.lessonPlanStep);
        //   this.updateLessonPlanStep(this.lessonPlanStep)
        // }
        this.lessonPlanStep.tiles = this.workbookActivityLessonStepService.applyExactTilesFilterTilesOption(this.lessonPlanStep.tiles, this.courseWorkbook || this.selectedCourseWorkbook);
        if (this.lessonPlanStep.activityId === 'miniTiles') {
          this.lessonPlanStep.miniTiles = this.lessonPlanStep.tiles;
        }
      } else if (this.lessonPlanStep.tileUpdating === TilesUpdatingOptionEnum.TilesFromTileLayout) {
        this.lessonPlanStep.tiles = this.workbookActivityLessonStepService.applyTilesFromTileLayout(this.lessonPlanStep.tiles);
        if (this.lessonPlanStep.activityId === 'miniTiles') {
          this.lessonPlanStep.miniTiles = this.lessonPlanStep.tiles;
        }
      }
      this.tiles = this.lessonPlanStep.tiles;
      this.updateLessonPlanStep(this.lessonPlanStep);

    } else {
      this.courseWorkbookActivity = JSON.parse(JSON.stringify(this.courseWorkbookActivity));
      this.courseWorkbookActivity.isTilesSorted = false;
      this.courseWorkbookActivity.tileUpdating = value;

      if (this.courseWorkbookActivity.activityTypeId === 'miniTiles') {
        this.courseWorkbookActivity.tiles = this.courseWorkbookActivity.miniTiles;
      }
      if (this.courseWorkbookActivity.tileUpdating === TilesUpdatingOptionEnum.ActiveWordsBased) {
        // this.addTilesForActiveWords().subscribe(tiles => {
        //   this.courseWorkbookActivity.tiles = tiles;
        //   this.updateCourseWorkbookActivityEvent.emit(this.courseWorkbookActivity);
        // })
        this.workbookActivityLessonStepService.applyActiveWordsTilesOption(this.courseWorkbookActivity)
          .pipe(take(1))
          .subscribe(tiles => {
            this.courseWorkbookActivity.tiles = tiles;
            if (this.courseWorkbookActivity.activityTypeId === 'miniTiles') {
              this.courseWorkbookActivity.miniTiles = this.courseWorkbookActivity.tiles;
            }
            this.tiles = tiles;
            this.updateCourseWorkbookActivityEvent.emit(this.courseWorkbookActivity);
          });

        return;
      } else if (this.courseWorkbookActivity.tileUpdating === TilesUpdatingOptionEnum.ExactTilesFilterBased) {
        // this.hasExactTilesFilter();

        // if (!(this.courseWorkbook.workbook.filters.exactTiles?.length)) {
        //   this.courseWorkbookActivity.tileUpdating = TilesUpdatingOptionEnum.Manual;
        // } else {
          // this.resetDefaultTiles();
          // this.courseWorkbookActivity.tiles = this.courseWorkbook.workbook.filters.exactTiles.map(item => item.tile);
        // this.courseWorkbookActivity.tiles = this.workbookActivityLessonStepService.applyExactTilesFilterTilesOption(this.courseWorkbook)
        // if (this.activityTypeId === 'workbookTiles') {
        //   this.courseWorkbookActivity.tiles = this.workbookActivityLessonStepService.applyExactTilesFilterTilesOption(this.courseWorkbook);
        //   this.tiles = this.courseWorkbookActivity.tiles;
        // }
        this.courseWorkbookActivity.tiles = this.workbookActivityLessonStepService.applyExactTilesFilterTilesOption(this.courseWorkbookActivity.tiles, this.courseWorkbook);
        if (this.courseWorkbookActivity.activityTypeId === 'miniTiles') {
          this.courseWorkbookActivity.miniTiles = this.courseWorkbookActivity.tiles;
        }
        // }
      } else if (this.courseWorkbookActivity.tileUpdating === TilesUpdatingOptionEnum.TilesFromTileLayout) {
        this.courseWorkbookActivity.tiles = this.workbookActivityLessonStepService.applyTilesFromTileLayout(this.courseWorkbookActivity.tiles);
        if (this.courseWorkbookActivity.activityTypeId === 'miniTiles') {
          this.courseWorkbookActivity.miniTiles = this.courseWorkbookActivity.tiles;
        }
      }
      this.tiles = this.courseWorkbookActivity.tiles;
      this.updateCourseWorkbookActivityEvent.emit(this.courseWorkbookActivity);
    }


  }

  tabChange(tabName) {
    this.activeTab = tabName;
  }

  hasExactTilesFilter() {
    if (this.lessonPlanStep) {
      // if (!(this.selectedCourseWorkbook.workbook.filters.exactTiles?.length)) {
      //   this.lessonPlanStep = JSON.parse(JSON.stringify(this.lessonPlanStep));
      //   this.lessonPlanStep.tileUpdating = TilesUpdatingOptionEnum.Manual;
      //   this.updateLessonPlanStep(this.lessonPlanStep)
      // } else {
      //   this.resetDefaultTiles();
      // }
    } else {
      // if (!(this.courseWorkbook.workbook.filters.exactTiles?.length)) {
      //   this.courseWorkbookActivity = JSON.parse(JSON.stringify(this.courseWorkbookActivity));
      //   this.courseWorkbookActivity.tileUpdating = TilesUpdatingOptionEnum.Manual;
      // } else {
      //   this.resetDefaultTiles();
      // }
    }
  }

  sortTiles() {
    // (this.workbookEditWordsTiles as any)?.workbookEditTiles?.sortDroppedTiles()
    if (this.lessonPlanStep) {
      this.utiltiyService
      // TODO: check tiles property of lessonPlanStep
      .sortDroppedTiles(this.lessonPlanStep.tiles)
      .pipe(take(1))
      .subscribe((sortedTiles) => {
        this.tiles = sortedTiles;
        this.lessonPlanStep.isTilesSorted = true;
        this.lessonPlanStep.tiles = sortedTiles;
        if (this.lessonPlanStep.activityId === 'miniTiles') {
          this.lessonPlanStep.miniTiles = this.lessonPlanStep.tiles;
        }
        // this.lessonPlanStepSubject.next(this.lessonPlanStep);
        this.updateLessonPlanStep(this.lessonPlanStep);
      });
    } else {
      let tiles = this.courseWorkbookActivity.tiles;

      this.utiltiyService
      .sortDroppedTiles(tiles)
      .pipe(take(1))
      .subscribe((sortedTiles) => {
        this.courseWorkbookActivity = JSON.parse(JSON.stringify(this.courseWorkbookActivity));
        this.courseWorkbookActivity.isTilesSorted = true;
        this.courseWorkbookActivity.tiles = sortedTiles;
        this.tiles = sortedTiles;
        if (this.courseWorkbookActivity.activityTypeId === 'miniTiles') {
          this.courseWorkbookActivity.miniTiles = this.courseWorkbookActivity.tiles;
        }
        this.updateCourseWorkbookActivityEvent.emit(this.courseWorkbookActivity);
      });
    }
  }

  // processTopWords(item, courseWorkbook) {
  //   if (!(item && courseWorkbook)) {
  //     return;
  //   }
  //   if (item.wordUpdating === WordsUpdatingOptionEnum.RemoveWords) {
  //     // let words = item.words.slice(0, item.numWords);
  //     // words = words.filter(item1 => {
  //     //   return courseWorkbook.fullWords.find(item2 => item1.wordid === item2.wordid);
  //     // });
  //     // if (words.length !== item.words.length) {
  //     //   item.words = words;
  //     // }
  //     item.words = this.workbookActivityLessonStepService.applyRemoveWordsOption(item, courseWorkbook)
  //     // item.words = words;
  //     // item.numWords = words.length;
  //   } else if (item.wordUpdating === WordsUpdatingOptionEnum.ReplaceWords) {
  //     // let originalWords = item.words.slice(0, item.numWords);
  //     // let filteredWords = originalWords.filter(item1 => {
  //     //   return courseWorkbook.fullWords.find(item2 => item1.wordid === item2.wordid);
  //     // });
  //     // let newWords = [...filteredWords];
  //     // if (originalWords.length !== filteredWords.length) {
  //     //   courseWorkbook.fullWords.some(item1 => {
  //     //     const isExist = filteredWords.find(item2 => item1.wordid === item2.wordid);
  //     //     if (!isExist) {
  //     //       newWords.push(item1);
  //     //       if (newWords.length === originalWords.length) {

  //     //         return true;
  //     //       }
  //     //     }
  //     //   });
  //     //   item.words = newWords;
  //       // item.numWords = newWords.length;
  //     // }
  //     item.words = this.workbookActivityLessonStepService.applyReplaceWordsOption(item, courseWorkbook)

  //   } else if (item.wordUpdating === WordsUpdatingOptionEnum.UseWords) {
  //       // item.words = courseWorkbook.fullWords;
  //     item.words = this.workbookActivityLessonStepService.applyUseWordsOption(courseWorkbook)

  //   }

  //   if (item.words.find(word => !word?.displayWord)) {
  //     // item.words.forEach((word, index) => {
  //     //   const found = courseWorkbook.fullWords.find(item => item.wordid === word.wordid)
  //     //   if (found) {
  //     //     item.words[index] = found;
  //     //   }
  //     // })
  //     item.words = this.workbookActivityLessonStepService.fixWordsData(item, courseWorkbook)

  //   }

  //   return item.words;

  // }


  // fixLessonPlanStepData() {


  //   combineLatest([
  //     this.lessonPlanStep$,
  //     this.store.select(getCurrentSetting),
  //     this.courseWorkbooks$
  //   ])
  //   .pipe(
  //     takeWhile(
  //       ([lessonPlanStep, setting, courseWorkbooks]: any[]) => {
  //         return !(setting && courseWorkbooks) && !(lessonPlanStep?.wordUpdating && lessonPlanStep?.tileUpdating) &&
  //         (!lessonPlanStep?.tiles?.length && lessonPlanStep?.tileUpdating !== TilesUpdatingOptionEnum.Manual)
  //       }, true)
  //   )
  //   .subscribe(
  //     ([lessonPlanStep, setting, courseWorkbooks]: any[]) => {
  //       this.lessonPlanStep = JSON.parse(JSON.stringify(this.lessonPlanStep));

  //       const courseWorkbook = this.courseWorkbooks.find(item => item._id === this.lessonPlanStep.courseWorkbookId)

  //       if (!(lessonPlanStep && setting && courseWorkbooks)) {
  //         return;
  //       }
  //       if (!lessonPlanStep?.wordUpdating || !lessonPlanStep?.tileUpdating) {
  //         lessonPlanStep.wordUpdating = setting.wordUpdating || WordsUpdatingOptionEnum.Manual
  //         lessonPlanStep.tileUpdating = setting.tileUpdating || TilesUpdatingOptionEnum.Manual
  //         if ((lessonPlanStep.tileUpdating === TilesUpdatingOptionEnum.ExactTilesFilterBased && !courseWorkbook.workbook.filters.exactTiles?.length) ||
  //         !lessonPlanStep.words?.length) {
  //           // lessonPlanStep.tiles = this.getAllTilesFromSetting(setting.bank)
  //           lessonPlanStep.tiles = this.workbookActivityLessonStepService.getAllTilesFromSetting()
  //           lessonPlanStep.tileUpdating === TilesUpdatingOptionEnum.Manual
  //         }

  //         else if (lessonPlanStep.tileUpdating === TilesUpdatingOptionEnum.Manual) {
  //           lessonPlanStep.tiles = this.workbookActivityLessonStepService.getAllTilesFromSetting()
  //           // lessonPlanStep.tiles = this.getAllTilesFromSetting(setting.bank)
  //         }
  //       }

  //       this.lessonPlanStep.words = this.processTopWords(this.lessonPlanStep, courseWorkbook);
  //       this.lessonPlanStep.numWords = this.lessonPlanStep.words.length;


  //       if (this.lessonPlanStep.tileUpdating === TilesUpdatingOptionEnum.ActiveWordsBased) {
  //         // this.addTilesForActiveWords().subscribe(tiles => {
  //         //   this.lessonPlanStep.tiles = tiles;
  //         // })
  //         const item = this.lessonPlanStep ? JSON.parse(JSON.stringify(this.lessonPlanStep)) : JSON.parse(JSON.stringify(this.courseWorkbookActivity))
  //         this.workbookActivityLessonStepService.applyActiveWordsTilesOption(item)
  //           .subscribe(tiles => {
  //             this.lessonPlanStep.tiles = tiles;
  //           })
  //       } else if (this.lessonPlanStep.tileUpdating === TilesUpdatingOptionEnum.ExactTilesFilterBased) {
  //         // this.hasExactTilesFilter();
  //         if (!(courseWorkbook.workbook.filters.exactTiles?.length)) {
  //           this.lessonPlanStep.tileUpdating = TilesUpdatingOptionEnum.Manual;
  //         } else {
  //           // this.resetDefaultTiles();
  //           this.lessonPlanStep.tiles = this.courseWorkbook.workbook.filters.exactTiles.map(item => item.tile);
  //           this.tiles = this.lessonPlanStep.tiles;
  //         }
  //       }
  //     }
  //   )
  //   // this.lessonPlanStepSubject.next()
  //   // this.lessonPlanStep$
  //   //   .subscribe(res => {})
  // }

  // getAllTilesFromSetting(tiles) {
  //   let result = [];
  //   for (const [row, rowValues] of Object.entries(tiles)) {
  //     for (const [col, colValue] of Object.entries(rowValues)) {
  //       result.push(colValue)

  //     }
  //   }
  //   return [...new Set(result)];
  // }

  removeAllWords() {
    this.words = [];

    if (this.lessonPlanStep) {
      // if (this.lessonPlanStep.tileUpdating === TilesUpdatingOptionEnum.ExactTilesFilterBased) {
      //   (this.workbookEditWordsTiles as any).workbookEditTiles.clearTiles();
      // }
      this.lessonPlanStep = JSON.parse(JSON.stringify(this.lessonPlanStep));
      this.lessonPlanStep.words = this.words;
      this.lessonPlanStep.numWords = this.lessonPlanStep.words.length;
      this.updateLessonPlanStep(this.lessonPlanStep);
    } else {
      this.courseWorkbookActivity = JSON.parse(JSON.stringify(this.courseWorkbookActivity));
      this.courseWorkbookActivity.words = this.words;
      this.courseWorkbookActivity.numWords = this.courseWorkbookActivity.words.length;

      this.updateCourseWorkbookActivityEvent.emit(this.courseWorkbookActivity);

    }

  }

  removeAllTiles() {
    this.tiles = [];
    if (this.lessonPlanStep) {
      this.lessonPlanStep = JSON.parse(JSON.stringify(this.lessonPlanStep));
      this.lessonPlanStep.tiles = this.tiles;
      if (this.lessonPlanStep.activityId === 'miniTiles') {
        this.lessonPlanStep.miniTiles = this.lessonPlanStep.tiles;
      }
      this.updateLessonPlanStep(this.lessonPlanStep);
    } else {
      this.courseWorkbookActivity = JSON.parse(JSON.stringify(this.courseWorkbookActivity));
      this.courseWorkbookActivity.tiles = this.tiles;
      if (this.courseWorkbookActivity.activityTypeId === 'miniTiles') {
        this.courseWorkbookActivity.miniTiles = this.courseWorkbookActivity.tiles;
      }
      this.updateCourseWorkbookActivityEvent.emit(this.courseWorkbookActivity);
    }
  }

  get activityTypeId() {
    return this.lessonPlanStep?.activityId || this.courseWorkbookActivity?.activityTypeId;
  }
}
