import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-course-list-dialog',
  templateUrl: './html/course-list-dialog.html',
  styleUrls: [
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss',
  ]
})

export class CourseListDialogComponent implements OnInit {
  activityType: string;
  notAllowedAcitivitesSight: string[];
  acitivtySwitch: boolean;
  constructor(
    public dialogRef: MatDialogRef<CourseListDialogComponent>,
    public route: ActivatedRoute,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilityService: UtilityService
  ) {
    // override the route reuse strategy
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit(): void {
    this.notAllowedAcitivitesSight = ['workbook-tiles', 'mini-tiles', 'lettercards'];
    const urlArray = document.URL.split('/');
    this.data.courseWorkbooksList = this.data.courseWorkbooksList.map(cw => {
      cw.link = `//activity/${urlArray[urlArray.length - 2]}/${cw._id}`;
      return cw;
    });
    this.activityType = urlArray[urlArray.length - 2];
    // this.checkActivity();
  }

  navigate(link: string, isPhoenetic, courseWorkbook) {
    if (this.checkActivity(courseWorkbook)) {
      if (isPhoenetic || !this.notAllowedAcitivitesSight.includes(this.activityType)) {
        if (!courseWorkbook.nonsense || this.activityType !== 'spelling') {
          this.router.navigate([link]);
          this.dialogRef.close();
        }
      }
    }
  }

  checkActivity(courseWorkbook) {
    const isActive = this.utilityService.isCourseWorkbookActive(courseWorkbook);
    if (isActive) {
      if (this.activityType === 'spelling' && courseWorkbook.workbook.nonsense) { return false; } else if (this.activityType === 'passages' && !courseWorkbook.passages.length) {
        return false;
      } else if (this.activityType === 'sentences' && !courseWorkbook.sentences.length) {
        return false;
      } else if (this.activityType === 'phrases' && !courseWorkbook.phrases.length) {
        return false;
      } else if (this.activityType === 'pdf-viewer' && !courseWorkbook.files.length) {
        return false;
      } else {
        return true;
      }
    }

  }

  onCancel() {
    this.dialogRef.close();
  }
}
