import { Injectable } from '@angular/core';
import { API_DIR } from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {take} from "rxjs/operators";

@Injectable()

export class ActivityTypeService {
  activities: any[] = [];

  constructor(private http: HttpClient) {}

  getAll() {
    return new Observable(observer => {
      if (this.activities.length > 0) {
        return observer.next(this.activities);
      }

      this.http
        .get(`${API_DIR}/activityType/all`)
        .pipe(
          take(1)
        )
        .subscribe(
          (activities: any) => {
            this.activities = activities.data;
            observer.next(this.activities);
          },
          error => {
            throw error;
          });
    });
  }
}
