// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage-course-modalbox .manage-container .mat-form-field .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

:host ::ng-deep .mat-button-disabled {
  cursor: not-allowed;
  background-color: white;
}
:host ::ng-deep .mat-button-disabled mdc-button__label {
  color: rgba(13, 2, 35, 0.24) !important;
}
:host ::ng-deep .mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}

.error-message {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  text-align: left;
  margin-left: 13px;
}`, "",{"version":3,"sources":["webpack://./src/core/components/manage-course-dialog/manage-course-dialog.component.scss"],"names":[],"mappings":"AAGY;EACI,4BAAA;AAFhB;;AAOA;EACI,4BAAA;AAJJ;;AAQI;EACI,mBAAA;EACA,uBAAA;AALR;AAMQ;EACI,uCAAA;AAJZ;AAOI;EACI,cAAA;AALR;;AASA;EACI,aAAA;EACA,gBAAA;EAEA,eAAA;EACA,gBAAA;EACA,iBAAA;AAPJ","sourcesContent":[" .manage-course-modalbox{\n    .manage-container {\n        .mat-form-field {\n            .mat-form-field-wrapper{\n                padding-bottom: 0 !important;\n            }\n        }\n    }\n}\n.mat-form-field-wrapper {\n    padding-bottom: 0 !important;\n}\n\n:host ::ng-deep {\n    .mat-button-disabled {\n        cursor: not-allowed;\n        background-color: white;\n        mdc-button__label {\n            color: rgba(13, 2, 35, 0.24) !important;\n        }\n    }\n    .mat-form-field-invalid .mat-form-field-outline-thick {\n        color: #f44336;\n    }\n}\n\n.error-message {\n    margin-top: 0;\n    margin-bottom: 0;\n    // color: rgb(255, 138, 101);\n    font-size: 12px;\n    text-align: left;\n    margin-left: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
