import { NgModule } from "@angular/core";
import { CoreModule } from "./../core/core.module";
import { WordlistRoutingModule } from "./wordlist-routing.module";
import { WordlistService } from "../core/services/wordlist.service";
import { WordlistComponent } from "./wordlist.component";
import { WordsComponent } from './Words/words.component';
import { SentencesComponent } from './Sentences/sentences.component';
import { FilesComponent } from './Files/files.component';

import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    WordlistComponent,
    FilesComponent,
    SentencesComponent,
    WordsComponent

  ],
  imports: [CoreModule, WordlistRoutingModule, FormsModule],
  providers: [WordlistService]
})
export class WordlistModule {}
