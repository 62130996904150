import { Store } from '@ngrx/store';
import { Component, Inject, Input, OnInit, OnDestroy } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FilesService} from '../../../../services/files.service';
import {switchMap, take, takeUntil} from 'rxjs/operators';
import { GetFile } from '../../../../store/file/file.actions';
import { selectGetFile } from '../../../../store';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-view-pdf',
  templateUrl: './html/view-pdf.html',
})
export class ViewPDFComponent implements OnInit, OnDestroy {
  pdfFile: any;
  loadingFile = true;
  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private filesService: FilesService,
    private store: Store
  ) {}

  ngOnInit() {
    this.getPDFFile(this.data.source);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  getPDFFile(fileID: string) {
    this.loadingFile = true;
    // this.filesService
    //   .getFile(fileID)
    //   .pipe(
    //     take(1)
    //   )
    //   .subscribe((file: any) => {
    //     this.pdfFile = file;
    //   });
    this.store.dispatch(new GetFile(fileID));
    this.store.select(selectGetFile)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((file: any) => {
        if (!file) {
          this.pdfFile = null;
          return;
        }
        this.pdfFile = file;
        this.loadingFile = false;
    });
  }  
}
