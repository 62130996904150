import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum SetCourseActionTypes {
    SetCurriculumCourse = '[CurriculumCompoment] Set Curriculum Course',
    SetCurriculumCourseSuccess = '[CurriculumCompoment] Set Curriculum Course Success',
    SetCurriculumCourseFail = '[CurriculumCompoment] Set Curriculum Course Fail',
    ResetCurriculumCourse = '[CurriculumCompoment] Reset Curriculum Course Data',

    SetCurriculumCourseById = '[CurriculumCompoment] Set Curriculum Course by ID',
    SetCurriculumCourseByIdSuccess = '[CurriculumCompoment] Set Curriculum Course by ID Success',

}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class SetCurriculumCourse implements Action {
    readonly type = SetCourseActionTypes.SetCurriculumCourse;
    constructor(public payload: any) {}
}

export class SetCurriculumCourseSuccess implements Action {
    readonly type = SetCourseActionTypes.SetCurriculumCourseSuccess;
    constructor(public payload: any) {}
}

export class SetCurriculumCourseFail implements Action {
    readonly type = SetCourseActionTypes.SetCurriculumCourseFail;
    constructor(public payload: string) {}
}

export class ResetCurriculumCourse implements Action {
    readonly type = SetCourseActionTypes.ResetCurriculumCourse;
    constructor() {}
}

export class SetCurriculumCourseById implements Action {
    readonly type = SetCourseActionTypes.SetCurriculumCourseById;
    constructor(public payload: any) {}
}

export class SetCurriculumCourseByIdSuccess implements Action {
    readonly type = SetCourseActionTypes.SetCurriculumCourseByIdSuccess;
    constructor(public payload: any) {}
}

export type SetCourseActions
= SetCurriculumCourse
| SetCurriculumCourseSuccess
| SetCurriculumCourseFail
| ResetCurriculumCourse
| SetCurriculumCourseById
| SetCurriculumCourseByIdSuccess;
