import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of,  Observable} from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AccountService } from '../../services/account.service';
import * as actions from './account.actions';

@Injectable()

export class AccountEffect {
    constructor(
        private actions$: Actions,
        private accountService: AccountService
    ) {}
    cancelScheduledSubscription$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.AccountActionTypes.CancelScheduledSubscription),
            map((action: actions.CancelScheduledSubscription) => action.payload),
            mergeMap((payload: any) => {
                return this.accountService.cancelScheduledSubscription(payload).pipe(
                    map((res: any) => new actions.CancelScheduledSubscriptionSuccess(payload)),
                    catchError((error) => of(new actions.CancelScheduledSubscriptionFail(error.message)))
                );
            })
        );
    });

    cancelSubscription$ = createEffect(() => {
        return this.actions$.pipe(
        ofType(actions.AccountActionTypes.CancelSubscription),
        map((action: actions.CancelSubscription) => action.payload),
        switchMap((payload: any) => {
            return this.accountService.cancelSubscription(payload).pipe(
                map((res: any) => new actions.CancelSubscriptionSuccess(res)),
                catchError((error) => of(new actions.CancelSubscriptionFail(error.message)))
            );
        })
    ); });

    addCreditCard$ = createEffect(() => {
        return this.actions$.pipe(
        ofType(actions.AccountActionTypes.AddCreditCard),
        map((action: actions.AddCreditCard) => action.payload),
        switchMap((payload: any) => {
            return this.accountService.addCreditCard(payload.card, payload.id).pipe(
                map((res: any) => new actions.AddCreditCardSuccess(res)),
                catchError((error) => of(new actions.AddCreditCardFail(error.message)))
            );
        })
    ); });

    deleteCreditCard$ = createEffect(() => {
        return this.actions$.pipe(
        ofType(actions.AccountActionTypes.DeleteCreditCard),
        map((action: actions.DeleteCreditCard) => action.payload),
        switchMap((payload: any) => {
            return this.accountService.deleteCreditCard(payload.cusId, payload.id).pipe(
                map((res: any) => new actions.DeleteCreditCardSuccess(res)),
                catchError((error) => of(new actions.DeleteCreditCardFail(error.message)))
            );
        })
    ); });

    getPaymentAccount$ = createEffect(() => {
        return this.actions$.pipe(
        ofType(actions.AccountActionTypes.GetPaymentAccount),
        map((action: actions.GetPaymentAccount) => action.payload),
        switchMap((payload: any) => {
            return this.accountService.getPaymentAccount(payload).pipe(
                map((res: any) => new actions.GetPaymentAccountSuccess(res)),
                catchError((error) => of(new actions.GetPaymentAccountFail(error.message)))
            );
        })
    ); });

    getPromoCode$ = createEffect(() => {
        return this.actions$.pipe(
        ofType(actions.AccountActionTypes.GetPromoCode),
        map((action: actions.GetPromoCode) => action.payload),
        switchMap((payload: any) => {
            return this.accountService.getPromoCode(payload).pipe(
                map((res: any) => new actions.GetPromoCodeSuccess(res)),
                catchError((error) => of(new actions.GetPromoCodeFail(error.message)))
            );
        })
    ); });

    DeleteCustomerPromo$ = createEffect(() => {
        return this.actions$.pipe(
        ofType(actions.AccountActionTypes.DeleteCustomerPromo),
        map((action: actions.DeleteCustomerPromo) => action.payload),
        switchMap((payload: any) => {
            return this.accountService.deleteCustomerPromo(payload).pipe(
                map((res: any) => new actions.DeleteCustomerPromoSuccess(res)),
                catchError((error) => of(new actions.DeleteCustomerPromoFail(error.message)))
            );
        })
    ); });

    DeleteSubscriptionPromo$ = createEffect(() => {
        return this.actions$.pipe(
        ofType(actions.AccountActionTypes.DeleteSubscriptionPromo),
        map((action: actions.GetPromoCode) => action.payload),
        switchMap((payload: any) => {
            return this.accountService.deleteSubscriptionPromo(payload).pipe(
                map((res: any) => new actions.DeleteSubscriptionPromoSuccess(res)),
                catchError((error) => of(new actions.DeleteSubscriptionPromoFail(error.message)))
            );
        })
    ); });

    ChangePlan$ = createEffect(() => {
        return this.actions$.pipe(
        ofType(actions.AccountActionTypes.ChangePlan),
        map((action: actions.ChangePlan) => action.payload),
        switchMap((payload: any) => {
            return this.accountService.changePlan(payload).pipe(
                map((res: any) => new actions.ChangePlanSuccess(res)),
                catchError((error) => of(new actions.ChangePlanFail(error.message)))
            );
        })
    ); });

    ScheduleNewPlan$ = createEffect(() => {
        return this.actions$.pipe(
        ofType(actions.AccountActionTypes.ScheduleNewPlan),
        map((action: actions.ScheduleNewPlan) => action.payload),
        switchMap((payload: any) => {
            return this.accountService.scheduleNewPlan(payload.plan, payload.date).pipe(
                map((res: any) => new actions.ScheduleNewPlanSuccess(res)),
                catchError((error) => of(new actions.ScheduleNewPlanFail(error.message)))
            );
        })
    ); });

    CancelRenewal$ = createEffect(() => {
        return this.actions$.pipe(
        ofType(actions.AccountActionTypes.CancelRenewal),
        map((action: actions.CancelRenewal) => action.payload),
        switchMap((payload: any) => {
            return this.accountService.cancelRenewal(payload).pipe(
                map((res: any) => new actions.CancelRenewalSuccess(res)),
                catchError((error) => of(new actions.CancelRenewalFail(error.message)))
            );
        })
    ); });

    GetAllSubscriptions$ = createEffect(() => {
        return this.actions$.pipe(
        ofType(actions.AccountActionTypes.GetAllSubscriptions),
        map((action: actions.GetAllSubscriptions) => action.payload),
        switchMap((payload: any) => {
            return this.accountService.getAllSubscriptions(payload).pipe(
                map((res: any) => new actions.GetAllSubscriptionsSuccess(res)),
                catchError((error) => of(new actions.GetAllSubscriptionsFail(error.message)))
            );
        })
    ); });

    ResendEmailVerification$ = createEffect(() => {
        return this.actions$.pipe(
        ofType(actions.AccountActionTypes.ResendEmailVerification),
        map((action: actions.ResendEmailVerification) => action.payload),
        switchMap((payload: any) => {
            console.log('payload recieved', payload);
            return this.accountService.resendEmailVerification(payload).pipe(
                map((res: any) => new actions.ResendEmailVerificationSuccess(res)),
                catchError((error) => of(new actions.ResendEmailVerificationFail(error.message)))
            );
        })
    ); });




}
