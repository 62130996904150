import { Store } from '@ngrx/store';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, AfterViewInit, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CourseService } from '../../services/courses.service';
import { combineLatest, forkJoin, Subject, Subscription } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { CourseWorkbooksService } from '../../services/course-workbooks.service';
import { ActivityTypeService } from '../../services/activity-type.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { WorkbookMenuComponent } from './workbooks.component';
import { faEdit, faTrash, faCopy } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityService } from '../../services/activity.service';
import { ListenerService } from '../../services/listener.service';
import {WorkbooksService} from '../../services/workbooks.service';
import { getWorkbook, getSelectedWorkbook, selectActivityTypes, selectCurrentTheme, selectCourseWorkbookState, getCourseWorkbook } from '../../store';
import { GetWorkbook } from '../../store/workbook/workbook.actions';
import { ActivityHeaderService } from '../../services/activity-header.service';
@Component({
  selector: 'app-course-workbooks-element',
  templateUrl: './html/course-workbooks.html',
  styleUrls: [
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss'

  ]
})

export class CourseWorkBooksComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @Input() courses: any[] = [];
  @Input() currentCourse: any;
  @Input() courseWorkbooks: any[] = [];
  private courseMenuOpen = false;
  @Output() toggleCourseMenuEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deleteCourseWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() deleteWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() editWorkbook: EventEmitter<any> = new EventEmitter();
  activities: any[] = [];
  activityDialog: any;
  currentWorkbook: any;

  constructor(
    private _api: ApiService,
    private dialog: MatDialog,
    private activityTypeService: ActivityTypeService,
    private activityService: ActivityService,
    private store: Store
  ) { }

  ngOnInit() {
    this.activityTypeService
      .getAll()
      .pipe(
        take(1)
      )
      .subscribe(activities => {
        // @ts-ignore
        this.activities = activities.map(activity => {
          if (activity.name.toLowerCase() === 'blackboard') {
            activity.url = '//activity/blackboard/';
          } else if (activity.name.toLowerCase() === 'workbook tiles') {
            activity.url = '//activity/workbook-tiles/';
          }

          return activity;
        });
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  openDialog(currentCourseWorkbook): void {
    this.currentWorkbook = currentCourseWorkbook;
    // this.store.dispatch(new GetWorkbook({workbookId:  currentCourseWorkbook.workbookId || currentCourseWorkbook.workbook.workbookId}));
    this.activityDialog = this.dialog.open(CourseWorkbookMenuComponent, {
      data: {
        workbook: currentCourseWorkbook,
        activities: this.activities,
        isHomePage: false
      },
      width: '350px'
    });

    this.activityDialog.componentInstance.functions = {
      closeDialog: (activity) => this.activityDialog.close()
    };
  }

  openCourseWorkbookSettingsDialog(currentWorkbook): void {
    const dialogref = this.dialog.open(CourseWorkbookSettingsMenuComponent, {
      data: { workbook: currentWorkbook },
      width: '350px'
    });

    dialogref.componentInstance.functions = {
      deleteCourseWorkbook: (courseWorkbookId) => {
        dialogref.close();
        this.deleteCourseWorkbook.emit(courseWorkbookId);
      },
      deleteWorkbook: (workbookId) => {
        dialogref.close();
        this.deleteWorkbook.emit(workbookId);
      },
      editWorkbook: (workbookId) => {
        dialogref.close();
        this.editWorkbook.emit(workbookId);
      }
    };
  }
  openCourseSelection() {
    this.courseMenuOpen = !this.courseMenuOpen;
    this.toggleCourseMenuEvent.emit(this.courseMenuOpen);
  }
}

@Component({
  selector: 'app-course-dialog',
  templateUrl: './html/course-dialog.html'
})
export class CourseDialogComponent {
  constructor() { }
}

@Component({
  selector: 'app-course-workbook-menu',
  templateUrl: './html/course-workbook-menu.html'
})
export class CourseWorkbookMenuComponent implements OnDestroy, AfterViewInit {
  functions;
  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';
  selectedPlan: string;
  workbook: any;
  courseWorkbookId: any;
  activities: any;
  private unsubscribe$: Subject<void> = new Subject();
  activityType: string;
  isDarkMode: boolean;
  courseWorkbook: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private listener: ListenerService,
    private workbooksService: WorkbooksService,
    private store: Store,
    private elementRef: ElementRef,
    private activityHeaderService: ActivityHeaderService
  ) {
    let workbookId = data.workbookId || data.workbook.workbookId;
    const courseWorkbookId = data._id;

    // this.listener.getPlan().subscribe((res: string) => {
    //   this.selectedPlan = res.toLowerCase();
    // });

    // this.workbooksService
    //   .get(data.workbookId || data.workbook.workbookId)
    //   .subscribe((result: any) => {
    //     this.workbook = result;
    //   });
      // setTimeout(() => {
      //   this.store.select(getSelectedWorkbook)
      //   .pipe(takeUntil(this.unsubscribe$))
      //   .subscribe((result: any) => {
      //     this.workbook = JSON.parse(JSON.stringify(result));
      //   });

      // }, 300);
      this.store.select(getCourseWorkbook, { id: courseWorkbookId})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(courseWorkbook => {
          this.courseWorkbook = courseWorkbook;
          if (this.courseWorkbook.workbook[0]) {
            this.courseWorkbook.workbook = this.courseWorkbook.workbook[0];
          }
        });

      combineLatest([
        this.store.select(getWorkbook, { id: workbookId }),
        this.listener.getPlan()
      ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([workbook, plan]) => {
        if (!plan) {
          return;
        }
        this.selectedPlan = plan.toLowerCase();
        this.workbook = JSON.parse(JSON.stringify(workbook));
        if (this.selectedPlan === 'staff' || this.selectedPlan === 'superhero plus') {
          this.workbook = this.data.workbook;
          if (this.workbook[0]) {
            this.workbook = this.workbook[0];
          }
        }
        if (!this.workbook.filters) {
          this.workbook = this.workbook.workbook;
        }
      });

      this.store.select(selectActivityTypes)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(activities => {
        if (!activities) {
          this.activities = [];
          return;
        }
        // @ts-ignore
        this.activities = JSON.parse(JSON.stringify(activities)).map(activity => {
          if (activity.name.toLowerCase() === 'blackboard') {
            activity.url = '//activity/blackboard/';
          } else if (activity.name.toLowerCase() === 'workbook tiles') {
            activity.url = '//activity/workbook-tiles/';
          }

          return activity;
        });
      });

      const splitedUrl = this.router.url.split('/');
      this.activityType = splitedUrl[2];

      this.store.select(selectCurrentTheme)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(theme => {
        if (theme === 'theme-dark') {
          this.isDarkMode = true;
        } else {
          this.isDarkMode = false;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    const activeEl = this.elementRef.nativeElement.querySelector(".selected");
    if (activeEl) {
      activeEl.scrollIntoView();
    }
  }

  closeActivityDialog(activity, data, e) {
    e.stopPropagation();
    if ((!this.workbook.filters.isPhonetic && (activity.name === 'Workbook Tiles' || activity.name === 'Lettercards' || activity.name.toLowerCase().indexOf('mini tile') >= 0)) ||
    (
      (activity.name === 'Sentences' && (this.data.sentences.length === 0 || this.data.sentences[0]?.numSentence === 0))
      || (activity.name === 'Passages' && (this.data.passages.length === 0 || this.data.passages[0]?.numPassage === 0))
      || (activity.name === 'Sentence Parts' && (this.data.phrases.length === 0 || this.data.phrases[0]?.numPhrase === 0))
      || (activity.name === 'PDF Viewer' && (this.data.files.length === 0 || this.data.files[0]?.numFile === 0))
      || (activity.name === 'Spelling'
        && (this.workbook.filters.isNonsense === undefined || this.workbook.filters.isNonsense.includes('nonsense') || this.workbook.filters.isNonsense.length === 0)))) {
      return;
    }

    if ((activity.name === 'Sentences' && (this.selectedPlan === 'Demo' || this.selectedPlan === 'Hero'))
    || (activity.name === 'Passages' && (this.selectedPlan === 'Demo' || this.selectedPlan === 'Hero'))
    || (activity.name === 'Sentence Parts' && (this.selectedPlan === 'Demo' || this.selectedPlan === 'Hero'))
    || (activity.name === 'PDF Viewer' && (this.selectedPlan === 'Demo' || this.selectedPlan === 'Hero'))
    || (activity.name === 'Mini Tile Board' && (this.selectedPlan === 'Demo' || this.selectedPlan === 'Hero'))
    || (activity.name === 'Whiteboard' && (this.selectedPlan === 'Demo' || this.selectedPlan === 'Hero'))
    || (activity.name === 'Blank Tiles' && (this.selectedPlan === 'Demo' || this.selectedPlan === 'Hero'))
    || (activity.name === 'Workbook Tiles' && (this.selectedPlan === 'Demo'))
    || (activity.name === 'Lettercards' && (this.selectedPlan === 'Demo'))
    || (activity.name === 'Timed Reading' && (this.selectedPlan === 'Demo'))
    || (activity.name === 'Workbook Tiles' && (this.selectedPlan === 'Demo'))
    || (activity.name === 'Lettercards' && (this.selectedPlan === 'Demo'))
    || (activity.name === 'Spelling' && (this.selectedPlan === 'Demo'))) {
      return;
    }
    let courseWorkbookId;
    courseWorkbookId = this.data._id;

    const routeUrl = activity.url + courseWorkbookId;
    this.activityHeaderService.killEvents(null, true);
    this.activityHeaderService.drawablesSubject.next([]);
    this.router.navigate([routeUrl]);
    this.functions.closeDialog(activity);
  }
  onCancel() {
    this.functions.closeDialog();
  }
  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }

  checkSelectedItem(activity) {
    return activity.url.includes(this.activityType);
  }
}

@Component({
  selector: 'app-course-workbook-settings-menu',
  templateUrl: './html/course-workbook-settings-menu.html'
})
export class CourseWorkbookSettingsMenuComponent {
  public faEdit = faEdit;
  public faTrash = faTrash;
  public faCopy = faCopy;
  functions;
  @Output() deleteCourseWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() deleteWorkbook: EventEmitter<any> = new EventEmitter();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  deleteCourseWorkbookEvent(courseWorkbookId) {
    return this.functions.deleteCourseWorkbook(courseWorkbookId);
  }

  deleteWorkbookEvent(workbookId) {
    return this.functions.deleteWorkbook(workbookId);
  }

  editWorkbookEvent(workbookId) {
    return this.functions.editWorkbook(workbookId);
  }
}
