import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import * as ActivityActions from "../store/activity/activity.actions";
import * as reducers from "../store";
import { SetUpdatingWorkbookActivityPending } from '../store/workbook-activity/workbook-activity.actions';
import { SetActivityOrLessonFixStatus } from '../store/user-interface/user-interface.actions';
import { ActivityHeaderService } from '../../core/services/activity-header.service';

@Injectable()
export class CanvasResolver  {
  constructor(
        private store: Store<any>,
        private activityHeaderService: ActivityHeaderService,
    ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
   
   
    return this.activityHeaderService.clearCanvases(this.activityHeaderService.tool);
  }
}
