import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum DummyActionTypes {
    GetDummyData = '[DummyService] Get Dummy Data',
    GetDummyDataSuccess = '[DummyService] Get Dummy Data Success',
    GetDummyDataFail = '[DummyService] Get Dummy Data Fail'
}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class GetDummyData implements Action {
    readonly type = DummyActionTypes.GetDummyData;

    constructor(public payload: any) {}
}

export class GetDummyDataSuccess implements Action {
    readonly type = DummyActionTypes.GetDummyDataSuccess;

    constructor(public payload: string) {}
}
export class GetDummyDataFail implements Action {
    readonly type = DummyActionTypes.GetDummyDataFail;

    constructor(public payload: string) {}
}


export type DummyDataActions
= GetDummyData
| GetDummyDataSuccess
| GetDummyDataFail;
