import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: "app-add-workbooks-element",
  templateUrl: "./html/workbooks.html",
  styleUrls: [
    "../../../assets/css/main.css",
    "../../../assets/scss/fontawesome.scss",
    "../../../assets/scss/brands.scss",
    "../../../assets/scss/regular.scss",
    "../../../assets/scss/solid.scss"
  ]
})
export class AddWorkBooksComponent implements OnInit {
  filteredWorkbooks = null;
  workbooks = null;
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {}

  openDialog(event?): void {
    const dialogRef = this.dialog.open(AddWorkbookDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  search(event) {}
}

@Component({
  selector: "app-add-workbook-dialog",
  templateUrl: "./html/add-workbook-dialog.html"
})

export class AddWorkbookDialogComponent {

  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';
  
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public dialogRef: MatDialogRef<AddWorkbookDialogComponent>,
  ) {


  }

  public getFromLibraries(libraryType) {
    this.router.navigate([`library`, { libraryType: libraryType } ]);
  }

  public makeWorkbookTog(userKnowsWords: boolean) {
    // if(value){
    //   this.router.navigate(["/wordlist/Words", {filter: 'words'}]);

    // } else {
    //   this.router.navigate(["/wordlist/Words", {filter: 'tiles'}]);
    // }
    this.router.navigate([`workbook-builder/new`, {userKnows : userKnowsWords}]);
  }

  onCancel() {
    this.dialogRef.close();
  }
  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }
}
