import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum SentenceActionTypes {

    
    GetManySentences = '[SentenceService] Get Many Sentences',
    GetManySentencesSuccess = '[SentenceService] Get Many Sentences Success',
    GetManySentencesFail = '[SentenceService] Get Many Sentences Fail',

    ClearWorkbookSentences = '[SentenceService] Clear Workbook Sentences',
    ClearWorkbookSentencesSuccess = '[SentenceService] Clear Workbook Sentences Success',
    ClearWorkbookSentencesFail = '[SentenceService] Clear Workbook Sentences Fail',

    GetManyWorkbookSentences = '[SentenceService] Get Many Workbook Sentences',
    GetManyWorkbookSentencesSuccess = '[SentenceService] Get Many Workbook Sentences Success',
    GetManyWorkbookSentencesFail = '[SentenceService] Get Many Workbook Sentences Fail',

    CreateSentence = '[SentenceService] Create Sentence',
    CreateSentenceSuccess = '[SentenceService] Create Sentence Success',
    CreateSentenceFail = '[SentenceService] Create Sentence Fail',

    CreateWorkbookSentence = '[SentenceService] Create Workbook Sentence',
    CreateWorkbookSentenceSuccess = '[SentenceService] Create Workbook Sentence Success',
    CreateWorkbookSentenceFail = '[SentenceService] Create Workbook Sentence Fail',

    UpdateSentence = '[SentenceService] Update Sentence',
    UpdateSentenceSuccess = '[SentenceService] Update Sentence Success',
    UpdateSentenceFail = '[SentenceService] Update Sentence Fail',

    UpdateWorkbookSentence = '[SentenceService] Update Workbook Sentence',
    UpdateWorkbookSentenceSuccess = '[SentenceService] Update Workbook Sentence Success',
    UpdateWorkbookSentenceFail = '[SentenceService] Update Workbook Sentence Fail',

    DeleteSentence = '[SentenceService] Delete Sentence',
    DeleteSentenceSuccess = '[SentenceService] Delete Sentence Success',
    DeleteSentenceFail = '[SentenceService] Delete Sentence Fail',

    DeleteWorkbookSentence = '[SentenceService] Delete Workbook Sentence',
    DeleteWorkbookSentenceSuccess = '[SentenceService] Delete Workbook Sentence Success',
    DeleteWorkbookSentenceFail = '[SentenceService] Delete Workbook Sentence Fail',

    DeleteManyWorkbookSentence = '[SentenceService] Delete Many Workbook Sentence',
    DeleteManyWorkbookSentenceSuccess = '[SentenceService] Delete Many Workbook Sentence Success',
    DeleteManyWorkbookSentenceFail = '[SentenceService] Delete Many Workbook Sentence Fail',

}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class GetManySentences implements Action {
    readonly type = SentenceActionTypes.GetManySentences;

    constructor(public payload: any) {}
}
export class GetManySentencesSuccess implements Action {
    readonly type = SentenceActionTypes.GetManySentencesSuccess;

    constructor(public payload: any) {}
}
export class GetManySentencesFail implements Action {
    readonly type = SentenceActionTypes.GetManySentencesFail;

    constructor(public payload: any) {}
}

export class ClearWorkbookSentences implements Action {
    readonly type = SentenceActionTypes.ClearWorkbookSentences;

    constructor(public payload: any) {}
}
export class ClearWorkbookSentencesSuccess implements Action {
    readonly type = SentenceActionTypes.ClearWorkbookSentencesSuccess;

    constructor(public payload: any) {}
}
export class ClearWorkbookSentencesFail implements Action {
    readonly type = SentenceActionTypes.ClearWorkbookSentencesFail;

    constructor(public payload: any) {}
}


export class GetManyWorkbookSentences implements Action {
    readonly type = SentenceActionTypes.GetManyWorkbookSentences;

    constructor(public payload: any) {}
}
export class GetManyWorkbookSentencesSuccess implements Action {
    readonly type = SentenceActionTypes.GetManyWorkbookSentencesSuccess;

    constructor(public payload: any) {}
}
export class GetManyWorkbookSentencesFail implements Action {
    readonly type = SentenceActionTypes.GetManyWorkbookSentencesFail;

    constructor(public payload: any) {}
}

export class CreateSentence implements Action {
    readonly type = SentenceActionTypes.CreateSentence;

    constructor(public payload: any) {}
}
export class CreateSentenceSuccess implements Action {
    readonly type = SentenceActionTypes.CreateSentenceSuccess;

    constructor(public payload: any) {}
}
export class CreateSentenceFail implements Action {
    readonly type = SentenceActionTypes.CreateSentenceFail;

    constructor(public payload: any) {}
}

export class CreateWorkbookSentence implements Action {
    readonly type = SentenceActionTypes.CreateWorkbookSentence;

    constructor(public payload: any) {}
}
export class CreateWorkbookSentenceSuccess implements Action {
    readonly type = SentenceActionTypes.CreateWorkbookSentenceSuccess;

    constructor(public payload: any) {}
}
export class CreateWorkbookSentenceFail implements Action {
    readonly type = SentenceActionTypes.CreateWorkbookSentenceFail;

    constructor(public payload: any) {}
}

export class UpdateSentence implements Action {
    readonly type = SentenceActionTypes.UpdateSentence;

    constructor(public payload: any) {}
}
export class UpdateSentenceSuccess implements Action {
    readonly type = SentenceActionTypes.UpdateSentenceSuccess;

    constructor(public payload: any) {}
}
export class UpdateSentenceFail implements Action {
    readonly type = SentenceActionTypes.UpdateSentenceFail;

    constructor(public payload: any) {}
}

export class UpdateWorkbookSentence implements Action {
    readonly type = SentenceActionTypes.UpdateWorkbookSentence;

    constructor(public payload: any) {}
}
export class UpdateWorkbookSentenceSuccess implements Action {
    readonly type = SentenceActionTypes.UpdateWorkbookSentenceSuccess;

    constructor(public payload: any) {}
}
export class UpdateWorkbookSentenceFail implements Action {
    readonly type = SentenceActionTypes.UpdateWorkbookSentenceFail;

    constructor(public payload: any) {}
}

export class DeleteSentence implements Action {
    readonly type = SentenceActionTypes.DeleteSentence;

    constructor(public payload: any) {}
}
export class DeleteSentenceSuccess implements Action {
    readonly type = SentenceActionTypes.DeleteSentenceSuccess;

    constructor(public payload: any) {}
}
export class DeleteSentenceFail implements Action {
    readonly type = SentenceActionTypes.DeleteSentenceFail;

    constructor(public payload: any) {}
}

export class DeleteWorkbookSentence implements Action {
    readonly type = SentenceActionTypes.DeleteWorkbookSentence;

    constructor(public payload: any) {}
}
export class DeleteWorkbookSentenceSuccess implements Action {
    readonly type = SentenceActionTypes.DeleteWorkbookSentenceSuccess;

    constructor(public payload: any) {}
}
export class DeleteWorkbookSentenceFail implements Action {
    readonly type = SentenceActionTypes.DeleteWorkbookSentenceFail;

    constructor(public payload: any) {}
}

export class DeleteManyWorkbookSentence implements Action {
    readonly type = SentenceActionTypes.DeleteManyWorkbookSentence;

    constructor(public payload: any) {}
}
export class DeleteManyWorkbookSentenceSuccess implements Action {
    readonly type = SentenceActionTypes.DeleteManyWorkbookSentenceSuccess;

    constructor(public payload: any) {}
}
export class DeleteManyWorkbookSentenceFail implements Action {
    readonly type = SentenceActionTypes.DeleteManyWorkbookSentenceFail;

    constructor(public payload: any) {}
}

export type SentenceActions
= ClearWorkbookSentences
| ClearWorkbookSentencesSuccess
| ClearWorkbookSentencesFail
| GetManySentences
| GetManySentencesFail
| GetManySentencesSuccess
| GetManyWorkbookSentences
| GetManyWorkbookSentencesFail
| GetManyWorkbookSentencesSuccess
| CreateSentence
| CreateSentenceFail
| CreateSentenceSuccess
| CreateWorkbookSentence
| CreateWorkbookSentenceFail
| CreateWorkbookSentenceSuccess
| UpdateSentence
| UpdateSentenceSuccess
| UpdateSentenceFail
| UpdateWorkbookSentence
| UpdateWorkbookSentenceFail
| UpdateWorkbookSentenceSuccess
| DeleteSentence
| DeleteSentenceFail
| DeleteSentenceSuccess
| DeleteWorkbookSentence
| DeleteWorkbookSentenceFail
| DeleteWorkbookSentenceSuccess;
