import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_DIR} from '../../environments/environment';

@Injectable()
export class TermsOfServiceService {
  constructor(private http: HttpClient) {
  }
  get() {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/termsOfService`)
        .subscribe(
          (termsOfService: any) => {
            observer.next(termsOfService.data);
          },
          error => {
            throw error;
          }
        );
    });
  }
}
