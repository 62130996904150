import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  ViewChild,
  ViewChildren,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { combineAll } from 'rxjs/operators';
import { UtilityService } from '../../../../services/utility.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionType } from '@ngrx/store';
import { AsyncPipe } from '@angular/common';
@Component({
  selector: 'app-selectable-letter-tiles',
  templateUrl: './html/selectable-letter-tiles.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    './word-filter.scss'
  ],
  styles: [
    `.is-inactive-menu {
      opacity: 0.16;
    }`
  ]
})

export class SelectableLetterTilesComponent implements OnInit, OnChanges, OnDestroy {
  public checkbox: any;
  @Input() set setTiles(tiles: any) {
    if (tiles.length) {
      if (this.tilesSubject) {
        this.tilesSubject.next(tiles);
      } else {
        this.tilesSubject = new BehaviorSubject<any>(tiles);
        this.tiles$ = this.tilesSubject.asObservable();
      }
    }
  }
  @Input() isPopup = false;
  @Input() tilesSettings: any;
  @Input() class: string;
  @Input() userSettings: any;
  @Input() tilesUpdatedObservable: Observable<any>;
  @Output() clickAction: EventEmitter<any> = new EventEmitter();
  @Output() updateQuery: EventEmitter<any> = new EventEmitter();
  openIndex = -1;
  @ViewChildren('selectionList') selectionLists: any;
  private subscriptions = new Subscription();

  tilesSubject: any;
  tiles$: any;
  constructor(private utiltiyService: UtilityService, private async: AsyncPipe) {
  }

  ngOnInit() {
    this.init();

    if (this.tilesUpdatedObservable) {
      this.subscriptions.add(
        this.tilesUpdatedObservable.subscribe(tiles => {
          this.tilesSubject.next(tiles);
          // this.tiles = tiles;
          this.init();
        })
      );
    }
  }
  ngOnChanges(changes: any) {
    if (changes.tiles) {
      // this.tiles = changes.tiles.currentVal
      // this.init();
    }


  }

  ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
  }
  init() {
    let tiles: any = this.async.transform(this.tiles$);
    if (tiles) {
      tiles.forEach((tile) => {
        if (tile.examples) {
          this.checkSelectedExamples(tile);
          // this.activeExamples(tile);
        }
        this.activeExamples(tile);
      });
    }
    if (tiles && this.isPopup) {
      tiles = tiles.filter((tile) => tile.split('.')[1] !== 'sight');
    } else if (tiles && !this.isPopup) {
      tiles = Array.from(new Set(tiles));
    }
    this.tilesSubject.next(tiles);
  }

  returnClassName(tile: any) {
    return this.utiltiyService.getTileClasses(typeof tile === 'string' ? tile : tile.tile, this.userSettings);

  }
  clickActionEvent(tile: string) {
    this.clickAction.emit(tile);
  }
  addTile() {

  }
  deleteTile(tileToRemove) {
    let tiles: any = this.async.transform(this.tiles$);

    tiles = tiles.filter((tile) => {
      return tile.tile !== tileToRemove.tile;
    });
    this.clickAction.emit(tiles);
    this.tilesSubject.next(tiles);
  }
  returnLetters(letters) {
    // if (letters === null) {
    //   return ;
    // }
    if (letters.split('.')[1] === 'blank') {
        return '';
    } else if (letters === 'tiles.sym.4') {
      return '.';
    }
    letters = letters.substring(letters.lastIndexOf('.') + 1);

    if (letters === 'period' ) {
      return '.';
    }

    return letters;
  }


  activeExamples(tile: any) {
    let tiles: any = this.async.transform(this.tiles$);

    const count = tile.examples?.reduce((a, { checked }) => a + checked, 0);
    // tile = JSON.parse(JSON.stringify(tile));
    tiles = JSON.parse(JSON.stringify(tiles));
    if (count >= tile.examples.length) {
      tile["allChecked"] = true;
      tile["partialChecked"] = false;
    }
    if (count < tile.examples.length && count !== 0) {
      tile["allChecked"] = false;
      tile["partialChecked"] = true;
    }
    if (count === 0) {
      tile["allChecked"] = false;
      tile["partialChecked"] = false;
    }

    // let index = tiles.findIndex((ctile ) => ctile.tile === tile.tile);
    // tiles[index] = tile;

    this.updateQuery.emit(tiles);
    this.tilesSubject.next(tiles);
  }

  checkSelectedExamples(tile: any) {
    tile.examples.forEach((example) => {
    });
  }

  changeStatus(tile: any, example: any) {
    // let tiles: any = this.async.transform(this.tiles$);
    // tiles = JSON.parse(JSON.stringify(tiles));
    // let org_tile = tiles[index];

    example = JSON.parse(JSON.stringify(example));
    tile = JSON.parse(JSON.stringify(tile));
    tile.examples.forEach(tileExample => {
      if (example.name === tileExample.name) {
        tileExample.checked = !tileExample.checked;
      }
    });
    // example.checked = !example.checked;
    // this.activeExamples(tile);
  }

  openWordsMenu(index: number) {
    this.openIndex = index;
  }

  closeWordsMenu(index: number) {
    let tiles: any = this.async.transform(this.tiles$);

    this.openIndex = -1;
    tiles = JSON.parse(JSON.stringify(tiles));
    let tile = tiles[index];

    if (tile) {
      const count = this.selectionLists._results[index].selectedOptions.selected.length;
      const selectedItems = this.selectionLists._results[index].selectedOptions.selected.map(item => item.value);
      if ( count >= tile.examples.length) {
        let sameTile = !!(selectedItems[0]?.split('-')[0] === tile.letter);
        if (sameTile) {
          tile["allChecked"] = true;
          tile["partialChecked"] = false;
        }
      }
      if ( count < tile.examples.length && count !== 0) {
        let sameTile = !!(selectedItems[0]?.split('-')[0] === tile.letter);
        if (sameTile) {
          tile["allChecked"] = false;
          tile["partialChecked"] = true;
        }

      }
      if (count === 0) {
        tile["allChecked"] = false;
        tile["partialChecked"] = false;
      }

      tile.examples.forEach(item => {
        let sameTile = !!(selectedItems[0]?.split('-')[0] === tile.letter);

        if (sameTile || count === 0 && sameTile === false) {
          item.checked = selectedItems.includes(item.value);
        }
      });

      this.updateQuery.emit(tiles);
      this.tilesSubject.next(tiles);
    }

  }
}
