import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  public basicColors = [
    '#000',
    '#fff',
    '#800000',
    '#FF0000',
    '#FFA500',
    '#FFFF00',
    '#800080',
    '#FF00FF',
    '#00FF00',
    '#008000',
    '#000080',
    '#0000FF',
    '#00FFFF',
    '#008080',
    '#C0C0C0',
  ];

  public highlighters: any[] = [
    '#966dea99',
    '#6096fd99',
    '#58bf7899',
    '#fcd05499',
    '#f7774199',
    '#d6424199',
    '#cf438a99',
    '#b0afaf99',
    '#e8cbff99',
    '#acc5ff99',
    '#96ffb899',
    '#fef3a099',
    '#fab77399',
    '#f88f8e99',
    '#f89fcf99',
  ];
  
  constructor() { }
}
