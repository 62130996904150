import * as lessonStepActions from './edit-lesson.actions';

export interface State {
  pending: boolean;
  error: any;
  succeeded: boolean;
  courseWorkbookCount: any;
  workbookCount: any;
  lessonStep: any;
  allLessonSteps: any;
  lessonId: string;
}

const initialState: State = {
  pending: false,
  error: null,
  succeeded: false,
  courseWorkbookCount: null,
  workbookCount: null,
  lessonStep: null,
  allLessonSteps: [],
  lessonId: null
};

export function reducer (
  state = initialState,
  action: lessonStepActions.LessonStepActions
): State {
  switch (action.type) {
    case lessonStepActions.LessonStepActionTypes.GetLessonStep: {
      return {
        ...state,
        lessonStep: null,
        pending: true,
        succeeded: false,
        error: null
      };
    }
    case lessonStepActions.LessonStepActionTypes.GetLessonStepSuccess: {
      return {
        ...state,
        lessonStep: action.payload,
        error: null
      };
    }
    case lessonStepActions.LessonStepActionTypes.GetLessonStepFail: {
      return {
        ...state,
        lessonStep: null,
        error: action.payload
      };
    }
    case lessonStepActions.LessonStepActionTypes.ClearLessonStep: {
      return {
        ...state,
        lessonStep: null,
        error: null
      };
    }

    case lessonStepActions.LessonStepActionTypes.EditGetAllLessonSteps: {
      return {
        ...state,
        allLessonSteps: [],
        pending: true,
        succeeded: false,
        error: null
      };
    }
    case lessonStepActions.LessonStepActionTypes.EditGetAllLessonStepsSuccess: {
      return {
        ...state,
        allLessonSteps: action.payload.items,
        lessonId: action.payload.id,
        error: null
      };
    }
    case lessonStepActions.LessonStepActionTypes.EditGetAllLessonStepsFail: {
      return {
        ...state,
        allLessonSteps: [],
        error: action.payload
      };
    }
    case lessonStepActions.LessonStepActionTypes.GetCourseWorkbookIdCount: {
      return {
        ...state,
        pending: true,
        succeeded: false,
        error: null
      };
    }
    case lessonStepActions.LessonStepActionTypes
      .GetCourseWorkbookIdCountSuccess: {
      return {
        ...state,
        courseWorkbookCount: action.payload,
        error: null
      };
    }
    case lessonStepActions.LessonStepActionTypes.GetCourseWorkbookIdCountFail: {
      return {
        ...state,
        courseWorkbookCount: null,
        error: action.payload
      };
    }
    case lessonStepActions.LessonStepActionTypes.GetWorkbookIdCount: {
      return {
        ...state,
        pending: true,
        succeeded: false,
        error: null
      };
    }
    case lessonStepActions.LessonStepActionTypes.GetWorkbookIdCountSuccess: {
      return {
        ...state,
        workbookCount: action.payload,
        error: null
      };
    }
    case lessonStepActions.LessonStepActionTypes.GetWorkbookIdCountFail: {
      return {
        ...state,
        workbookCount: null,
        error: action.payload
      };
    }
    case lessonStepActions.LessonStepActionTypes.UpdateLessonStep: {
      return {
        ...state,
        pending: true,
        succeeded: false,
        error: null
      };
    }
    case lessonStepActions.LessonStepActionTypes.UpdateLessonStepSuccess: {
      return {
        ...state,
        allLessonSteps: state.allLessonSteps?.map(step => {
          if (step._id === action.payload.id) {
            return action.payload.lessonPlanStep;
          }
          return step;
        }),
        error: null
      };
    }

    case lessonStepActions.LessonStepActionTypes.CreateLessonStep: {
      return {
        ...state,
        pending: true,
        succeeded: false,
        error: null
      };
    }
    case lessonStepActions.LessonStepActionTypes.CreateLessonStepSuccess: {
      return {
        ...state,
        allLessonSteps: [...state.allLessonSteps, ...[action.payload]],
        error: null
      };
    }

    case lessonStepActions.LessonStepActionTypes.DeleteLessonStep: {
      return {
        ...state,
        pending: true,
        succeeded: false,
        error: null
      };
    }
    case lessonStepActions.LessonStepActionTypes.DeleteLessonStepSuccess: {
      let unfilteredLessonSteps = JSON.parse(
        JSON.stringify(state.allLessonSteps)
      );
      const allLessonSteps = unfilteredLessonSteps.filter(
        item => item._id !== action.payload
      );
      return {
        ...state,
        allLessonSteps,
        error: null
      };
    }

    default:
      return state;
  }
}

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.succeeded;
export const getError = (state: State) => state.error;
export const getLessonStep = (state: State) => state.lessonStep;
export const GetAllLessonSteps = (state: State) => state.allLessonSteps;
export const getCourseWorkbookIdCount = (state: State) =>
  state.courseWorkbookCount;
export const getWorkbookIdCount = (state: State) => state.workbookCount;
