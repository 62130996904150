import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {API_DIR} from '../../environments/environment';

@Injectable()

export class SharingService {

  constructor(private http: HttpClient) {}

  get(userId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/sharing/user/${userId}`)
        .subscribe(
          (bundle: any) => {
            observer.next(bundle.data);
          },
          (error: any) => {
            throw error;
          }
        );
    });
  }

  apply(code: string) {
      return new Observable(observer => {
        this.http
          .post(`${API_DIR}/sharing/apply`, {code})
          .subscribe(
            (result: any) => {
              observer.next(result.data);
            },
            (error: any) => {
              observer.error(error);
            }
          );
      });
  }

  delete(id: string) {
    return this.http.delete(`${API_DIR}/sharing/${id}`);
  }

  rename(id: string, name: string) {
    return this.http.put(`${API_DIR}/sharing/${id}`, {name});
  }

  create(data: any) {
    return this.http.post(`${API_DIR}/sharing`, data);
  }
}
