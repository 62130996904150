import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PasswordresetComponent } from "./passwordreset.component";

const PasswordresetRoutes: Routes = [
  {
    path: "passwordreset",
    component: PasswordresetComponent,
    data: {}
  }
];

@NgModule({
  imports: [RouterModule.forChild(PasswordresetRoutes)],
  exports: [RouterModule]
})
export class PasswordresetRoutingModule {}
