import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { filter, first, tap } from "rxjs/operators";
import * as SubscriptionsActions from '../store/subscriptions/subscriptions.actions';
import * as SubscriptionsSelectors from '../store';


@Injectable()
export class AllSubscribedCoursesResolver  {
  constructor(private store: Store<any>) { }

  resolve() {
    return this.store.pipe(
      select(SubscriptionsSelectors.selectAllSubscriptionSuccess),
      tap(areLoaded => {
        if (!areLoaded) {
          if (localStorage?.profile) {
            const profile = JSON.parse(localStorage.profile);
            const uid = profile?.user_metadata.uid;
            this.store.dispatch(new SubscriptionsActions.GetAllSubscribedCourses(uid));
          }
        }
      }),
      filter(areLoaded => areLoaded),
      first(),
    );
  }
}
