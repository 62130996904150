import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, observable} from 'rxjs';
import {API_DIR} from '../../environments/environment';
import {take} from 'rxjs/operators';
import {UserService} from './user.service';

@Injectable()
export class AccountService {
  constructor(private http: HttpClient, private userService: UserService) {
  }

  cancelScheduledSubscription(scheduledSubscriptionId: string) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/account/subscription/scheduled/${scheduledSubscriptionId}`)
        .subscribe(
          () => observer.next(),
          error => observer.error(error)
        );
    });
  }

  cancelSubscription(subscriptionId: string) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/account/subscription/${subscriptionId}`, {cancel_at_period_end : true})
        .subscribe(
          (subscription: any) => observer.next(subscription.data),
          (error: any) => {
            throw error;
          }
        );
    });
  }

  addCreditCard(cardInfo: any, customerId: string) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/account/card/${customerId}`, {token: cardInfo})
        .subscribe(
          (card: any) => {
            observer.next(card.data);
          },
          (error: any) => {
            throw error;
          }
        );
    });
  }

  deleteCreditCard(customerId: string, cardId: any) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/account/card/${customerId}/${cardId}`)
        .subscribe(
          () =>
            observer.next(),
            error => observer.error(error)
        );
    });
  }

  getPaymentAccount(customerId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/account/customer/${customerId}`)
        .subscribe(
          (account: any) => observer.next(account.data),
          (error: any) => {
            throw error;
          }
        );
    });
  }

  getPromoCode(promoCode: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/account/promo/${promoCode}`)
        .subscribe(
          (promo: any) => observer.next(promo.data),
          (error: any) => observer.error(error));
    });
  }

  deleteCustomerPromo(customerId: string) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/account/promo/${customerId}`)
        .subscribe(
          () =>
            observer.next(),
            error => observer.error(error)
        );
    });
  }

  deleteSubscriptionPromo(subscriptionId: string) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/account/promo/${subscriptionId}`)
        .subscribe(
          () =>
            observer.next(),
            error => observer.error(error)
        );
    });
  }

  changePlan(plan: any) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/account/subscription/customer/${JSON.parse(localStorage.profile).user_metadata.cusID}`, plan)
        .subscribe(
          (subscription: any) => observer.next(subscription.data),
          (error: any) => {
            throw error;
          }
        );
    });
  }

  scheduleNewPlan(plan: any, start_date: string) {
    // plan["start_date"]= start_date;
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/account/subscription/customer/${this.userService.auth0UserInfo.user_metadata.cusID}`, {
          ...plan,
          start_date
        })
        .subscribe(
          (subscriptions: any) => observer.next(subscriptions.data),
          (error: any) => {
            throw error;
          }
        );
    });
  }

  cancelRenewal(subscriptionId: string) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/account/subscription/${subscriptionId}`, {cancel_at_period_end : true})
        .subscribe(
          (subscription: any) => observer.next(subscription.data),
          (error: any) => {
            throw error;
          }
        );
    });
  }

  getAllSubscriptions(customerId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/account/customer/subscription/${customerId}`)
        .subscribe(
          (subscriptions: any) => observer.next(subscriptions.data),
          (error: any) => {
            throw error;
          }
        );
    });
  }

  resendEmailVerification(userId: string) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/account/auth0/user/resendEmailVerification`, {userId})
        .subscribe(
          () => observer.next(),
          (error: any) => {
            throw error;
          }
        );
    });
  }

  setEmailVerificationAccepted(userId: string) {
    return new Observable(observer => {
      this.http
        .patch(`${API_DIR}/account/auth0/user/setEmailVerificationAccepted`, {userId})
        .subscribe(
          () => observer.next(),
          (error: any) => {
            throw error;
          }
        );
    });
  }
}
