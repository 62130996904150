import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as workbookActivityActions from './workbook-activity.actions';

export interface State {
    pending: boolean;
    error: any;
    succeeded: boolean;
    updated: boolean;
    workbookActivity: any;
}

const initialState: State = {
    pending: false,
    error: null,
    succeeded: false,
    updated: false,
    workbookActivity: null
};

export function reducer(state = initialState, action: workbookActivityActions.WorkbookActivityActions): State {
    switch (action.type) {
        case workbookActivityActions.WorkbookActivityActionTypes.GetWorkbookActivity: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }

        case workbookActivityActions.WorkbookActivityActionTypes.GetWorkbookActivitySuccess: {
            return {
                // ...adapter.addOne(action.payload, state),
                ...state,
                pending: true,
                succeeded: false,
                error: null,
                workbookActivity: action.payload
            };
        }

        case workbookActivityActions.WorkbookActivityActionTypes.UpdateWorkbookActivity: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                updated: false,
                error: null
            };
        }

        case workbookActivityActions.WorkbookActivityActionTypes.UpdateWorkbookActivitySuccess: {
            return {
                // ...adapter.updateOne({
                //     id: action.payload._id,
                //     changes: action.payload
                // }, state),
                ...state,
                pending: false,
                succeeded: true,
                error: null,
                updated: true,
                workbookActivity: action.payload
            };
        }

        case workbookActivityActions.WorkbookActivityActionTypes.SetUpdatingWorkbookActivityPending: {
          return {
              ...state,
              updated: false,
          };
        }

        case workbookActivityActions.WorkbookActivityActionTypes.SetUpdatingWorkbookActivitySuccess: {
          return {
              ...state,
              updated: true,
          };
        }

        case workbookActivityActions.WorkbookActivityActionTypes.ClearWorkbookActivity: {
          return {
              ...state,
              workbookActivity: null
          };
        }

        default:
            return state;
    }
}
