import { Store } from '@ngrx/store';
import { ViewWordsDialogComponent } from '../view-words-dialog/view-words-dialog.component';
import { Component, EventEmitter, ViewChild, ElementRef, Inject, Input, Renderer2, OnDestroy, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Swappable, Sortable, Plugins } from '@shopify/draggable';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragEnter } from '@angular/cdk/drag-drop';
import { AddWorkBooksComponent, AddWorkbookDialogComponent } from './add-workbooks.component';
import { UserService } from '../../services/user.service';
import { _variable_images } from '../../../environments/environment';
import { ListenerService } from '../../services/listener.service';
import { DataService } from '../../services/data.service';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { selectCurrentTheme } from '../../store';
import { takeUntil } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { PostWords } from '../../store/words/words.actions';
import { UtilityService } from '../../services/utility.service';
interface IWorkBooks {

  _id: string;
  order: number;
  courseId: string;
  workbookId: string;
  phrases: any[];
  files: any[];
  sentences: any[];
  passages: any[];
  preview: any[];
  tiles: any[];
  desc: string;
  name: string;
  nonsense: boolean;
  real: boolean;
  phonetic: string;
  hasFiles: boolean;
  hasPassages: boolean;
  hasSentences: boolean;
  hasPhrases: boolean;
  wordOrder?: any;
}

@Component({
  selector: 'app-workbook-cards-element',
  templateUrl: './html/workbook-card.html',
  styleUrls: [
    '../../styles/workbook-cards.scss',
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss'

  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class WorkbookCardsComponent implements OnInit, OnDestroy {
  selectedPlan: string;
  @ViewChild('reorderTable', { static: false }) reorderTable: ElementRef;
  public toggleReorder: boolean;
  public containers: any;
  public draggingIndex: any;
  public swappingIndex: any;
  private unsubscribe$: Subject<void> = new Subject();
  public workbooks: any = [];

  public workbooksSubject: any = new BehaviorSubject<any>(this.workbooks);

  public workbooks$: any = this.workbooksSubject.asObservable();

  public emailVerified: boolean = JSON.parse(localStorage.profile).email_verified;

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private listnerService: ListenerService,
    private dataService: DataService,
    private r2: Renderer2,
    private store: Store,
    private async: AsyncPipe,
    private utilityService: UtilityService
  ) { }
  // @ts-ignore
  @Input() set canReorder(reorder: boolean) {
    this.toggleReorder = reorder;
    if (reorder === true) {
      setTimeout(() => {
        this.makeReorder();

      }, 1000);
    }
  }
  @Input() set setWorkbooks(workbooks: any) {
    this.workbooksSubject.next(workbooks);
  }
  @Input() course: any;
  @Input() hasSettings = false;
  @Input() hasNew = false;
  @Input() userId: string;
  @Input() isCurriculumPage: boolean;
  @Output() settingsClick: EventEmitter<any> = new EventEmitter(false);
  @Output() cardClick: EventEmitter<any> = new EventEmitter(false);
  @Output() deleteCourseWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() deleteWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() addWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() copyWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() editWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() changeOrder: EventEmitter<any> = new EventEmitter();
  @Output() begin: EventEmitter<any> = new EventEmitter();
  addCircleCurriculumImage: any;
  addBlankCircleCurriculumImage: any;
  dragPosition = { x: 0, y: 0 };
  blankWorkBookCard: any = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  ngOnInit() {
    this.listnerService.getPlan().pipe(takeUntil(this.unsubscribe$)).subscribe((plan: string) => {
      this.selectedPlan = plan;
    });
    // this.addCircleCurriculumImage = _variable_images + 'add-circle-1.png';
    // this.addBlankCircleCurriculumImage = _variable_images + 'add-circle-blank-div.png';
    this.dataService._variable_images.pipe(takeUntil(this.unsubscribe$)).subscribe(path => {
      this.addCircleCurriculumImage = path + 'add-circle-1.png';
      this.addBlankCircleCurriculumImage = path + 'add-circle-blank-div.png';
    });

    this.store.select(selectCurrentTheme)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(theme => {
        if (theme === 'theme-dark') {
          this.addCircleCurriculumImage = 'assets/svgs/regular/add-circle-2.svg';
        } else {
          this.addCircleCurriculumImage = 'assets/svgs/regular/add-circle-1.svg';
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public viewWords(index): void {
    let workbooks: any = this.async.transform(this.workbooks$);

    const dialogRef = this.dialog.open(ViewWordsDialogComponent, {
      panelClass: 'wordlist_modal',
      width: '250px',
      data: { words: workbooks[index].workbook }
    });

  }
  public beginEvent(workbook) {
    this.begin.emit(workbook);
  }
  public trigEditWorkbook(workbook) {
    // this.store.dispatch(new PostWords({
    //   id: workbook.workbookId,
    //   filter: workbook.filters
    // }));

    this.editWorkbook.emit(workbook.workbookId);
  }
  public trigCopyWorkbook(workbookId) {
    this.copyWorkbook.emit(workbookId);
  }

  public trigDeleteCourseWorkbook(courseWorkbookId) {
    this.deleteCourseWorkbook.emit(courseWorkbookId);
  }
  public trigDeleteWorkbook(workbookId) {
    this.deleteWorkbook.emit(workbookId);
  }

  cardClickEvent(workbook) {
    this.cardClick.emit(workbook);
  }
  makeReorder() {
    let workbooks: any = this.async.transform(this.workbooks$);

    const tableElement = this.reorderTable.nativeElement;
    tableElement.innerHTML = '';

    // grab table container
    // for each workbook of workbooks
    this.dataService._variable_images.pipe(takeUntil(this.unsubscribe$)).subscribe((path: any) => {

      let tr = this.r2.createElement('tr');
      this.r2.addClass(tr, 'reorderContainer');
      let first = this.r2.createElement('td');
      let filler = this.r2.createElement('div');
      this.r2.addClass(filler, 'workbook');
      first.appendChild(filler);
      // this.r2.addClass(first, 'workbook');
      this.r2.addClass(first, 'filler');
      // if ('assets/img/theme-dark/' === path) {
      //   this.r2.addClass(first, 'add-success-dark');
      // } else {
      //   this.r2.addClass(first, 'add-success-light');
      // }
      this.r2.appendChild(tr, first);
      workbooks.forEach((workbook, index, array) => {
        // create parent div
        let div = this.r2.createElement('td');
        this.r2.setProperty(div, 'id', workbook._id);

        this.r2.addClass(div, 'dropzone');
        this.r2.addClass(div, 'drag_box');
        // create child div with material card class
        let matDiv = this.r2.createElement('div');
        this.r2.addClass(matDiv, 'mat-card');
        this.r2.addClass(matDiv, 'workbook');
        this.r2.addClass(matDiv, 'content');
        this.r2.addClass(matDiv, 'reorder-workbook');
        this.r2.setProperty(matDiv, 'id', workbook._id);
        // add font color class "primary-light"
        this.r2.addClass(matDiv, 'primary-light');
        // on 'click' event fire cardClickEvent function
        let matDivClick = fromEvent(matDiv, 'click');
        matDivClick.pipe(takeUntil(this.unsubscribe$)).subscribe((event: any) => {
          this.cardClickEvent(workbook);
        });
        // create child of child div
        let subDiv = this.r2.createElement('div');

        // Create div to hold the ellipsis button and "Remove" button
        let actionDiv = this.r2.createElement('div');
        this.r2.addClass(actionDiv, 'action-div');

        // create mat-button
        let button = this.r2.createElement('button');
        this.r2.addClass(subDiv, 'example-handle');
        this.r2.addClass(button, 'mat-icon-button');
        // add class 'ellipsis' to button
        this.r2.addClass(button, 'ellipsis');

        // add i tag with classes 'fas' 'fa-ellipsis-v'
        let el1 = this.r2.createElement('img');

        this.r2.setAttribute(el1, 'src', path + 'move.png');
        this.r2.appendChild(button, el1);

        // create remove button for deleting workbook from course
        let removeButton = this.r2.createElement('button');
        this.r2.setProperty(removeButton, 'textContent', "Remove");
        this.r2.addClass(removeButton, 'remove-btn');
        this.r2.addClass(removeButton, 'bg_transparent_dark_salmon');
        this.r2.listen(removeButton, 'mousedown', (event) => {
          event.stopPropagation();
          this.trigDeleteCourseWorkbook(workbook._id);
        });

        let action = this.r2.createElement('div');
        // create div with mat-card-actions classes attached
        this.r2.addClass(action, 'mat-card-actions');
        this.r2.addClass(action, 'actions');
        // add style 'padding' of '10px' to actions div
        this.r2.setStyle(action, 'padding', '10px');
        let name = '';
        // check to see if workbook has a workbook property, if it does, set name to workbook.workbook.name, else set name to workbook.name, 
        // also check to see if workbook.workbook is an array, if it is, set name to workbook.workbook[0].name
        if (workbook.workbook) {
          if (Array.isArray(workbook.workbook)) {
            name = workbook.workbook[0].name;
          } else {
            name = workbook.workbook.name;
          }
        } else {
          name = workbook.name;
        }

        // create text node with name of workbook
        let text = this.r2.createText(name);
        this.containers = [];
        this.containers.push(matDiv);

        // append respective children to their parents
        this.r2.appendChild(action, text);

        this.r2.appendChild(actionDiv, button);
        this.r2.appendChild(actionDiv, removeButton);

        this.r2.appendChild(subDiv, actionDiv);
        this.r2.appendChild(subDiv, action);
        this.r2.appendChild(matDiv, subDiv);
        this.r2.appendChild(div, matDiv);
        this.r2.appendChild(tr, div);

      });
      this.r2.appendChild(this.reorderTable.nativeElement, tr);
      // add Draggablejs swappable object to child element

      // const swappable = new Swappable(tr, {
      //   draggable: '.reorder-workbook',
      //   mirror: {
      //     appendTo: this.reorderTable.nativeElement,
      //     constrainDimensions: true
      //   },
      //   plugins: [Plugins.ResizeMirror]
      // });

      // swappable.on('swappable:start', (event: any) => {

      //   this.draggingIndex = event.data.dragEvent.data.originalSource;

      // });
      // swappable.on('swappable:swapped', (event: any) => {
      //   // this.swappingIndex = parseInt(event.data.swappedElement.id, 10)
      //   this.swappingIndex = event.data.swappedElement;
      // });
      //   swappable.on('swappable:stop', (event: any) => {
      //     const swappingIndex = parseInt(this.swappingIndex.id, 10);
      //     const currentIndex = parseInt(this.draggingIndex.id, 10);
      //     const temp = workbooks[currentIndex];
      //     workbooks[currentIndex] = workbooks[swappingIndex];
      //     workbooks[swappingIndex] = temp;
      //     // @ts-ignore
      //     // [this.workbooks[currentIndex], this.workbooks[swappingIndex]] = [this.workbooks[swappingIndex], this.workbooks[currentIndex]]
      //     this.r2.removeAttribute(this.swappingIndex, 'id');
      //     this.r2.removeAttribute(this.draggingIndex, 'id');
      //     this.r2.setAttribute(this.draggingIndex, 'id', `${swappingIndex}`);
      //     this.r2.setAttribute(this.swappingIndex, 'id', `${currentIndex}`);
      //     this.changeOrder.emit(workbooks);
      // });

      let newIndex: any;
      let oldIndex: any;

      const sortAble = new Sortable(tr, {
        draggable: '.drag_box',
        // @ts-ignore
        dropzone: '.dropzone',
        swapAnimation: { duration: 500, easingFunction: "ease-in-out", horizontal: true },
        plugins: [Plugins.SwapAnimation]
      });

      let draggingIndex: any;
      let startingX: number;
      sortAble.on('drag:start', (event: any) => {
        if (event.originalEvent.target.closest('.remove-btn')) {
          event.cancel();
        }
        startingX = event.originalEvent.clientX;
      });
      sortAble.on('drag:move', (event: any) => {
        draggingIndex = event.data.source.children[0].id;
      });

      sortAble.on('drag:stop', (event: any) => {
        if (!event.data.source) { return; }
        setTimeout(() => {
          let newOrderIds = Array.from(this.reorderTable.nativeElement.childNodes[0].childNodes)
            .filter((node: any) => node?.id && node.cellIndex > -1)
            .sort((a: any, b: any) => {
              return a.cellIndex - b.cellIndex;
            })
            .map((node: any) => node.id);

          let workbooks: any = this.async.transform(this.workbooks$);

          workbooks = workbooks.sort((a: any, b: any) => {
            return newOrderIds.indexOf(a._id) - newOrderIds.indexOf(b._id);
          }).map((workbook, index) => {
            workbook.order = index;
            return workbook;
          });
          this.changeOrder.emit(workbooks);
        }, 100);
      });

      // sortAble.on('sortable:sorted', (evt) => {
      //   newIndex = evt.data.newIndex;
      //   oldIndex = evt.data.oldIndex;
      //   // this.r2.setProperty(evt.data.dragEvent.data.originalSource.children[0], 'id', newIndex);
      //   // if(newIndex > oldIndex){
      //   //   this.r2.setProperty(evt.data.dragEvent.data.over.children[0], 'id', newIndex - 1);
      //   // } else if(newIndex < oldIndex) {
      //   //   this.r2.setProperty(evt.data.dragEvent.data.over.children[0], 'id', newIndex + 1);
      //   // }
      // });

    });
    // -- end -- //
  }







  settingsClickEvent($event, workbook) {
    $event.stopPropagation();
    this.settingsClick.emit(workbook);
  }


  openDialog() {
    if (!JSON.parse(localStorage.profile).email_verified) {
      this.utilityService.openVerificationEmailDialog();
      return;
    }
    let workbooks: any = this.async.transform(this.workbooks$);
    if ((this.selectedPlan === 'Demo' && workbooks.length > 2) || (this.selectedPlan === 'Hero' && workbooks.length > 24)) { return; }
    const dialogRef = this.dialog.open(AddWorkbookDialogComponent, {
      panelClass: 'add-wordbook-modalbox',
      width: '350px'
    });
  }

  isPhonetic(event) {
    if (event.workbook) {
      if (event.workbook.length > 0) {
        return !event.workbook[0].filters.isPhonetic;
      } else {
        return !event.workbook.filters.isPhonetic;
      }
    } else {
      return !event.filters.isPhonetic;
    }

  }


  entered(event: CdkDragEnter) {
    let workbooks: any = this.async.transform(this.workbooks$);
    let moveFiles = (arr, fromIndex, toIndex) => {
      let element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
      return arr;
    };

    if ((this.selectedPlan === 'Demo' && event.container.data > 2) || (this.selectedPlan === 'Hero' && event.container.data > 24)) { return; }

    workbooks = moveFiles(workbooks, event.item.data, event.container.data);
    this.workbooksSubject.next(workbooks);
  }

  drop(event: CdkDragDrop<IWorkBooks[]>) {
    let workbooks: any = this.async.transform(this.workbooks$);

    let moveFiles = (arr, fromIndex, toIndex) => {
      let element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
      return arr;
    };

    workbooks = moveFiles(workbooks, event.previousIndex, event.currentIndex);
  }

  dropped($event: CdkDragDrop<number, any>) {
    let workbooks: any = this.async.transform(this.workbooks$);
    this.changeOrder.emit(workbooks);
  }

  isWorkbookActive(courseWorkbook: any) {
    return this.utilityService.isCourseWorkbookActive(courseWorkbook);
  }
}

@Component({
  selector: 'app-create-workbook-options-element',
  templateUrl: './html/create-workbook-options.html'
})
export class CreateWorkbookOptionsComponent {
  // @Output() userKnowsWords: EventEmitter<any> = new EventEmitter(false);
  functions;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) { }

  goToWorkbookBuilder(userKnowsWords) {
    this.router.navigate([`workbook-builder/new`, { userKnows: userKnowsWords, isFromLibrary: true }]);
  }
}