import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { TutorialDialogComponent } from '../../../support/support.component';
import { MatDialog } from '@angular/material/dialog';
import { _variable_images } from '../../../../../environments/environment';
import { DataService } from '../../../../services/data.service';
import { AsyncPipe } from '@angular/common';
import { BehaviorSubject, Subscription } from 'rxjs';
@Component({
  selector: 'app-word-filter',
  templateUrl: './html/word-filter.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    './word-filter.scss',
    '../workbook-builder.scss'
  ]
})

export class WordFilterComponent implements OnDestroy {
  @Input() set setFilter(filter: any) {
    if (filter) {
      if (!this.filterSubject) {
        this.filterSubject = new BehaviorSubject<any>(filter);
        this.filter$ = this.filterSubject.asObservable();
      }
      this.filterSubject.next(filter);
      // this.filter = JSON.parse(JSON.stringify(this.filter))
    }
  }
  @Input() userKnowsWords = false;
  @Input() syllableCount: number;
  @Input() changeNameModalClosed = false;
  @Output() updateQuery: EventEmitter<any> = new EventEmitter();
  @Output() closeFilter: EventEmitter<any> = new EventEmitter();

  public filterSubject: any;
  public filter$: any;
  faTimes = faTimesCircle;
  helpIconImage: any;
  private subscriptions = new Subscription();
  constructor(private dialog: MatDialog,
    private dataService: DataService,
    private async: AsyncPipe
  ) {
    // this.helpIconImage = _variable_images + 'help-header.png';
    this.subscriptions.add(
      this.dataService._variable_images.subscribe(path => {
        this.helpIconImage = path + 'help-header.png';
      })
    );
  }

  ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
  }

  updateQueryEvent(query: any) {
    this.updateQuery.emit(query);
  }

  closeFilterEvent() {
    let filter: any = this.async.transform(this.filter$);
    this.closeFilter.emit(filter.name);
  }

  openTutorialDialog(): void {
    let filter: any = this.async.transform(this.filter$);
    const dialogRef = this.dialog.open(TutorialDialogComponent, {
      panelClass: 'tutorial_dialog_modal',
      data: {
        title: 'Workbook Builder Filter Card - ' + filter.title,
        link:  filter.filterUrl
      },
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
