import { Injectable } from '@angular/core';
import * as amplitude from '@amplitude/analytics-browser';
// import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { AMPLITUDE_API_KEY, AMPLITUDE_IDENTIFY_URL } from '../../environments/environment';
import { AppConfig } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AmplitudeService {

    private initialized: boolean = false;
    // private sessionReplayTracking: any;

    constructor() { }

    public init(): void {
        if (this.initialized) {
            return;
        }

        try {
            let dynamicConfigs: { [key: string]: string } = {}

            // We can check which version the user is currently using
            if (localStorage.getItem('appversion')) {
                dynamicConfigs.app_version = localStorage.getItem('appversion');
            }
    
            // this.sessionReplayTracking = sessionReplayPlugin({
            //     debugMode: AppConfig.production ? false : true,
            // });
            // amplitude.add(this.sessionReplayTracking);
    
            // Initialize the SDK here or dynamically based on your project
            amplitude.init(AMPLITUDE_API_KEY, {
                fetchRemoteConfig: true,
                // logLevel: AppConfig.production ? amplitude.Types.LogLevel.Warn : amplitude.Types.LogLevel.Debug,
                autocapture: true,
                ...dynamicConfigs,
            });
    
            this.initialized = true;
        } catch (error) {
            console.error('Amplitude initialization failed:', error);
            // Optionally, set initialized to false or handle accordingly
            // this.initialized = false;
        }
    }

    logEvent(eventName: string, eventProperties?: Record<string, any>): void {
        if (!this.initialized) {
            console.warn('Amplitude not initialized. Event not logged:', eventName);
            return;
        }
        amplitude.track(eventName, eventProperties);
    }

    setUserId(userId: string): void {
        if (!this.initialized) {
            console.warn('Amplitude not initialized. User ID not set:', userId);
            return;
        }
        amplitude.setUserId(userId);
    }

    identifyUser(identityTraits: Record<string, any>): void {
        if (!this.initialized) {
            console.warn('Amplitude not initialized. User traits not set:', identityTraits);
            return;
        }
        const identify = new amplitude.Identify();

        for (const key in identityTraits) {
            identify.set(key, identityTraits[key]);
        }

        amplitude.identify(identify);
    }

    setGroup(groupType: string, groupName: string): void {
        if (!this.initialized) {
            console.warn('Amplitude not initialized. Group not set:', groupType, groupName);
            return;
        }
        amplitude.setGroup(groupType, groupName);
    }

    identifyUserImmediately(identityTraits: Record<string, any>): void {
        if (!this.initialized) {
            console.warn('Amplitude not initialized. User traits not set:', identityTraits);
            return;
        }

        // Create a API call to the Amplitude Identify API
        const amplitudeEndpoint = AMPLITUDE_IDENTIFY_URL;

        fetch(amplitudeEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                api_key: AMPLITUDE_API_KEY,
                identification: {
                    user_id: identityTraits.user_id,
                    user_properties: identityTraits,
                },
            }),
        }).then((response) => {
            if (response.ok) {
                console.log('Amplitude Identify API success:', response);
            } else {
                console.error('Amplitude Identify API failed:', response);
            }
        }
        ).catch((error) => {
            console.error('Amplitude Identify API error:', error);
        });
    }

    // // Stop session replay tracking
    // async stopSessionReplay(): Promise<void> {
    //     await amplitude.remove(this.sessionReplayTracking.name).promise;
    // }

    // // Start session replay tracking
    // async startSessionReplay(): Promise<void> {
    //     await amplitude.add(this.sessionReplayTracking).promise;
    // }
}