import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum WorkbookActionTypes {
    GetWorkbook = '[WorkbookService] Get Workbook',
    GetWorkbookSuccess = '[WorkbookService] Get Workbook Success',
    GetWorkbookFail = '[WorkbookService] Get Workook Fail',

    GetWhizzimoLibraryWorkbooks = '[WorkbookService] Get Whizzimo Library Workbooks',
    GetWhizzimoLibraryWorkbooksSuccess = '[WorkbookService] Get Whizzimo Library Workbooks Success',
    GetWhizzimoLibraryWorkbooksFail = '[WorkbookService] Get Whizzimo Library Workooks Fail',


    GetMyLibraryWorkbooks = '[WorkbookService] Get All My Library Workbooks',
    GetMyLibraryWorkbooksSuccess = '[WorkbookService] Get All My Library Workbooks Success',
    GetMyLibraryWorkbooksFail = '[WorkbookService] Get All My Library Workooks Fail',

    ClearWorkbooks = '[WorkbookService] Clear Workbooks',

    CreateWorkbook = '[WorkbookService] Create Workbook',
    CreateWorkbookSuccess = '[WorkbookService] Create Workbook Success',
    CreateWorkbookFail = '[WorkbookService] Create Workook Fail',

    CopyWorkbook = '[WorkbookService] Copy Workbook',
    CopyWorkbookSuccess = '[WorkbookService] Copy Workbook Success',
    CopyWorkbookFail = '[WorkbookService] Copy Workook Fail',

    
    UpdateWorkbook = '[WorkbookService] Update Workbook',
    UpdateWorkbookSuccess = '[WorkbookService] Update Workbook Success',
    UpdateWorkbookFail = '[WorkbookService] Update Workbook Fail',

    DeleteWorkbook = '[WorkbookService] Delete Workbook',
    DeleteWorkbookSuccess = '[WorkbookService] Delete Workbook Success',
    DeleteWorkbookFail = '[WorkbookService] Delete Workook Fail',

    RemoveWorkbookFromBuilder = '[CourseWorkbookService] Remove Workbook to Course Via Builder Page',
    RemoveWorkbookFromBuilderSuccess = '[CourseWorkbookService] Remove Workbook to Course Via Builder Page Success',
    RemoveWorkbookFromBuilderFail = '[CourseWorkbookService] Remove Workbook to Course Via Builder Page Fail',


    StoreWorkbookItem = '[CourseWorkbookService] Store Workbook Item',
    StoreWorkbookItemSuccess = '[CourseWorkbookService] Store Workbook Item Success',
}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class GetWorkbook implements Action {
    readonly type = WorkbookActionTypes.GetWorkbook;

    constructor(public payload: any) {}
}
export class GetWorkbookSuccess implements Action {
    readonly type = WorkbookActionTypes.GetWorkbookSuccess;

    constructor(public payload: any) {}
}
export class GetWorkbookFail implements Action {
    readonly type = WorkbookActionTypes.GetWorkbookFail;

    constructor(public payload: any) {}
}

export class GetWhizzimoLibraryWorkbooks implements Action {
    readonly type = WorkbookActionTypes.GetWhizzimoLibraryWorkbooks;

    constructor(public payload: any) {}
}
export class GetWhizzimoLibraryWorkbooksSuccess implements Action {
    readonly type = WorkbookActionTypes.GetWhizzimoLibraryWorkbooksSuccess;

    constructor(public payload: any) {}
}
export class GetWhizzimoLibraryWorkbooksFail implements Action {
    readonly type = WorkbookActionTypes.GetWhizzimoLibraryWorkbooksFail;

    constructor(public payload: any) {}
}

export class GetMyLibraryWorkbooks implements Action {
    readonly type = WorkbookActionTypes.GetMyLibraryWorkbooks;

    constructor(public payload: any) {}
}
export class GetMyLibraryWorkbooksSuccess implements Action {
    readonly type = WorkbookActionTypes.GetMyLibraryWorkbooksSuccess;

    constructor(public payload: any) {}
}
export class GetMyLibraryWorkbooksFail implements Action {
    readonly type = WorkbookActionTypes.GetMyLibraryWorkbooksFail;

    constructor(public payload: any) {}
}

export class CreateWorkbook implements Action {
    readonly type = WorkbookActionTypes.CreateWorkbook;

    constructor(public payload: any) {}
}
export class CreateWorkbookSuccess implements Action {
    readonly type = WorkbookActionTypes.CreateWorkbookSuccess;

    constructor(public payload: any) {}
}
export class CreateWorkbookFail implements Action {
    readonly type = WorkbookActionTypes.CreateWorkbookFail;

    constructor(public payload: any) {}
}
export class CopyWorkbook implements Action {
    readonly type = WorkbookActionTypes.CopyWorkbook;

    constructor(public payload: any) {}
}
export class CopyWorkbookSuccess implements Action {
    readonly type = WorkbookActionTypes.CopyWorkbookSuccess;

    constructor(public payload: any) {}
}
export class CopyWorkbookFail implements Action {
    readonly type = WorkbookActionTypes.CopyWorkbookFail;

    constructor(public payload: any) {}
}

export class UpdateWorkbook implements Action {
    readonly type = WorkbookActionTypes.UpdateWorkbook;

    constructor(public payload: any) {}
}
export class UpdateWorkbookSuccess implements Action {
    readonly type = WorkbookActionTypes.UpdateWorkbookSuccess;

    constructor(public payload: any) {}
}
export class UpdateWorkbookFail implements Action {
    readonly type = WorkbookActionTypes.UpdateWorkbookFail;

    constructor(public payload: any) {}
}

export class DeleteWorkbook implements Action {
    readonly type = WorkbookActionTypes.DeleteWorkbook;

    constructor(public payload: any) {}
}
export class DeleteWorkbookSuccess implements Action {
    readonly type = WorkbookActionTypes.DeleteWorkbookSuccess;

    constructor(public payload: any) {}
}
export class DeleteWorkbookFail implements Action {
    readonly type = WorkbookActionTypes.DeleteWorkbookFail;

    constructor(public payload: any) {}
}

export class RemoveWorkbookFromBuilder implements Action {
    readonly type = WorkbookActionTypes.RemoveWorkbookFromBuilder;

    constructor(public payload: any) {
    }
}

export class RemoveWorkbookFromBuilderSuccess implements Action {
    readonly type = WorkbookActionTypes.RemoveWorkbookFromBuilderSuccess;

    constructor(public payload: any) {
    }
}

export class RemoveWorkbookFromBuilderFail implements Action {
    readonly type = WorkbookActionTypes.RemoveWorkbookFromBuilderFail;

    constructor(public payload: any) {
    }
}

export class ClearWorkbooks implements Action {
    readonly type = WorkbookActionTypes.ClearWorkbooks;
}

export class StoreWorkbookItem implements Action {
    readonly type = WorkbookActionTypes.StoreWorkbookItem;

    constructor(public payload: any) {
    }
}

export class StoreWorkbookItemSuccess implements Action {
    readonly type = WorkbookActionTypes.StoreWorkbookItemSuccess;

    constructor(public payload: any) {
    }
}

export type WorkbookActions
= GetWorkbook
| GetWorkbookSuccess
| GetWorkbookFail
| GetMyLibraryWorkbooks
| GetMyLibraryWorkbooksSuccess
| GetMyLibraryWorkbooksFail
| GetWhizzimoLibraryWorkbooks
| GetWhizzimoLibraryWorkbooksSuccess
| GetWhizzimoLibraryWorkbooksFail
| ClearWorkbooks
| CreateWorkbook
| CreateWorkbookSuccess
| CreateWorkbookFail
| CopyWorkbook
| CopyWorkbookSuccess
| CopyWorkbookFail
| UpdateWorkbook
| UpdateWorkbookSuccess
| UpdateWorkbookFail
| DeleteWorkbook
| DeleteWorkbookSuccess
| DeleteWorkbookFail
| RemoveWorkbookFromBuilder
| RemoveWorkbookFromBuilderSuccess
| RemoveWorkbookFromBuilderFail
| StoreWorkbookItem
| StoreWorkbookItemSuccess;
