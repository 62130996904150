import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'app-add-collection-dialog',
    templateUrl: './add-collection-dialog.component.html',
    styleUrls: ['./add-collection-dialog.component.scss'],
    // encapsulation : ViewEncapsulation.None
})
export class AddCollectionDialogComponent implements OnInit {
    functions;
    name = new UntypedFormControl;
    disabled = false;
    compact = false;
    invertX = false;
    invertY = false;
    public maxlength: boolean;
    // renameCollectionForm = new FormGroup({
    //     name: new FormControl('', [Validators.maxLength(40)])
    // });
    renameCollectionForm: UntypedFormGroup;
    shown: 'native' | 'hover' | 'always' = 'native';
    constructor(public dialogRef: MatDialogRef<AddCollectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: UntypedFormBuilder
    ) {
        this.renameCollectionForm = this.formBuilder.group({
          name: [this.data.name, [Validators.required, Validators.maxLength(40), (control: AbstractControl) => {
            const exist = this.data.collections?.find(collection => collection.name === control.value && collection._id !== this.data.id);
            if (exist) {
              return { duplicate: true};
            }
            return false;
          }]]
        });
    }


    ngOnInit() {
    }

    onCancel() {
        this.dialogRef.close();
    }

    onSave() {
      this.functions.rename(this.renameCollectionForm.get('name').value);
      this.dialogRef.close();
    }

    openCreateCollection(type: string) {
        this.functions.setType(type);
        this.dialogRef.close();
    }

    // onkey(event: any): void {
    //     const name = this.renameCollectionForm.get('name');
    //     this.maxlength = name.status === "INVALID"  ? true : false;
    // }

    setShown() {
        if (this.shown === 'native') {
            this.shown = 'hover';
        } else if (this.shown === 'hover') {
            this.shown = 'always';
        } else {
            this.shown = 'native';
        }
    }
}
