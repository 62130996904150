import * as CourseWorkbooksActions from './course-workbook.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface State extends EntityState<any> {
    selectedId: string;
    id: string;
    pending: boolean;
    error: any;
    succeeded: boolean;
    all: boolean;
    courseWorkbook: any;
}

export const adapter: EntityAdapter<string> = createEntityAdapter({
    selectId: (CourseWorkbook: any) => CourseWorkbook._id
});
const initialState: State = adapter.getInitialState({
    selectedId: null,
    id: null,
    pending: false,
    error: null,
    succeeded: false,
    all: false,
    courseWorkbook: null,
});


export function reducer(state = initialState, action: CourseWorkbooksActions.CourseWorkbookAction): State {
    switch (action.type) {
        case CourseWorkbooksActions.CourseWorkbookActionTypes.GetAllCourseWorkbooks: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.GetAllCourseWorkbooksSuccess: {
            return <any>{
                ...adapter.addMany(action.payload.items, state),
                selectedId: action.payload.id,
                succeeded: true,
                pending: false,
                all: true,
                error: null,
            };
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.GetAllCourseWorkbooksFail: {
            return {
                ...state,
                succeeded: false,
                pending: false,
                selectedId: null,
                error: action.payload
            };
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.ClearCourseWorkbooks: {
            return {
                ...adapter.removeAll(state),
                selectedId: null,
                succeeded: false,
                pending: false,
                error: null
            };
        }

        case CourseWorkbooksActions.CourseWorkbookActionTypes.GetCourseWorkbook: {
            return {
                ...state,
                // id: action.payload,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.GetCourseWorkbookSuccess: {
            if (state.entities[action.payload._id]) {
                let item = JSON.parse(JSON.stringify(state.entities[action.payload._id]));
                let updated = {
                    ...item,
                    ...action.payload,
                    name: item.name
                };
                return {
                    ...adapter.updateOne({
                        id: action.payload._id,
                        changes: updated
                    }, state),
                    succeeded: true,
                    pending: false,
                    // id: state.id,
                    // courseWorkbook: action.payload,
                    error: null
                };

            } else {
                return {
                    ...adapter.addOne(action.payload, state),
                    succeeded: true,
                    pending: false,
                    error: null
                };
            }
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.GetCourseWorkbookFail: {
            return {
                ...state,
                succeeded: false,
                pending: false,
                id: null,
                error: action.payload
            };
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.SetCurrentCourseWorkbook: {
            return {
                ...state,
                succeeded: true,
                pending: false,
                error: null,
                courseWorkbook: state.entities[action.payload],
            };
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.AddWorkbookToCourse: {
            return {
                ...state,
                succeeded: false,
                pending: true,
                error: null,
            };
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.AddWorkbookToCourseSuccess: {
            return {
                ...adapter.addOne(action.payload, state),
                succeeded: true,
                pending: false,
                error: null,
            };
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.RemoveWorkbookFromCourse: {
            return {
                ...state,
                succeeded: false,
                pending: true,
                error: null,
            };
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.RemoveWorkbookFromCourseSuccess: {
            return {
                ...adapter.removeOne(action.payload, state),
                succeeded: true,
                pending: false,
                error: null,
            };
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.RemoveWorkbookFromCourseViaLibrary: {
            return {
                ...state,
                succeeded: false,
                pending: true,
                error: null,
            };
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.RemoveWorkbookFromCourseViaLibrarySuccess: {
            return {
                ...adapter.removeOne(action.payload, state),
                succeeded: true,
                pending: false,
                error: null,
            };
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.CopyCourseWorkbook: {
            return {
                ...state,
                succeeded: false,
                pending: true,
                error: null,
            };
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.CopyCourseWorkbookSuccess: {
            return {
                ...adapter.addOne(action.payload, state),
                succeeded: true,
                pending: false,
                error: null,
            };
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.CreateCourseWorkbook: {
            return {
                ...state,
                succeeded: false,
                pending: true,
                error: null,
            };
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.CreateCourseWorkbookSuccess: {
            const payload = JSON.parse(JSON.stringify(action.payload));
            if (Array.isArray(payload.workbook)) {
                payload.workbook = payload.workbook[0];
            }
            return {
                ...adapter.addOne(payload, state),
                succeeded: true,
                pending: false,
                error: null,
            };
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.UpdateByWorkbook: {
            return {

                // ...adapter.addOne(action.payload.courseWorkbook, state),

                ...state,
                succeeded: false,
                pending: true,
                error: null,
            };
        }
        case CourseWorkbooksActions.CourseWorkbookActionTypes.UpdateByWorkbookSuccess: {
            return {
                ...state,
                succeeded: true,
                pending: false,
                error: null,
            };
        }
        default:
            return state;
    }
}

export const {
    selectIds: SelectAllIds,
    selectEntities: SelectAllEntities,
    selectAll: SelectAllCourseWorkbook,
} = adapter.getSelectors();

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.succeeded;
export const getAllSucceeded = (state: State) => state.all;
export const getError = (state: State) => state.error;
export const getSelectedCourseWorkbook = (state: State) => {
    return state.entities[state.selectedId];
};
export const getCourseWorkbook = (state: State) => state.courseWorkbook;
export const getAllCourseWorkbook = (state: State) => {
    let subscriptions = SelectAllCourseWorkbook(state);
    return subscriptions;
};
