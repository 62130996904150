import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-quick-workbook-search',
    templateUrl: './quick-workbook-search.component.html',
    styleUrls: ['./quick-workbook-search.component.scss'],
})
export class QuickWorkbookSearchComponent implements OnInit, OnDestroy {
    @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

    @Input() userWorkbooks: any[] = [];
    @Input() libraryWorkbooks: any[] = [];
    @Input() courseWorkbooks: any[] = [];
    @Input() courseWorkbooks$: Observable<any[]> = new Observable();
    @Output() addToCourseWorkbook: EventEmitter<any> = new EventEmitter();
    @Output() requestScrollToBottom = new EventEmitter<void>();

    filteredMyWorkbooks: any[] = [];
    filteredWhizzimoWorkbooks: any[] = [];

    searchQuery: any = '';
    loadingWorkbooks: { [id: string]: boolean } = {}; // Track loading state for each workbook

    // Observables for input debounce
    private searchSubject = new Subject<string>();
    private onDestroy = new Subject<void>();

    userWorkbooksSet: Set<string> = new Set();
    prefilteredLibraryWorkbooks: any[] = [];

    ngOnInit() {

        this.courseWorkbooks$.pipe(
            takeUntil(this.onDestroy)
        ).subscribe((courseWorkbooks) => {
            this.courseWorkbooks = courseWorkbooks;

            this.userWorkbooks = this.userWorkbooks.map((workbook) => {
                if (this.courseWorkbooks.some((courseWorkbook) => courseWorkbook.name === workbook.name || courseWorkbook.workbook?.name === workbook.name)) {
                    workbook.added = true;
                }
                return workbook;
            });

            this.filteredMyWorkbooks = this.filteredMyWorkbooks.map((workbook) => {
                if (this.courseWorkbooks.some((courseWorkbook) => courseWorkbook.name === workbook.name || courseWorkbook.workbook?.name === workbook.name)) {
                    workbook.added = true;
                }
                return workbook;
            });

            this.libraryWorkbooks = this.libraryWorkbooks.map((workbook) => {
                if (this.courseWorkbooks.some((courseWorkbook) => courseWorkbook.name === workbook.name || courseWorkbook.workbook?.name === workbook.name)) {
                    workbook.added = true;
                }
                return workbook;
            });

            this.filteredWhizzimoWorkbooks = this.filteredWhizzimoWorkbooks.map((workbook) => {
                if (this.courseWorkbooks.some((courseWorkbook) => courseWorkbook.name === workbook.name || courseWorkbook.workbook?.name === workbook.name)) {
                    workbook.added = true;
                }
                return workbook;
            });

            // Cache the user workbooks as a Set for faster lookups
            this.userWorkbooksSet = new Set(this.userWorkbooks.map((workbook) => workbook.name ? workbook.name : workbook.workbook.name));

            // Pre-filter the library workbooks once to remove those already in user workbooks
            this.prefilteredLibraryWorkbooks = this.libraryWorkbooks.filter(
                (workbook) => !this.userWorkbooksSet.has(workbook.name ? workbook.name : workbook.workbook.name)
            );

            // Sorts "My Workbooks" alphabetically
            this.userWorkbooks = this.userWorkbooks.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

            // if(this.searchQuery.target.value !== "" && this.searchQuery.target.value !== undefined && this.searchQuery.target.value !== null){
            //     this.filterWorkbooks(this.searchQuery.target.value);
            // }
            for(let workbook of Object.keys(this.loadingWorkbooks)) {
                this.loadingWorkbooks[workbook] = false;
            }
        });

        // Initialize filtered lists
        this.filteredMyWorkbooks = this.userWorkbooks;
        this.filteredWhizzimoWorkbooks = this.prefilteredLibraryWorkbooks;
        // this.courseWorkbooks$.pipe(
        //     take(1)
        // ).subscribe((courseWorkbooks) => {
        //     this.courseWorkbooks = courseWorkbooks;

        //     this.userWorkbooks = this.userWorkbooks.map((workbook) => {
        //         if (this.courseWorkbooks.some((courseWorkbook) => courseWorkbook.name === workbook.name || courseWorkbook.workbook?.name === workbook.name)) {
        //             workbook.added = true;
        //         }
        //         return workbook;
        //     });

        //     this.libraryWorkbooks = this.libraryWorkbooks.map((workbook) => {
        //         if (this.courseWorkbooks.some((courseWorkbook) => courseWorkbook.name === workbook.name || courseWorkbook.workbook?.name === workbook.name)) {
        //             workbook.added = true;
        //         }
        //         return workbook;
        //     });

        //     // Cache the user workbooks as a Set for faster lookups
        //     this.userWorkbooksSet = new Set(this.userWorkbooks.map((workbook) => workbook.name ? workbook.name : workbook.workbook.name));

        //     // Pre-filter the library workbooks once to remove those already in user workbooks
        //     this.prefilteredLibraryWorkbooks = this.libraryWorkbooks.filter(
        //         (workbook) => !this.userWorkbooksSet.has(workbook.name ? workbook.name : workbook.workbook.name)
        //     );

        //     // Sorts "My Workbooks" alphabetically
        //     this.userWorkbooks = this.userWorkbooks.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

        //     // Initialize filtered lists
        //     this.filteredMyWorkbooks = [...this.userWorkbooks];
        //     this.filteredWhizzimoWorkbooks = [...this.prefilteredLibraryWorkbooks];

        //     // if(this.searchQuery && this.searchQuery.target.value !== "" && this.searchQuery.target.value !== undefined && this.searchQuery.target.value !== null){
        //     //     this.filterWorkbooks(this.searchQuery.target.value);
        //     // }
        // });

        // Handle search input with debounce
        this.searchSubject
            .pipe(
                // debounceTime(100), // Delay for 300ms to prevent excessive filtering
                // distinctUntilChanged(),
                takeUntil(this.onDestroy)
            )
            .subscribe((query) => {
                this.filterWorkbooks(query);
            });
    }

    ngOnDestroy() {
        // Clean up subscriptions
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    // Function to handle input change and trigger debounce
    onSearchChange(event: any) {
        this.searchQuery = event;
        this.searchSubject.next(event.target.value);
    }

    // Function to filter workbooks based on search query
    private filterWorkbooks(query: string) {
        const lowerQuery = query.toLowerCase();

        // Filter My Workbooks
        this.filteredMyWorkbooks = this.userWorkbooks.filter((workbook) =>
            workbook.name.toLowerCase().includes(lowerQuery)
        );

        // Filter prefiltered library workbooks
        this.filteredWhizzimoWorkbooks = this.prefilteredLibraryWorkbooks.filter((workbook) =>
            workbook.name.toLowerCase().includes(lowerQuery)
        );
    }

    clearSearch() {
        this.searchQuery = '';
        this.onSearchChange(''); // Clear search results
    }

    // // Handle option selection but keep the dropdown open
    // onOptionSelected(event: any) {
    //     const selectedWorkbook = event.option.value;
    //     if (selectedWorkbook.added) {
    //         this.removeWorkbook(selectedWorkbook);
    //     } else {
    //         this.addWorkbook(selectedWorkbook);
    //     }

    //     // Manually reopen the panel to prevent it from closing
    //     // setTimeout(() => {
    //     //     this.autocompleteTrigger.openPanel();
    //     // });
    // }

    addWorkbook(workbook, type) {

        if (type === "" || type === undefined || type === null) {
            return;
        }
        
        // Check if workbook is already in course workbooks
        if (this.courseWorkbooks.some((courseWorkbook) => courseWorkbook.name === workbook.name || courseWorkbook.workbook?.name === workbook.name)) {
            return;
        }

        this.loadingWorkbooks[workbook._id] = true;

        this.addToCourseWorkbook.emit({ workbook, type });

        // NOTE: This may need some debouncing
        // setTimeout(() => {
        //     this.requestScrollToBottom.emit();
        // }, 1500);
        // if(type === "MyLibrary") {
        //     this.userWorkbooks = this.userWorkbooks.map((userWorkbook) => {
        //         if (userWorkbook.name === workbook.name || userWorkbook.workbook?.name === workbook.name) {
        //             userWorkbook.added = true;
        //         }
        //         return userWorkbook;
        //     });
        //     // this.filteredMyWorkbooks = [...this.userWorkbooks];
        // } else {
        //     this.libraryWorkbooks = this.libraryWorkbooks.map((libraryWorkbook) => {
        //         if (libraryWorkbook.name === workbook.name || libraryWorkbook.workbook?.name === workbook.name) {
        //             libraryWorkbook.added = true;
        //         }
        //         return libraryWorkbook;
        //     });
        //     // this.filteredWhizzimoWorkbooks = [...this.libraryWorkbooks];
        // }
    }

    // Function to display workbook title in the dropdown
    displayFn(workbook): string {
        return workbook ? workbook.name : '';
    }

    toggleLoadingSpinner(workbookId: string, loading: boolean) {
        return this.loadingWorkbooks[workbookId] = loading;
    }

    // Toggle the dropdown to show all results
    toggleDropdown() {
        if (this.autocompleteTrigger.panelOpen) {
            this.autocompleteTrigger.closePanel();
        } else {
            this.autocompleteTrigger.openPanel();
        }
    }
}