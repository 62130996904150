import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import { GetManyWorkbookPhrases } from "../store/phrase/phrase.actions";
import * as selectors from "../store";


@Injectable()
export class WorkbookPhrasesResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const workbookId = route.params.workbookId;
    return this.store.pipe(
      select(selectors.isWorkbookPhrasesLoaded, { id: workbookId}),
      tap(isLoaded => {
        // if (!isLoaded) {
          this.store.dispatch(new GetManyWorkbookPhrases(workbookId));
        // }
      }),
      filter(isLoaded => {
        if (workbookId === 'new') { return true; }
        return isLoaded;
      }),
      first(),
    );
  }
}

