import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], criteria: Object): any {
      return items.filter(item => {
        let result = true;
        for (const [key, value] of Object.entries(criteria)) {
          if (value && !(item[key].toLowerCase()).includes(value.toLowerCase())) {
            result = false;
            break;
          }
        }
        return result;
      });
  }
}
