import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_DIR } from "../../environments/environment";
import { Observable } from "rxjs";

@Injectable()
export class FeedbackService {
    constructor(private http: HttpClient) { }

    createFeedback(feedback: any) {
        return new Observable(observer => {
            this.http
                .post(`${API_DIR}/feedback`, feedback)
                .subscribe(
                    (res: any) => {
                        observer.next(res.data);
                    },
                    error => {
                        throw error;
                    }
                );
        });
    }
}