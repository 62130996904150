import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_DIR} from '../../environments/environment';

@Injectable()
export class PrivacyPolicyService {
  constructor(private http: HttpClient) {
  }
  get() {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/privacyPolicy`)
        .subscribe(
          (privacyPolicy: any) => {
            observer.next(privacyPolicy.data);
          },
          error => {
            throw error;
          }
        );
    });
  }
}
