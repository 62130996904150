import { NgModule } from '@angular/core';

import { CoreModule } from '../core/core.module';

import {DiscontinuedModalComponent, LoginComponent, LoginFormDialogComponent} from './login.component';

import { LoginService } from '../core/services/login.service';
import { AuthService } from '../core/services/auth.service';

import { LoginRoutingModule } from './login-routing.module';
// test
@NgModule({
  declarations: [LoginComponent, LoginFormDialogComponent, DiscontinuedModalComponent],
  imports: [CoreModule, LoginRoutingModule],
  providers: [LoginService, AuthService]
})
export class LoginModule {}
