// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field {
  width: 40%;
}

.filter {
  margin-top: 5px;
  margin-bottom: 10px;
}

.form-item {
  margin-left: 10px;
  margin-right: 10px;
}

.tile-box {
  height: 35vh;
}

.tile-trash {
  height: 20%;
  background: #ccc;
  border: dotted 3px #999;
  min-height: 50px;
}

.filter-box {
  margin-right: auto;
  margin-left: auto;
  height: 100%;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.wordlist {
  width: 20vh;
}`, "",{"version":3,"sources":["webpack://./src/wordlist/Words/words.scss"],"names":[],"mappings":"AAIA;EACE,UAAA;AAHF;;AAKA;EACE,eAAA;EACA,mBAAA;AAFF;;AAKA;EACE,iBAAA;EACA,kBAAA;AAFF;;AAKA;EACE,YAAA;AAFF;;AAKA;EACE,WAAA;EAEA,gBAAA;EACA,uBAAA;EACA,gBAAA;AAHF;;AAMA;EACE,kBAAA;EACA,iBAAA;EACA,YAAA;AAHF;;AAKA;EACE,UAAA;AAFF;;AAIA;EACE,WAAA;AADF","sourcesContent":["// .container {\n//   padding: 35px;\n// }\n\n.input-field {\n  width: 40%;\n}\n.filter {\n  margin-top: 5px;\n  margin-bottom: 10px;\n}\n\n.form-item {\n  margin-left: 10px;\n  margin-right: 10px;\n}\n\n.tile-box {\n  height: 35vh;\n}\n\n.tile-trash {\n  height: 20%;\n\n  background: #ccc;\n  border: dotted 3px #999;\n  min-height: 50px;\n}\n\n.filter-box {\n  margin-right: auto;\n  margin-left: auto;\n  height: 100%;\n}\n.cdk-drag-placeholder {\n  opacity: 0;\n}\n.wordlist {\n  width: 20vh;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
