// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .same_plan_title {
        padding: 20px;
      }
      mat-dialog-content {
        display: block !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        font-size: 14px !important;
        letter-spacing: normal !important;
        line-height: 20px !important;
      }
    `, "",{"version":3,"sources":["webpack://./src/Settings/Account/account.component.ts"],"names":[],"mappings":";MACM;QACE,aAAa;MACf;MACA;QACE,yBAAyB;QACzB,yBAAyB;QACzB,4BAA4B;QAC5B,0BAA0B;QAC1B,iCAAiC;QACjC,4BAA4B;MAC9B","sourcesContent":["\n      .same_plan_title {\n        padding: 20px;\n      }\n      mat-dialog-content {\n        display: block !important;\n        padding-top: 0 !important;\n        padding-bottom: 0 !important;\n        font-size: 14px !important;\n        letter-spacing: normal !important;\n        line-height: 20px !important;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
