import {Subject} from 'rxjs';

export class TileActivityService {
  static setTilePositionsSubject = new Subject<any>();

  tileDropdownSettings: any[] = [
    {
      label: 'Tile Color',
      model: 'tilebgcolor',
      values: [
        { value: 'c-red', display: 'Red' },
        { value: 'c-peach', display: 'Peach' },
        { value: 'c-yellow', display: 'Yellow' },
        { value: 'c-lightyellow', display: 'Light Yellow' },
        { value: 'c-green', display: 'Green' },
        { value: 'c-lightgreen', display: 'Light Green' },
        { value: 'c-blue', display: 'Blue' },
        { value: 'c-lightblue', display: 'Light Blue' },
        { value: 'c-purple', display: 'Purple' },
        { value: 'c-lightpurple', display: 'Light Purple' },
        { value: 'c-orange', display: 'Orange' },
        { value: 'c-gray', display: 'Gray' },
        { value: 'c-pink', display: 'Pink' },
        { value: 'c-cream', display: 'Cream' },
        { value: 'c-transparent', display: 'Transparent' },
      ]
    },
    {
      label: 'Tile Font Color',
      model: 'tilefontcolor',
      values: [
        { value: 'fc-black', display: 'Black' },
        { value: 'fc-white', display: 'White' },
        { value: 'fc-red', display: 'Red' },
      ]
    },
  ];

  getObservable() {
    return TileActivityService.setTilePositionsSubject.asObservable();
  }

  setTilePositions() {
    TileActivityService.setTilePositionsSubject.next(null);
  }
}
