import { reducers } from '../store/index';
import * as CourseWorkbookActions from '../store/course-workbook/course-workbook.actions';
import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import * as selectors from "../store";

@Injectable()
export class AllCourseWorkbooksResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(selectors.getSetCourseData),
      tap(courseData => {
        if (courseData?.id) {
          this.store.dispatch(new CourseWorkbookActions.GetAllCourseWorkbooks(courseData.id));
        }
      }),
      filter(data => {
        if (data?._id) {
          return true;
        }
        // return !!data;
      }),
      first()
    );
  }
}
