import { Component, OnInit } from '@angular/core';
import { LessonsService } from '../core/services/lessons.service';

@Component({
  selector: 'app-lessons',
  templateUrl: './html/lessons.html',
  styleUrls: ['../assets/css/main.css']
})

export class LessonsComponent implements OnInit {

  constructor(private _curr: LessonsService) {}

  ngOnInit() {}
}
