import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum AccountActionTypes {
    GetAllSubscriptions = '[AccountService] Get All Account Subscriptions',
    GetAllSubscriptionsSuccess = '[AccountService] Get All Account Subscriptions Success',
    GetAllSubscriptionsFail = '[AccountService] Get All Account Subscriptions Fail',

    ResendEmailVerification = '[AccountService] Resend Email Verification',
    ResendEmailVerificationSuccess = '[AccountService] Resend Email Verification Success',
    ResendEmailVerificationFail = '[AccountService] Resend Email Verification Fail',

    CancelRenewal = '[AccountService] Cancel Renewal',
    CancelRenewalSuccess = '[AccountService] Cancel Renewal Success',
    CancelRenewalFail = '[AccountService] Cancel Renewal Fail',

    ScheduleNewPlan = '[AccountService] Schedule New Plan',
    ScheduleNewPlanSuccess = '[AccountService] Schedule New Plan Success',
    ScheduleNewPlanFail = '[AccountService] Schedule New Plan Fail',

    ChangePlan = '[AccountService] Change Plan',
    ChangePlanSuccess = '[AccountService] Change Plan Success',
    ChangePlanFail = '[AccountService] Change Plan Fail',

    DeleteSubscriptionPromo = '[AccountService] Delete Subscription Promo',
    DeleteSubscriptionPromoSuccess = '[AccountService] Delete Subscription Promo Success',
    DeleteSubscriptionPromoFail = '[AccountService] Delete Subscription Promo Fail',

    DeleteCustomerPromo = '[AccountService] Delete Customer Promo',
    DeleteCustomerPromoSuccess = '[AccountService] Delete Customer Promo Success',
    DeleteCustomerPromoFail = '[AccountService] Delete Customer Promo Fail',

    GetPromoCode = '[AccountService] Get Promo Code',
    GetPromoCodeSuccess = '[AccountService] Get Promo Code Success',
    GetPromoCodeFail = '[AccountService] Get Promo Code Fail',

    GetPaymentAccount = '[AccountService] Get Payment Account',
    GetPaymentAccountSuccess = '[AccountService] Get Payment Account Success',
    GetPaymentAccountFail = '[AccountService] Get Payment Account Fail',

    DeleteCreditCard = '[AccountService] Delete Credit Card',
    DeleteCreditCardSuccess = '[AccountService] Delete Credit Card Success',
    DeleteCreditCardFail = '[AccountService] Delete Credit Card Fail',

    AddCreditCard = '[AccountService] Add Credit Card',
    AddCreditCardSuccess = '[AccountService] Add Credit Card Success',
    AddCreditCardFail = '[AccountService] Add Credit Card Fail',

    CancelSubscription = '[AccountService] Cancel Subscriptions',
    CancelSubscriptionSuccess = '[AccountService] Cancel Subscriptions Success',
    CancelSubscriptionFail = '[AccountService] Cancel Subscriptions Fail',


    CancelScheduledSubscription = '[AccountService] Cancel Scheduled Subscriptions',
    CancelScheduledSubscriptionSuccess = '[AccountService] Cancel Scheduled Subscriptions Success',
    CancelScheduledSubscriptionFail = '[AccountService] Cancel Scheduled Subscriptions Fail',

}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class GetAllSubscriptions implements Action {
    readonly type = AccountActionTypes.GetAllSubscriptions;

    constructor(public payload: any) {}
}

export class GetAllSubscriptionsSuccess implements Action {
    readonly type = AccountActionTypes.GetAllSubscriptionsSuccess;

    constructor(public payload: string) {}
}
export class GetAllSubscriptionsFail implements Action {
    readonly type = AccountActionTypes.GetAllSubscriptionsFail;

    constructor(public payload: string) {}
}

export class ResendEmailVerification implements Action {
    readonly type = AccountActionTypes.ResendEmailVerification;

    constructor(public payload: any) {}
}

export class ResendEmailVerificationSuccess implements Action {
    readonly type = AccountActionTypes.ResendEmailVerificationSuccess;

    constructor(public payload: string) {}
}

export class ResendEmailVerificationFail implements Action {
    readonly type = AccountActionTypes.ResendEmailVerificationFail;

    constructor(public payload: string) {}
}


export class CancelRenewal implements Action {
    readonly type = AccountActionTypes.CancelRenewal;

    constructor(public payload: any) {}
}
export class CancelRenewalSuccess implements Action {
    readonly type = AccountActionTypes.CancelRenewalSuccess;

    constructor(public payload: any) {}
}
export class CancelRenewalFail implements Action {
    readonly type = AccountActionTypes.CancelRenewalFail;

    constructor(public payload: string) {}
}


export class ScheduleNewPlan implements Action {
    readonly type = AccountActionTypes.ScheduleNewPlan;

    constructor(public payload: any) {}
}
export class ScheduleNewPlanSuccess implements Action {
    readonly type = AccountActionTypes.ScheduleNewPlanSuccess;

    constructor(public payload: any) {}
}
export class ScheduleNewPlanFail implements Action {
    readonly type = AccountActionTypes.ScheduleNewPlanFail;

    constructor(public payload: string) {}
}

export class ChangePlan implements Action {
    readonly type = AccountActionTypes.ChangePlan;

    constructor(public payload: any) {}
}
export class ChangePlanSuccess implements Action {
    readonly type = AccountActionTypes.ChangePlanSuccess;

    constructor(public payload: string) {}
}
export class ChangePlanFail implements Action {
    readonly type = AccountActionTypes.ChangePlanFail;

    constructor(public payload: string) {}
}

export class DeleteSubscriptionPromo implements Action {
    readonly type = AccountActionTypes.DeleteSubscriptionPromo;

    constructor(public payload: any) {}
}
export class DeleteSubscriptionPromoSuccess implements Action {
    readonly type = AccountActionTypes.DeleteSubscriptionPromoSuccess;

    constructor(public payload: string) {}
}
export class DeleteSubscriptionPromoFail implements Action {
    readonly type = AccountActionTypes.DeleteSubscriptionPromoFail;

    constructor(public payload: string) {}
}

export class DeleteCustomerPromo implements Action {
    readonly type = AccountActionTypes.DeleteCustomerPromo;

    constructor(public payload: any) {}
}
export class DeleteCustomerPromoSuccess implements Action {
    readonly type = AccountActionTypes.DeleteCustomerPromoSuccess;

    constructor(public payload: string) {}
}
export class DeleteCustomerPromoFail implements Action {
    readonly type = AccountActionTypes.DeleteCustomerPromoFail;

    constructor(public payload: string) {}
}

export class GetPromoCode implements Action {
    readonly type = AccountActionTypes.GetPromoCode;

    constructor(public payload: any) {}
}
export class GetPromoCodeSuccess implements Action {
    readonly type = AccountActionTypes.GetPromoCodeSuccess;

    constructor(public payload: string) {}
}
export class GetPromoCodeFail implements Action {
    readonly type = AccountActionTypes.GetPromoCodeFail;

    constructor(public payload: string) {}
}

export class GetPaymentAccount implements Action {
    readonly type = AccountActionTypes.GetPaymentAccount;

    constructor(public payload: any) {}
}
export class GetPaymentAccountSuccess implements Action {
    readonly type = AccountActionTypes.GetPaymentAccountSuccess;

    constructor(public payload: any) {}
}
export class GetPaymentAccountFail implements Action {
    readonly type = AccountActionTypes.GetPaymentAccountFail;

    constructor(public payload: any) {}
}

export class DeleteCreditCard implements Action {
    readonly type = AccountActionTypes.DeleteCreditCard;

    constructor(public payload: any) {}
}
export class DeleteCreditCardSuccess implements Action {
    readonly type = AccountActionTypes.DeleteCreditCardSuccess;

    constructor(public payload: any) {}
}
export class DeleteCreditCardFail implements Action {
    readonly type = AccountActionTypes.DeleteCreditCardFail;

    constructor(public payload: any) {}
}

export class AddCreditCard implements Action {
    readonly type = AccountActionTypes.AddCreditCard;

    constructor(public payload: any) {}
}
export class AddCreditCardSuccess implements Action {
    readonly type = AccountActionTypes.AddCreditCardSuccess;

    constructor(public payload: any) {}
}
export class AddCreditCardFail implements Action {
    readonly type = AccountActionTypes.AddCreditCardFail;

    constructor(public payload: any) {}
}

export class CancelSubscription implements Action {
    readonly type = AccountActionTypes.CancelSubscription;

    constructor(public payload: any) {}
}
export class CancelSubscriptionSuccess implements Action {
    readonly type = AccountActionTypes.CancelSubscriptionSuccess;

    constructor(public payload: any) {}
}
export class CancelSubscriptionFail implements Action {
    readonly type = AccountActionTypes.CancelSubscriptionFail;

    constructor(public payload: any) {}
}

export class CancelScheduledSubscription implements Action {
    readonly type = AccountActionTypes.CancelScheduledSubscription;

    constructor(public payload: any) {}
}
export class CancelScheduledSubscriptionSuccess implements Action {
    readonly type = AccountActionTypes.CancelScheduledSubscriptionSuccess;

    constructor(public payload: any) {}
}
export class CancelScheduledSubscriptionFail implements Action {
    readonly type = AccountActionTypes.CancelScheduledSubscriptionFail;

    constructor(public payload: any) {}
}

export type AccountAction
= GetAllSubscriptions
| GetAllSubscriptionsSuccess
| GetAllSubscriptionsFail
| ResendEmailVerification
| ResendEmailVerificationSuccess
| ResendEmailVerificationFail
| CancelRenewal
| CancelRenewalSuccess
| CancelRenewalFail
| ScheduleNewPlan
| ScheduleNewPlanSuccess
| ScheduleNewPlanFail
| ChangePlan
| ChangePlanSuccess
| ChangePlanFail
| DeleteSubscriptionPromo
| DeleteSubscriptionPromoSuccess
| DeleteSubscriptionPromoFail
| DeleteCustomerPromo
| DeleteCustomerPromoSuccess
| DeleteCustomerPromoFail
| GetPromoCode
| GetPromoCodeSuccess
| GetPromoCodeFail
| GetPaymentAccount
| GetPaymentAccountSuccess
| GetPaymentAccountFail
| DeleteCreditCard
| DeleteCreditCardSuccess
| DeleteCreditCardFail
| AddCreditCard
| AddCreditCardSuccess
| AddCreditCardFail
| CancelSubscription
| CancelSubscriptionSuccess
| CancelSubscriptionFail
| CancelScheduledSubscription
| CancelScheduledSubscriptionSuccess
| CancelScheduledSubscriptionFail;
