import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {title: string, content: string, logOff: boolean},
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  okayClick() {
    if (this.data.logOff) {
      this.authService.logout();
    }
  }
}
