import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_DIR } from '../../environments/environment';

import { map } from 'rxjs/operators';


@Injectable()

export class ApiService {

  constructor(private http: HttpClient) {}

  public getWorkBooks(id) {
    return this.http.get(API_DIR + '/workbook/user/' + id)
    .pipe(
      map(result => result)
    );
  }

  public mkWorkBook(json) {
    return this.http.post(API_DIR + '/workbook', json)
    .pipe(
      map(result => result)
    );

  }

  public getUserSettings(id) {
    return this.http.get(API_DIR + '/userSettings/user/' + id)
    .pipe(
      map(result => result)
    );
  }

  public updateUserSettings(id, json) {
    return this.http.put(API_DIR + '/userSettings/' + id, json)
    .pipe(
      map(result => result)
    );
  }

  public mkCourse(json) {
    return this.http.post(API_DIR + '/course', json)
    .pipe(
      map(result => result)
    );
  }

  public getUserData(id) {
    return this.http.get(API_DIR + '/account/auth0/user/' + id)
    .pipe(
      map(result => result)
    );
  }

  public removeUser(id) {
    return this.http.delete(API_DIR + '/user/' + id)
    .pipe(
      map(result => result)
    );
  }


}
