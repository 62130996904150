import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import { GetManyPassages } from "../store/passage/passage.actions";
import { selectGetPassages } from "../store";

@Injectable()
export class AllPassagesResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(selectGetPassages),
      tap(areLoaded => {
        if (!areLoaded) {
          const uid = JSON.parse(localStorage.profile).user_metadata.uid;
          this.store.dispatch(new GetManyPassages(uid));
        }
      }),
      filter(areLoaded => areLoaded),
      first(),
    );
  }
}
