import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {WordGroupCardsComponent} from './word-group-cards.component';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-word-group',
  templateUrl: './html/word-group.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
    './sentences-and-more.scss'

  ]
})

export class WordGroupComponent implements OnInit, OnDestroy {
  @Input() sectionName: string;
  @Input() sectionDescription: string;
  @Input() searchTerm: string;
  @Input() workbookId: any;
  @Input() wordGroupItems: any[] = [];
  @Input() wordGroupWorkbookItems: any[] = [];
  @Input() mismatch = '';
  @Input() itemsObservable: Observable<any>;
  @Output() addToWorkbook: EventEmitter<any> = new EventEmitter<any>(false);
  @Output() removeFromWorkbook: EventEmitter<any> = new EventEmitter<any>();
  @Output() remove: EventEmitter<any> = new EventEmitter<any>(false);
  @Output() update: EventEmitter<any> = new EventEmitter<any>(false);
  @Output() reorder: EventEmitter<any> = new EventEmitter<any>(false);
  @Output() addContentEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearMismatchEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('wordGroupCards', {static: false}) wordGroupCardsChild: WordGroupCardsComponent;
  addingNew = false;
  @Input() bankOpen = false;

  constructor(
  ) {}

  ngOnInit() {

  }

  ngOnDestroy() {
  }

  addToWorkbookEvent(itemId: string) {
    this.addToWorkbook.emit(itemId);
  }

  removeWorkbookEvent(itemId: string) {
    this.removeFromWorkbook.emit({collectionId: this.workbookId, itemId: itemId});
  }

  removeEvent(itemId: string) {
    this.remove.emit(itemId);
  }

  updateEvent(update: any) {
    this.update.emit(update);
  }

  reorderEvent(reorder: any) {
    this.reorder.emit(reorder);
  }

  openNewCard() {
    this.addingNew = true;
  }

  closeNewCard() {
    this.wordGroupCardsChild.model = '';
    this.addingNew = false;
  }

  addNewContent(content: any) {
    this.addContentEvent.emit(content);
  }

  clearMismatch(mismatchedLocation) {
    this.clearMismatchEvent.emit(mismatchedLocation);
  }

  toggleBank() {
    this.bankOpen = !this.bankOpen;
  }
}
