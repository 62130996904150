import { Store } from '@ngrx/store';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { ActivityService } from '../../services/activity.service';
import { ListenerService } from '../../../core/services/listener.service';
import {LessonPlansService} from '../../services/lesson-plans.service';
import {take, takeUntil} from 'rxjs/operators';
import {LessonPlansStepsService} from '../../services/lesson-plans-steps.service';
import {combineLatest, Subject} from 'rxjs';
import { _variable_images } from '../../../environments/environment';
import { DataService } from '../../services/data.service';
import { selectGetAllLessonEditedSteps, selectGetAllLessons, selectGetLessonStep } from '../../store';

@Component({
  selector: 'app-header-lesson-plans',
  templateUrl: './html/header-lesson-plans.html',
  styleUrls: [
    './header-activities.scss',
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss'
  ]
})

export class HeaderLessonPlansComponent implements OnInit, OnDestroy {
  @Input() courseId: string;
  @Input() lessonPlanId: string;
  @Input() lessonPlanStepId: string;
  @Output() toggleLessonPlansMenuEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleLessonPlanStepsMenuEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleHeaderHover: EventEmitter<any> = new EventEmitter<any>();

  public lessonPlan: any;
  public lessonPlanStep: any;
  public lessonPlans: any[];
  public lessonPlanSteps: any[];
  public lessonPlanStepIndex: number;
  downActivityImage: any;
  private unsubscribe$: Subject<void> = new Subject();
  lessonPlanStep$ = this.store.select(selectGetLessonStep);


  constructor(private lessonPlansService: LessonPlansService, private lessonPlanStepsService: LessonPlansStepsService,
    private dataService: DataService, private store: Store) {}

  ngOnInit() {
    this.dataService._variable_images.pipe(takeUntil(this.unsubscribe$)).subscribe(path => {
      this.downActivityImage = path + 'down-activity.png';
    });
    // this.downActivityImage = _variable_images + 'down-activity.png';
    combineLatest([
      // this.lessonPlansService.getAll(this.courseId),
      this.store.select(selectGetAllLessons),
      // this.lessonPlanStepsService.getAll(this.lessonPlanId)
      this.store.select(selectGetAllLessonEditedSteps)
    ])
      // .pipe(take(1))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([lessonPlans, lessonPlanSteps]) => {
        if (!lessonPlans || !lessonPlanSteps) {
          this.lessonPlans = [];
          this.lessonPlanSteps = [];
          return;
        }
        // @ts-ignore
        this.lessonPlans = JSON.parse(JSON.stringify(lessonPlans));
        // @ts-ignore
        this.lessonPlanSteps = JSON.parse(JSON.stringify(lessonPlanSteps));

        this.lessonPlan = this.lessonPlans.find(lessonPlan => lessonPlan._id === this.lessonPlanId);

        // since deletions cause the order value of the lesson plan to be off adjust the order value just in case
        const sortedLessonPlanSteps = this.lessonPlanSteps
          .sort((a, b) => {
            if (a.order > b.order) {
              return 1;
            }

            if (a.order < b.order) {
              return -1;
            }

            return 0;
          })
          .map((lessonPlan, index) => {
            lessonPlan.order = index;

            return lessonPlan;
          });

        this.lessonPlanStepIndex = sortedLessonPlanSteps.findIndex(lessonPlanStep => lessonPlanStep._id === this.lessonPlanStepId);

        this.lessonPlanStep = sortedLessonPlanSteps[this.lessonPlanStepIndex];
      });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleLessonPlansMenu() {
    this.toggleLessonPlansMenuEvent.emit();
  }

  toggleLessonPlanStepsMenu() {
    this.toggleLessonPlanStepsMenuEvent.emit();
  }

  toggleHeaderHoverEvent(isEnter: boolean) {
    this.toggleHeaderHover.emit(isEnter);
  }
}
