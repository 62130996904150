import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { StripeService } from '../../core/services/stripe.service';
import { take } from 'rxjs/operators';
import { UtilityService } from '../../core/services/utility.service';
import { AccountService } from '../../core/services/account.service';
import { UserService } from '../../core/services/user.service';
import { SuccessfulComponent } from './successful.component';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-plan',
  templateUrl: './html/select-plan.html',
  styleUrls: [
    '../../assets/css/main.css',
    '../../assets/scss/fontawesome.scss',
    '../../assets/scss/brands.scss',
    '../../assets/scss/regular.scss',
    '../../assets/scss/solid.scss'
  ]
})
export class SelectPlanComponent implements OnInit {
  @Input() currentPlan: any;
  @Output() currentPlanChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() currentPage: number;
  @Input() selectedPlanId: string;
  @Output() currentPageChange: EventEmitter<any> = new EventEmitter<any>();
  plans: any[] = [];
  showPromoCode = false;
  promoCode = '';
  isPromoCodeInvalid = false;
  showDiscount = false;
  promo: any;
  selectedPlan: any = {
    product: ''
  };
  processing: boolean;


  constructor(
    private accountService: AccountService,
    private stripeService: StripeService,
    private utilityService: UtilityService,
    private userService: UserService,
    public dialogRef: MatDialogRef<SelectPlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) { 
    this.selectedPlanId = this.data.selectedPlan;
  }

  ngOnInit(): void {
    this.stripeService
      .getAllPlans()
      .pipe(
        take(1)
      )
      .subscribe((plans: any[]) => {
        this.plans = plans.sort((a, b) => {
          if (a.amount_decimal > b.amount_decimal) {
            return -1;
          }

          if (b.amount_decimal > a.amount_decimal) {
            return 1;
          }

          return 0;
        });

        if (this.selectedPlanId) {
          this.setPlanById(this.selectedPlanId);
        }
      });
  }

  formatAmount(amount: number) {
    return this.utilityService.formatAmount(amount);
  }

  changePlan() {
    this.processing = true;
    const isDowngrade = this.selectedPlan.name < this.currentPlan;
    const planRequest: any = {
      planId: this.selectedPlan.id,
      promoId: this.promoCode,
      isDowngrade: isDowngrade
    };

    this.accountService
      .changePlan(planRequest)
      .pipe(
        take(1)
      )
      .subscribe((subscription: any) => {
        this.processing = false;
        // this.currentPageChange.emit(3);
        this.dialogRef.close();
        this.openDialog(subscription.plan, this.plans);
        this.currentPlanChange.emit(subscription.plan);
      });
  }

  getPromoCode() {
    this.accountService
      .getPromoCode(this.promoCode)
      .pipe(
        take(1)
      )
      .subscribe(
        plan => {
          this.isPromoCodeInvalid = false;
          this.showDiscount = true;
          this.promo = plan;
        },
        error => {
          this.isPromoCodeInvalid = true;
          this.showDiscount = false;
        }
      );
  }

  toggleShowPromoCode() {
    this.showPromoCode = true;
  }

  setPlan(plan: any) {
    this.selectedPlan = plan;
  }

  setPlanById(id: string) {
    this.selectedPlan = this.plans.find(plan => plan.id === id);
  }

  openDialog (planDetails, allPlans) {
    const dialogRef = this.dialog.open(SuccessfulComponent, {
      panelClass: 'successful-update-modalbox',
      data: { plan: planDetails, Plans: allPlans }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
