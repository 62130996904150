import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-view-sharing-list-dialog',
    templateUrl: './view-sharing-list-dialog.component.html',
    styleUrls: ['./view-sharing-list-dialog.component.scss']
})
export class ViewSharingListDialogComponent implements OnInit {
    contentArray: any = [];
    wordCount: number;

    isLoading = false;

    disabled = false;
    compact = false;
    invertX = false;
    invertY = false;
    shown: 'native' | 'hover' | 'always' = 'native';
    constructor(public dialogRef: MatDialogRef<ViewSharingListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.isLoading = false;
        this.contentArray = this.data.contentNames;
    }

    setShown() {
        if (this.shown === 'native') {
            this.shown = 'hover';
        } else if (this.shown === 'hover') {
            this.shown = 'always';
        } else {
            this.shown = 'native';
        }
    }

    getContentName(type: string) {
        switch (type) {
          case 'settings':
            return 'Settings';
          case 'course':
            return 'Courses';
          default:
            return 'Workbooks';
        }
      }
}
