// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  margin-left: 6px;
}

.color-container button {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  min-height: 32px;
  padding: 0px;
}
.color-container button.active .material-icons {
  padding: 4px;
}

.row-header {
  display: flex;
  padding-left: 16px;
}

.option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

.color-menu {
  width: 272px;
}

.black {
  background-color: white;
}

.active {
  background-color: #f1eeff;
}`, "",{"version":3,"sources":["webpack://./src/activities/activity-header/color-menu/color-menu.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EAGA,gBAAA;AADF;;AAGA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;AAAF;AAEI;EACE,YAAA;AAAN;;AAKA;EACE,aAAA;EAGA,kBAAA;AAJF;;AAOA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AAJF;;AAOA;EACE,YAAA;AAJF;;AAQA;EACE,uBAAA;AALF;;AAQA;EACE,yBAAA;AALF","sourcesContent":[".color-container{\n  display: flex;\n  flex-direction: row;\n  flex-grow: 1;\n  flex-wrap: wrap;\n  // justify-content: center;\n  // align-items: center;\n  margin-left: 6px;\n}\n.color-container button{\n  width: 32px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 32px;\n  min-height: 32px;\n  padding: 0px;\n  &.active {\n    .material-icons {\n      padding: 4px;\n    }\n  }\n}\n\n.row-header {\n  display: flex;\n  // align-content: center;\n  // justify-content: center;\n  padding-left: 16px;\n}\n\n.option {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 32px;\n  height: 32px;\n}\n\n.color-menu {\n  width: 272px;\n}\n\n\n.black {\n  background-color: white;\n}\n\n.active {\n  background-color: #f1eeff;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
