import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum PassageActionTypes {
    GetManyPassages = '[PassageService] Get Many Passages',
    GetManyPassagesSuccess = '[PassageService] Get Many Passages Success',
    GetManyPassagesFail = '[PassageService] Get Many Passages Fail',

    ClearWorkbookPassages = '[PassageService] Clear Workbook Passages',
    ClearWorkbookPassagesSuccess = '[PassageService] Clear Workbook Passages Success',
    ClearWorkbookPassagesFail = '[PassageService] Clear Workbook Passages Fail',


    GetManyWorkbookPassages = '[PassageService] Get Many Workbook Passages',
    GetManyWorkbookPassagesSuccess = '[PassageService] Get Many Workbook Passages Success',
    GetManyWorkbookPassagesFail = '[PassageService] Get Many Workbook Passages Fail',

    CreatePassage = '[PassageService] Create Passage',
    CreatePassageSuccess = '[PassageService] Create Passage Success',
    CreatePassageFail = '[PassageService] Create Passage Fail',

    CreateWorkbookPassage = '[PassageService] Create Workbook Passage',
    CreateWorkbookPassageSuccess = '[PassageService] Create Workbook Passage Success',
    CreateWorkbookPassageFail = '[PassageService] Create Workbook Passage Fail',

    UpdatePassage = '[PassageService] Update Passage',
    UpdatePassageSuccess = '[PassageService] Update Passage Success',
    UpdatePassageFail = '[PassageService] Update Passage Fail',

    UpdateWorkbookPassage = '[PassageService] Update Workbook Passage',
    UpdateWorkbookPassageSuccess = '[PassageService] Update Workbook Passage Success',
    UpdateWorkbookPassageFail = '[PassageService] Update Workbook Passage Fail',

    DeletePassage = '[PassageService] Delete Passage',
    DeletePassageSuccess = '[PassageService] Delete Passage Success',
    DeletePassageFail = '[PassageService] Delete Passage Fail',

    DeleteWorkbookPassage = '[PassageService] Delete Workbook Passage',
    DeleteWorkbookPassageSuccess = '[PassageService] Delete Workbook Passage Success',
    DeleteWorkbookPassageFail = '[PassageService] Delete Workbook Passage Fail',

    DeleteManyWorkbookPassage = '[PassageService] Delete Many Workbook Passage',
    DeleteManyWorkbookPassageSuccess = '[PassageService] Delete Many Workbook Passage Success',
    DeleteManyWorkbookPassageFail = '[PassageService] Delete Many Workbook Passage Fail',

}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class GetManyPassages implements Action {
    readonly type = PassageActionTypes.GetManyPassages;

    constructor(public payload: any) {}
}
export class GetManyPassagesSuccess implements Action {
    readonly type = PassageActionTypes.GetManyPassagesSuccess;

    constructor(public payload: any) {}
}
export class GetManyPassagesFail implements Action {
    readonly type = PassageActionTypes.GetManyPassagesFail;

    constructor(public payload: any) {}
}

export class ClearWorkbookPassages implements Action {
    readonly type = PassageActionTypes.ClearWorkbookPassages;

    constructor(public payload: any) {}
}
export class ClearWorkbookPassagesSuccess implements Action {
    readonly type = PassageActionTypes.ClearWorkbookPassagesSuccess;

    constructor(public payload: any) {}
}
export class ClearWorkbookPassagesFail implements Action {
    readonly type = PassageActionTypes.ClearWorkbookPassagesFail;

    constructor(public payload: any) {}
}


export class GetManyWorkbookPassages implements Action {
    readonly type = PassageActionTypes.GetManyWorkbookPassages;

    constructor(public payload: any) {}
}
export class GetManyWorkbookPassagesSuccess implements Action {
    readonly type = PassageActionTypes.GetManyWorkbookPassagesSuccess;

    constructor(public payload: any) {}
}
export class GetManyWorkbookPassagesFail implements Action {
    readonly type = PassageActionTypes.GetManyWorkbookPassagesFail;

    constructor(public payload: any) {}
}

export class CreatePassage implements Action {
    readonly type = PassageActionTypes.CreatePassage;

    constructor(public payload: any) {}
}
export class CreatePassageSuccess implements Action {
    readonly type = PassageActionTypes.CreatePassageSuccess;

    constructor(public payload: any) {}
}
export class CreatePassageFail implements Action {
    readonly type = PassageActionTypes.CreatePassageFail;

    constructor(public payload: any) {}
}

export class CreateWorkbookPassage implements Action {
    readonly type = PassageActionTypes.CreateWorkbookPassage;

    constructor(public payload: any) {}
}
export class CreateWorkbookPassageSuccess implements Action {
    readonly type = PassageActionTypes.CreateWorkbookPassageSuccess;

    constructor(public payload: any) {}
}
export class CreateWorkbookPassageFail implements Action {
    readonly type = PassageActionTypes.CreateWorkbookPassageFail;

    constructor(public payload: any) {}
}

export class UpdatePassage implements Action {
    readonly type = PassageActionTypes.UpdatePassage;

    constructor(public payload: any) {}
}
export class UpdatePassageSuccess implements Action {
    readonly type = PassageActionTypes.UpdatePassageSuccess;

    constructor(public payload: any) {}
}
export class UpdatePassageFail implements Action {
    readonly type = PassageActionTypes.UpdatePassageFail;

    constructor(public payload: any) {}
}

export class UpdateWorkbookPassage implements Action {
    readonly type = PassageActionTypes.UpdateWorkbookPassage;

    constructor(public payload: any) {}
}
export class UpdateWorkbookPassageSuccess implements Action {
    readonly type = PassageActionTypes.UpdateWorkbookPassageSuccess;

    constructor(public payload: any) {}
}
export class UpdateWorkbookPassageFail implements Action {
    readonly type = PassageActionTypes.UpdateWorkbookPassageFail;

    constructor(public payload: any) {}
}

export class DeletePassage implements Action {
    readonly type = PassageActionTypes.DeletePassage;

    constructor(public payload: any) {}
}
export class DeletePassageSuccess implements Action {
    readonly type = PassageActionTypes.DeletePassageSuccess;

    constructor(public payload: any) {}
}
export class DeletePassageFail implements Action {
    readonly type = PassageActionTypes.DeletePassageFail;

    constructor(public payload: any) {}
}

export class DeleteWorkbookPassage implements Action {
    readonly type = PassageActionTypes.DeleteWorkbookPassage;

    constructor(public payload: any) {}
}
export class DeleteWorkbookPassageSuccess implements Action {
    readonly type = PassageActionTypes.DeleteWorkbookPassageSuccess;

    constructor(public payload: any) {}
}
export class DeleteWorkbookPassageFail implements Action {
    readonly type = PassageActionTypes.DeleteWorkbookPassageFail;

    constructor(public payload: any) {}
}

export class DeleteManyWorkbookPassage implements Action {
    readonly type = PassageActionTypes.DeleteManyWorkbookPassage;

    constructor(public payload: any) {}
}
export class DeleteManyWorkbookPassageSuccess implements Action {
    readonly type = PassageActionTypes.DeleteManyWorkbookPassageSuccess;

    constructor(public payload: any) {}
}
export class DeleteManyWorkbookPassageFail implements Action {
    readonly type = PassageActionTypes.DeleteManyWorkbookPassageFail;

    constructor(public payload: any) {}
}

export type PassageActions
= ClearWorkbookPassages
| ClearWorkbookPassagesSuccess
| ClearWorkbookPassagesFail
| GetManyPassages
| GetManyPassagesFail
| GetManyPassagesSuccess
| GetManyWorkbookPassages
| GetManyWorkbookPassagesFail
| GetManyWorkbookPassagesSuccess
| CreatePassage
| CreatePassageFail
| CreatePassageSuccess
| CreateWorkbookPassage
| CreateWorkbookPassageFail
| CreateWorkbookPassageSuccess
| UpdatePassage
| UpdatePassageSuccess
| UpdatePassageFail
| UpdateWorkbookPassage
| UpdateWorkbookPassageFail
| UpdateWorkbookPassageSuccess
| DeletePassage
| DeletePassageFail
| DeletePassageSuccess
| DeleteWorkbookPassage
| DeleteWorkbookPassageFail
| DeleteWorkbookPassageSuccess;
