import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of,  Observable} from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { SentenceService } from '../../services/sentence.service';

import * as actions from './sentence.actions';

@Injectable()

export class SentencesEffect {
    constructor(
        private actions$: Actions,
        private store$: Store,
        private sentenceService: SentenceService
    ) {}
    getManySentences$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SentenceActionTypes.GetManySentences),
            map((action: actions.GetManySentences) => action.payload),
            switchMap((payload: any) => {
                return this.sentenceService.getManySentences(payload).pipe(
                    map((res: any) => new actions.GetManySentencesSuccess(res)),
                    catchError((error) => of(new actions.GetManySentencesFail(error.message)))
                );
            })
        );
    });

    getManyWorkbookSentences$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SentenceActionTypes.GetManyWorkbookSentences),
            map((action: actions.GetManyWorkbookSentences) => action.payload),
            switchMap((payload: any) => {
                return this.sentenceService.getManyWorkbookSentences(payload).pipe(
                    map((res: any) => new actions.GetManyWorkbookSentencesSuccess({
                        id: payload,
                        data: res
                    })),
                    catchError((error) => of(new actions.GetManyWorkbookSentencesFail(error.message)))
                );
            })
        );
    });

    createSentence$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SentenceActionTypes.CreateSentence),
            map((action: actions.CreateSentence) => action.payload),
            switchMap((payload: any) => {
                return this.sentenceService.createSentence(payload.sentenceData).pipe(
                    switchMap((res: any) => {
                        const workbookSentence = {
                            workbookId: payload.workbookId,
                            sentenceId: res._id,
                            ownerKey: payload.sentenceData.ownerKey
                        };
                        return [
                            new actions.CreateSentenceSuccess(res),
                            new actions.CreateWorkbookSentence(workbookSentence)
                        ];
                    }),
                    catchError((error) => of(new actions.CreateSentenceFail(error.message)))
                );
            })
        );
    });

    createWorkbookSentence$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SentenceActionTypes.CreateWorkbookSentence),
            map((action: actions.CreateWorkbookSentence) => action.payload),
            switchMap((payload: any) => {
                return this.sentenceService.createWorkbookSentence(payload).pipe(
                    map((res: any) => new actions.CreateWorkbookSentenceSuccess(res)),
                    catchError((error) => of(new actions.CreateWorkbookSentenceFail(error.message)))
                );
            })
        );
    });

    updateSentence$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SentenceActionTypes.UpdateSentence),
            map((action: actions.UpdateSentence) => action.payload),
            switchMap((payload: any) => {
                return this.sentenceService.updateSentence(payload.id, payload.sentence).pipe(
                    map((res: any) => new actions.UpdateSentenceSuccess(payload)),
                    catchError((error) => of(new actions.UpdateSentenceFail(error.message)))
                );
            })
        );
    });

    updateWorkbookSentence$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SentenceActionTypes.UpdateWorkbookSentence),
            map((action: actions.UpdateWorkbookSentence) => action.payload),
            switchMap((payload: any) => {
                return this.sentenceService.updateWorkbookSentence(payload.id, payload.sentences).pipe(
                    switchMap((res: any) => [
                        new actions.UpdateWorkbookSentenceSuccess(payload.sentences),
                        // new actions.ClearWorkbookSentences({}),
                        // new actions.GetManyWorkbookSentences(payload.id)
                    ]),
                    catchError((error) => of(new actions.UpdateWorkbookSentenceFail(error.message)))
                );
            })
        );
    });

    deleteSentence$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SentenceActionTypes.DeleteSentence),
            map((action: actions.DeleteSentence) => action.payload),
            switchMap((payload: any) => {
                return this.sentenceService.deleteSentence(payload).pipe(
                    map((res: any) => new actions.DeleteSentenceSuccess(payload)),
                    catchError((error) => of(new actions.DeleteSentenceFail(error.message)))
                );
            })
        );
    });

    deleteWorkbookSentence$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SentenceActionTypes.DeleteWorkbookSentence),
            map((action: actions.DeleteWorkbookSentence) => action.payload),
            switchMap((payload: any) => {
                return this.sentenceService.deleteWorkbookSentence(payload.collectionId, payload.itemId).pipe(
                    map((res: any) => new actions.DeleteWorkbookSentenceSuccess(payload)),
                    catchError((error) => of(new actions.DeleteWorkbookSentenceFail(error.message)))
                );
            })
        );
    });

    deleteManyWorkbookSentence$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SentenceActionTypes.DeleteManyWorkbookSentence),
            map((action: actions.DeleteManyWorkbookSentence) => action.payload),
            switchMap((payload: any) => {
                return this.sentenceService.deleteManyWorkbookSentences(payload).pipe(
                    map((res: any) => new actions.DeleteManyWorkbookSentenceSuccess(res)),
                    catchError((error) => of(new actions.DeleteManyWorkbookSentenceFail(error.message)))
                );
            })
        );
    });

}
