import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { TileLayoutComponent } from './TileLayout/tileLayout.component';
import { LetterTilesComponent } from './Basic/basic.component';
import { AdvancedComponent } from './Advanced/advanced.component';
import { SettingsComponent } from './settings.component';
import { AuthGuard } from '../core/services/auth-guard.service';
import { SettingsResolver } from '../core/resolvers/settings.resolver';
import { AllSubscribedCoursesResolver } from '../core/resolvers/all-subscribed-courses.resolver';
import { AllCoursesResolver } from '../core/resolvers/all-courses.resolver';
import { PlanResolver } from '../core/resolvers/plan.resolver';
import { NewAdvancedComponent } from './new-advanced/new-advanced.component';
const settingsRoutes: Routes = [
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    resolve: {
      allSettingsLoaded: SettingsResolver,
      allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
      allCoursesLoaded: AllCoursesResolver,
      planLoaded: PlanResolver

    },
    children: [
      {
        path: '', redirectTo: 'tilelayout', pathMatch: 'full' },
      {
        path: 'tilelayout', component: TileLayoutComponent,
      resolve: {
        allSettingsLoaded: SettingsResolver,
        allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
        allCoursesLoaded: AllCoursesResolver,
      }
    },
      {
        path: 'tilelayout/:missingTiles',
      component: TileLayoutComponent,
      resolve: {
        allSettingsLoaded: SettingsResolver,
        allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
        allCoursesLoaded: AllCoursesResolver,
      }
    },
      { path: 'lettertiles',
      component: LetterTilesComponent,
      resolve: {
        allSettingsLoaded: SettingsResolver,
        allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
        allCoursesLoaded: AllCoursesResolver,
      }
    },
      { path: 'advanced',
      // component: AdvancedComponent,
      component: NewAdvancedComponent,
      resolve: {
        allSettingsLoaded: SettingsResolver,
        allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
        allCoursesLoaded: AllCoursesResolver,
      }
     },
      {
        path: 'flashcards',
        loadChildren: () => import('./flashcard-settings/flashcard-settings.module').then(m => m.FlashcardSettingsModule),
        resolve: {
          allSettingsLoaded: SettingsResolver,
          allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
          allCoursesLoaded: AllCoursesResolver,
        }
       },
      {
        path: 'whiteboard',
        loadChildren: () => import('./whiteboard-settings/whiteboard-settings.module').then(m => m.WhiteboardSettingsModule),
        resolve: {
          allSettingsLoaded: SettingsResolver,
          allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
          allCoursesLoaded: AllCoursesResolver,
        }
      },
      {
        path: 'timed-reading',
        loadChildren: () => import('./timed-reading-settings/timed-reading-settings.module').then(m => m.TimedReadingSettingsModule),
        resolve: {
          allSettingsLoaded: SettingsResolver,
          allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
          allCoursesLoaded: AllCoursesResolver,
        }
      },
      {
        path: 'wordcards',
        loadChildren: () => import('./wordcard-settings/wordcard-settings.module').then(m => m.WordcardSettingsModule),
        resolve: {
          allSettingsLoaded: SettingsResolver,
          allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
          allCoursesLoaded: AllCoursesResolver,
        }
      },
      {
        path: 'lettercards',
        loadChildren: () => import('./lettercard-settings/lettercard-settings.module').then(m => m.LettercardSettingsModule),
        resolve: {
          allSettingsLoaded: SettingsResolver,
          allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
          allCoursesLoaded: AllCoursesResolver,
        }
      },
      {
        path: 'blackboard',
        loadChildren: () => import('./blackboard-settings/blackboard-settings.module').then(m => m.BlackboardSettingsModule),
        resolve: {
          allSettingsLoaded: SettingsResolver,
          allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
          allCoursesLoaded: AllCoursesResolver,
        }
      },
      {
        path: 'admin-panel',
        loadChildren: () => import('./admin-panel-settings/admin-panel-settings.module').then(m => m.AdminPanelSettingsModule),
        resolve: {
          allSettingsLoaded: SettingsResolver,
          allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
          allCoursesLoaded: AllCoursesResolver,
        }
      },
      {
        path: 'workbook-tiles',
        loadChildren: () => import('./workbook-tile-settings/workbook-tile-settings.module').then(m => m.WorkbookTileSettingsModule),
        resolve: {
          allSettingsLoaded: SettingsResolver,
          allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
          allCoursesLoaded: AllCoursesResolver,
        }
      },
      {
        path: 'mini-tiles',
        loadChildren: () => import('./mini-tile-settings/mini-tile-settings.module').then(m => m.MiniTileSettingsModule),
        resolve: {
          allSettingsLoaded: SettingsResolver,
          allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
          allCoursesLoaded: AllCoursesResolver,
        }
      },
      {
        path: 'tiles',
        loadChildren: () => import('./tile-settings/tile-settings.module').then(m => m.TileSettingsModule),
        resolve: {
          allSettingsLoaded: SettingsResolver,
          allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
          allCoursesLoaded: AllCoursesResolver,
        }
      },
      {
        path: 'spelling',
        loadChildren: () => import('./spelling-settings/spelling-settings.module').then(m => m.SpellingSettingsModule),
        resolve: {
          allSettingsLoaded: SettingsResolver,
          allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
          allCoursesLoaded: AllCoursesResolver,
        }
      },
      {
        path: 'passages',
        loadChildren: () => import('./passages-settings/passage-settings.module').then(m => m.PassageSettingsModule),
        resolve: {
          allSettingsLoaded: SettingsResolver,
          allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
          allCoursesLoaded: AllCoursesResolver,
        }
      },
      {
        path: 'sentence-parts',
        loadChildren: () => import('./phrases-settings/phrase-settings.module').then(m => m.PhraseSettingsModule),
        resolve: {
          allSettingsLoaded: SettingsResolver,
          allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
          allCoursesLoaded: AllCoursesResolver,
        }
      },
      {
        path: 'sentences',
        loadChildren: () => import('./sentences-settings/sentence-settings.module').then(m => m.SentenceSettingsModule),
        resolve: {
          allSettingsLoaded: SettingsResolver,
          allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
          allCoursesLoaded: AllCoursesResolver,
        }
      },
      {
        path: 'pdf-viewer',
        loadChildren: () => import('./pdf-viewer-settings/pdf-viewer-settings.module').then(m => m.PdfViewerSettingsModule),
        resolve: {
          allSettingsLoaded: SettingsResolver,
          allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
          allCoursesLoaded: AllCoursesResolver,
        }
      },
      {
        path: 'general',
        loadChildren: () => import('./general-settings/general-settings.module').then(m => m.GeneralSettingsModule),
        resolve: {
          allSettingsLoaded: SettingsResolver,
          allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
          allCoursesLoaded: AllCoursesResolver,
        }
      },
      {
        path: 'blank-tiles',
        loadChildren: () => import('./blank-tiles-settings/blank-tiles-settings.module').then(m => m.BlankTilesSettingsModule),
        resolve: {
          allSettingsLoaded: SettingsResolver,
          allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
          allCoursesLoaded: AllCoursesResolver,
        }
      },

    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(settingsRoutes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule {}
