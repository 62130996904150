import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, finalize, first, tap } from "rxjs/operators";
import * as CourseWorkbooksActions from '../store/course-workbook/course-workbook.actions';
import * as CourseWorkbooksSelectors from '../store';



@Injectable()
export class MainCourseWorkbooksResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(CourseWorkbooksSelectors.selectAllCourseWorkbookSuccess),
      filter(areLoaded => !!areLoaded),
      first(),
    );
  }
}
