import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, finalize, first, tap } from "rxjs/operators";
import * as WorkbooksActions from '../store/workbook/workbook.actions';
import * as WorkbooksSelectors from '../store';



@Injectable()
export class AllMyLibraryWorkbooksResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(WorkbooksSelectors.selectAllWorkbooksSuccess),
      tap(areLoaded => {
        if (!areLoaded) {
          // GET MY LIBRARY WORKBOOKS
          this.store.dispatch(new WorkbooksActions.GetMyLibraryWorkbooks(''));
        }
      }),
      filter(areLoaded => areLoaded),
      first(),
    );
  }
}
