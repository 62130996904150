import { Store } from '@ngrx/store';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { SettingsService } from '../../services/new-settings.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../services/user.service';
import { _variable_images } from '../../../environments/environment';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DataService } from '../../services/data.service';
import * as SettingsActions from '../../store/settings/settings.actions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-saved-settings-dialog',
  templateUrl: './add-saved-settings-dialog.component.html',
  styleUrls: ['./add-saved-settings-dialog.component.scss']
})
export class AddSavedSettingsDialogComponent implements OnInit, OnDestroy {

  // public formData = {
  //   name: '',
  //   teacherKey: JSON.parse(localStorage.getItem('profile')).user_metadata.uid,
  // };
  formAction: any;
  deleteImage: any;
  duplicateImage: any;
  public allSettings: any;
  public maxlength: boolean;
  // AddSettingForm = new FormGroup({
  //     name: new FormControl('', [Validators.maxLength(25)])
  // });
  addSettingForm: UntypedFormGroup;
  private subscriptions = new Subscription();

  constructor(public dialogRef: MatDialogRef<AddSavedSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private settings: SettingsService,
    private userService: UserService,
    private dataService: DataService,
    private store: Store,
    private formBuilder: UntypedFormBuilder
    ) {
      // this.formData.name = this.data.settings && this.data.settings.name;
      this.addSettingForm = this.formBuilder.group({
        name: [this.data?.settings?.name, [Validators.required, Validators.maxLength(25), (control: AbstractControl) => {
          const exist = this.data.settingsAll?.find(setting => setting.name === control.value && setting._id !== this.data?.settings?._id);
          if (exist) {
            return { duplicate: true};
          }
          return false;
        }]],
        teacherKey: JSON.parse(localStorage.getItem('profile')).user_metadata.uid
      });
      // this.deleteImage = _variable_images + 'delete-modal.png';
      // this.duplicateImage = _variable_images + 'duplicate.png';
     }


  ngOnInit() {
    this.subscriptions.add(
      this.dataService._variable_images.subscribe(path => {
        this.deleteImage = path + 'delete-modal.png';
        this.duplicateImage = path + 'duplicate.png';
      })
    );
    this.formAction = this.data.action;
    this.allSettings = this.data.settingsAll;
  }

  ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSave() {
    // this.settings.create(JSON.parse(localStorage.profile).user_metadata.uid, this.formData).subscribe(
    //   (res: any) => {
    //     this.dialogRef.close(res.data);
    //   }, err => {
    //   });
    this.store.dispatch(new SettingsActions.CreateSetting(this.addSettingForm.value));
    this.dialogRef.close(this.addSettingForm.value);
  }
//   onkey(event: any): void {
//     const name = this.AddSettingForm.get('name');
//     this.maxlength = name.status === "INVALID"  ? true : false;
//     this.formData.name = name.value;
// }

  onCopy() {
    // this.settings.updateCurrent(this.data.settings._id, JSON.parse(localStorage.profile).user_metadata.uid).subscribe();

    // this.settings.copy(this.data.settings._id, JSON.parse(localStorage.profile).user_metadata.uid)
    // .subscribe((res: any) => {
    //   this.dialogRef.close({data: res.data, copy: true});

    // },
    // err => {
    //   throw err;
    // });
    const data = {
      id: this.data.settings._id,
      key: JSON.parse(localStorage.profile).user_metadata.uid
    };
    this.store.dispatch(new SettingsActions.CopySetting(data));
  }

  onDelete() {
    this.store.dispatch(new SettingsActions.DeleteSetting(this.data.settings._id));
    this.dialogRef.close('deleted');
    // this.settings.delete(this.data.settings.settingsId).subscribe(
    //   (res) => {
    //     this.dialogRef.close(true);
    //   }, err => {
    //   });
  }

  onUpdate() {
    // this.settings.update(this.data.settings._id, this.formData).subscribe((res: any) => {
    //   this.dialogRef.close(true);
    // }, err => {
    // });
    const formData = {...this.addSettingForm.value, _id: this.data.settings._id};
    this.store.dispatch(new SettingsActions.UpdateSetting(formData));
    this.dialogRef.close('updated');
  }
}
