import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, finalize, first, tap } from "rxjs/operators";
import * as CoursesActions from '../store/courses/courses.actions';
import * as CoursesSelectors from '../store';

import { GetWorkbookActivity } from '../../core/store/workbook-activity/workbook-activity.actions';
import {  selectActivityTypes, selectGetWorkbookActivity, selectGetWorkbookActivityByParams } from '../../core/store';

import {combineLatest, Observable, Subject} from 'rxjs';
import { SetActivityOrLessonFixStatus } from '../store/user-interface/user-interface.actions';

@Injectable()
export class WorkbookActivityResolver  {
    private unsubscribe$: Subject<void> = new Subject();

  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const activityTypeId = route.url[0].path.replace( /-([a-z])/ig, ( all, letter ) => {
      return letter.toUpperCase();
    });
    const courseWorkbookId = route.url[1].path;
    return this.store.pipe(
      select(selectGetWorkbookActivityByParams, { activityTypeId, courseWorkbookId }),
      tap(areLoaded => {
        this.store.dispatch(new SetActivityOrLessonFixStatus('outdated'));
        if (!areLoaded) {
          // this.store.select(selectActivityTypes)

          // .subscribe(activityTypes => {
          //   const courseWorkbookId = route.params.courseWorkbookId;
            // let activityTypeId = activityTypes.find(activity => activity.name.toLowerCase() === 'flashcards')._id;
            const activityTypeId = route.url[0].path.replace( /-([a-z])/ig, ( all, letter ) => {
              return letter.toUpperCase();
            });

            this.store.dispatch(new GetWorkbookActivity({
              courseWorkbookId: courseWorkbookId,
              activityTypeId: activityTypeId
            }));
          // });

        }
      }),
      filter(areLoaded => areLoaded),
      first(),
    );
  }
}
