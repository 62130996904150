import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { WordsComponent } from "./Words/words.component";
import { SentencesComponent } from "./Sentences/sentences.component";
import { FilesComponent } from "./Files/files.component";
import { WordlistComponent } from "./wordlist.component";

const WordlistRoutes: Routes = [
  {
    path: "wordlist",
    component: WordlistComponent,
    children: [
      { path: "", redirectTo: "Words", pathMatch: "full" },
      { path: "Words", component: WordsComponent },
      { path: "Sentences", component: SentencesComponent },
      { path: "Files", component: FilesComponent }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(WordlistRoutes)],
  exports: [RouterModule]
})
export class WordlistRoutingModule {}
