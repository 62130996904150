import { Component, EventEmitter, Output, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-spelling-dialog',
  templateUrl: './spelling-dialog.component.html',
  styleUrls: ['./spelling-dialog.component.scss']
})
export class SpellingDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SpellingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
  }

}
