import { CreateWorkbookSuccess, GetMyLibraryWorkbooks, StoreWorkbookItemSuccess } from './../workbook/workbook.actions';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of,  Observable} from 'rxjs';
import { catchError, filter, map, switchMap, tap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { CourseWorkbookService } from '../../services/course-workbook.service';
import { CourseWorkbooksService } from '../../services/course-workbooks.service';
import { WorkbooksService } from '../../services/workbooks.service';
import * as actions from './course-workbook.actions';
import { CopyWorkbookSuccess, CreateWorkbook } from '../workbook/workbook.actions';
import {  selectCourseWorkbookState, selectGetAllCourseWorkbook } from '..';


@Injectable()

export class CourseWorkbookEffect {
    constructor(
        private actions$: Actions,
        private store$: Store,
        private courseWorkbookService: CourseWorkbookService,
        private courseWorkbooksService: CourseWorkbooksService,
        private workbookService: WorkbooksService,
    ) {}
    getCourseWorkbook$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseWorkbookActionTypes.GetCourseWorkbook),
            map((action: actions.GetCourseWorkbook) => action.payload),
            mergeMap((payload: any) => {
                return this.courseWorkbookService.get(payload).pipe(
                    map((res: any) => new actions.GetCourseWorkbookSuccess(res)),
                    catchError((error) => of(new actions.GetCourseWorkbookFail(error.message)))
                );
            })
        );
    });

    getAllCourseWorkbooks$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseWorkbookActionTypes.GetAllCourseWorkbooks),
            map((action: actions.GetAllCourseWorkbooks) => action.payload),
            // withLatestFrom(this.store$.select(selectCourseWorkbookState)),
            // filter(([ courseId, state ]) => {
            //     return state.selectedId !== courseId;
            // }),
            switchMap((payload) => {
                return this.courseWorkbookService.getAll(payload).pipe(
                    switchMap((res: any) => {
                        return [
                            new actions.ClearCourseWorkbooks(),
                            new actions.GetAllCourseWorkbooksSuccess({
                            id: payload,
                            items: res}),
                        ];
                }),
                );
            })
        );
    });
    addWorkbookToCourse$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseWorkbookActionTypes.AddWorkbookToCourse),
            map((action: actions.AddWorkbookToCourse) => action.payload),
            mergeMap(payload => {
                let workbookData = payload.workbookData;
                let formData = { ...payload };
                delete formData.workbookData;
                return this.courseWorkbooksService
                    .create(formData).pipe(
                        map((result: any) => {
                            workbookData = {...workbookData, ...result.data};
                            workbookData.workbookId = payload.workbookId;
                            return new actions.AddWorkbookToCourseSuccess(workbookData);
                        })
                    );
            })
        );
    });

    addWhizzimoWorkbookToCourse$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseWorkbookActionTypes.AddWhizzimoWorkbookToCourse),
            map((action: actions.AddWhizzimoWorkbookToCourse) => action.payload),

            mergeMap(payload => {
                let courseWorkbookData = null;
                return this.workbookService.copy(payload.id, payload.key, payload.changeName).pipe(

                    switchMap((newWorkbook: any) => {
                        courseWorkbookData = newWorkbook;
                        let courseWorkbookForm = {
                            ...payload.courseWorkbookData,
                            ownerKey: newWorkbook.owner,
                            workbookId: newWorkbook._id,
                        };
                        return this.courseWorkbooksService
                        .create(courseWorkbookForm)
                        .pipe(
                            tap((courseWorkbook: any) => {
                                courseWorkbookData = {...courseWorkbookData, ...courseWorkbook.data};
                                this.store$.dispatch(new actions.AddWorkbookToCourseSuccess(courseWorkbookData));
                                const workbookData = {
                                    ...courseWorkbookData,
                                    _id: courseWorkbook.data.workbookId
                                };
                                this.store$.dispatch(new StoreWorkbookItemSuccess(workbookData));
                            }),
                        );
                    }),
                );
                // return this.courseWorkbooksService
                //     .create(payload).pipe(
                //         switchMap((res: any) => {
                //             return this.store$.select(getWorkbook, {id: payload.workbookId})
                //         }),
                //         map(workbook => {
                //             return new actions.AddWhizzimoWorkbookToCourseSuccess(workbook)
                //         })
                //     )
            }),
            // tap(data => {
            //     return this.store$.dispatch(new CreateWorkbook(data))

            // }),
            // tap(newWorkbook => {
            //     this.store$.dispatch(new actions.AddWorkbookToCourse(newWorkbook))

                // const data = {...payload.courseWorkbookData }
            // })
        );
    }, {dispatch: false});



    removeWorkbookFromCourse$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseWorkbookActionTypes.RemoveWorkbookFromCourse),
            map((action: actions.RemoveWorkbookFromCourse) => action.payload),
            switchMap(payload => {
                return this.courseWorkbooksService
                    .delete(payload).pipe(
                        map(() => {
                            return new actions.RemoveWorkbookFromCourseSuccess(payload);
                        })
                    );
            })
        );
    });

    removeWorkbookFromCourseViaLibrary$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseWorkbookActionTypes.RemoveWorkbookFromCourseViaLibrary),
            map((action: actions.RemoveWorkbookFromCourseViaLibrary) => action.payload),
            switchMap((payload: any) => {
                return this.courseWorkbooksService
                  .deleteFromCourse(payload.workbookId, payload.courseId)
                    .pipe(
                        map((result) => {
                            return new actions.RemoveWorkbookFromCourseViaLibrarySuccess(payload.courseWorkbookId);
                        })
                    );
            })
        );
    });

    copyCourseWorkbook$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseWorkbookActionTypes.CopyCourseWorkbook),
            map((action: actions.CopyCourseWorkbook) => action.payload),
            switchMap((payload: any) => {
                return this.courseWorkbooksService.copy(payload.courseWorkbook._id).pipe(
                    switchMap((result: any) => {
                        let courseWorkbook = JSON.parse(JSON.stringify(payload.courseWorkbook));
                        courseWorkbook.workbook.name = result.data.name;
                        const newCourseWorkbook = {...courseWorkbook, ...result.data};

                        const newWorkbook = {...payload.workbook, filters: newCourseWorkbook.workbook[0].filters, name: result.data.name, _id: result.data.workbookId};
                        return [
                            new CopyWorkbookSuccess(newWorkbook),
                            new actions.CopyCourseWorkbookSuccess(newCourseWorkbook)
                        ];
                    })
                );
            }),
        );
    });

    UpdateByWorkbook$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseWorkbookActionTypes.UpdateByWorkbook),
            map((action: actions.UpdateByWorkbook) => action.payload),
            switchMap((payload: any) => {
                return this.courseWorkbooksService.updateByWorkbook(payload.courseWorkbook, payload.ownerKey, payload._id)
                .pipe(
                    switchMap((result: any) => {
                        return [
                            new actions.ClearCourseWorkbooks(),
                            new actions.GetAllCourseWorkbooks(payload.courseId)
                        ];
                    })
                );
            }),
        );
    });
}
