import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-add-import-collection-dialog',
    templateUrl: './add-import-collection-dialog.component.html',
    styleUrls: ['./add-import-collection-dialog.component.scss']
})
export class AddImportCollectionDialogComponent implements OnInit {
    functions;
    code: string;
    constructor(public dialogRef: MatDialogRef<AddImportCollectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog
    ) {
    }


    ngOnInit() {
    }

    onCancel() {
        this.dialogRef.close();
    }
    onSave(code: string) {
        this.onCollectionAdded(code);
    }
    onCollectionAdded(code: string) {
        this.dialogRef.close();
        this.functions.import(code);

    }
}
