import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import { GetManyWorkbookSentences } from "../store/sentence/sentence.actions";
import * as selectors from "../store";


@Injectable()
export class WorkbookSentencesResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const workbookId = route.params.workbookId;
    return this.store.pipe(
      select(selectors.isWorkbookSentencesLoaded, { id: workbookId}),
      tap(isLoaded => {
        if (!isLoaded) {
          this.store.dispatch(new GetManyWorkbookSentences(workbookId));
        }
      }),
      filter(isLoaded => {
        if (workbookId === 'new') { return true; }
        return isLoaded;
      }),
      first(),
    );
  }
}

