import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_DIR } from '../../environments/environment';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()

export class CourseWorkbooksService {
  courseWorkbooks: any[] = [];
  currentCourseWorkbook: any;

  constructor(private http: HttpClient) {}

  get(courseWorkbookId: string) {
    return  new Observable(observer => {
        this.http
          .get(`${API_DIR}/courseWorkbook/${courseWorkbookId}`)
          .pipe(
            take(1)
          )
          .subscribe(
          (courseWorkbook: any) => {
            this.currentCourseWorkbook = courseWorkbook.data;
            observer.next(this.currentCourseWorkbook);
          },
          error => {
            throw error;
          });
    });
  }

  getAll(courseId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/courseWorkbook/course/${courseId}`)
        .pipe(
          take(1)
        )
        .subscribe(
          (courseWorkbooks: any) => {
            this.courseWorkbooks = courseWorkbooks.data;
            observer.next(this.courseWorkbooks);
          },
          error => {
            throw error;
          });
    });
  }

  create(courseWorkbook: any) {
    return this.http.post(`${API_DIR}/courseWorkbook`, courseWorkbook);
  }

  update(courseWorkbook: any, courseWorkbookId: string) {
    return this.http.put(`${API_DIR}/courseWorkbook/${courseWorkbookId}`, courseWorkbook);
  }

  delete(courseWorkbookId: string) {
    return this.http.delete(`${API_DIR}/courseWorkbook/${courseWorkbookId}`);
  }

  copy(courseWorkbookId: string) {
    return this.http.post(`${API_DIR}/courseWorkbook/copy/${courseWorkbookId}`, {});
  }

  deleteFromCourse(workbookId: string, courseId) {
    return this.http.delete(`${API_DIR}/courseWorkbook/${workbookId}/${courseId}`, {});
  }

  getCountByWorkbookId(workbookId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/courseWorkbook/count/workbook/${workbookId}`)
        .pipe(take(1))
        .subscribe(
          (count: any) => {
            observer.next(count.data);
          },
          error => {
            throw error;
          });
    });
  }

  updateByWorkbook(payload: any, ownerKey: string, workbookId: string) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/courseWorkbook/${ownerKey}/${workbookId}`, payload)
        .subscribe(
          (count: any) => {
            observer.next(count.data);
          },
          error => {
            throw error;
          });
    });
  }
}
