import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_DIR } from '../../environments/environment';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};


@Injectable()
export class WordlistService {
  public tiles: any = [
    "tiles.bv.a",
    "tiles.bv.e",
    "tiles.bv.i",
    "tiles.bv.o",
    "tiles.bv.u",
    "tiles.bv.y",
    "tiles.bc.b",
    "tiles.bc.c",
    "tiles.bc.f"
  ];
  public filters: any = [
      "isPhonetic",
      "isNonsense",
      "nLetters",
      "nSyllables",
      "exactWords",
      "excludeWords",
      "wordContains",
      "wordDoesNotContains",
      "wordBeginsWith",
      "wordEndsWith",
      "anyGrapheme",
      "firstGrapheme",
      "lastGrapheme",
      "doesNotContainGrapheme",
      "nSounds",
      "wordMustContain",
      "firstSylType",
      "secondSylType",
      "thirdSylType",
      "fourthSylType",
      "fifthSylType",
      "sixthSylType",
      "sylNumber",
      "syllableDivision",
      "soundLetterPairings",
      "quickExclude",
      "quickInclude",
      "sylTypes",
      "onsetLetters",
      "rimeLetters",
      "initialblendArray",
      "endingblendArray",
      "cvcPatterns",
      "suffixes",
      "prefixes"
  ];

  constructor(private http: HttpClient) {

  }

    public getWordLib(json) {
      return this.http.post(API_DIR + '/workbook/words', json, httpOptions )
      .pipe(
        map(result => result)
      );

    }

  public wordListBuilder(filter) {
    const dictionary = {};
    const search = filter.split(',');
    const filters = Object.keys(this.filters);

    search.forEach((search) => {

      switch (search) {
        case 'isPhonetic':
        case 'isNonsense':
        case 'nLetters':
        case 'nSyllables':
        case 'exactWords':
      }
    });
  }
}
