import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsRoutingModule } from '../../Settings/settings-routing.module';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { API_DIR } from '../../environments/environment';
import { UserService } from './user.service';
import { map, switchMap, take } from 'rxjs/operators';
import { isArray } from 'util';
import { DefaultLayoutAlignDirective } from '@angular/flex-layout';
import { of } from "rxjs";

@Injectable({providedIn: 'root'})
export class SettingsService {
  public activitySettings: any;
  public activitySettingsSubjet$ = new Subject<any>();
  public updateActivitySettings: any;
  public activitySettingsDefault: any;
  constructor(private http: HttpClient, userService: UserService) {


  }


  get(userId: string) {
    return new Observable(observer => {
      this.http
      .get(`${API_DIR}/settings/${userId}`)
      .subscribe((settings: any) => {
        observer.next(settings.data);
      },
      error => {
        throw error;
      });
    });
  }
  getAll(userId: string) {
    return new Observable(observer => {
      this.http
      .get(`${API_DIR}/settings/user/${userId}`)
      .subscribe((settings: any) => {
        observer.next(settings.data);
      },
      error => {
        throw error;
      });
    });
  }

  updateSettings(settings: any) {
    this.activitySettings = settings;
    this.updateCurrent(settings._id, settings.ownerKey).pipe(take(1)).subscribe();
    this.activitySettingsSubjet$.next(settings);
    localStorage.setItem('activitySettings', JSON.stringify(settings));
  }

  update(settingsId: string, updates: any) {
    this.activitySettings = {...this.activitySettings, ...updates};
    return this.http.put(`${API_DIR}/settings/${settingsId}`, updates);
  }

  updateCurrent(activitySettingsId: string, userId: string) {
    return this.http
    .put(`${API_DIR}/settings/updateCurrent/${activitySettingsId}/${userId}`, {});
    // .subscribe((data) => {
    // },
    // error => {
    // });
  }
  create(userId: string, setting: any) {
    return this.http.post(`${API_DIR}/settings/${userId}`, setting);
  }

  copy(activitySettingsId: string, ownerKey: string) {
    const object: any = {
      ownerKey: ownerKey
    };
    return this.http.post(`${API_DIR}/settings/copy/${activitySettingsId}`, object);
  }

  delete(activitySettingsId: string) {
    return this.http.delete(`${API_DIR}/settings/${activitySettingsId}`);

  }

  updateCompoundSounds(compoundSound: any, settingsId: string) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/settings/compoundSounds/${settingsId}`, compoundSound)
        .pipe(
          take(1)
        )
        .subscribe(
          () => {
                  observer.next();
          },
          error => {
            throw error;
          }
        );
    });
  }

  checkVersion() {
    // return this.http.get(`${API_DIR}/version`).pipe(map((res: any) => res.data));
    // TODO: revert back for version checking
    return of(11);
  }
}
