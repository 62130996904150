import * as dummyDataActions from './dummmy-actions';

export interface State {
    pending: boolean;
    error: any;
    succeeded: boolean;
    dummyData: any;
    dummyDataInterface?: any;
}

const initialState: State = {
    pending: false,
    error: null,
    succeeded: false,
    dummyData: null,
    dummyDataInterface: null
};

export function reducer(state = initialState, action: dummyDataActions.DummyDataActions): State {
    switch (action.type) {
        case dummyDataActions.DummyActionTypes.GetDummyData: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
                dummyDataInterface: null
            };
        }
        case dummyDataActions.DummyActionTypes.GetDummyDataSuccess: {
            return {
                ...state,
                dummyData: action.payload,
                error: null
            };
        }
        case dummyDataActions.DummyActionTypes.GetDummyDataFail: {
            return {
                ...state,
                dummyData: null,
                error: action.payload
            };
        }
        default:
            return state;
    }
}

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.dummyDataInterface;
export const getError = (state: State) => state.error;
export const getDummyData = (state: State) => state.dummyData;
