// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line-holder {
  display: flex;
  flex-flow: column;
  width: 85%;
  margin: auto;
  justify-content: space-evenly;
}

.set-z-index {
  z-index: 2;
}

.ng-dragging {
  z-index: 10;
}

.bounds {
  height: 70vh;
  width: 85vw;
  margin-left: auto;
  margin-right: auto;
}

.flashcardBounds {
  height: 70vh;
  margin-left: auto;
  margin-right: auto;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.x-small-line {
  height: 32px !important;
}

.small-line {
  height: 42px !important;
}

.medium-line {
  height: 62px !important;
}

.large-line {
  height: 82px !important;
}

.x-large-line {
  height: 102px !important;
}

.xx-large-line {
  height: 122px !important;
}

.xxx-large-line {
  height: 142px !important;
}

.bumping-tiles-container {
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: -30px;
}`, "",{"version":3,"sources":["webpack://./src/core/components/bumping-tiles/bumping-tiles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,iBAAA;EACA,UAAA;EACA,YAAA;EACA,6BAAA;AADF;;AAIA;EACE,UAAA;AADF;;AAGA;EACE,WAAA;AAAF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AACA;EACE,YAAA;EACA,iBAAA;EACA,kBAAA;AAEF;;AACA;EACE,UAAA;AAEF;;AAAA;EACE,uBAAA;AAGF;;AADA;EACE,uBAAA;AAIF;;AADA;EACE,uBAAA;AAIF;;AADA;EACE,uBAAA;AAIF;;AADA;EACE,wBAAA;AAIF;;AADA;EACE,wBAAA;AAIF;;AADA;EACE,wBAAA;AAIF;;AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;AAGF","sourcesContent":["// @import \"../../../styles.scss\";\n// @import \"../../../component_variable.scss\";\n.line-holder{\n  display: flex;\n  flex-flow: column;\n  width: 85%;\n  margin: auto;\n  justify-content: space-evenly;\n}\n\n.set-z-index {\n  z-index: 2;\n}\n.ng-dragging {\n  z-index: 10;\n}\n.bounds {\n  height: 70vh;\n  width: 85vw;\n  margin-left: auto;\n  margin-right: auto;\n}\n.flashcardBounds {\n  height: 70vh;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.cdk-drag-placeholder {\n  opacity: 0;\n}\n.x-small-line {\n  height: 32px !important;\n}\n.small-line{\n  height: 42px !important;\n\n}\n.medium-line{\n  height: 62px !important;\n\n}\n.large-line {\n  height: 82px !important;\n\n}\n.x-large-line {\n  height: 102px !important;\n\n}\n.xx-large-line {\n  height: 122px !important;\n\n}\n.xxx-large-line {\n  height: 142px !important;\n\n}\n\n.bumping-tiles-container {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  margin-top: -30px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
