import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum PlanActionTypes {
    GetPlan = '[PlanService] Get Plan',
    GetPlanSuccess = '[PlanService] Get Plan Success',
    GetPlanFail = '[PlanService] Get Plan Fail',

    ClearPlan = '[PlanService] Clear Plan',

    GetAllPlans = '[PlanService] Get All Plans',
    GetAllPlansSuccess = '[PlanService] Get All Plans Success',
    GetAllPlansFail = '[PlanService] Get All Plans Fail'

}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class GetPlan implements Action {
    readonly type = PlanActionTypes.GetPlan;

    constructor(public payload: string) {}
}

export class GetPlanSuccess implements Action {
    readonly type = PlanActionTypes.GetPlanSuccess;

    constructor(public payload: any) {}
}
export class GetPlanFail implements Action {
    readonly type = PlanActionTypes.GetPlanFail;

    constructor(public payload: string) {}
}
export class ClearPlan implements Action {
    readonly type = PlanActionTypes.ClearPlan;

}

export class GetAllPlans implements Action {
    readonly type = PlanActionTypes.GetAllPlans;

    constructor(public payload: any) {}
}

export class GetAllPlansSuccess implements Action {
    readonly type = PlanActionTypes.GetAllPlansSuccess;

    constructor(public payload: any) {}
}
export class GetAllPlansFail implements Action {
    readonly type = PlanActionTypes.GetAllPlansFail;

    constructor(public payload: string) {}
}



export type PlanActions
= GetPlan
| GetPlanSuccess
| GetPlanFail
| ClearPlan
| GetAllPlans
| GetAllPlansSuccess
| GetAllPlansFail;
