import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_DIR } from '../../environments/environment';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable()

export class RegistrationService {
  authData: any;

  constructor(private http: HttpClient, private router: Router, private _api: ApiService) {}

  public register(json) {

    return this.http.post(API_DIR + '/registration', json, httpOptions);
  }
}
