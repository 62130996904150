import { Store } from '@ngrx/store';
import { Component, Output, EventEmitter, Input, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { ActivityHeaderService } from '../../../core/services/activity-header.service';
import { DataService } from '../../../core/services/data.service';
import { getCurrentSetting, selectCurrentTheme } from '../../../core/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { ActivityCanvasService } from '../../../fabric-canvas/services/activity-canvas.service';
import { ColorService } from '../../../core/services/color.service';


/**
 * @title Basic menu
 */
@Component({
  selector: 'app-color-menu',
  templateUrl: './color-menu.html',
  styleUrls: ['./color-menu.scss'],
})


export class ColorMenuComponent implements AfterViewInit, OnDestroy {
  @Output() selectColorEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectLineWidthEvent = new EventEmitter<any>();
  @Input() colorStatus = false;
  @Input() type: 'marker' | 'stamp';

  lineWidth = 0;
  public basicColors: string[] = this._colorService.basicColors;

  public highlighters: string[] = this._colorService.highlighters;
  public stampColor;
  public markerColor;
  activityTool: any;
  private unsubscribe$: Subject<void> = new Subject();
  public isDarkMode: boolean;
  disappearLinesEnabled = this.activityCanvasService.disappearLinesEnabled;
  isShowMarkerControls = this.activityCanvasService.isShowMarkerControls;

  constructor(
    public service: ActivityHeaderService,
    public activityCanvasService: ActivityCanvasService,
    public dataService: DataService,
    public store: Store,
    public _colorService: ColorService) {
      // this.dataService.getMode().subscribe((modes: any) => {
      //   if (modes.dark) {
      //     this.selectedColor = '#b0afaf';
      //     this.selectColor(this.selectedColor);
      //   }
      // });

      this.store.select(selectCurrentTheme)
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe(theme => {
          if (theme === 'theme-dark') {
            // this.selectColor('#fff');
            this.isDarkMode = true;
          } else {
            // this.selectColor('#000');
            this.isDarkMode = false;
          }
        });

      // this.store.select(getCurrentSetting)
      //   .pipe(filter(res => !!res), takeUntil(this.unsubscribe$))
      //   .subscribe(settings => {
      //     this.markerColor = settings.markerColor;
      //     this.stampColor = settings.stampColor;
      //   })
      
      // this.stampColor = this.service.color;
      // if (this.selectedColor === 'black') {
      //   this.selectedColor = '#000';
      // }
      // this.lineWidth = this.service.lineWidth;
     }

  ngAfterViewInit(): void {
      this.lineWidth = this.activityCanvasService.lineWidth;
      this.markerColor = this.activityCanvasService.markerColor;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectColor(color, type: 'marker' | 'stamp') {
    if (type === 'marker') {
      this.markerColor = color;
      return this.selectColorEvent.emit(this.markerColor);
    } else {
      this.stampColor = color;
      return this.selectColorEvent.emit(this.stampColor);
    }
  }
  activeColour(tool) {
    // this.service.tool = '';
    this.service.activityTool = 'color';
    this.activityTool = tool;
  }

  selectLineWidth(value) {
    // this.service.lineWidth = value;
    this.service.toggleTool('draw');
    // this.activityCanvasService.setMarkerWidth(value);
    // this.activityCanvasService.activateTool('draw');
    this.selectLineWidthEvent.emit(value);
    // this.selectColorEvent.emit(this.markerColor);
  }

  toggleDisappear(value) {
    this.activityCanvasService.toggleDisappearLines(value);
    this.activityCanvasService.activateTool('draw');
  }

  stopPropagate(ev) {
    ev.stopPropagation();
  }
}

