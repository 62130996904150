import { Store } from '@ngrx/store';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild, OnChanges, ViewChildren, QueryList } from '@angular/core';
import {FilesService} from '../../../../services/files.service';
import {UserService} from '../../../../services/user.service';
import {combineLatest, fromEvent, Observable, Subject, BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, map, switchMap, take, takeUntil} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {Router} from '@angular/router';
import {UtilityService} from '../../../../services/utility.service';
import { UpdateFileSuccess, ReorderWorkbookFilesSuccess, UpdateWorkbookFileSuccess, UpdateFile } from './../../../../store/file/file.actions';
import * as reducers from '../../../../store';
import { AsyncPipe } from '@angular/common';
import {FileCardsComponent} from './file-cards.component';

@Component({
  selector: 'app-workbook-builder-files',
  templateUrl: './html/files.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
    './files.scss'

  ]
})

export class FilesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('fileSearchBox') fileSearchBox;
  @ViewChild('workbookFiles') workbookFilesCard: FileCardsComponent;

  @ViewChild('fileBank') fileBank: FileCardsComponent;
  @Input() workbookName: string;
  @Input() workbookId: string;
  isMobile: boolean;


  files: any[];
  public workbookFiles = [];
  public workbookFilesSubject: any = new BehaviorSubject<any>(this.workbookFiles);
  public workbookFiles$ = this.workbookFilesSubject.asObservable();

  private searchBox: any;
  private keyup$: any;
  // _iOSDevice = /iPhone|iPod|iPad|Android/.test(navigator.platform);


  addFileSubject: Subject<any> = new Subject<any>();
  addWorkbookFileSubject: Subject<any> = new Subject<any>();
  removeFileSubject: Subject<any> = new Subject<any>();
  removeWorkbookFileSubject: Subject<any> = new Subject<any>();
  searchTermSubject: Subject<any> = new Subject<any>();
  // otherItemsUpdatedSubject: Subject<any> = new Subject<any>();
  private unsubscribe$: Subject<void> = new Subject();
  public showBank = false;
  newGeneralFile = false;
  newWorkbookFile = false;

  selectionModeSubject: Subject<boolean> = new Subject<boolean>();
  inSelectionMode = false;

  workbookFilesSelectedCount: number = 0;
  fileBankSelectedCount: number = 0;

  inWorkbookFilesColor: string;

  private subscriptions: Subscription[] = [];

  constructor(
    private filesService: FilesService,
    private userService: UserService,
    private utilityService: UtilityService,
    private store: Store,
    private async: AsyncPipe
  ) {
    this.isMobile = this.utilityService.mobileCheck();
    this.inWorkbookFilesColor = this.utilityService.getRandomColor();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    combineLatest([
      // this.filesService.getManyFiles(JSON.parse(localStorage.profile).user_metadata.uid),
      this.store.select(reducers.selectGetFiles),
      this.store.select(reducers.selectGetWorkbookFiles)
    ])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([files, workbookFiles]) => {
      if (!files || !workbookFiles) {
        // this.files = [];
        // this.workbookFiles = [];
        return;
      }
      // @ts-ignore
      if (files) {
        this.files = JSON.parse(JSON.stringify(files));
      }
      let orderedWorkbookFiles = [];
      if (workbookFiles?.length) {
      // @ts-ignore
      if (workbookFiles[0].files?.length) {
        orderedWorkbookFiles = JSON.parse(JSON.stringify(workbookFiles[0].files))?.sort((a, b) => a?.sortOrder ? a?.sortOrder - b?.sortOrder : 0) || [];
      }
      }
      
      this.workbookFilesSubject.next(orderedWorkbookFiles);

    });
  }

  ngAfterViewInit(): void {
    this.searchBox = this.fileSearchBox.nativeElement;
    this.keyup$ = fromEvent(this.searchBox, 'keyup');

    this.keyup$
      .pipe(
        map((i: any) => i.currentTarget.value),
        debounceTime(200),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(searchTerm => {
        this.searchTermSubject.next(searchTerm);
      });

      const sub = this.workbookFilesCard.selectedFilesCountChange.subscribe((count: number) => {
        this.workbookFilesSelectedCount = count;
      });
      this.subscriptions.push(sub);
  }

  ngAfterViewChecked() {
    if(this.showBank && this.fileBank) {
      const sub = this.fileBank.selectedFilesCountChange.subscribe((count: number) => {
        this.fileBankSelectedCount = count;
      });
      this.subscriptions.push(sub);
    }
  }

  removeFromWorkbookEvent(workbookFileId: string) {
    // this.filesService
    //   .deleteWorkbookFile(workbookFileId)
    //   .pipe(
    //     take(1)
    //   )
    //   .subscribe(() => {
    //     const index = this.workbookFiles.findIndex(workbookFile => workbookFile._id === workbookFileId);

    //     if (index >= 0) {
    //       this.workbookFiles.splice(index, 1);
    //     }

    //     this.removeWorkbookFile(workbookFileId);
    //   });
  }
  toggleBank() {
    if(this.showBank && this.inSelectionMode) {
      // Turns off selection mode. Emits event to clear all selections in the file cards component
      this.toggleSelectionMode();
    }
    return this.showBank = !this.showBank;
  }

  toggleSelectionMode() {
    this.inSelectionMode = !this.inSelectionMode
    this.selectionModeSubject.next(this.inSelectionMode);
  }

  addManyToWorkbook() {
    this.fileBank.addManyToWorkbook();
  }

  removeManyFromFileBank() {
    this.fileBank.removeManyEvent();
  }

  removeManyFromWorkbookFiles() {
    this.workbookFilesCard.removeManyEvent(); 
  }

  selectAll(card: string) {
    if(card === 'workbookFiles') {
      this.workbookFilesCard.selectAll();
    } else if(card === 'fileBank') {
      this.fileBank.selectAll();
    }
  }

  clearSelectedFiles(card: string) {
    if(card === 'workbookFiles') {
      this.workbookFilesCard.clearSelection();
    } else if(card === 'fileBank') {
      this.fileBank.clearSelection();
    }
  }

  clearSearch() {
    this.searchTermSubject.next('');
    this.fileSearchBox.nativeElement.value = '';
  }

  addToWorkbookEvent(fileId: string) {
    // this.filesService
    //   .createWorkbookFile({fileId: fileId, workbookId: this.workbookId})
    //   .pipe(
    //     take(1)
    //   )
    //   .subscribe((workbookFile: any) => {
    //     this.workbookFiles.push(workbookFile);
    //     this.addWorkbookFileSubject.next(workbookFile);
    //     // this.otherItemsUpdatedSubject.next(this.workbookFiles);
    //   });
  }

  updateWorkbookFiles(workbookFiles) {
    this.workbookFilesSubject.next(workbookFiles[0].files);
    if (this.workbookFilesCard) {
      this.workbookFilesCard.updateFilterFilesSubject();
    }
  }

  removeEvent(fileId: string) {
    // this.filesService
    //   .deleteFile(fileId)
    //   .pipe(
    //     take(1)
    //   )
    //   .subscribe(() => {
    //     const index = this.files.findIndex(file => file._id === fileId);

    //     if (index >= 0) {
    //       this.files.splice(index, 1);
    //     }

    //     this.removeFileSubject.next(fileId);
    //   });
  }

  // theses are necessary for the situation where you are adding a file from the workbook files section or removing a workbook file from
  // the files section, otherwise a refresh would be needed to see the changes
  addFile($event: any) {
    // this.addFileSubject.next($event);
  }

  removeWorkbookFile($event) {
    // this.removeWorkbookFileSubject.next($event);
  }

  moveFile($event: any) {
    // const newFile = $event.container.data[$event.currentIndex];
    // newFile["sortOrder"] = $event.currentIndex;
    // this.filesService.updateWorkbookFile(newFile._id, newFile);
  }

  updateWorkbookFileName(event: any) {
    let workbookFiles: any = this.async.transform(this.workbookFiles$);
    let requests = [];

    console.log('updateWorkbookFileName', event); 
    workbookFiles = workbookFiles.map((workbookFile, index) => {
      if (workbookFile.file.file === event.fileName) {
        console.log('found file', workbookFile);
        let newWorkbookFile = {
          ...workbookFile,
        name: event.name
        };
        // this.store.dispatch(new UpdateWorkbookFileSuccess({id: workbookFile._id, file: newWorkbookFile}));
        return newWorkbookFile;
      }
      return workbookFile;
    });

    console.log('workbookFiles', workbookFiles);
    this.store.dispatch(new ReorderWorkbookFilesSuccess(workbookFiles));
    // requests.push(this.filesService.updateWorkbookFile(this.workbookId, workbookFiles));

    this.clearSearch();

    // combineLatest(requests)
    //     .pipe(take(1))
    //     .subscribe();
  }
}


