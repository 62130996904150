import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import { selectGetPairings } from "../store";
import { GetPairings } from "../store/words/words.actions";

@Injectable()
export class WordPairingsResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(selectGetPairings),
      tap(isLoaded => {
        if (!isLoaded) {
          this.store.dispatch(new GetPairings(''));
        }
      }),
      filter(isLoaded => !!isLoaded),
      first()
    );
  }
}

