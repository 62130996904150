import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum WordsActionTypes {
    PostWords = '[WordsService] Post Words',
    PostWordsSuccess = '[WordsService] Post Words Success',
    PostWordsFail = '[WordsService] Post Words Fail',

    GetTilesByWordId = '[WordsService] Get Tiles By WordId ',
    GetTilesByWordIdSuccess = '[WordsService] Get Tiles By WordId Success',
    GetTilesByWordIdFail = '[WordsService] Get Tiles By WordId Fail',


    GetWordsByWordId = '[WordsService] Get Words by WordId',
    GetWordsByWordIdSuccess = '[WordsService] Get Words by WordId Success',
    GetWordsByWordIdFail = '[WordsService] Get Words by WordId Fail',


    GetPairings = '[WordsService] Get Pairings',
    GetPairingsSuccess = '[WordsService] Get Pairings Success',
    GetPairingsFail = '[WordsService] Get Pairings Fail',


    ClearWords = '[WordsService] Clear Words',
    SetToEmpty = '[WordsService] Set To Empty'
}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class PostWords implements Action {
    readonly type = WordsActionTypes.PostWords;

    constructor(public payload: any) {}
}

export class PostWordsSuccess implements Action {
    readonly type = WordsActionTypes.PostWordsSuccess;

    constructor(public payload: any) {}
}
export class PostWordsFail implements Action {
    readonly type = WordsActionTypes.PostWordsFail;

    constructor(public payload: string) {}
}

export class GetTilesByWordId implements Action {
    readonly type = WordsActionTypes.GetTilesByWordId;

    constructor(public payload: any) {}
}

export class GetTilesByWordIdSuccess implements Action {
    readonly type = WordsActionTypes.GetTilesByWordIdSuccess;

    constructor(public payload: string) {}
}
export class GetTilesByWordIdFail implements Action {
    readonly type = WordsActionTypes.GetTilesByWordIdFail;

    constructor(public payload: string) {}
}

export class GetWordsByWordId implements Action {
    readonly type = WordsActionTypes.GetWordsByWordId;

    constructor(public payload: any) {}
}

export class GetWordsByWordIdSuccess implements Action {
    readonly type = WordsActionTypes.GetWordsByWordIdSuccess;

    constructor(public payload: string) {}
}
export class GetWordsByWordIdFail implements Action {
    readonly type = WordsActionTypes.GetWordsByWordIdFail;

    constructor(public payload: string) {}
}

export class GetPairings implements Action {
    readonly type = WordsActionTypes.GetPairings;

    constructor(public payload: any) {}
}

export class GetPairingsSuccess implements Action {
    readonly type = WordsActionTypes.GetPairingsSuccess;

    constructor(public payload: string) {}
}
export class GetPairingsFail implements Action {
    readonly type = WordsActionTypes.GetPairingsFail;

    constructor(public payload: string) {}
}
export class ClearWords implements Action {
    readonly type = WordsActionTypes.ClearWords;

    constructor(public payload: any) {}
}
export class SetToEmpty implements Action {
    readonly type = WordsActionTypes.SetToEmpty;

    constructor(public payload: any) {}
}

export type WordsActions
= PostWords
| PostWordsSuccess
| PostWordsFail
| GetTilesByWordId
| GetTilesByWordIdSuccess
| GetTilesByWordIdFail
| GetWordsByWordId
| GetWordsByWordIdSuccess
| GetWordsByWordIdFail
| GetPairings
| GetPairingsSuccess
| GetPairingsFail
| ClearWords
| SetToEmpty;
