import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-delete-add-collection-dialog',
    templateUrl: './delete-collection-dialog.component.html',
    styleUrls: ['./delete-collection-dialog.component.scss'],
    encapsulation : ViewEncapsulation.None
})
export class DeleteCollectionDialogComponent  {
     functions;
    constructor(public dialogRef: MatDialogRef<DeleteCollectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }


    close(): void {
        this.dialogRef.close(false);
    }

    deleteCollection(): void {
        this.functions.deleteCollection(this.data.id);
        this.dialogRef.close();

    }

}
