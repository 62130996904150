import { NgModule } from '@angular/core';

import { CoreModule } from '../core/core.module';

import {RegistrationComponent, RegistrationInfoDialogComponent} from './registration.component';

import { RegistrationService } from '../core/services/registration.service';

import { RegistrationRoutingModule } from './registration-routing.module';

@NgModule({
    declarations: [RegistrationComponent, RegistrationInfoDialogComponent],
    imports: [
        CoreModule,
        RegistrationRoutingModule
    ],
    providers: [RegistrationService]
})

export class RegistrationModule {}
