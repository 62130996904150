import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_DIR} from '../../environments/environment';

@Injectable()

export class SubscriptionService {

  constructor(private http: HttpClient) {
  }

  get(id: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/subscription/${id}`)
        .subscribe(
          (subscription: any) => observer.next(subscription.data),
          error => {
            throw error;
          }
        );
    });
  }

  getMany(userId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/subscription/user/${userId}`)
        .subscribe(
          (subscriptions: any) => observer.next(subscriptions.data),
          error => {
            throw error;
          }
        );
    });
  }

  create(subscription: any) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/subscription`, subscription)
        .subscribe(
          (result: any) => observer.next(result.data),
          error => {
              throw error;
            }
          );
    });
  }

  delete(subscriptionId: any) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/subscription/${subscriptionId}`)
        .subscribe(
          (result: any) => observer.next(result.data),
          error => {
            throw error;
          }
        );
    });
  }
}
