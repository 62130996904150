import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-word-textarea-filter',
  templateUrl: './html/word-filter-textarea.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    './word-filter.scss'

  ]
})

export class WordFilterTextareaComponent implements OnInit {
  @Input() filter: any;
  @Output() updateQuery: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {

  }


  updateQueryEvent(event: any) {
    if (!event) { return; }

    // When you insert list of words separated by new line, it will be converted to comma separated list
    if(event.value.includes('\n')) {
      event.value = event.value.replaceAll('\n', ',');
    }

    const values = event.value.split(',').map(value => value.trim().toLowerCase());
    this.updateQuery.emit({[this.filter.name]: values});
  }
}
