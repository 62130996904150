import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class NavbarListenerService {
   private _navBarListner = new BehaviorSubject<any>(false);
   private _navBarAction = new Subject<'open' | 'close'>();

   isSidenavExpand = true;

   navBarListner() {
      return this._navBarListner.asObservable();
   }

   public navBarAction$ = this._navBarAction.asObservable();

   navbarCallback(callback: boolean) {
      this.isSidenavExpand = callback ? false : true;
      this._navBarListner.next(callback);
   }

   // Method to trigger the open event
   notifyNavbarOpened(): void {
      this._navBarAction.next('open');
   }

   // Method to trigger the close event
   notifyNavbarClosed(): void {
      this._navBarAction.next('close');
   }

}
