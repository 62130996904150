import * as planActions from './plan.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface State extends EntityState<any> {
    selectedId: string;
    id: string;
    pending: boolean;
    error: any;
    succeeded: boolean;
    all: boolean;
    plan: any;
}

export const adapter: EntityAdapter<string> = createEntityAdapter({
    selectId: (plan: any) => plan._id
});
const initialState: State = adapter.getInitialState({
    selectedId: null,
    id: null,
    pending: false,
    error: null,
    succeeded: false,
    all: false,
    plan: null
});


export function reducer(state = initialState, action: planActions.PlanActions): State {
    switch (action.type) {
        case planActions.PlanActionTypes.GetAllPlans: {
            return {
                ...state,
                selectedId: action.payload,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case planActions.PlanActionTypes.GetAllPlansSuccess: {
            return {
                ...adapter.addMany(action.payload, state),
                succeeded: true,
                pending: false,
                all: true,
                error: null
            };
        }
        case planActions.PlanActionTypes.GetAllPlansFail: {
            return {
                ...state,
                succeeded: false,
                pending: false,
                selectedId: null,
                error: action.payload,
                all: false
            };
        }
        case planActions.PlanActionTypes.GetPlan: {
            return {
                ...state,
                id: action.payload,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case planActions.PlanActionTypes.GetPlanSuccess: {
            return {
                ...state,
                succeeded: true,
                pending: false,
                id: state.id,
                plan: action.payload,
                error: null
            };
        }
        case planActions.PlanActionTypes.GetPlanFail: {
            return {
                ...state,
                succeeded: false,
                pending: false,
                id: null,
                error: action.payload
            };
        }
        case planActions.PlanActionTypes.ClearPlan: {
            return {
                ...state,
                succeeded: false,
                pending: false,
                id: null,
                plan: null,
                error: null
            };

        }
        default:
            return state;
    }
}

export const {
    selectIds: SelectAllIds,
    selectEntities: SelectAllEntities,
    selectAll: SelectAllPlans,
} = adapter.getSelectors();

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.succeeded;
export const getError = (state: State) => state.error;
export const getAllSucceeded = (state: State) => state.all;
export const getSelectedPlan = (state: State) => {
    return state.entities[state.selectedId];
};
export const getPlan = (state: State) => state.plan;

export const getAllPlans = (state: State) => {
    let courses = SelectAllPlans(state);
    // courses = courses.sort((a: any, b: any) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
    return [...courses];
};
