import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_DIR } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()

export class PhraseService {

  constructor(private http: HttpClient) {}

  getManyPhrases(userId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/phrase/user/${userId}`)
        .subscribe(
          (phrases: any) => observer.next(phrases.data),
          error => {
            throw error;
          }
        );
    });
  }

  getManyWorkbookPhrases(workbookId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/workbookPhrase/workbook/${workbookId}`)
        .subscribe(
          (workbookPhrases: any) => observer.next(workbookPhrases.data),
          error => {
            throw error;
          }
        );
    });
  }

  createPhrase(newPhrase: any) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/phrase`, newPhrase)
        .subscribe(
          (phrase: any) => observer.next(phrase.data),
          error => {
            throw error;
          }
        );
    });
  }

  createWorkbookPhrase(newWorkbookPhrase: any) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/workbookPhrase`, newWorkbookPhrase)
        .subscribe(
          (workbookPhrase: any) => {
            observer.next(workbookPhrase.data);
          },
          error => {
            throw error;
          }
        );
    });
  }

  updatePhrase(phraseId: string, updatedPhrase: any) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/phrase/${phraseId}`, {phrase: updatedPhrase})
        .subscribe(
          (phrase: any) => observer.next(phrase.data),
          error => {
            throw error;
          }
        );
    });
  }

  updateWorkbookPhrase(phraseId: string, data: any) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/workbookPhrase/${phraseId}`, data)
        .subscribe(
          (phrase: any) => observer.next(phrase.data),
          error => {
            throw error;
          }
        );
    });
  }

  deletePhrase(phraseId: string) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/phrase/${phraseId}`)
        .subscribe(
          () => observer.next(),
          error => {
            throw error;
          }
        );
    });
  }

  deleteWorkbookPhrase(collectionId: string, itemId: string) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/workbookPhrase/${collectionId}/${itemId}`)
        .subscribe(
          () => observer.next(),
          error => {
            throw error;
          }
        );
    });
  }

  deleteManyWorkbookPhrases(phraseId: string) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/workbookPhrase/phrase/${phraseId}`)
        .subscribe(
          () => observer.next(),
          error => {
            throw error;
          }
        );
    });
  }
}
