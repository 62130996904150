import { Store } from '@ngrx/store';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { ActivityService } from '../../services/activity.service';
import { ListenerService } from '../../../core/services/listener.service';
import {CourseWorkbookService} from '../../services/course-workbook.service';
import {take, takeUntil} from 'rxjs/operators';
import { _variable_images } from '../../../environments/environment';
import { DataService } from '../../services/data.service';
import { selectCurrentTheme, selectGetAllCourseWorkbook } from '../../store';
import { Subject } from 'rxjs';
import { AnyTxtRecord } from 'dns';

@Component({
  selector: 'app-header-activities',
  templateUrl: './html/header-activities.html',
  styleUrls: [
    './header-activities.scss',
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss'
  ]
})

export class HeaderActivitiesComponent implements OnInit, OnDestroy {
  @Input() courseWorkbook: any;
  @Input() activityName: string;
  @Output() toggleShowActivitiesMenuEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleShowCourseWorkbooksMenuEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleHeaderHover: EventEmitter<any> = new EventEmitter<any>();
  courseWorkbooks: any[];
  downActivityImage: any;
  downActivityImage2: any;
  private unsubscribe$: Subject<void> = new Subject();
public  name: any;
  constructor(private courseWorkbookService: CourseWorkbookService,
    private dataService: DataService, private store: Store) {}

  ngOnInit() {
    this.dataService._variable_images.pipe(takeUntil(this.unsubscribe$)).subscribe(path => {
      this.downActivityImage = path + 'down-activity.png';
    });
    this.downActivityImage = _variable_images + 'down-activity.png';
    // this.courseWorkbookService
    //   .getAll(this.courseWorkbook.courseId)
    //   .pipe(take(1))
    //   .subscribe((courseWorkbooks: any[]) => {
    //     this.courseWorkbooks = courseWorkbooks;
    //   });

    this.store.select(selectGetAllCourseWorkbook)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(courseWorkbooks => {
        if (!courseWorkbooks || courseWorkbooks.length === 0) {
          return;
        }
        this.courseWorkbooks = courseWorkbooks;
        let courseWorkbook = this.courseWorkbooks
        .find((courseWorkbook) =>
        courseWorkbook.workbookId === this.courseWorkbook.workbookId);
        if (courseWorkbook) {
          this.name = courseWorkbook.workbook.name;
        }
      });

    this.store.select(selectCurrentTheme)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(theme => {
        if (theme === 'theme-dark') {
          this.downActivityImage = 'assets/img/down.png';
          this.downActivityImage2 = 'assets/img/down-gray.png';
        } else {
          this.downActivityImage = _variable_images + 'down-activity.png';
          this.downActivityImage2 = this.downActivityImage;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public toggleShowActivitiesMenu() {
    this.toggleShowActivitiesMenuEvent.emit();
  }

  public toggleShowCourseWorkbooksMenu() {
    this.toggleShowCourseWorkbooksMenuEvent.emit();
  }

  toggleHeaderHoverEvent(isEnter: boolean) {
    this.toggleHeaderHover.emit(isEnter);
  }
}
