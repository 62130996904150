import {Component, OnInit} from '@angular/core';
import {SettingsService} from '../../core/services/new-settings.service';
import {TilesService} from '../../core/services/word-filter-tiles.service';
import {UserService} from '../../core/services/user.service';
import {switchMap, take} from 'rxjs/operators';
import {PairingsService} from '../../core/services/pairings.service';
import { Store } from '@ngrx/store';
import { getCurrentSetting } from '../../core/store';
import * as SettingsActions from "../../core/store/settings/settings.actions";

@Component({
  selector: 'app-advanced',
  templateUrl: './html/advanced.html',
  styleUrls: [
    '../../assets/css/main.css',
    '../../assets/scss/fontawesome.scss',
    '../../assets/scss/brands.scss',
    '../../assets/scss/regular.scss',
    '../../assets/scss/solid.scss',
    './advanced.component.scss'
  ]
})
export class AdvancedComponent implements OnInit {
  userSettings: any = {};
  tiles: any[] = [];
  compoundSounds: any[] = [];

  constructor(
    private settingsService: SettingsService,
    private tilesService: TilesService,
    private userService: UserService,
    private pairingsService: PairingsService,
    private store: Store,
  ) {}
  // this.subscriptions.add(
  //   .pipe(takeUntil(this.unsubscribe$))
  //   .subscribe(userSettings => {
  //     if(!this.userSettingsSubject){
  //       this.userSettingsSubject = new BehaviorSubject<any>(userSettings);
  //       this.userSettings$ = this.userSettingsSubject.asObservable();
  //     }
  //     this.userSettingsSubject.next(userSettings)
  //   })
  // );

  ngOnInit() {
    this.tilesService
      .getAllTiles()
      .pipe(
        take(1),
        switchMap((tiles: any[]) => {
          this.tiles = tiles;
          return this.store.select(getCurrentSetting);
        }),
        switchMap((userSettings: any) => {
          if (userSettings) {
            this.userSettings = userSettings;

            this.compoundSounds = Object.keys(this.userSettings.compoundSounds)
              .map(key => {
                return {name: key, value: this.userSettings.compoundSounds[key]};
              })
              .map((compoundSound: any) => {
                compoundSound.tileType = this.tiles.find(tile => {
                  return tile.tiles.includes(t => {
                    const tileValue = t.lastIndexOf('.') + 1;
                    const compoundTileName = compoundSound.name.substring(0, compoundSound.name.indexOf('-'));
                    return t.substring(tileValue === compoundTileName);
                  });
                });
  
                return compoundSound;
              });
  
            return this.pairingsService.getPairingsFromCompoundSound(Object.keys(this.userSettings.compoundSounds));
  
          }
        }))
      .subscribe((pairings: any) => {
        pairings.forEach(pairing => {
          const index = this.compoundSounds.findIndex(compoundSound => compoundSound.name === pairing.compoundSound);
          this.compoundSounds[index].graphemeSets = pairing.graphemeSets;
          this.compoundSounds[index].example = pairing.example;
        });
      });
  }

  update(compoundSoundName: string, value: number) {
    // update model for ui
    const index = this.compoundSounds.findIndex(compoundSound => compoundSound.name === compoundSoundName);
    this.compoundSounds[index].value = value;

    // update db
      this.store.dispatch(new SettingsActions.UpdateCompoundSounds({
        compoundSounds: { name: compoundSoundName, value: value },
        id: this.userSettings._id
      }));
  
  }
}
