import * as CoursesActions from '../../store/courses/courses.actions';
import { Store } from '@ngrx/store';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CourseService } from '../../services/courses.service';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { ListenerService } from '../../services/listener.service';
import { Router } from '@angular/router';
import { _variable_images } from '../../../environments/environment';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { DataService } from '../../services/data.service';
import * as reducers from '../../store';

@Component({
  selector: 'app-manage-course-dialog',
  styleUrls: ['./manage-course-dialog.component.scss'],
  templateUrl: './manage-course-dialog.component.html'
})
export class ManageCourseDialogComponent implements OnInit, OnDestroy {
  shared = false;
  duplicateImage: any;
  deleteImage: any;
  selectedPlan: string;
  public functions;
  public maxlengthDes: boolean;
  public maxlengthName: boolean;

  private unsubscribe$: Subject<void> = new Subject();
  private courses: any;
  duplicateMessage = '';

  manageCourseForm: UntypedFormGroup;
constructor(
    public dialogRef: MatDialogRef<ManageCourseDialogComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any, private _courseService: CourseService, private listnerService: ListenerService, private router: Router,
    private dataService: DataService, private store: Store, private formBuilder: UntypedFormBuilder) {
      // this.duplicateImage = _variable_images + 'duplicate.png';
      // this.deleteImage = _variable_images + 'delete-modal.png';
      this.listnerService.getPlan().pipe(takeUntil(this.unsubscribe$)).subscribe((res: string) => {
        this.selectedPlan = res;
      });

      this.manageCourseForm = this.formBuilder.group({
        courseName: [this.data.currentCourse.courseName, [Validators.maxLength(40), Validators.required, (control: AbstractControl) => {
          const exist = !!this.courses?.find(({_id, courseName}) => courseName === control.value && _id !== this.data.currentCourse._id);
          if (exist) {
            return { duplicate: true };
          }
          return false;
        }]],
        notes: [this.data.currentCourse.notes, [Validators.maxLength(160)]],
      });



    }

  ngOnInit() {
    this.dataService._variable_images.pipe(takeUntil(this.unsubscribe$)).subscribe(path => {
      this.duplicateImage = path + 'duplicate.png';
      this.deleteImage = path + 'delete-modal.png';
    });

    this.store.select(reducers.selectGetAllCourses)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(courses => this.courses = courses);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  onSave(copy?: boolean, id?: string): void {
    let formData = { ...this.data.currentCourse, ...this.manageCourseForm.value};
    this.store.dispatch(new CoursesActions.UpdateCourse(formData));
    this.dialogRef.close('course updated');

    // this.dialogRef.close('updated');
    // this._courseService
    //   .update(this.data.currentCourse)
    //   .pipe(
    //     take(1),
    //     switchMap((result: any) => {
    //       this.dialogRef.close('updated');
    //       this.listnerService.callback('updateSideNav');
    //        return new Observable(observer => observer.next([]));
    //     })
    //   )
    //   .subscribe(
    //     () => {
    //       if (copy) {
    //         this.listnerService.callback(`CopiedSideNav:${id}`);
    //       }
    //     }
    //   );
  }
// onkeyName(event: any): void {
//     const name = this.manageCourseForm.get('name');
//     this.maxlengthName = name.status === "INVALID"  ? true : false;
//     this.data.currentCourse.courseName = name.value;
// }
// onkeyDes(event: any): void {
//   const name = this.manageCourseForm.get('description');
//   this.maxlengthDes = name.status === "INVALID"  ? true : false;
//   this.data.currentCourse.notes = name.value;
// }

  deleteCourse(): void {
    if (this.data.canDelete) {
      this.dialogRef.close('deleted');
    }

    // if (this.data.canDelete && (!this.data.isFirstCourse || (this.selectedPlan !== 'Superhero' && this.selectedPlan !== 'Hero' && this.selectedPlan !== 'Demo'))) {
    //   this.store.dispatch(new CoursesActions.DeleteCourse(this.data.currentCourse._id));
    //   // this._courseService.delete(this.data.currentCourse._id)
    //   //   .pipe(
    //   //     take(1)
    //   //   )
    //   //   .subscribe(
    //   //     (res) => {
    //   //       this.dialogRef.close('deleted');
    //   //     }
    //   //   );
    //   //   setTimeout(() => {
    //   //     this.dialogRef.close();
    //   //   }, 1200);
    // }
  }

  copyCourse() {
    if (!this.data.isFirstCourse || (this.selectedPlan !== 'Superhero' && this.selectedPlan !== 'Hero' && this.selectedPlan !== 'Demo')) {
      this.store.dispatch(new CoursesActions.CopyCourse(this.data.currentCourse._id));
      this.dialogRef.close('duplicated');

      // this._courseService.copy(this.data.currentCourse._id)
      // .pipe(
      //   take(1)
      // )
      // .subscribe(
      //   (data) => {
      //     this.dialogRef.close('duplicated');
      //   }
      // );
      // this.onSave(true, this.data.currentCourse._id);

    }
  }

  shareCode() {
    if (typeof(navigator.clipboard) === 'undefined') {
      alert('Course SubscriptionCode: ' + this.data.currentCourse._id);
    } else {
      navigator.clipboard.writeText(this.data.currentCourse._id);
      this.shared = true;
      setTimeout(() => this.shared = false, 2000);
    }
  }
}
