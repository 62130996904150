import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddCourseDialogComponent } from '../add-course-dialog/add-course-dialog.component';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddCourseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private authService: AuthService) { }

  ngOnInit() {
  }

  onNoClick(): void {
    if (this.data.logOffNoClick) {
      this.authService.logout();
    }
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    if (this.data.confirmFunction) {
      this.data.confirmFunction();
    }

    if (this.data.logOff) {
      this.authService.logout();
    }

    this.dialogRef.close(true);
  }

}
