import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pairing-section',
  templateUrl: './html/pairing-section.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss'

  ]
})

export class PairingsSectionComponent implements OnInit {
  @Input() title: string;
  @Input() filterName: string;
  @Input() pairings: any[] = [];
  @Output() updateQuery: EventEmitter<any> = new EventEmitter<any>();

  currentOptions: any[] = [];

  constructor() {}

  ngOnInit(): void {
  }

  updateQueryEvent(event: any) {
    this.updateQuery.emit({[this.filterName]: event[this.filterName]});
  }

  setCurrentOptions($event, examples) {
    if ($event.isUserInput) {
      this.currentOptions = examples;
    }
  }
}
