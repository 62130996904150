import { NgModule } from '@angular/core';

import { CoreModule } from '../core/core.module';

import { LessonsComponent } from './lessons.component';

import { LessonsService } from '../core/services/lessons.service';

import { LessonsRoutingModule } from './lessons-routing.module';

@NgModule({
  declarations: [LessonsComponent],
  imports: [
    CoreModule,
    LessonsRoutingModule
  ],
  providers: [LessonsService]
})

export class LessonsModule {}
