import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {API_DIR} from '../../environments/environment';
import {map, take} from 'rxjs/operators';
import { WordsService } from './words.service';


@Injectable()

export class ActivityService implements OnInit {
  public workbook;
  public activity;
  public lessonPlan;
  public lessonPlans;
  public  lessonPlanStep;
  public  lessonPlanSteps;
  public courseWorkbooks;

  constructor(private http: HttpClient, private wordsService: WordsService) {
    this.workbook = new ReplaySubject(1);
    this.activity = new ReplaySubject(1);
    this.lessonPlan = new ReplaySubject(1);
    this.lessonPlans = new ReplaySubject(1);
    this.lessonPlanStep = new ReplaySubject(1);
    this.lessonPlanSteps = new ReplaySubject(1);
    this.courseWorkbooks = new ReplaySubject(1);
  }

  selectWorkbook(workbook) {
    this.workbook.next(workbook);
  }

  selectActivity(activity) {
    this.activity.next(activity);
  }

  ngOnInit() {}

  blackboardInit(blackboardInitRequest: any) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/activity/blackboard`, {blackboardInitRequest})
        .pipe(
          take(1)
        )
        .subscribe(
          (req: any) => {
            observer.next(req.data);
          },
          error => {
            throw error;
          });
    });
  }

  miniTilesInit(miniTilesInitRequest: any) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/activity/miniTiles`, {miniTilesInitRequest})
        .pipe(
          take(1)
        )
        .subscribe(
          (req: any) => {
            observer.next(req.data);
          },
          error => {
            throw error;
          });
    });
  }

  workbookTilesInit(workbookTilesInitRequest: any) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/activity/workbookTiles`, {workbookTilesInitRequest})
        .pipe(
          take(1)
        )
        .subscribe(
          (req: any) => {
            observer.next(req.data);
          },
          error => {
            throw error;
          });
    });
  }
}
