import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { LessonPlansComponent } from './lesson-plans.component';
import {LessonPlansRoutingModule} from './lesson-plans-routing.module';
import {LessonPlansService} from '../core/services/lesson-plans.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
  DeleteLessonPlanStepComponent,
  EditLessonPlanComponent,
  NoCourseWorkbooksComponent
} from './Edit-Lesson-Plan/edit-lesson-plan.component';
import {LessonPlansStepsService} from '../core/services/lesson-plans-steps.service';
import {CourseWorkbookEditModule} from '../core/components/course-workbook-edit/course-workbook-edit.module';
import { NgsContenteditableModule } from '@ng-stack/contenteditable';

@NgModule({
    declarations: [
        LessonPlansComponent,
        EditLessonPlanComponent,
        DeleteLessonPlanStepComponent,
        NoCourseWorkbooksComponent,
    ],
    imports: [
        CoreModule,
        LessonPlansRoutingModule,
        FontAwesomeModule,
        CourseWorkbookEditModule,
        NgsContenteditableModule
    ],
    providers: [
        LessonPlansService,
        LessonPlansStepsService
    ]
})

export class LessonPlansModule {}
