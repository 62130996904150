import { Store } from '@ngrx/store';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild, ViewContainerRef
} from '@angular/core';
import {SettingsService} from '../../services/new-settings.service';
import {UserService} from '../../services/user.service';
import {UtilityService} from '../../services/utility.service';
import {AUDIO_DIR} from '../../../environments/environment';

import {ListenerService} from '../../services/listener.service';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import { getCurrentSetting } from '../../store';
import { filter, takeUntil } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
@Component({
  selector: 'app-bumping-tiles',
  templateUrl: './html/bumping-tiles.html',
  styleUrls: [
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss',
    './bumping-tiles.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class BumpingTilesComponent implements OnInit, AfterViewInit, OnDestroy {
    // @ts-ignore
    @Input() set lines(lines: number = 0) {
      if (this.paperLines.length > 0) {
          this.paperLines = [];
      }
      for (let i = 0; i < lines; i++) {
        this.paperLines.push({value: 'line'});
      }
  }
  @Input() set setTiles (tiles: any[]) {
    if (!this.tilesSubject) {
      this.tilesSubject = new BehaviorSubject<any>(tiles);
      this.tiles$ = this.tilesSubject.asObservable();
    } else {
      this.tilesSubject.next(tiles);
    }
  }
  @Input() set properties(tiles: any) {
    if (this.tileProperties.length > 0) {
      this.tileProperties = [];
    }
    if (!tiles) {
      this.tileProperties = [];
    } else {
      this.tileProperties = tiles;
    }
  }
  @Input() set newTiles(tiles: any[]) {
    if (tiles.length > 0) {
      this.tilesSubject.next(tiles);
    }
  }
  @Input() set newProperties(properties: any[]) {
    if (properties.length > 0) {
      this.tileProperties = properties;
    }
  }
  @Input() preserveTileOrder = false;
  @Input() cols: number;
  @Input() class = 'bumping-tiles';
  @Input() sight = false;
  @Input() tileSize: number;
  @Input() tileColors = false;
  @Input() isFlashcards = false;
  @Input() showLines = false;
  @Input() padding = '';
  @Input() lowerCase = true;
  @Input() lockAxis = '';
  @Input() boxWidth = 50;
  @Input() boxHeight = 50;
  @Input() containerHeight = 0;
  @Input() bumpingEnabled = true;
  @Input() releasedEvent: Observable<any>;
  @Input() wordState: string;
  @Output() removeTileEvent: EventEmitter<any> = new EventEmitter<any>();
  userSettings: any;
  currentDraggingIndex = 0;
  paperLines: any = [];
  tileProperties: any[] = [];
  tiles: any[] = [];

  tilesSubject: any;

  tiles$: any;
  sound: any = new Audio(AUDIO_DIR + 'snapclick.mp3');
  private bumpingDirection: string;
  private currentBumpedTileIndex: number;
  private currentTileRealPosition: any;
  private subscriptions = new Subscription();
  private unsubscribe$: Subject<void> = new Subject();
  inBounds = true;
  myOutOfBounds = {
    top: true,
    right: false,
    bottom: false,
    left: false
  };
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  snapping = true;
  allowDrag = true;

  constructor(
    private Renderer: Renderer2,
    private settingsService: SettingsService,
    private userService: UserService,
    public utiltiyService: UtilityService,
    private cd: ChangeDetectorRef,
    private listenerService: ListenerService,
    private store: Store,
    private async: AsyncPipe
  ) {
  }

  ngOnDestroy(): void {
    let tiles: any = this.async.transform(this.tiles$);

    tiles = [];
    this.tilesSubject.next(tiles);
    this.subscriptions.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit () {
    this.subscriptions.add(
      this.releasedEvent.subscribe((result: any) => {
        if (result.data) {
          this.onMoving(result.data.event, result.data.index);
          this.updatePosition(result.data.event, result.data.index);
        } else if (result === 'hide') {
          // this.tiles.forEach((tile, index, array) => this.updatePosition({x: tile.x, y: tile.y}, index));
        } else if (result === 'show') {
          // this.tiles.forEach((tile, index, array) => this.updatePosition({x: tile.x, y: tile.y}, index));
        } else if (result === 'released') {
          this.snap();
        } else if (result === 'resize') {
          let tiles: any = this.async.transform(this.tiles$);
          if (tiles) {
            tiles.forEach((tile, index) => {
              this.onMoving(tile, index);
            });
          }
        }
      })
    );

    const userId = JSON.parse(localStorage.profile).user_metadata.uid;
    // This is working with session storage right now, but eventually we want it tied to a user setting. Should be easy to adjust as needed.
    // Lines can be adjust as an input variable, so each activity can have a unique number of lines, or the user can change how many they want.
    if (localStorage.getItem('showLines') === 'show') {
      this.showLines = true;
    }

    // const interval = setInterval(() => {
    //   if (this.settingsService.activitySettings) {
    //     this.userSettings = this.settingsService.activitySettings;

    //     clearInterval(interval);
    //   }
    // }, 300);
    this.store.select(getCurrentSetting).pipe(filter(setting => !!setting), takeUntil(this.unsubscribe$)).subscribe(setting => {
      this.userSettings = JSON.parse(JSON.stringify(setting));
      this.snapping = this.userSettings.snap;
    });
  }

  ngAfterViewInit() {

    setTimeout(() => {
    }, 100);
  }
  checkEdge(event) {
    this.edge = event;
  }
  outOfBounds(position) {
    if (this.myOutOfBounds[position]) {
      this.myOutOfBounds[position] = false;
    } else {
      this.myOutOfBounds[position] = true;
    }
  }
  getTileClasses(i: any) {
    return {
      // 'x-small-tile': this.tileSize === 0,
      'small-tile': this.tileSize === 1,
      'medium-tile': this.tileSize === 2,
      'large-tile': this.tileSize === 3,
      'x-large-tile': this.tileSize === 4
      // 'xx-large-tile': this.tileSize === 5
      // 'xxx-large-tile': this.tileSize === 6

    };
  }
  onStart(event: ElementRef) {
    this.allowDrag = true;
    this.Renderer.addClass(event, this.userSettings.tilehighlightcolor);
  }

  returnLetters(letters, index) {
    if (letters === null) {
      return ;
    }

    if (letters.split('.')[1] === 'blank') {
        return '';
    } else if (letters === 'tiles.sym.4') {
      return '.';
    }

    if (letters === 'period' ) {
      return '.';
    }
    return letters;

  }

  onMoving (event, i) {
    this.currentDraggingIndex = i;
    if (!this.allowDrag) { return; }
    let tiles: any = this.async.transform(this.tiles$);

    tiles.forEach((block, index) => {
      if (index === i) {
        return;
      }
      if (!this.edge.left || !this.edge.right) {
        return;
      }
      this.recursiveBump(i, event, index, i);

    });
  }

  recursiveBump(currentTileIndex, event, nextTileIndex, originalTileIndex) {
    let tiles: any = this.async.transform(this.tiles$);
    if (this.bumpingEnabled && this.tileProperties[nextTileIndex]) {
      const nextWidth = this.tileProperties[nextTileIndex].width || this.boxWidth;
      const currentWidth = this.tileProperties[currentTileIndex].width || this.boxWidth;
      const elmDropZone = document.getElementById('tileDropzone');
      if (elmDropZone) {
        const currentTileWidth = this.tileProperties[currentTileIndex].width;
        if ( (event.x + (currentWidth + 10)) > elmDropZone.offsetWidth ) {
          tiles[currentTileIndex]  = {x: elmDropZone.offsetWidth - ( currentWidth + 10), y: event.y};
          this.allowDrag = false;
          return;
        }
        if (!this.isFlashcards && event.y > elmDropZone.offsetHeight - this.boxHeight - 10 ) {
          tiles[currentTileIndex]  = {x: event.x, y: elmDropZone.offsetHeight - this.boxHeight - 10 };
          this.allowDrag = false;
          return;
        }
        if (event.x < 0) {
          tiles[currentTileIndex]  = {x: 1, y: event.y};
          this.allowDrag = false;
          return;
        }
        // if (event.y < 10) {
        //   tiles[currentTileIndex]  = {x: event.x, y: 10};
        //   this.allowDrag = false;
        //   return;
        // }
    }
      // make sure the moving x is within the bounds of the stationary x
      let forwardHorizontalPredicate = event.x + currentWidth >= tiles[nextTileIndex].x;

      if (this.preserveTileOrder) {
        forwardHorizontalPredicate = forwardHorizontalPredicate && currentTileIndex < nextTileIndex;
      } else {
        forwardHorizontalPredicate = forwardHorizontalPredicate && event.x <= tiles[nextTileIndex].x;
      }

      // make sure the moving x is withing the bounds of the stationary y
      let verticalPredicate = event.y + this.boxHeight >= tiles[nextTileIndex].y
        && event.y <= tiles[nextTileIndex].y + this.boxHeight;

      // // if the general lock axis is supplied keep tiles in order always
      if (this.lockAxis && forwardHorizontalPredicate) {
        verticalPredicate = nextTileIndex > this.currentDraggingIndex;
      }
      // if (forwardHorizontalPredicate && verticalPredicate) {
      //   const bumpedIndex = this.tileProperties[originalTileIndex].tilesBumped && this.tileProperties[originalTileIndex].tilesBumped.length ? this.tileProperties[originalTileIndex].tilesBumped[0] : null;

      //   if(bumpedIndex && ( tiles[originalTileIndex].x + currentWidth >= tiles[bumpedIndex].x )){
      //     // tiles[originalTileIndex] = {x:tiles[bumpedIndex], y:event.y};
      //   }
      // }
      // if bumping from the front
      if (forwardHorizontalPredicate && verticalPredicate) {
        return this.forwardBumpHelper(event, currentTileIndex, originalTileIndex, nextTileIndex, currentWidth);
      }

      // make sure the moving x is within the bounds of the stationary x
      let backwardHorizontalPredicate = event.x < tiles[nextTileIndex].x + nextWidth;

      if (this.preserveTileOrder) {
        backwardHorizontalPredicate = backwardHorizontalPredicate && currentTileIndex > nextTileIndex;
      } else {
        backwardHorizontalPredicate = backwardHorizontalPredicate
          && event.x + nextWidth >= tiles[nextTileIndex].x + (this.tileProperties[nextTileIndex].width / 2);
      }

      if (this.lockAxis && backwardHorizontalPredicate) {
        verticalPredicate = nextTileIndex < this.currentDraggingIndex;
      }

      // if bumping from behind
      if (backwardHorizontalPredicate && verticalPredicate) {
        return this.backwardBumpHelper(event, currentTileIndex, originalTileIndex, nextTileIndex, nextWidth);
      }

      // if not bumping
      const notBumpingPredicate = Math.abs(
        (event.x + (this.tileProperties[originalTileIndex].width / 2))
        - (tiles[nextTileIndex].x + (this.tileProperties[nextTileIndex].width / 2))
      ) > (this.tileProperties[nextTileIndex].width / 2) + (this.tileProperties[originalTileIndex].width / 2) + 5;
      if (
        notBumpingPredicate
        && this.tileProperties[currentTileIndex].tilesBumped
        && this.tileProperties[currentTileIndex].tilesBumped.includes(nextTileIndex)
      ) {
        this.notBumpingHelper(event, currentTileIndex, nextTileIndex, currentWidth, nextWidth);
      }
    }

  }

  forwardBumpHelper(event, currentTileIndex, originalTileIndex, nextTileIndex, currentWidth) {
    this.bumpingDirection = 'forward';
    let tiles: any = this.async.transform(this.tiles$);

    if (!this.tileProperties[currentTileIndex].isBumping) {
      this.tileProperties[currentTileIndex].isBumping = true;
      if (this.snapping) {
        this.sound.play();
      }
    }

    if (!this.tileProperties[currentTileIndex].tilesBumped) {
      this.tileProperties[currentTileIndex].tilesBumped = [];
    }

    if (!this.tileProperties[currentTileIndex].tilesBumped.includes(nextTileIndex)) {
      this.tileProperties[currentTileIndex].tilesBumped = [nextTileIndex];
    }
    // get tiles actual position from the event as the values in the tiles array is not always
    // up to date
    // also store the value of the tile it is bumping so that we can snap to it on release
    if (currentTileIndex === originalTileIndex) {
      this.currentTileRealPosition = {x: event.x, y: event.y};
      this.currentBumpedTileIndex = nextTileIndex;
    }
    // update the x postion for all tiles being bumped
    let newX = event.x + currentWidth + 2;
    const elmDropZone = document.getElementById('tileDropzone');
    if (elmDropZone) {
      // const nextTileWidth = tiles[nextTileIndex].width;
      const nextTileWidth = this.tileProperties[nextTileIndex].width;
      if ( newX > elmDropZone.offsetWidth - (nextTileWidth + 10) ) {
        newX = elmDropZone.offsetWidth - nextTileWidth - 10;
        this.allowDrag = false;
        return;
      }
    }
    tiles[nextTileIndex] = {x: newX, y: tiles[nextTileIndex].y};
    this.tilesSubject.next(tiles);
    tiles.forEach((block, index) => {
      if (index === nextTileIndex || index === originalTileIndex) { return; }

      this.recursiveBump(nextTileIndex, tiles[nextTileIndex], index, originalTileIndex);
    });
  }

  backwardBumpHelper(event, currentTileIndex, originalTileIndex, nextTileIndex, nextWidth) {
    this.bumpingDirection = 'backward';
    let tiles: any = this.async.transform(this.tiles$);

    if (!this.tileProperties[currentTileIndex].isBumping) {
      this.tileProperties[currentTileIndex].isBumping = true;
      if (this.snapping) {
        this.sound.play();
      }
    }

    if (!this.tileProperties[currentTileIndex].tilesBumped) {
      this.tileProperties[currentTileIndex].tilesBumped = [];
    }

    if (!this.tileProperties[currentTileIndex].tilesBumped.includes(nextTileIndex)) {
      this.tileProperties[currentTileIndex].tilesBumped = [nextTileIndex];
    }

    if (currentTileIndex === originalTileIndex) {
      this.currentTileRealPosition = {x: event.x, y: event.y};
      this.currentBumpedTileIndex = nextTileIndex;
    }

    let newX = event.x - nextWidth - 2;
    const elmDropZone = document.getElementById('tileDropzone');
    if (elmDropZone) {
      if (newX < 0) {
        newX = 1;
        this.allowDrag = false;
        return;
      }
    }
    tiles[nextTileIndex] = {x: newX, y: tiles[nextTileIndex].y};
    this.tilesSubject.next(tiles);
    tiles.forEach((block, index) => {
      if (index === nextTileIndex || index === originalTileIndex) { return; }

      this.recursiveBump(nextTileIndex, tiles[nextTileIndex], index, originalTileIndex);
    });
  }

  notBumpingHelper(event, currentTileIndex, nextTileIndex, currentWidth, nextWidth) {
    this.bumpingDirection = null;
    let tiles: any = this.async.transform(this.tiles$);

    const bumpIndex = this.tileProperties[currentTileIndex].tilesBumped.indexOf(nextTileIndex);
    this.tileProperties[currentTileIndex].tilesBumped.splice(bumpIndex);

    this.tileProperties[currentTileIndex].lockAxis = '';
    this.tileProperties[currentTileIndex].isBumping = false;
  }

  updatePosition($event, index) {
    let tiles: any = this.async.transform(this.tiles$);

    // if ($event.y < -this.boxHeight - 10 && removeTile) {
    //   let tiles: any = this.async.transform(this.tiles$);
    //   this.tilesSubject.next(tiles.filter((_, i) => i !== index));
    //   return this.removeTileEvent.emit(index);
    // }

    tiles[index] = {x: $event.x, y: $event.y};
    this.tilesSubject.next(tiles);
  }

  snap() {
    let tiles: any = this.async.transform(this.tiles$);
    tiles = JSON.parse(JSON.stringify(tiles));

    const currentTileIndex = this.currentDraggingIndex;

    const currentWidth = this.tileProperties[currentTileIndex]?.width || this.boxWidth;
    const elmDropZone = document.getElementById('tileDropzone');
    if (elmDropZone) {
      const event = tiles[currentTileIndex];
      if (!event) {
        return;
      }

      if(event.y < -this.boxHeight) {
        this.removeTileEvent.emit(currentTileIndex);
        return;
      }

      if ( (event.x + (currentWidth + 10)) > elmDropZone.offsetWidth ) {
        tiles[currentTileIndex].x  = elmDropZone.offsetWidth - ( currentWidth + 10);
      }
      if (!this.isFlashcards && event.y > elmDropZone.offsetHeight - this.boxHeight - 10 ) {
        tiles[currentTileIndex].y  = elmDropZone.offsetHeight - this.boxHeight - 10;
      }
      if (event.x < 0) {
        tiles[currentTileIndex].x = 1;
      }
    }

    if (!this.bumpingEnabled) {
      this.tilesSubject.next(tiles);
      return;
    }

    let currentTileProperties = this.tileProperties[this.currentDraggingIndex];

    if (!currentTileProperties || !currentTileProperties.tilesBumped || currentTileProperties.tilesBumped.length === 0) {
      this.tileProperties.forEach((tp, i) => {
        if (tp.tilesBumped) {
          this.tileProperties[i].tilesBumped = [];
          this.tileProperties[i].isBumpinlettg = false;
          this.tileProperties[i].lockAxis = '';
        }
      });
      this.tilesSubject.next(tiles);
      return;
    }
    const bumpedTile = tiles[currentTileProperties.tilesBumped[0]];
    // don't allow tile to have itself as
    if (!bumpedTile || currentTileProperties.tilesBumped[0] === this.currentDraggingIndex) {
      this.tileProperties[this.currentDraggingIndex].lockAxis = '';
      return;
    }

    if (
      tiles[this.currentDraggingIndex].y < bumpedTile.y + this.boxHeight + 5
      && tiles[this.currentDraggingIndex].y > bumpedTile.y - 50
    ) {
      tiles[this.currentDraggingIndex] = {x: this.currentTileRealPosition.x, y: bumpedTile.y};
    }

    this.tileProperties[this.currentDraggingIndex].lockAxis = 'y';
    let bumpedList = this.getBumpedListIndex(this.currentDraggingIndex, []);

    const offset = 2;
    if (bumpedList.length > 0) {
      let bumpedList1 = bumpedList.slice(1);
      if (bumpedList1.includes(bumpedList[0])) {
        bumpedList = bumpedList.slice( bumpedList1.lastIndexOf(bumpedList[0]) + 1);
      }
      const elmDropZone = document.getElementById('tileDropzone');
      const firstIndex = bumpedList[0];
      let isBoundary = tiles[firstIndex].x < 10;
      if (elmDropZone) {
        const tileWidth = this.tileProperties[firstIndex].width;
        if ( (tiles[firstIndex].x + (tileWidth + 10)) > elmDropZone.offsetWidth - 10 ) {
          isBoundary = true;
        }
      }

      let offcount = 0;
      for (let index = 0; index < bumpedList.length; index++) {
        if (!isBoundary && this.tileProperties[this.currentDraggingIndex].tilesBumped?.length > 1) {
          continue;
        }
        const tileIndex = bumpedList[index];
        if (tiles[tileIndex].y > tiles[this.currentDraggingIndex].y + 70 || tiles[tileIndex].y < tiles[this.currentDraggingIndex].y - 70) {
          offcount++;
          if (!isBoundary) {
            continue;
          }
        }

        if (this.currentDraggingIndex === tileIndex) {
          if (offcount === bumpedList.length - 1) {
            continue;
          }
        }

        if (index > 0) {
          const prevIndex = bumpedList[index - 1];
          if (this.bumpingDirection === "forward") {
            tiles[tileIndex].x = tiles[prevIndex].x - this.tileProperties[tileIndex].width - offset;
          } else {
            tiles[tileIndex].x = tiles[prevIndex].x + this.tileProperties[prevIndex].width + offset;
          }
        }
      }
    }


    this.tilesSubject.next(tiles);
    this.tileProperties.forEach(tp => {
      tp.tilesBumped = [];
      tp.isBumping = false;
      tp.lockAxis = '';
    });
  }

  getBumpedListIndex(startIndex, indexes, max= 20) {
    const tilesBumped = this.tileProperties[startIndex].tilesBumped;
    if (tilesBumped?.length > 0) {
      if (max === 0) {
        return [...indexes];
      }
      const indexes1 = this.getBumpedListIndex(tilesBumped[0], indexes, max - 1);
      return [...indexes1, startIndex];
    }
     return [startIndex];
  }

  getOnsetRimeTileBg(tileProperties) {
    if (this.wordState === 'Onset/Rime') {
      if (tileProperties.type === 'onset') {
        return this.userSettings.flashcards?.onsetColor + ' fc-black'
      } else if (tileProperties.type === 'rime') {
        return this.userSettings.flashcards?.rimeColor + ' fc-black'
      }
    }
  }
}
