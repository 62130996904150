import {  Component, Input, Output, Renderer2, ElementRef, HostListener, ViewChild, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { SettingsService } from '../../../core/services/new-settings.service';
import { ListenerService } from '../../../core/services/listener.service';
import { switchMap, take } from 'rxjs/operators';
import {ActivityService} from '../../../core/services/activity.service';
import {ActivityHeaderService} from '../../../core/services/activity-header.service';
import { fromEvent, Subscription, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.html',
  styleUrls: [
    './text-box.scss',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss',
  ],

})

export class TextBoxComponent implements AfterViewInit, OnDestroy {
  constructor(private eRef: ElementRef,
    private host: ElementRef<HTMLElement>,
    private settingsService: SettingsService,
    private listenerService: ListenerService,
    private r2: Renderer2,
    public router: Router,
    private elRef: ElementRef,
    // public activityHeaderService: ActivityHeaderService

    // private activityHeaderService: ActivityHeaderService
    ) {
      let heightMonitor = fromEvent(this.host.nativeElement, 'keypress');
      // heightMonitor.subscribe((e) => {

      //   if (this.textBoxValue.nativeElement.offsetHeight >= this.maxHeight - (this.maxHeight * .10)) {
      //     e.stopImmediatePropagation();
      //     e.stopPropagation();
      //     e.preventDefault();
      //   }
      // });
      this.subscriptions.add(
        this.listenerService
        .listener().subscribe((results: any) => {
          if (results === 'destroy all text') {
            if (!this.value || this.value === '') {
              this.onclose(true);

            }
          } else if (results === 'clear trash') {
            this.onclose(true);

          } else if (results === 'hide text') {
            if (this.isPad) {
              this.hide = true;
            }
          } else if (results === 'show text' && this.isPad) {
            this.hide = false;
          }
        })
      );
      const interval = setInterval(() => {
      if (this.settingsService.activitySettings) {
        clearInterval(interval);
        this.loaded = true;
        this.userSettings = this.settingsService.activitySettings;
        TextBoxComponent.fontSize = this.textBoxFontSize = TextBoxComponent.hasFontSizeChanged
          ? TextBoxComponent.fontSize
          : (this.userSettings.fontSize || this.userSettings.defaulttextsize);
        this.fontSizeIndex = this.fontSizes.indexOf(TextBoxComponent.fontSize);
      }
    }, 100);
    const secinterval = setInterval(() => {

    if (this.host.nativeElement.parentElement) {
      clearInterval(secinterval);
      const classList = Array.from(this.host.nativeElement.parentElement.classList);
      if (classList.includes('activity_wrapper') ) {
        this.isPad = true;
        this.zIndex = 115;

      } else if (classList.includes('ng-star-inserted')) {
        this.isPad = false;
        this.zIndex = 113;

      } else if (classList.includes('pdf-viewer')) {
        this.isPad = false;
        this.zIndex = 111;
      } else {
        this.hide = false;
        this.zIndex = 1;

      }
  }
    }, 100);

    // if(Array.from(this.host.nativeElement.parentElement.classList).includes('activity_wrapper')){
    //   this.isPad = true
    // }
    // this.activityHeader = TextBoxComponent.activityHeader.tool$;
    this.subscriptions.add(
      TextBoxComponent.activityHeader.tool$
        .subscribe(tool => {
          this.toolSubject.next(tool) ;
          if (tool !== 'text') {
            this.unFocus();
          }
        })
    );
   }

  get containerHeight() {
    if (this.elRef.nativeElement.parentElement) {
      return this.elRef.nativeElement.parentElement?.parentElement.parentElement.offsetHeight;
    }
    // @ts-ignore
  }

  get containerWidth() {
    return this.elRef.nativeElement.parentElement?.parentElement.parentElement.offsetWidth;
  }

  get textboxMaxWidth() {
    return this.containerWidth - this.position.x;
  }
  static fontSize = 32;
  static activityHeader;
  static hasFontSizeChanged = false;
  protected subscriptions = new Subscription();
  public value: String = '';
  public textBoxFontSize =  TextBoxComponent.fontSize;
  public toolSubject = new BehaviorSubject('text');

  public tool$ = this.toolSubject.asObservable();
  public loaded = false;
  private minSize = 14;
  private maxSize = 64;
  public isPad = false;
  private firstClick: Boolean = true;
  public hide = false;
  public fontSizes = [24, 32, 40, 48, 64];
  public fontSizeIndex: any;
  @ViewChild('textBoxValue', { static: false }) textBoxValue: ElementRef;
  @Input() position: any = { x: 0, y: 0 };
  focused: Boolean = true;
  @Input() id: number;
  @Input() zIndex = 110;
  @Input() parent: any;
  @Input() maxWidth;
  @Input() maxHeight;
  @Input() opacity = 1;
  @Input() positions = [];
  @Input() activity = '';
  @Input() pointerEvents = 'auto';
  // @Input() activityHeader;

  @Output() toggleHover: EventEmitter<any> = new EventEmitter<any>();
  @Output() destroy: EventEmitter<any> = new EventEmitter();
  userSettings: any;
  @ViewChild('draggable') draggable: ElementRef;
  @ViewChild('inputWrapper') inputWrapper: ElementRef;
  // maxTextWidth: number;
  inputWrapperHeight;
  previousTextBoxHeight;
  lastChars = [];

   onclose(isTrash?: boolean) {
     if (isTrash === true || this.textBoxValue.nativeElement.innerHTML === '') {
      this.host.nativeElement.remove();
      // this.listenerService.callback('toggleText')
      // this.activityHeaderService.tool = 'text';
      this.r2.removeChild(this.host.nativeElement.parentElement, this.host.nativeElement);
       this.hide = true;
       this.textBoxValue.nativeElement.innerHTML = '';
     }
   }
   public fontChanged() {
    return TextBoxComponent.hasFontSizeChanged;
  }

  ngAfterViewInit() {
        const interval = setInterval(() => {
          if (this.textBoxValue) {
            clearInterval(interval);

            // if (this.focused) {
              this.textBoxValue.nativeElement.focus();
              this.previousTextBoxHeight = this.textBoxValue.nativeElement.offsetHeight;
            // }
          }
        }, 100);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    const currentWidth = this.textBoxValue.nativeElement.offsetWidth + 1;
    // this.parent = this.eRef.nativeElement
    if (TextBoxComponent.activityHeader.tool !== 'text') {
      this.unFocus();
      return;
    }
    if (this.eRef.nativeElement.contains(event.target)) {
      this.textBoxValue.nativeElement.focus();
      this.focused = true;
    } else if (this.firstClick) {
      if (this.focused) {
        event.preventDefault();
        event.stopPropagation();
        this.maxWidth = this.draggable.nativeElement.parentElement.offsetWidth - this.position.x;

      }
      this.focused = false;
    }

    if (this.focused) {
      this.maxWidth = this.draggable.nativeElement.parentElement.offsetWidth - this.position.x;
    } else {
      this.maxWidth = this.textBoxValue.nativeElement.offsetWidth + (this.textBoxFontSize / 2);
    }
  }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    if (event.charCode === 13) {
      if (this.isMaxHeight()) {
        return false;
      }
    }

    // let updatedTextWidth = this.textBoxValue.nativeElement.offsetWidth;
    // if ((updatedTextWidth < this.textboxMaxWidth) && event.charCode !== 13) {
    //   updatedTextWidth += TextBoxComponent.fontSize;
    // }

    // this.isTextLengthMax = false;
    // if (updatedTextWidth > 210) {
    //   if (updatedTextWidth > this.textboxMaxWidth) {
    //     updatedTextWidth = this.textboxMaxWidth;
    //   }
    // } else {
    //   updatedTextWidth = 210;
    // }
    // this.maxWidth = updatedTextWidth;
  }

  setFontSize(size: boolean) {
    TextBoxComponent.hasFontSizeChanged = true;
    if (size && this.fontSizeIndex !== this.fontSizes.length - 1) {
      this.fontSizeIndex = this.fontSizeIndex + 1;
      TextBoxComponent.fontSize = this.textBoxFontSize = this.fontSizes[this.fontSizeIndex];
    } else if ( !size && this.fontSizeIndex !== 0) {
      this.fontSizeIndex = this.fontSizeIndex - 1;
      TextBoxComponent.fontSize = this.textBoxFontSize = this.fontSizes[this.fontSizeIndex];
    }
    let position = this.positions.find((position) => position.font === this.fontSizes[this.fontSizeIndex]);
    TextBoxComponent.prototype.position = position?.position;
  }

  toggleIsMouseOverHeader(isMouseOverHeader: boolean) {
    this.toggleHover.emit(isMouseOverHeader);
  }

  focusChanged(b: boolean) {
    this.focused = true;
    this.firstClick = true;
  }

  isPdfViewer() {
    return this.router.url.includes('pdf-viewer');
  }

  onDragging(event) {
  }

  onDragStart(event) {
    this.maxWidth = this.textBoxValue.nativeElement.offsetWidth + (TextBoxComponent.fontSize / 4);
    if (this.maxWidth < 210) {
      this.maxWidth = 210;
    }

  }

  onDragEnd(event) {
    const transformValues = event.style.transform.match(/\d+/g);
    const [x, y] = transformValues;
    this.position = { x: +x, y: +y };
    this.firstClick = true;
    this.maxWidth = this.draggable.nativeElement.parentElement.offsetWidth - this.position.x;
  }



  onValueChange(event) {
    // this.maxWidth = this.textBoxValue.nativeElement.offsetWidth + (TextBoxComponent.fontSize / 2);
    // if (this.maxWidth < 210) {
    //   this.maxWidth = 210;
    // }

    if (this.isMaxHeight()) {
      this.inputWrapperHeight = this.inputWrapper.nativeElement.offsetHeight;
    } else {
      this.inputWrapperHeight = undefined;
    }

  }

  isMaxHeight() {
    const controlsHeight = 48;
    return this.position.y + this.draggable.nativeElement.offsetHeight + controlsHeight > this.containerHeight;
  }

  unFocus() {
    this.focused = false;
    this.maxWidth = this.textBoxValue.nativeElement.offsetWidth + (this.textBoxFontSize / 2);
  }

}
