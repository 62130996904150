import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, finalize, first, tap } from "rxjs/operators";
import * as SettingsActions from '../store/settings/settings.actions';
import * as SettingsSelectors from '../store';



@Injectable()
export class SettingsResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(SettingsSelectors.selectAllSettingsSuccess),
      tap(areLoaded => {
        if (!areLoaded) {
          const whizzimoId = JSON.parse(localStorage.getItem('profile')).user_metadata.uid;
          if (whizzimoId) {
            this.store.dispatch(new SettingsActions.GetAllSettings(whizzimoId));
          }
        }
      }),
      filter(areLoaded => areLoaded),
      first(),
    );
  }
}
