import {Component, Input, OnInit} from '@angular/core';
import {faCalendar, faTrash, faUniversity} from '@fortawesome/free-solid-svg-icons';



@Component({
  selector: 'app-header-settings',
  templateUrl: './html/header-settings.html',
  styleUrls: [
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss'
  ]
})

export class HeaderSettingsComponent implements OnInit {
  public faCalendar = faCalendar;
  public faUniversity = faUniversity;

  constructor() {}

  ngOnInit() {}

}
