import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import { GetSharingData } from "../store/sharing/sharing.actions";
import { selectGetAllCollectionsSuccess } from "../store/";

@Injectable()
export class AllSharingCollectionsResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userId = JSON.parse(localStorage.profile).user_metadata.uid;
    return this.store.pipe(
      select(selectGetAllCollectionsSuccess),
      tap(isLoaded => {
        if (!isLoaded) {
          this.store.dispatch(new GetSharingData(userId));
        }
      }),
      filter(isLoaded => isLoaded),
      first(),
    );
  }
}
