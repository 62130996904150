// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blank-tile {
  min-width: 65px;
  min-height: 65px;
  border: #D2D2D2 1px solid;
  background-color: white;
  box-shadow: inset 0 1 1 #AAAAAA;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blue-halo {
  box-shadow: 0 0 5px blue;
}

.red-halo {
  box-shadow: 0 0 5px red;
}

.full-tile {
  height: 80%;
  width: 80%;
  text-align: center;
  padding-top: 30%;
  font-weight: bold;
  font-size: larger;
  margin-top: 2px;
  cursor: pointer;
}

.cdk-drag-placeholder {
  display: none;
}

.cdk-drag-preview {
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  font-stretch: normal;
  font-size: 1.6837vw;
  font-style: normal;
  line-height: 76px;
  text-align: center;
  letter-spacing: normal;
  border-radius: 4px !important;
  border: solid 1px rgba(72, 30, 159, 0.24);
  padding-right: 10px;
  padding-left: 10px;
  min-width: 76px;
  min-height: 76px;
}`, "",{"version":3,"sources":["webpack://./src/core/components/spelling/spelling-area.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,uBAAA;EACA,+BAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AACA;EACE,wBAAA;AAEF;;AAAA;EACE,uBAAA;AAGF;;AADA;EACE,WAAA;EACA,UAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;AAIF;;AADA;EAEE,aAAA;AAGF;;AADE;EACE,qBAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,oBAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,sBAAA;EACA,6BAAA;EACA,yCAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AAIJ","sourcesContent":[".blank-tile{\n  min-width: 65px;\n  min-height: 65px;\n  border: #D2D2D2 1px solid;\n  background-color: white;\n  box-shadow: inset 0 1 1 #AAAAAA;\n  margin: 5px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.blue-halo{\n  box-shadow: 0 0 5px blue;\n}\n.red-halo{\n  box-shadow: 0 0 5px red;\n}\n.full-tile{\n  height: 80%;\n  width: 80%;\n  text-align: center;\n  padding-top: 30%;\n  font-weight: bold;\n  font-size: larger;\n  margin-top: 2px;\n  cursor: pointer;\n}\n\n.cdk-drag-placeholder {\n  // opacity: 0;\n  display: none;\n  }\n  .cdk-drag-preview {\n    display: inline-block;\n    align-items: center;\n    justify-content: center;\n    font-weight: bolder;\n    font-stretch: normal;\n    font-size: 1.6837vw;\n    font-style: normal;\n    line-height: 76px;\n    text-align: center;\n    letter-spacing: normal;\n    border-radius: 4px !important;\n    border: solid 1px rgba(72, 30, 159, 0.24);\n    padding-right: 10px;\n    padding-left: 10px;\n    min-width: 76px;\n    min-height: 76px;  }\n//   .cdk-drop-list-receiving {\n// \t  opacity: 0;\n//   }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
