  import { Component, Input } from '@angular/core';
// import { TilesService }    from "../../core/services/tiles.service";

// import fontawesome          from '@fortawesome/fontawesome';
// import { faCrosshairs }     from '@fortawesome/fontawesome-free-solid/faCrosshairs';
//
// fontawesome.library.add(faCrosshairs);




@Component({
  selector: 'app-tile',

  template: `<div  class="tile">{{tile}}</div>`,
  styleUrls: [
    '../../assets/css/main.css',
    '../../assets/scss/fontawesome.scss',
    '../../assets/scss/brands.scss',
    '../../assets/scss/regular.scss',
    '../../assets/scss/solid.scss'
  ]
})
export class TileComponent {
    @Input() tile: string;

    constructor() {

    }


}
