import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_DIR} from '../../environments/environment';
import {take} from 'rxjs/operators';

@Injectable()
export class PairingsService {
  constructor(private http: HttpClient) {
  }

  getAllPairings() {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/pairings`)
        .pipe(
          take(1)
        )
        .subscribe(
          (pairings: any) => observer.next(pairings.data),
          (error: any) => {
            throw error;
          }
        );
    });
  }

  getPairingsFromCompoundSound(compoundSounds: any[]) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/pairings/compoundSounds`, compoundSounds)
        .pipe(
          take(1)
        )
        .subscribe(
          (pairings: any) => observer.next(pairings.data),
          (error: any) => {
            throw error;
          }
        );
    });
  }
}
