import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LessonsComponent } from './lessons.component';

const LessonsRoutes: Routes = [
  {
    path: 'lessons', component: LessonsComponent, data: {}
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(LessonsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class LessonsRoutingModule {}
