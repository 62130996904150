import { GetAllSettings } from './../settings/settings.actions';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of,  Observable} from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { SharingService } from '../../services/sharing.service';
import { GetAllCourse } from '../courses/courses.actions';
import { GetMyLibraryWorkbooks } from '../workbook/workbook.actions';
import * as actions from './sharing.actions';

@Injectable()

export class SharingEffect {
    constructor(
        private actions$: Actions,
        private store$: Store,
        private sharingService: SharingService
    ) {}
    getSharing$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SharingActionTypes.GetSharingData),
            map((action: actions.GetSharingData) => action.payload),
            switchMap((payload: any) => {
                return this.sharingService.get(payload).pipe(
                    map((res: any) => new actions.GetSharingDataSuccess(res)),
                    catchError((error) => of(new actions.GetSharingDataFail(error.message)))
                );
            })
        );
    });

    sharingCreate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SharingActionTypes.SharingCreate),
            map((action: actions.SharingCreate) => action.payload),
            switchMap((payload: any) => {
                return this.sharingService.create(payload).pipe(
                    map((res: any) => new actions.SharingCreateSuccess(res.data)),
                    catchError((error) => of(new actions.SharingCreateFail(error.message)))
                );
            })
        );
    });
    SharingApply$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SharingActionTypes.SharingApply),
            map((action: actions.SharingApply) => action.payload),
            switchMap((payload: any) => {
                return this.sharingService.apply(payload).pipe(
                    map((res: any) => {
                        if (res.type === "workbook") {
                            this.store$.dispatch(new GetMyLibraryWorkbooks(''));
                        } else if (res.type === "course") {
                            this.store$.dispatch(new GetAllCourse(''));
                        } else if (res.type === 'settings') {
                            this.store$.dispatch(new GetAllSettings(JSON.parse(localStorage.profile).user_metadata.uid));
                        }
                        return new actions.SharingApplySuccess(res);
                    }),
                    catchError((error) => of(new actions.SharingApplyFail(error.message)))
                );
            })
        );
    });

    SharingDelete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SharingActionTypes.SharingDelete),
            map((action: actions.SharingDelete) => action.payload),
            switchMap((payload: any) => {
                return this.sharingService.delete(payload).pipe(
                    map((res: any) => new actions.SharingDeleteSuccess(payload)),
                    catchError((error) => of(new actions.SharingDeleteFail(error.message)))
                );
            })
        );
    });

    SharingRename$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SharingActionTypes.SharingRename),
            map((action: actions.SharingRename) => action.payload),
            switchMap((payload: any) => {
                return this.sharingService.rename(payload.id, payload.name).pipe(
                    map((res: any) => new actions.SharingRenameSuccess(payload)),
                    catchError((error) => of(new actions.SharingRenameFail(error.message)))
                );
            })
        );
    });

}
