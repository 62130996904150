import { Store } from '@ngrx/store';
import { Component, EventEmitter, Input, OnChanges, SimpleChanges, OnInit, Output, OnDestroy } from '@angular/core';
import {UtilityService} from '../../services/utility.service';
import { SettingsService } from '../../services/new-settings.service';
import { UserService } from '../../services/user.service';
import {MatDialog} from "@angular/material/dialog";
import {SpellingDialogComponent} from './spelling-dialog/spelling-dialog.component';
import {WORD_AUDIO_DIR} from '../../../environments/environment';
import { getCurrentSetting } from '../../store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-spelling-tiles',
  templateUrl: './html/spelling-area.html',
  styleUrls: [
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss',
    './spelling-area.scss'
  ]
})

export class SpellingAreaComponent implements OnChanges, OnDestroy {
  // words should be an object that contain both the audio reading and the string version of the word
  @Input() words: any[];
  @Input() tiles: any[];
  @Input() sight: boolean;
  @Input() currentWord: any;
  @Input() blankTiles: any[] = [];
  @Input() spellingAttempt: string[];
  @Input() correctAnswers = 0;
  @Input() tileSize = 2;
  @Input() incorrectAnswer = false;
  @Input() userSettings: any;
  @Input() answerCheckStatus: any;
  @Input() rows = 1;
  @Output() simpleDropEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeTileEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() dropzonesEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() wordIndex = 0;
  @Input() tileProperties: any = [];
  @Input() draggingEvent: any;
  @Input() set dropped(dropped: any) {
    if (!dropped) {
      return;
    }
    this.simpleDrop(dropped.event, dropped.index);
  }
  @Input() skippable = false;
  @Input() restartTestButton = false;
  audio = new Audio();
  currentObject: any;
  tileCount = 12;
  public tileStatus = true;
  private unsubscribe$: Subject<void> = new Subject();


  ngOnChanges(changes: SimpleChanges) {
    if (changes.rows) {
      this.makeBlankTiles();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  constructor(
    private utiltiyService: UtilityService,
    private dialog: MatDialog,
    private settingsService: SettingsService,
    private userService: UserService,
    private store: Store
    ) {
      // const userId = JSON.parse(localStorage.profile).user_metadata.uid;
      // const interval = setInterval(() => {
      //   if (this.settingsService.activitySettings) {
      //     this.userSettings = this.settingsService.activitySettings;
      //     let dropzones = Array.from(document.querySelectorAll('.dropzone'));
      //     this.dropzonesEvent.emit(dropzones);
      //     clearInterval(interval);
      //   }
      // }, 300);
      this.store.select(getCurrentSetting).pipe(takeUntil(this.unsubscribe$)).subscribe(setting => {
        if (!setting) {
          this.userSettings = null;
          return;
        }
        this.userSettings = JSON.parse(JSON.stringify(setting));
        setTimeout(() => {
          let dropzones = Array.from(document.querySelectorAll('.dropzone'));
          this.dropzonesEvent.emit(dropzones);
        }, 300);
      });
    }

  getTileClasses(col: string) {
    return this.utiltiyService.getTileClasses(col, this.userSettings);
  }

  allowDrop(value: any) {
    return () => value === '';
  }
  dragEnd(event, index) {
    this.blankTiles[index] = {"value": "", "highlight" : false, "classes" : null};
    this.checkTiles();
  }
  exited(event, index) {
  }
  simpleDrop($event, index) {
    if ($event.previousContainer.connectedTo.length === 1) {
        this.blankTiles[index].classes = $event.item._rootElement.classList;
        this.updateTile($event.item._rootElement.innerText, index);
        this.blankTiles[index].highLight = false;
        this.currentObject = null;
      
    } else {
      if ($event.distance.y < -121) {
        this.blankTiles[index].classes = "";
        this.updateTile("", index);
  
        } else {
          this.blankTiles[index].classes = $event.item.element.nativeElement.classList;
          this.updateTile($event.item.element.nativeElement.innerText, index);
          this.blankTiles[index].highLight = false;
          this.currentObject = null;
  
        }

    }
  }

  checkTiles() {
      this.tileStatus = this.blankTiles.every((tile) => tile.value.length === 0);
  }
  checkAnswer() {
    let answer: any;
    let guess = [];

    for (let i = 0; i < this.blankTiles.length; i++) {
      if (this.blankTiles[i] && this.blankTiles[i].value !== '') {
        guess.push(this.blankTiles[i].value);
      }
    }
    let result: boolean;
    if (this.answerCheckStatus === 0 || this.sight) {
      // check answers by letters only
      answer = this.currentWord.displayWord;
      let guessString = guess.join('');
      result = answer === guessString ? true : false;
    } else if (this.answerCheckStatus === 1 ) {
      // check answers by letters and tiles
      answer = this.tiles[this.wordIndex];
      answer = answer.map((tile) => {
        return tile.split('.')[2];
      });
      result = answer.every((letter, index, array) => {
        return ( letter === guess[index]);
      });
    }

    guess = guess.splice(0, answer.length);
    if (result) {
      this.incorrectAnswer = false;
      this.correctAnswers += 1;
      if (this.wordIndex < this.words.length) {
        this.wordIndex += 1;
        this.makeBlankTiles();
      }
    } else {
      this.incorrectAnswer = true;
      this.skippable = true;
    }

    if (this.wordIndex === this.words.length) {
      this.openDialog();
    }
  }

  skipWord() {
    this.skippable = false;
    this.incorrectAnswer = false;
    if (this.wordIndex === this.words.length - 1) {
      this.openDialog();
    } else {
    this.wordIndex += 1;
    this.makeBlankTiles();
    }
  }

  makeBlankTiles() {
    if (this.words) {
      this.blankTiles = [];
      this.currentWord = this.words[this.wordIndex];
      for (let i = 0; i < this.tileCount; i++) {
        this.blankTiles.push({"value": "", "highlight" : false, "classes" : null});
      }
      this.checkTiles();
    }
  }

  updateTile(letter, position) {
    this.blankTiles[position].value = letter;
    this.checkTiles();
  }

  playAudio(wordId) {
    this.audio.src = WORD_AUDIO_DIR 
    + this.words[this.wordIndex].wordid 
    + '_' 
    + this.words[this.wordIndex].word.toLowerCase()
    + '.mp3';
    this.audio.load();
    this.audio.play();
  }

  percentageOfCorrectAnswer(total, correct) {
    return correct / total;
  }
  openDialog() {
    const dialogRef = this.dialog.open(SpellingDialogComponent, {
      panelClass: 'score-modalbox',
      data: {skipped : this.words.length - this.correctAnswers, correctPercentage: this.percentageOfCorrectAnswer(this.words.length, this.correctAnswers)}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.restartTest();
      } else {
        this.restartTestButton = true;
      }
    });
  }
  restartTest() {
    this.wordIndex = 0;
    this.skippable = false;
    this.incorrectAnswer = false;
    this.restartTestButton = false;
    this.correctAnswers = 0;
    this.makeBlankTiles();
  }
}
