import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import { EditGetAllLessonSteps } from "../store/edit-lesson/edit-lesson.actions";
import { selectGetLessonPlan, selectGetLessonStepsLoaded } from "../store";
import { GetLesson } from '../store/lesson/lesson.actions';
@Injectable()
export class LessonPlanResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const lessonPlanId = route.params.lessonPlanId;
    if (lessonPlanId !== 'new') {
        return this.store.pipe(
            select(selectGetLessonPlan, { id: lessonPlanId}),
              tap((isLoaded) => {
                if (!isLoaded) {
                  this.store.dispatch(new GetLesson(lessonPlanId));
                }
              }),
              filter(isLoaded => !!isLoaded),
              first(),
            );
        
    }
  }
}
