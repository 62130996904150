import { Store } from '@ngrx/store';
import {Component, OnDestroy, EventEmitter, Output, OnInit, Inject, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {FilterTypes} from '../../../../models/filter-types';
import {WordsService} from '../../../services/words.service';
import {filter, switchMap, take, takeUntil} from 'rxjs/operators';
import {WorkbooksService} from '../../../services/workbooks.service';
import {ActivatedRoute, Router} from '@angular/router';
import { BehaviorSubject, Observable, pipe, Subject } from 'rxjs';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { WORD_AUDIO_DIR } from '../../../../environments/environment';
import {CourseWorkbooksService} from '../../../services/course-workbooks.service';
import {UserService} from '../../../services/user.service';
import {CourseService} from '../../../services/courses.service';
import { ListenerService } from '../../../services/listener.service';
import { PlanService } from '../../../services/plan.service';
import { getSetCourseData, selectGetMyLibraryWorkbooks, selectGetPostResponseByWorkbookId, selectGetWorkbookFiles,
  selectGetWorkbookPassages, selectGetWorkbookPhrases, selectGetWorkbookSentences, selectGetPostResponse, selectGetAllCourseWorkbook, selectAllCourseWorkbookSuccess } from '../../../store';
import { CreateWorkbook, CreateWorkbookSuccess, GetWorkbook, UpdateWorkbook, UpdateWorkbookSuccess } from '../../../store/workbook/workbook.actions';
import { ClearCourseWorkbooks, CreateCourseWorkbookSuccess, GetAllCourseWorkbooks, GetCourseWorkbook, UpdateByWorkbook } from '../../../store/course-workbook/course-workbook.actions';
import { ClearWords, PostWords } from '../../../store/words/words.actions';
import { AsyncPipe } from '@angular/common';
import { ClearActivityInitData } from '../../../store/activity/activity.actions';
import { ClearWorkbookActivity } from '../../../store/workbook-activity/workbook-activity.actions';
import { ClearLessonStep } from '../../../store/edit-lesson/edit-lesson.actions';
@Component({
  selector: 'app-workbook-builder-words',
  templateUrl: './html/words.html',
  styleUrls: [
    '../../../../assets/css/main.css',
    '../../../../assets/scss/fontawesome.scss',
    '../../../../assets/scss/brands.scss',
    '../../../../assets/scss/regular.scss',
    '../../../../assets/scss/solid.scss',
    './workbook-builder.scss'

  ]
})

export class WorkbookBuilderWordsComponent implements OnInit, OnDestroy {
  @Input() workbookNameObservable = new Observable();
  @Input() workbookDescriptionObservable = new Observable();
  @Input() workbook: any;
  @Input() newWorkbook = false;
  @Input() isFromLibrary = false;
  @Input() workbookId: string;
  @Input() changeNameModalClosed = false;
  @Output() updatedWorkbook: EventEmitter<any> = new EventEmitter<any>();
  @Output() requestScrollToBottom = new EventEmitter<void>();
  public userKnowsWords;
  public filters: any[] = FilterTypes;
  public query: any = {};
  public words: any[] = [];

  public wordsSubject: any = new BehaviorSubject<any>(this.words);

  public words$ = this.wordsSubject.asObservable();
  public name: string;
  public workbooks: any;
  public bottomSheetOpen: boolean;
  public bottomSheetRef: any;
  public selectedWords: any = [];
  public filterSelection: string;
  @Output() syllableCount;
  faSpinner = faSpinner;
  isSearching: boolean;
  audio = new Audio();
  public userId: string;
  public plan: string;
  selectedPlan: any;
  currentCourse: any;
  public courseWorkbooks: any;
  private unsubscribe$: Subject<void> = new Subject();
  private hasPhrases = false;
  private hasSentences = false;
  private hasPassages = false;
  private hasFiles = false;

  constructor(
    private wordsService: WordsService,
    private workbookService: WorkbooksService,
    private route: ActivatedRoute,
    private router: Router,
    private _bottomSheet: MatBottomSheet,
    private courseWorkbooksService: CourseWorkbooksService,
    private courseService: CourseService,
    private userService: UserService,
    private listenerService: ListenerService,
    private planService: PlanService,
    private store: Store,
    private async: AsyncPipe
  ) {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this._bottomSheet.dismiss();
    this.store.dispatch(new ClearWords({
      id: this.workbookId
    }));
  }

  ngOnInit() {
    this.listenerService.getPlan().pipe(takeUntil(this.unsubscribe$)).subscribe((plan) => {
      this.plan = plan;
    });
    this.filters = [...[], ...this.filters];
    this.filters.forEach((filter) => {
      filter.visible = false;
    });

    this.filterFilters = this.filterFilters.bind(this);
    const idIndex = this.route.url['value'].length - 1;
    this.workbookId = this.route.url['value'][idIndex].path;
    this.userId = JSON.parse(localStorage.getItem('profile')).user_metadata.uid;

    // this.workbookService
    // .getAll()
    // .subscribe((workbooks) => {
    //   this.workbooks = workbooks;
    // });
    // this.store.dispatch(new PostWords({
    //   id: this.workbookId,
    //   filter: this.workbook.filters
    // }));
    this.store.select(selectGetMyLibraryWorkbooks)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(workbooks => this.workbooks = JSON.parse(JSON.stringify(workbooks)));

    this.store.select(getSetCourseData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(currentCourse => {
        this.currentCourse = JSON.parse(JSON.stringify(currentCourse));
      });

      this.store.select(selectGetAllCourseWorkbook)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(courseWorkbooks => {
        this.courseWorkbooks = [...(courseWorkbooks || [])];
      });

    Object.keys(this.workbook.filters).forEach(key => {
      const filterIndex = this.filters.findIndex(filter => filter.name === key);
      if (filterIndex >= 0) {
        this.filters[filterIndex].currentVal = this.workbook.filters[key];
        if (!this.filters[filterIndex].required) {
          this.filters[filterIndex].visible = true;
        }
      }
    });
    this.workbookNameObservable
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(name => {
        this.workbook.name = name;
      });

    this.workbookDescriptionObservable
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(desc => {
        this.workbook.desc = desc;
      });
      // this.search();
      this.getSelectedPlan();
    // this.search();

    this.store.select(selectGetWorkbookPhrases)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(workbookPhrases => {
        this.hasPhrases = !!(workbookPhrases?.length);
      });

    this.store.select(selectGetWorkbookSentences)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(workbookSentences => {
        this.hasSentences = !!(workbookSentences?.length);
      });

      this.store.select(selectGetWorkbookPassages)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(workbookPassages => {
        this.hasPassages = !!(workbookPassages?.length);
      });

      this.store.select(selectGetWorkbookFiles)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(workbookFiles => {
        this.hasFiles = !!(workbookFiles?.length);
      });

    this.buildWordList()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((words: any) => {
      this.wordsSubject.next(words);

      console.log("The list has been built.");

      // this code is a patch for an issue of the sylnumber not being updated for child components
      // setTimeout(() => {
      //     const nSyllable = this.filters.find(filter => filter.name === 'nSyllables');
      //     this.updateQuery({nSyllables: nSyllable.currentVal + 3})
      //   },500)
  
      
      //   setTimeout(() => {
      //     const nSyllable = this.filters.find(filter => filter.name === 'nSyllables');
      //     this.updateQuery({nSyllables: nSyllable.currentVal})
      //   },1000)
    });
  }

  updateQuery(value: any) {
    const queryName = Object.keys(value)[0];
    if (value[queryName] === null) {
      return (delete this.workbook.filters[queryName]);
    }
    if (value.nSyllables) {
      this.syllableCount = (value.nSyllables.lte - 1);
    }
    this.workbook.filters = {...this.workbook.filters, ...value};
  }

  search(exit: boolean = false) {
     for (let i in this.workbook.filters) {
       if (this.workbook.filters.hasOwnProperty(i)) {
        if ((this.workbook.filters[i][0] === '' && this.workbook.filters[i].length === 1) || this.workbook.filters[i].length === 0) {
          delete this.workbook.filters[i];
        }
       }
     }

    this.store.dispatch(new PostWords({
      id: this.workbookId,
      filter: this.workbook.filters
    }));
  }

  buildWordList() {
    return new Observable(observer => {
      this.isSearching = true;
      this.store.select(selectGetPostResponseByWorkbookId, { id: this.workbookId })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((words: any[]) => {
          if (!words) {
            this.words = [];
            return;
          }

          console.log("We building a new list after a search has happened");
          // reorder words by workbook preview
          let reorderedWords = [...JSON.parse(JSON.stringify(words))];
          // if (this.workbook && this.workbook.preview) {
          //   for (let i = 0; i < this.workbook.preview.length; i++) {
          //     const index = reorderedWords.findIndex(w => w.wordid === this.workbook.preview[i].wordid);
          //     if (index > -1) {
          //       reorderedWords = this.moveWords(reorderedWords, index, i);
          //     }
          //   }
          // }

          const exactWordsFilter = this.workbook.filters.exactWords;
          this.isSearching = false;

          // This should apply to all new workbook
          if (this.workbook.wordOrder && this.workbook.wordOrder.length > 0) {
            // let orderedWords = this.reorganizeWords(this.workbook.wordOrder, reorderedWords);
            let orderedWords = null;

            const newWordSet = new Set(reorderedWords.map(word => word.word));
            const commonWords = this.workbook.wordOrder.filter(word => newWordSet.has(word.word));

            if(commonWords.length === 0) {
              // Reset the wordOrder if there are no common words
              this.workbook.wordOrder = null;

              // This may need to do the functionality below that starts with "if (exactWordsFilter && exactWordsFilter.length > 0) {"
              orderedWords = reorderedWords;

              observer.next(
                this.plan === 'Demo'
                  ? orderedWords.slice(0, 7)
                  : orderedWords
              );
            } else {
              const commonWordsSet = new Set(commonWords.map(word => word.word));
              const newWordsMap = new Map(reorderedWords.map(word => [word.word, word]));
              const commonWordsData = commonWords.map(word => newWordsMap.get(word.word));
              const remainingNewWords = reorderedWords.filter(word => !commonWordsSet.has(word.word));
              
              orderedWords = [...commonWordsData, ...remainingNewWords];
  
              observer.next(
                this.plan === 'Demo'
                  ? orderedWords.slice(0, 7)
                  : orderedWords
              );
            }
          } else {
            if (exactWordsFilter && exactWordsFilter.length > 0) {
              let searchedWords: any[] = [];
              exactWordsFilter.forEach(word => {
                const searchedWord = JSON.parse(JSON.stringify(reorderedWords)).filter(w => w.word === word);
                if (searchedWord.length > 0) {
                  searchedWords = searchedWords.concat(searchedWord);
                }
              });

              console.log(searchedWords);
              searchedWords = this.plan === 'Demo' ? searchedWords.slice(0, 7) : searchedWords;
              // searchedWords = this.plan !== 'Staff' ? searchedWords.filter(word => word.planType.toLowerCase() === 'staff') : searchedWords;
              observer.next(searchedWords);
            } else {
              observer.next(
                this.plan === 'Demo'
                  ? reorderedWords.slice(0, 7)
                  : reorderedWords
              );
            }
          }
        });
    });
  }

  generateTiles() {
    let words: any = this.async.transform(this.words$);
    return new Observable(observer => {
      if (this.workbook.filters.exactSounds) {
        observer.next(this.workbook.filters.exactSounds);
      } else {
        const userId = JSON.parse(localStorage.profile).user_metadata.uid;
        this.wordsService
          .getTilesByWordIds(userId, words.slice(0, 30).map(word => word.wordid))
          .pipe(
            take(1)
          )
          .subscribe(
            (tiles: any[]) => {
              tiles = [...new Set(tiles.flat())];
              observer.next(tiles);
            },
            error => {
              throw error;
            }
          );
      }
    });
  }

  public save(exit: boolean, isLibrary: boolean, workbookId = null) {

    console.log("Save is called.");
    this.search();
    setTimeout(() => {
      this.closeBottomSheet();
      let words: any = this.async.transform(this.words$);
      // Update
      if ((workbookId == null && this.workbook._id) || (typeof workbookId === 'string' && workbookId.length > 0 && workbookId !== 'new')) {
        let updatedWorkbook = {
          name: this.workbook.name,
          owner: this.workbook.owner ? this.workbook.owner : this.userId,
          desc: this.workbook.desc,
          filters: this.workbook.filters,
          workbookId: this.workbook._id,
          hasPhrases: this.hasPhrases,
          hasSentences: this.hasSentences,
          hasPassages: this.hasPassages,
          hasFiles: this.hasFiles,
          courseWorkbookId: this.currentCourse?._id,
          tiles: null,
          wordOrder: null, // This shouild always been null unless an order is set
        };
        if (typeof workbookId === 'string' && workbookId.length > 0 && workbookId !== 'new') {
          updatedWorkbook.workbookId = workbookId;
        }
        updatedWorkbook["nonsense"] = false;
        const nonsenseWord = words.filter((word) => word.wordid >= 1000000);
        if (nonsenseWord && nonsenseWord.length > 0) {
          updatedWorkbook["nonsense"] = true;
        }
        updatedWorkbook["preview"] = words.slice(0, 50).map(word => ({word: word.word, wordid: word.wordid}));

        // Saves the order that the words were left in after rearranging them
        if (this.workbook.wordOrder && this.workbook.wordOrder.length > 0) {
          updatedWorkbook.wordOrder = this.workbook.wordOrder;
        }

        this.store.dispatch(new UpdateWorkbook({
          _id: this.workbook._id,
          data: updatedWorkbook,
        }));

        // updated workbook gets frozen after dispatching the action so we need to copy it
        let updatedWorkbookCopy = JSON.parse(JSON.stringify(updatedWorkbook));
        
        this.generateTiles()
          .pipe(take(1))
          .subscribe(tiles => {
            if (tiles) {
              updatedWorkbookCopy.tiles = tiles;
              this.updatedWorkbook.emit(updatedWorkbookCopy);

              this.store.dispatch(new UpdateWorkbook({
                _id: this.workbook._id,
                data: updatedWorkbookCopy,
              }));
              
              if (exit && isLibrary) {
                this.router.navigateByUrl(`/library;libraryType=myLibrary`);
              } else if (exit) {
                let courseWorkbook = this.courseWorkbooks.find(item => item.workbookId === this.workbook._id)
                if (courseWorkbook) {
                  courseWorkbook = JSON.parse(JSON.stringify(courseWorkbook));
                  courseWorkbook.workbook = updatedWorkbook;
                }

                this.store.dispatch(new UpdateByWorkbook({
                  _id: this.workbook._id,
                  ownerKey: this.userId,
                  courseWorkbook: updatedWorkbookCopy,
                  courseId: this.currentCourse?._id
                }));

                this.store.dispatch(new ClearActivityInitData());
                this.store.dispatch(new ClearWorkbookActivity());
                this.store.dispatch(new ClearLessonStep());
                // this.store.dispatch(new GetAllCourseWorkbooks(this.currentCourse?._id));

                setTimeout(() => {
                  this.router.navigate([`my-curriculum`]);
                }, 500);
              }
            }
          });
      } else {
        let maxOrder = 0;
        this.courseWorkbooks.map(({order}) => {
          if (order > maxOrder) {
            maxOrder = order;
          }
        });

        const newWorkbook: any = {
          name: this.workbook.name ? this.workbook.name : "Untitled Workbook",
          owner: this.workbook.owner ? this.workbook.owner : this.userId,
          desc: this.workbook.desc ? this.workbook.desc : "Enter a workbook description (optional)",
          filters: this.workbook.filters,
          concept: "new",
          order: maxOrder + 1
        };
        newWorkbook.nonsense = false;
        const nonsenseWord = words.filter((word) => word.wordid >= 1000000);
        if (nonsenseWord && nonsenseWord.length > 0) {
          newWorkbook.nonsense = true;
        }
        newWorkbook.preview = words.slice(0, 50).map(word => ({word: word.word, wordid: word.wordid}));

        let workbookTmp = null;
        this.generateTiles()
        .pipe(
        switchMap((tiles: any) => {
          newWorkbook.tiles = tiles;
          return this.workbookService.create(newWorkbook);
        }),
        switchMap((workbook: any) => {
          this.store.dispatch(new CreateWorkbookSuccess(workbook));
          workbookTmp = workbook;
          if (this.isFromLibrary) {
            return new Observable<any>(observer => observer.next({data: {workbookId: workbook._id}}));
          }
          const newCourseWorkbook = {
            courseId: this.currentCourse?._id,
            ownerKey: this.userId,
            workbookId : workbook._id
          };

          return this.courseWorkbooksService.create(newCourseWorkbook);
        })
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          if (exit && isLibrary) {
            this.router.navigate([`/library`, {libraryType: 'myLibrary'}]);
          } else if (exit) {
              setTimeout(() => {
                this.router.navigate([`my-curriculum`]);
              }, 300);

            this.store.dispatch(new GetAllCourseWorkbooks(this.currentCourse?._id));

          } else {
            if (!isLibrary) {
              this.store.dispatch(new CreateCourseWorkbookSuccess({
                ...workbookTmp,
                ...result.data
              }));
            }
            this.router.navigate([`/workbook-builder/${result.data.workbookId}`, {isFromLibrary: this.isFromLibrary}]);
          }
        });
      }
    }, 500)
  }

  filterFilters(filter: any) {
    const maxSyllables = this.workbook.filters.nSyllables
      ? this.workbook.filters.nSyllables.lte
      : FilterTypes.find(filterType => filterType.name === 'nSyllables').maxNum;

    // const sylNumPredicate = filter.sylNum
    //   ? filter.sylNum <= maxSyllables
    //   : true;

    return !filter.visible && !filter.required;
    // && sylNumPredicate;
  }

  openFilter(name: any) {
    const index = this.filters.findIndex(f => f.name === name);
    const filter = this.filters.splice(index, 1)[0];
    delete filter.currentVal;
    filter.visible = true;
    this.filters.push(JSON.parse(JSON.stringify(filter)));
    this.filterSelection = "";

    // Emit an event when the filter is opened to scroll to bottom
    setTimeout(() => {
      this.requestScrollToBottom.emit();
    }, 200);
  }

  closeFilter(filterName: any) {
    this.workbook = JSON.parse(JSON.stringify(this.workbook));
    if (filterName === "pairings") {
      delete this.workbook.filters['firstPairing'];
      delete this.workbook.filters['lastPairing'];
      delete this.workbook.filters['basicPairing'];
    }
    if (filterName === "sylTypes") {
      delete this.workbook.filters['wordMustContain'];
      delete this.workbook.filters['firstSylType'];
      delete this.workbook.filters['secondSylType'];
      delete this.workbook.filters['thirdSylType'];
      delete this.workbook.filters['fourthSylType'];
      delete this.workbook.filters['fifthSylType'];
      delete this.workbook.filters['sixthSylType'];
    }

    if (filterName === "exactWords") {
      delete this.workbook.filters['exactWords'];
      this.search();
    }
    const index = this.filters.findIndex(filter => filter.name === filterName);
    this.filters[index].visible = false;
    delete this.workbook.filters[`${filterName}`];
    this.filterSelection = null;
    // check for syllableType and soundLetterPairing

  }

  selectWord(wordIndex: number) {
    let words: any = this.async.transform(this.words$);
    words[wordIndex].selected = !words[wordIndex].selected;
    if (words[wordIndex].selected) {
      this.selectedWords.push(words[wordIndex]);
    } else {
      this.selectedWords = this.selectedWords.filter( word => {
        return word.word !== words[wordIndex].word;
      });
    }

    this.wordsSubject.next(words);
    this.openBottomSheet();
  }

  playAudio(wordIndex: number) {
    let words: any = this.async.transform(this.words$);
    this.audio.src = WORD_AUDIO_DIR + words[wordIndex].wordid + '_' + words[wordIndex].displayWord.toLowerCase() + '.mp3';
    this.audio.load();
    this.audio.play();
  }

  openBottomSheet() {
    let words: any = this.async.transform(this.words$);

    if (!this.bottomSheetOpen) {
      this.bottomSheetRef = this._bottomSheet.open(WordBottomSheetComponent,
        {
          hasBackdrop : false,
          disableClose: true
        }
      );
      this.bottomSheetOpen = true;
      this.bottomSheetRef.instance.deselectAllEvent.pipe(takeUntil(this.unsubscribe$)).subscribe(($event) => {
        this.deselectAll(words);
      });
      this.bottomSheetRef.instance.selectAllEvent.pipe(takeUntil(this.unsubscribe$)).subscribe(($event) => {
        this.selectAll(words);
      });
      this.bottomSheetRef.instance.keepSelectedEvent.pipe(takeUntil(this.unsubscribe$)).subscribe(($event) => {
        this.keepSelected(this.selectedWords);
      });
      this.bottomSheetRef.instance.removeSelectedEvent.pipe(takeUntil(this.unsubscribe$)).subscribe(($event) => {
        this.removeSelected(this.selectedWords);
      });
      this.bottomSheetRef.afterDismissed().pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        this.bottomSheetOpen = false;
      });
    }
  }

  closeBottomSheet() {
    if (this.bottomSheetRef) {
      this.bottomSheetRef.dismiss();
    }
  }

  selectAll(words: any[]) {
    // this prevents duplicates
    this.selectedWords = [];
    words.forEach(word => {
      this.selectedWords.push(word);
      word.selected = true;
    });
  }

  deselectAll(words: any[]) {
    this.selectedWords = [];
    words.forEach(word => {
      word.selected = false;
    });
    this.closeBottomSheet();
  }

  keepSelected(words) {
    const plainWords: string[] = [];
    words.forEach(word => plainWords.push(word.word));
    this.openFilter('exactWords');
    const filterIndex = this.filters.findIndex(f => f.name === 'exactWords');
    this.filters[filterIndex].currentVal = plainWords;
    // this.workbook.filters = Object.assign(this.workbook.filters, {'exactWords': plainWords});
    this.workbook.filters = Object.assign(JSON.parse(JSON.stringify(this.workbook.filters)), {'exactWords': plainWords});
    this.selectedWords = [];
    this.search();
  }

  removeSelected(words) {
    const plainWords: string[] = [];
    this.workbook.filters = JSON.parse(JSON.stringify(this.workbook.filters));
    words.forEach(word => plainWords.push(word.word));
    this.openFilter('excludeWords');
    const filterIndex = this.filters.findIndex(f => f.name === 'excludeWords');
    // this.filters[filterIndex].currentVal = plainWords;
    if (!this.workbook.filters.excludeWords) {
      this.workbook.filters = Object.assign(this.workbook.filters, {'excludeWords': plainWords});
    } else {
      this.workbook.filters.excludeWords = this.workbook.filters.excludeWords.concat(plainWords);
    }
    this.filters[filterIndex].currentVal = this.workbook.filters.excludeWords;
    this.selectedWords = [];
    this.search();
  }
  getSelectedPlan() {
    this.planService
      .getPlan(JSON.parse(localStorage.profile).user_metadata.cusID)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.selectedPlan = result.name;
    });
  }

  reorderItems($event) {

    // console.log("Reorder items is called.")
    if ($event.container.id === $event.previousContainer.id) {
      // console.log($event)
      const words = JSON.parse(JSON.stringify(this.moveWords($event.container.data, $event.previousIndex, $event.currentIndex)));
      this.wordsSubject.next(words);

      // console.log(words)

      const orderedValue = words.map(_ => _.word);
      const exactWordsFilter = this.filters.find(_ => _.name === 'exactWords');
      if (exactWordsFilter && exactWordsFilter.currentVal?.length) {
        // console.log("Exact words")
        exactWordsFilter.currentVal = orderedValue;
      }
      if (this.workbook.filters.exactWords?.length) {
        this.workbook.filters.exactWords = orderedValue;
      }

      // This is happening because the user wants to maintain the order of the words
      // We are saving the order, word, and wordid
      this.workbook.wordOrder = words.map((word, index) => {
        return {
          order: index,
          word: word.word,
          wordid: word.wordid
        };
      });

      console.log(this.workbook.wordOrder);
    } else {
      transferArrayItem($event.previousContainer.data, $event.container.data, $event.previousIndex, $event.currentIndex);
    }
  }

  private moveWords(arr: any[], fromIndex: number, toIndex: number) {
    let element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
  }


  private reorganizeWords(oldWords: any, newWords: any) {
    // Create a Set from the newWords array for quick lookups
    const newWordsSet = new Set(newWords.map(obj => obj.word));

    // Determine the common words based on oldWords order, but only store the word strings
    const commonWords = oldWords.filter(obj => newWordsSet.has(obj.word));

    // If no common words are found, return all newWords as they are
    if (commonWords.length === 0) {
      return newWords;
    }

    const commonWordsSet = new Set(commonWords.map(obj => obj.word));

    // Create a Map for quick access to objects in newWords by their word property
    const newWordsMap = new Map(newWords.map(obj => [obj.word, obj]));

    // Collect common words objects from newWords based on the commonWords order
    const commonWordsData = commonWords.map(obj => newWordsMap.get(obj.word))

    // Filter out common words from newWords to get the remaining new words
    const remainingNewWords = newWords.filter(obj => !commonWordsSet.has(obj.word));

    // Combine the arrays: common words first, then the remaining new words
    return [...commonWordsData, ...remainingNewWords];
  }
}

@Component({
  selector: 'app-word-bottom-sheet',
  templateUrl: './word-bottom-sheet/html/word-bottom-sheet.html',
  styleUrls: ['./word-bottom-sheet/word-bottom-sheet.scss']
})
export class WordBottomSheetComponent {
  @Output() removeSelectedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() keepSelectedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectAllEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() deselectAllEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _bottomSheetRef: MatBottomSheetRef<WordBottomSheetComponent>) {}

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  removeSelected() {
    this.removeSelectedEvent.emit();
    this._bottomSheetRef.dismiss();
  }

  keepSelected() {
    this.keepSelectedEvent.emit();
    this._bottomSheetRef.dismiss();
  }

  selectAll() {
    this.selectAllEvent.emit();
  }

  deselectAll() {
    this.deselectAllEvent.emit();
  }

  close() {
    this._bottomSheetRef.dismiss();
  }
}
