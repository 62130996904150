// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
      display: flex;
      flex-direction: column;
      height: 100%;
    }`, "",{"version":3,"sources":["webpack://./src/core/components/workbooks/workbook-builder/workbook-builder.component.ts"],"names":[],"mappings":"AAAA;MACM,aAAa;MACb,sBAAsB;MACtB,YAAY;IACd","sourcesContent":[":host {\n      display: flex;\n      flex-direction: column;\n      height: 100%;\n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
