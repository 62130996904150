import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'paginate',
    pure: false
})

export class PaginatePipe implements PipeTransform {
    transform(items: any[], pageIndex: number, pageSize: number): any {
      return items.slice(pageIndex * pageSize, ((pageIndex + 1) * pageSize));
    }
}
