import { GetManySentences } from '../store/sentence/sentence.actions';
import { selectGetSentences } from '../store/index';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from 'rxjs/operators';

@Injectable()
export class AllSentencesResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(selectGetSentences),
      tap(areLoaded => {
        if (!areLoaded) {
          const uid = JSON.parse(localStorage.profile).user_metadata.uid;
          this.store.dispatch(new GetManySentences(uid));
        }
      }),
      filter(areLoaded => areLoaded),
      first(),
    );
  }
}
