import {NgModule} from '@angular/core';
import {CoreModule} from '../../core.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DndModule} from 'ng2-dnd';
import { AsyncPipe } from '@angular/common';
import {MatCardModule} from '@angular/material/card';
@NgModule({
  imports: [
    CoreModule,
    MatCardModule,
    FontAwesomeModule,
    DndModule,
  ],
  providers: [AsyncPipe]
})

export class CourseWorkbookEditModule {}
