import * as FeedbackActions from './feedback.actions';

export interface State {
    pending: boolean;
    error: any;
    succeeded: boolean;
    feedback: any;
}

const initialState: State = {
    pending: false,
    error: null,
    succeeded: false,
    feedback: null
};

export function reducer(state = initialState, action: FeedbackActions.FeedbackActions): State {
    switch (action.type) {
        case FeedbackActions.FeedbackActionTypes.createFeedback: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case FeedbackActions.FeedbackActionTypes.createFeedbackSuccess: {
            return {
                ...state,
                feedback: action.payload,
                error: null
            };
        }
        case FeedbackActions.FeedbackActionTypes.createFeedbackFailure: {
            return {
                ...state,
                feedback: null,
                error: action.payload
            };
        }
        default: {
            return state;
        }
    }
}

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.succeeded;
export const getError = (state: State) => state.error;
export const getFeedback = (state: State) => state.feedback;