import {Component, EventEmitter, Input, OnInit, Output, OnChanges, ChangeDetectionStrategy, ViewChild, ElementRef} from '@angular/core';
import {BehaviorSubject, Observable, take} from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { MatTabGroup } from '@angular/material/tabs';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-course-workbook-edit-words-tiles',
  templateUrl: './html/course-workbook-edit-words-tiles.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CourseWorkbookEditWordTilesComponent implements OnInit, OnChanges {
  @Input() tiles: any[];
  @Input() words: any[];
  @Input() tileType = 'tiles';
  @Input() tilesSettings: any;
  @Input() lessonPlanStep: any; // lessonPlanStep or workbookActivity
  @Input() changeWorkbookObservable: Observable<any>;
  @Input() shuffleWordsObservable: Observable<any>;
  @Input() numWordsObservable: Observable<any>;
  @Input() isSpellingActivity: any;
  @Output() updateLessonPlanStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() serveCourseWorkbook: EventEmitter<any> = new EventEmitter<any>();
  @Output() tabChange = new EventEmitter<string>();
  @Input() set setNumWords(numWords: number) {
    if (numWords) {
      this.numWords = numWords;
    }
  }
  @ViewChild('workbookEditWords') workbookEditWords: ElementRef;
  @ViewChild('workbookEditTiles') workbookEditTiles: ElementRef;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  public courseWorkbook: any;
  public numWords = 0;
  activeTab = 'Words';
  selectedIndex = 0;


  constructor(private async: AsyncPipe, private utilityService: UtilityService) {
    this.activeTab = this.utilityService.activeLessonStepTab;
    this.selectedIndex = this.utilityService.activeLessonStepTab === 'Tiles' ? 1 : 0;

  }
  updateLessonPlanStep($event) {
    this.updateLessonPlanStepEvent.emit($event);
    // this.lessonPlanStep = $event;
  }

  ngOnInit(): void {
    const test = this.words;
  }
  ngOnChanges(changes) {
    if (changes.lessonPlanStep ) {
      this.numWords = changes.lessonPlanStep.currentValue.numWords;
    }

    if (changes.selectedTabIndex) {
      this.tabGroup.selectedIndex = changes.selectedTabIndex.currentValue;
    }
  }

  tabChanged(event) {
    this.changeWorkbookObservable.pipe(take(1)).subscribe((courseWorkbook) => {
      if (!this.courseWorkbook) {
        this.courseWorkbook = courseWorkbook;
      }
    });
    this.activeTab = event.tab.textLabel;
    if (this.activeTab.toLowerCase() === 'words') {
      setTimeout(() => {
        this.serveCourseWorkbook.emit(this.courseWorkbook);

      }, 100);
    }
    this.tabChange.emit(this.activeTab);
  }
}
