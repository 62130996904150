import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of,  Observable} from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as actions from './dummmy-actions';

@Injectable()

export class DummyDataEffect {
  getDummyData$;

    constructor(
        private actions$: Actions,
        private store$: Store
    ) {
      this.getDummyData$ = createEffect(() => this.actions$.pipe(
        ofType(actions.DummyActionTypes.GetDummyData),
        map((action: actions.GetDummyData) => action.payload),
        switchMap((payload: any) => {
          return new Observable(observer => observer.next(payload)).pipe(
            map((res: string) => new actions.GetDummyDataSuccess('dummy data successfully retrieved!')),
            catchError((error) => of(new actions.GetDummyDataFail(error.message)))
          );
        })
      ));
    }
}
