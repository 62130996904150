export const AppConfig = {
  production: false,
  environment: 'ALT',
  auth0: {
    domain: 'auth0.whizzimo.com',
    clientId: 'wU43cB6mIwtryOiVTRI20vgbW6MSIztI',
    callbackURL: 'https://alt.whizzimo.com/',
    Options: {
      allowSignUp: false,
      rememberLastLogin: true,
      theme: {
        logo: '/assets/img/Circle Purple W Logo.png',
        primaryColor: '#481E9F'
      },
      forgotPasswordLink: '#/passwordreset',
      auth: {
        autoParseHash: true,
        redirect: true,
        redirectUrl: 'https://alt.whizzimo.com/',
        responseType: 'id_token token',
        audience: 'https://whizzimoapp.auth0.com/userinfo',
        params: {
          scope: 'openid profile email'
        },
        sso: true
      },
      languageDictionary: {
        title: 'Whizzimo'
      },
      autoclose: true,
      _enableIdPInitiatedLogin: true,
      oidcConformant: true
    }
  },
  files: {
    root: 'https://storage.googleapis.com/whizzimo-prod.appspot.com/'
  },

};

export const PUBLISHABLE_KEY = 'pk_live_Y4o8SekqMB1wIH357JGTD8ye';
export const APP_ID = 'rlzyt6p2';

export const API_DIR = 'https://alt-server.whizzimo.com';
export const AUDIO_DIR = 'https://storage.googleapis.com/audio-files-whizzimo/';
export const WORD_AUDIO_DIR = 'https://storage.googleapis.com/audio-files-whizzimo/words/';
export const BARTON_USER = 'WhizzimoAcademy:2357';
export const _variable_images = 'assets/img/theme1/';
export const isAlt = true;
export const GCLOUD_KEYFILE_URI = "./WhizzimoDevGCloudKey.json";
export const BUG_SNAG_API_KEY = 'e6bf3aa37f01dd2427b114e01fc07da7'
export const AIRBRAKE_PROJECT_ID = 510905;
export const AIRBRAKE_API_KEY = '09292cdb4cac1ea8782644220610e988';
export const ROLLBAR_API_KEY = 'a30efb75406043a893fc72b3b180b7bf';
export const POSTHOG_API_KEY = 'phc_IIHvFTcAXqpkqQK8G5O7tTQ8nMLga1LJZjh8Nd0bXEj';
export const POSTHOG_APP_URL = 'https://app.posthog.com';
export const MIXPANEL_TOKEN = 'b4d8886e654ab143a71d81e7798e2a49';
export const AMPLITUDE_API_KEY = 'ec6058c93c469e69e797da28cb65dcf1';
export const AMPLITUDE_IDENTIFY_URL = 'https://api2.amplitude.com/identify';