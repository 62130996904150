// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .close-btn {
        background: transparent !important;
      }
    `, "",{"version":3,"sources":["webpack://./src/activities/activity-header/course-workbook-edit-menu.component.ts"],"names":[],"mappings":";MACM;QACE,kCAAkC;MACpC","sourcesContent":["\n      .close-btn {\n        background: transparent !important;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
