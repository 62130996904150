import { Component, OnInit } from '@angular/core';
import {UserService} from '../../core/services/user.service';
import {switchMap, take} from 'rxjs/operators';
import {SettingsService} from '../../core/services/new-settings.service';
import {TilesService} from '../../core/services/word-filter-tiles.service';
import {UtilityService} from '../../core/services/utility.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-tile-layout',
  templateUrl: './html/tileLayout.html',
  styleUrls: [
    '../../assets/css/main.css',
    '../../assets/scss/fontawesome.scss',
    '../../assets/scss/brands.scss',
    '../../assets/scss/regular.scss',
    '../../assets/scss/solid.scss'
  ]
})
export class TileLayoutComponent implements OnInit {
  currentDragVal: any;
  currentDragIndex: string;
  rows: any[] = [];
  flatRows: any[] = [];
  userSettings: any;
  allTileSets: any[] = [];
  missingTiles: any[] = [];
  private tileBankId: string;

  constructor(
    private settings: SettingsService,
    private tilesService: TilesService,
    private userService: UserService,
    private settingsService: SettingsService,
    private utilityService: UtilityService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    [...Array(20).keys()].forEach(() => this.rows.push(''));

    this.rows = this.rows.map(row => {
      const values = [];
      [...Array(15).keys()].forEach(() => values.push(''));
      return values;
    });

    const userId = JSON.parse(localStorage.profile).user_metadata.uid;

     // @ts-ignore
    this.flatRows = this.rows.flat();

    this.settingsService.get(userId)
      .pipe(
        take(1),
        switchMap((userSettings: any) => {
          this.userSettings = userSettings;

          Object.keys(this.userSettings.bank).forEach(row => {
            const rowIndex = row.split('r')[1];
            Object.keys(this.userSettings.bank[row]).forEach(col => {
              const colIndex = col.split('c')[1];
              this.rows[+rowIndex][+colIndex] = this.userSettings.bank[row][col];
            });
          });

          return this.tilesService.getAllTiles();
        })
      )
      .subscribe((tiles: any[]) => {
        // must add enabled to the tile sets so that we can dynamically update whether a tile is enabled or not
        tiles = tiles.map(tileSet => {
          tileSet.tiles = tileSet.tiles.map(tile => ({name: tile, enabled: this.flatRows.indexOf(tile) < 0}));

          return tileSet;
        });

        this.allTileSets = tiles;

        this.missingTiles = this.route.url['value'][1]
          ? this.route.url['value'][1].path
            .split(',')
            .map(tile => ({name: tile, enabled: this.flatRows.indexOf(tile) < 0}))
          : [];
      });
  }

  dragging($event: any) {
    this.currentDragIndex = $event.$event.mouseEvent.currentTarget.id;
    this.currentDragVal = $event.value;
  }

  draggingNew(tile: any) {
    this.currentDragVal = tile;
  }

  returnTileToBank(event: any) {
    // only update if this came from the users tiles not the tile bank itself
    if (!this.currentDragIndex) {
      return;
    }

    if (this.missingTiles.length > 0) {
      const index = this.missingTiles.findIndex(tile => tile.name === this.currentDragVal);
      if (index >= 0) {
        this.missingTiles[index].enabled = true;
      }
    }

    const previousIds = this.parseId(this.currentDragIndex);
    this.rows[previousIds.rowId][previousIds.colId] = '';

    this.setTileEnabled(true);

    this.saveTiles();
  }

  simpleDrop($event: any) {
    const newIds = this.parseId($event.mouseEvent.currentTarget.id.split('container')[1]);
    this.rows[newIds.rowId][newIds.colId] = this.currentDragVal;

    if (this.currentDragIndex) {
      const previousIds = this.parseId(this.currentDragIndex);
      this.rows[previousIds.rowId][previousIds.colId] = '';
    } {
      this.setTileEnabled(false);
    }

    if (this.missingTiles.length > 0) {
      const index = this.missingTiles.findIndex(tile => tile.name === this.currentDragVal);
      if (index >= 0) {
        this.missingTiles[index].enabled = false;
      }
    }

    this.currentDragVal = '';
    this.currentDragIndex = null;

    this.saveTiles();
  }

  allowDrop(value: any) {
    return () => value === '';
  }

  getTileClasses(col: string) {
    return this.utilityService.getTileClasses(col, this.userSettings);
  }

  private parseId(id: string) {
    const rowId = id.split(' ')[0].split('r')[1];
    const colId = id.split(' ')[1].split('c')[1];

    return {rowId: +rowId, colId: +colId};
  }

  private saveTiles() {
    const updatedTileBank = {};

    this.rows.forEach((row, i) => {
      let isEmpty = true;
      row.forEach((col, j) => {
        if (col !== '') {
          if (isEmpty) {
            isEmpty = false;
            updatedTileBank[`r${i}`] = {};
          }

          updatedTileBank[`r${i}`][`c${j}`] = col;
        }
      });
    });

    this.tilesService
      .putTileBank(this.tileBankId, updatedTileBank)
      .pipe(
        take(1)
      )
      .subscribe(() => {});
  }

  private setTileEnabled(isEnableds: boolean) {
    // update tile set enabled value if this a tile newly dragged into the grid
    for (let i = 0; i < this.allTileSets.length; i++) {
      const set = this.allTileSets[i];
      let isFound = false;

      for (let j = 0; j < set.tiles.length; j++) {
        const tile = set.tiles[j];
        if (tile.name === this.currentDragVal) {
          this.allTileSets[i].tiles[j].enabled = isEnableds;
          isFound = true;
          break;
        }
      }

      if (isFound) {
        break;
      }
    }
  }
}
