import * as SubscriptionActions from './subscriptions.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { truncate } from 'fs';

export interface State extends EntityState<any> {
    selectedId: string;
    id: string;
    pending: boolean;
    error: any;
    succeeded: boolean;
    all: boolean;
    Subscription: any;
}

export const adapter: EntityAdapter<string> = createEntityAdapter({
    selectId: (Subscription: any) => Subscription._id
});
const initialState: State = adapter.getInitialState({
    selectedId: null,
    id: null,
    pending: false,
    error: null,
    succeeded: false,
    all: false,
    Subscription: null
});


export function reducer(state = initialState, action: SubscriptionActions.SubscriptionsActions): State {
    switch (action.type) {
        case SubscriptionActions.SubscriptionActionTypes.CreateSubscription: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }

        case SubscriptionActions.SubscriptionActionTypes.CreateSubscriptionSuccess: {
            return {
                ...adapter.addOne(action.payload, state),
                pending: false,
                succeeded: true,
                error: null
            };
        }
        case SubscriptionActions.SubscriptionActionTypes.CreateSubscriptionFail: {
            return {
                ...state,
                pending: false,
                succeeded: false,
                error: action.payload
            };
        }

        case SubscriptionActions.SubscriptionActionTypes.DeleteSubscription: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
                all: state.all
            };
        }
        case SubscriptionActions.SubscriptionActionTypes.DeleteSubscriptionSuccess: {
            return {
                ...adapter.removeOne(action.payload, state),
                pending: false,
                succeeded: true,
                error: null
            };
        }

        case SubscriptionActions.SubscriptionActionTypes.DeleteSubscriptionFail: {
            return {
                ...state,
                pending: false,
                succeeded: false,
                error: action.payload,
            };
        }
        case SubscriptionActions.SubscriptionActionTypes.GetAllSubscribedCourses: {
            return {
                ...state,
                selectedId: action.payload,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case SubscriptionActions.SubscriptionActionTypes.GetAllSubscribedCoursesSuccess: {
            const subscriptions = action.payload.slice();
            subscriptions
            .sort((a, b) => a.order !== b.order ? a.order < b.order ? -1 : 1 : 0);
            
            return {
                ...adapter.addMany(subscriptions, state),
                succeeded: false,
                pending: false,
                all: true,
                error: null
            };
        }
        case SubscriptionActions.SubscriptionActionTypes.GetAllSubscribedCoursesFail: {
            return {
                ...state,
                succeeded: false,
                pending: false,
                selectedId: null,
                error: action.payload
            };
        }
        case SubscriptionActions.SubscriptionActionTypes.ClearSubscriptions: {
            return {
                ...adapter.removeAll(state),
                succeeded: false,
                pending: false,
                error: null
            };
        }

        case SubscriptionActions.SubscriptionActionTypes.GetSubscription: {
            return {
                ...state,
                id: action.payload,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case SubscriptionActions.SubscriptionActionTypes.GetSubscriptionSuccess: {
            return {
                ...state,
                succeeded: true,
                pending: false,
                id: state.id,
                Subscription: action.payload,
                error: null
            };
        }
        case SubscriptionActions.SubscriptionActionTypes.GetSubscriptionFail: {
            return {
                ...state,
                succeeded: false,
                pending: false,
                id: null,
                error: action.payload
            };
        }
        default:
            return state;
    }
}

export const {
    selectIds: SelectAllIds,
    selectEntities: SelectAllEntities,
    selectAll: SelectAllSubscriptions,
} = adapter.getSelectors();

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.succeeded;
export const getAllSucceeded = (state: State) => state.all;
export const getError = (state: State) => state.error;
export const getSelectedSubscription = (state: State) => {
    return state.entities[state.selectedId];
};
export const getSubscription = (state: State) => state.Subscription;
export const getAllSubscriptions = (state: State) => {
    let subscriptions = SelectAllSubscriptions(state);
    subscriptions = subscriptions.sort((a: any, b: any) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
    return subscriptions;
};
