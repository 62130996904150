import * as wordsActions from './words.actions';

export interface State {
    pending: boolean;
    error: any;
    succeeded: boolean;
    postResponse: any;
    words: any;
    tiles: any;
    pairings: any;
}

const initialState: State = {
    pending: false,
    error: null,
    succeeded: false,
    postResponse: {},
    words: null,
    tiles: null,
    pairings: null
};

export function reducer(state = initialState, action: wordsActions.WordsActions): State {
    switch (action.type) {
        case wordsActions.WordsActionTypes.PostWords: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };
        }
        case wordsActions.WordsActionTypes.PostWordsSuccess: {
            return {
                ...state,
                postResponse: {
                    ...state.postResponse,
                    [action.payload.id]: action.payload.response
                },
                error: null
            };
        }
        case wordsActions.WordsActionTypes.PostWordsFail: {
            return {
                ...state,
                postResponse: {},
                error: action.payload
            };
        }
        case wordsActions.WordsActionTypes.GetWordsByWordId: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };
        }
        case wordsActions.WordsActionTypes.GetWordsByWordIdSuccess: {
            return {
                ...state,
                words: action.payload,
                error: null
            };
        }
        case wordsActions.WordsActionTypes.GetWordsByWordIdFail: {
            return {
                ...state,
                words: null,
                error: action.payload
            };
        }
        case wordsActions.WordsActionTypes.GetTilesByWordId: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };
        }
        case wordsActions.WordsActionTypes.GetTilesByWordIdSuccess: {
            return {
                ...state,
                tiles: action.payload,
                error: null
            };
        }
        case wordsActions.WordsActionTypes.GetTilesByWordIdFail: {
            return {
                ...state,
                tiles: null,
                error: action.payload
            };
        }
        case wordsActions.WordsActionTypes.GetPairings: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };
        }
        case wordsActions.WordsActionTypes.GetPairingsSuccess: {
            return {
                ...state,
                pairings: action.payload,
                error: null
            };
        }
        case wordsActions.WordsActionTypes.GetPairingsFail: {
            return {
                ...state,
                pairings: null,
                error: action.payload
            };
        }
        case wordsActions.WordsActionTypes.ClearWords: {
            return {
                ...state,
                postResponse: {
                    ...state.postResponse,
                    [action.payload.id]: []
                },
                error: null
            };
        }
        case wordsActions.WordsActionTypes.SetToEmpty: {
            return {
                ...state,
                postResponse: {
                    ...state.postResponse,
                    [action.payload.id]: null
                },
                error: null
            };
        }

        default:
            return state;
    }
}

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.succeeded;
export const getError = (state: State) => state.error;
export const getPairings = (state: State) => state.pairings;
export const getPostResponse = (state: State) => state.postResponse;
export const getWordsByWordIds = (state: State) => state.words;
export const getTilesByWordIds = (state: State) => state.tiles;
