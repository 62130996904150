import { CreatePassage, CreateWorkbookPassage, DeletePassage, DeleteWorkbookPassage, UpdatePassage, UpdateWorkbookPassage } from './../../../../store/passage/passage.actions';
import { CreateSentence, DeleteWorkbookSentence, DeleteSentenceSuccess, DeleteSentence, CreateWorkbookSentence, UpdateSentence, UpdateWorkbookSentence } from './../../../../store/sentence/sentence.actions';
import { Store } from '@ngrx/store';
import {Component, Input, OnDestroy, OnInit, ViewChild, Inject} from '@angular/core';
import { WordGroupComponent } from './word-group.component';
import {PhraseService} from '../../../../services/phrase.service';
import {combineLatest, Observable, Subject} from 'rxjs';
import {switchMap, take, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {PassageService} from '../../../../services/passage.service';
import {SentenceService} from '../../../../services/sentence.service';
import {UserService} from '../../../../services/user.service';
import * as reducers from '../../../../store';
// import * as reducers from '../../../../store/reducers';
import { CreatePhrase, CreateWorkbookPhrase, DeletePhrase, DeleteWorkbookPhrase, UpdatePhrase, UpdateWorkbookPhrase } from '../../../../store/phrase/phrase.actions';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-workbook-builder-sentences',
  templateUrl: './html/sentences-and-more.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../word-filters/word-filter.scss',
    '../workbook-builder.scss',
    './sentences-and-more.scss'

  ]
})



export class WorkbookBuilderSentencesAndMoreComponent implements OnInit, OnDestroy {
  @Input() workbookName: string;
  @Input() workbookId: string;
  @ViewChild('phrase', {static: false}) phraseChild: WordGroupComponent;
  @ViewChild('sentence', {static: false}) sentenceChild: WordGroupComponent;
  @ViewChild('passage', {static: false}) passageChild: WordGroupComponent;
  sentences: any[] = [];
  workbookSentence: any;
  workbookSentences: any[];
  phrases: any[] = [];
  workbookPhrase: any;
  workbookPhrases: any[];
  passages: any[] = [];
  workbookPassage: any;
  workbookPassages: any[];
  phraseMismatch = '';
  sentenceMismatch = '';
  passageMismatch = '';
  searchTerm = '';
  workbookPhraseChangeSubject: Subject<any> = new Subject<any>();
  workbookSentenceChangeSubject: Subject<any> = new Subject<any>();
  workbookPassageChangeSubject: Subject<any> = new Subject<any>();


  private abbreviations = [
    '\\.[Bb][Ii][Zz]',
    '\\.[Cc][Oo][Mm]',
    '\\.[Ee][Dd][Uu]',
    '\\.[Ii][Nn][Ff][Oo]',
    '\\.[Mm][Ee]',
    '\\.[Oo][Rr][Gg]',
    '\\s*[Aa]\\.[Dd]\\.',
    '\\s*[Aa]\\.[Mm]\\.',
    '\\s*[Pp]\\.[Mm]\\.',
    '\\s*[Aa][Bb][Bb][Rr]\\.',
    '\\s*[Aa][Uu][Gg]\\.',
    '\\s*[Aa][Vv][Ee]\\.',
    '\\s*[Bb]\\.[Aa]\\.',
    '\\s*[Bb]\\.[Cc]\\.',
    '\\s*[Bb]\\.[Ss]\\.',
    '\\s*[Dd]\\.[Cc]\\.',
    '\\s*[Dd][Ee][Cc]\\.',
    '\\s*[Dd][Rr]\\.',
    '\\s*[Ff][Ee][Bb]\\.',
    '\\s*[Ff][Ll]\\.',
    '\\s*[Ii]\\.[Ee]\\.',
    '\\s*[Aa][Pp][Rr]\\.',
    '\\s*[Mm][Aa][Rr]\\.',
    '\\s*[Jj][Aa][Nn]\\.',
    '\\s*[Jj][Uu][Ll]\\.',
    '\\s*[Jj][Uu][Nn]\\.',
    '\\s*[Jj]\\.[Rr]\\.',
    '\\s*[Mm][Rr]\\.',
    '\\s*[Mm][Rr][Ss]\\.',
    '\\s*[Mm][Ss]\\.',
    '\\s*[Nn][Oo][Vv]\\.',
    '\\s*[Oo][Cc][Tt]\\.',
    '\\s*[Ss][Ee][Pp][Tt]\\.',
    '\\s*:[Ss][Gg][Tt]\\.',
    '\\s*[Ss][Rr]\\.',
    '\\s*[Ss][Tt]\\.',
    '\\s*[Uu]\\.[Ss]\\.',
    '\\s*[Uu]\\.[Ss]\\.[Aa]\\.',
    '\\s*[Vv][Ss]\\.'
  ];

  wordGroupType = {
    phrase: 'Phrase',
    sentence: 'Sentence',
    passage: 'Passage'
  };

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private phraseService: PhraseService,
    private passageService: PassageService,
    private sentenceService: SentenceService,
    private userService: UserService,
    private router: Router,
    private store: Store,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    combineLatest([
      // this.phraseService.getManyPhrases(JSON.parse(localStorage.profile).user_metadata.uid),
      // this.passageService.getManyPassages(JSON.parse(localStorage.profile).user_metadata.uid),
      // this.sentenceService.getManySentences(JSON.parse(localStorage.profile).user_metadata.uid),
      // this.phraseService.getManyWorkbookPhrases(this.workbookId),
      // this.passageService.getManyWorkbookPassages(this.workbookId),
      // this.sentenceService.getManyWorkbookSentences(this.workbookId)
      this.store.select(reducers.selectGetPhrases),
      this.store.select(reducers.selectGetPassages),
      this.store.select(reducers.selectGetSentences),
      this.store.select(reducers.selectGetWorkbookPhrases),
      this.store.select(reducers.selectGetWorkbookPassages),
      this.store.select(reducers.selectGetWorkbookSentences),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([phrases, passages, sentences, workbookPhrases, workbookPassages, workbookSentences]) => {
        // @ts-ignore
        const orderedPhrases = JSON.parse(JSON.stringify(phrases));
        this.phrases = orderedPhrases;
        // @ts-ignore
        const orderedPassages = JSON.parse(JSON.stringify(passages));
        this.passages = orderedPassages;
        // @ts-ignore
        const orderedSentences = JSON.parse(JSON.stringify(sentences));
        this.sentences = orderedSentences;
        // @ts-ignore
        const orderedWorkbookPhrases = workbookPhrases.length > 0 ?
        JSON.parse(JSON.stringify(workbookPhrases[0]?.phrases))?.sort((a, b) => a ? a.order - b.order : 0)
        : [];
        this.workbookPhrases = orderedWorkbookPhrases;
        this.workbookPhrase = workbookPhrases.length > 0 ? JSON.parse(JSON.stringify(workbookPhrases[0])) : [];
        // @ts-ignore
        const orderedWorkbookPassages =  workbookPassages.length > 0 ?
         JSON.parse(JSON.stringify(workbookPassages[0]?.passages))?.sort((a, b) => a ? a.order - b.order : 0)
         : [];
        this.workbookPassages = orderedWorkbookPassages;
        this.workbookPassage = workbookPassages.length > 0 ? JSON.parse(JSON.stringify(workbookPassages[0])) : [];
        // @ts-ignore
        const orderedWorkbookSentences = workbookSentences.length > 0 ?
         JSON.parse(JSON.stringify(workbookSentences[0]?.sentences))?.sort((a, b) => a ? a.order - b.order : 0)
         : [];
        this.workbookSentences = orderedWorkbookSentences;
        this.workbookSentence = workbookSentences.length > 0 ? JSON.parse(JSON.stringify(workbookSentences[0])) : [];
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  addPhraseToWorkbook(phraseId: string) {
    let ownerKey = this.userService.getUserInfo().user_metadata.uid;
    this.store.dispatch(new CreateWorkbookPhrase({phraseId: phraseId, workbookId: this.workbookId, ownerKey: ownerKey}));
  }

  addSentenceToWorkbook(sentenceId: string) {
    let ownerKey = this.userService.getUserInfo().user_metadata.uid;
    this.store.dispatch(new CreateWorkbookSentence({sentenceId: sentenceId, workbookId: this.workbookId, ownerKey: ownerKey}));

  }

  addPassageToWorkbook(passageId: string) {
    let ownerKey = this.userService.getUserInfo().user_metadata.uid;
    this.store.dispatch(new CreateWorkbookPassage({passageId: passageId, workbookId: this.workbookId, ownerKey: ownerKey}));
  }

  removePhraseFromWorkbook(collectionData: any) {
    this.store.dispatch(new DeleteWorkbookPhrase({collectionId: collectionData.collectionId, itemId: collectionData.itemId}));
  }

  removeSentenceFromWorkbook(collectionData: any) {
    this.store.dispatch(new DeleteWorkbookSentence({collectionId: collectionData.collectionId, itemId: collectionData.itemId}));

  }

  removePassageFromWorkbook(collectionData: any) {
    this.store.dispatch(new DeleteWorkbookPassage({collectionId: collectionData.collectionId, itemId: collectionData.itemId}));
  }

  removePhrase(phraseId: string) {
    this.store.dispatch(new DeletePhrase(phraseId));
  }

  removeSentence(sentenceId: string) {
    this.store.dispatch(new DeleteSentence(sentenceId));
  }

  removePassages(passageId: string) {
    this.store.dispatch(new DeletePassage(passageId));
  }

  updatePhrase(updatedPhrase: any) {
    updatedPhrase.value = updatedPhrase.value.trim();
    const sameOne = this.phrases.find(({_id, phrase}) => {
      if (phrase === updatedPhrase.value && _id !== updatedPhrase.itemId) {
        return true;
      }
      return false;
    });
    if (sameOne) {
      const workbookPhrase = this.workbookPhrases.find(({phraseId}) => phraseId === updatedPhrase.itemId);
      if (workbookPhrase) {
        this.removePhrase(sameOne._id);
        this.store.dispatch(new UpdatePhrase({
          id: updatedPhrase.itemId,
          Phrase: updatedPhrase.value
        }));
      } else {
        this.removePhrase(updatedPhrase.itemId);
      }
    } else {
      this.store.dispatch(new UpdatePhrase({
        id: updatedPhrase.itemId,
        Phrase: updatedPhrase.value
      }));
      const index = this.workbookPhrases.findIndex(({_id}) => _id === updatedPhrase.itemId);
      if (index > -1) {
        this.workbookPhrases[index].phrase = updatedPhrase.value;
        this.workbookPhrase.phrases = this.workbookPhrases;
        this.store.dispatch(new UpdateWorkbookPhrase({
          id: this.workbookPhrase._id,
          phrases: this.workbookPhrases
        }));

      }

    }
  }

  updateSentence(updatedSentence: any) {
    updatedSentence.value = updatedSentence.value.trim();

    const sameOne = this.sentences.find(({_id, sentence}) => {
      if (sentence === updatedSentence.value && _id !== updatedSentence.itemId) {
        return true;
      }
      return false;
    });
    if (sameOne) {
      const workbookSentence = this.workbookPhrases.find(({sentenceId}) => sentenceId === updatedSentence.itemId);
      if (workbookSentence) {
        this.removeSentence(sameOne._id);
        this.store.dispatch(new UpdateSentence({
          id: updatedSentence.itemId,
          sentence: updatedSentence.value
        }));
      } else {
        this.removeSentence(updatedSentence.itemId);
      }
    } else {
      this.store.dispatch(new UpdateSentence({
        id: updatedSentence.itemId,
        sentence: updatedSentence.value
      }));
      const index = this.workbookSentences.findIndex(({_id}) => _id === updatedSentence.itemId);
      if (index > -1) {
        this.workbookSentences[index].sentence = updatedSentence.value;
        this.workbookSentence.sentences = this.workbookSentences;
        this.store.dispatch(new UpdateWorkbookSentence({
          id: this.workbookPassage._id,
          sentences: this.workbookSentences
        }));

      }

    }
  }


  updatePassage(updatedPassage: any) {
    updatedPassage.value = updatedPassage.value.trim();
    const sameOne = this.passages.find(({_id, passage}) => {
      if (passage === updatedPassage.value && _id !== updatedPassage.itemId) {
        return true;
      }
      return false;
    });
    if (sameOne) {
      const workbookPassage = this.workbookPassages.find(({passageId}) => passageId === updatedPassage.itemId);
      const index = this.workbookPassages.findIndex(({passageId}) => passageId === updatedPassage.itemId);
      if (workbookPassage) {
        this.removePassages(sameOne._id);
        this.store.dispatch(new UpdatePassage({
          id: updatedPassage.itemId,
          Passage: updatedPassage.value
        }));

      } else {
        this.removePassages(updatedPassage.itemId);
      }
    } else {
      const index = this.workbookPassages.findIndex(({_id}) => _id === updatedPassage.itemId);
      this.store.dispatch(new UpdatePassage({
        id: updatedPassage.itemId,
        Passage: updatedPassage.value
      }));
      if (index > -1) {
        this.workbookPassages[index].passage = updatedPassage.value;
        this.workbookPassage.passages = this.workbookPassages;
        this.store.dispatch(new UpdateWorkbookPassage({
          id: this.workbookPassage._id,
          passages: this.workbookPassages
        }));

      }

    }
  }

  reorderPhrase(reorderedPhrase: any) {
    // this.phraseService
    // .updateWorkbookPhrase(reorderedPhrase._id, reorderedPhrase)
    // .pipe(
    //   take(1),
    //   switchMap(() => this.phraseService.getManyWorkbookPhrases(this.workbookId))
    // )
    // .subscribe((workbookPhrases: any[]) => {
    // });
    this.store.dispatch(new UpdateWorkbookPhrase({
      id: this.workbookPhrase._id,
      phrases: reorderedPhrase
    }));
  }

  reorderSentence(reorderedSentence: any) {
    // this.sentenceService.updateWorkbookSentence
    // (reorderedSentence._id, reorderedSentence)
    // .pipe(
    //   take(1),
    //   switchMap(() => this.sentenceService.getManyWorkbookSentences(this.workbookId))
    // )
    // .subscribe((workbookSentences: any[]) => {
    // });
    this.store.dispatch(new UpdateWorkbookSentence({
      id: this.workbookSentence._id,
      sentences: reorderedSentence
    }));
  }

  reorderPassage(reorderedPassage: any) {
    // this.passageService
    // .updateWorkbookPassage(reorderedPassage._id, reorderedPassage)
    // .pipe(
    //   take(1),
    //   switchMap(() => this.passageService.getManyWorkbookPassages(this.workbookId))
    // )
    // .subscribe((workbookPassages: any[]) => {
    // });
    this.store.dispatch(new UpdateWorkbookPassage({
      id: this.workbookPassage._id,
      passages: reorderedPassage
    }));
  }


  update(wordGroup: any) {
    const editedType = this.validateWordGroup(wordGroup.value);

    if (editedType === wordGroup.type) {
      return this[`${editedType.toLowerCase()}Service`]
        [`update${editedType}`](wordGroup)
        .pipe(take(1))
        .subscribe(() => {});
    }

    if (editedType === wordGroup.type) {
      const oldId = wordGroup.value._id;
      delete wordGroup.value._id;
      this[`${editedType.toLowerCase()}Service`][`create${editedType}`]({
        [`${editedType.toLowerCase()}`]: wordGroup.value[editedType.toLowerCase()],
        ownerKey: JSON.parse(localStorage.profile).user_metadata.uid
      })
        .pipe(
          take(1),
          switchMap((newWordGroup: any) => {
            this[`${editedType.toLowerCase()}s`].push(newWordGroup);

            return combineLatest([
              this[`${editedType.toLowerCase()}Service`][`delete${editedType}`](oldId),
              this[`${editedType.toLowerCase()}Service`][`deleteManyWorkbook${editedType}s`](oldId),
              this[`${editedType.toLowerCase()}Service`][`createWorkbook${editedType}`]({
                workbookId: this.workbookId,
                [`${editedType.toLowerCase()}Id`]: newWordGroup._id
              }),
            ]);
          })
        )
        .subscribe(([result1, result2, result3]) => {
          this[`workbook${editedType}s`].push(result3);
        });
    }
  }

  showExistDialog() {
    const dialogRef = this.dialog.open(AlertDialogComponent, {});
    dialogRef.componentInstance.title = "This content has already been added to this workbook.";
  }

  uploadAndAdd(content: string, contentType: string) {
    const ownerKey = JSON.parse(localStorage.profile).user_metadata.uid;
    if (contentType === 'Phrase') {
      if (this.workbookPhrases.find(({phrase}) => phrase === content)) {
        this.showExistDialog();
        return;
      }

      const existingOne = this.phrases.find(({phrase}) => phrase === content);
      if (existingOne) {
        this.addPhraseToWorkbook(existingOne._id);
      } else {
        this.store.dispatch(new CreatePhrase({
          phraseData: {
            ownerKey,
            phrase: content,
          },
          workbookId: this.workbookId
        }));
      }
    } else if (contentType === 'Sentence') {
      if (this.workbookSentences.find(({sentence}) => sentence === content)) {
        this.showExistDialog();
        return;
      }

      const existingOne = this.sentences.find(({sentence}) => sentence === content);
      if (existingOne) {
        this.addSentenceToWorkbook(existingOne._id);
      } else {
        this.store.dispatch(new CreateSentence({
          sentenceData: {
            ownerKey,
            sentence: content,
          },
          workbookId: this.workbookId
        }));
      }
    } else if (contentType === 'Passage') {
      if (this.workbookPassages.find(({passage}) => passage === content)) {
        this.showExistDialog();
        return;
      }

      const existingOne = this.passages.find(({passage}) => passage === content);
      if (existingOne) {
        this.addPassageToWorkbook(existingOne._id);
      } else {
        this.store.dispatch(new CreatePassage({
          passageData: {
            ownerKey,
            passage: content,
          },
          workbookId: this.workbookId
        }));
      }
    }
  }

  uploadObservable(content: string, contentType: string) {
    const wordGroupType = contentType;
      return new Observable(observer => {
        return this[`${wordGroupType.toLowerCase()}Service`]
          [`create${wordGroupType}`]({
            [wordGroupType.toLowerCase()]: content,
            ownerKey: JSON.parse(localStorage.profile).user_metadata.uid
          })
          .pipe(take(1))
          .subscribe((newItem: any) => {
            this[`${wordGroupType.toLowerCase()}s`].push(newItem);
            observer.next(newItem);
          });
      });
  }

  validateWordGroup(wordGroup: string, contentType: string = ''): string {
    let wordGroupTestValue  = wordGroup;
    // ignore abbreviations
    for (let i = 0; i < this.abbreviations.length; i++) {
      const regex = new RegExp(`\\b${this.abbreviations[i]}`, 'g');
      wordGroupTestValue = wordGroupTestValue.replace(regex, '');
    }

    // If the word group has no terminal punc, after stripping abbrv, and no quotes, it is a phrase
    // it is not a phrase if the type is sentence but and it has a period at the end
    if ((wordGroupTestValue.match(/[\?\.!]/g) || []).length === 0
        && (wordGroupTestValue.match(/"/g) || []).length === 0) {
      // it is not a phrase if the type is sentence, or it has a period at the end unless the type is phrase
      if (contentType === 'Phrase' || contentType !== 'Sentence' || wordGroup.lastIndexOf('.') !== wordGroup.length - 1) {
        return this.wordGroupType.phrase;
      }
    }


    // Assure the string ends with a terminal point, or terminal point followed by a quotation
    const endsInPunc = (wordGroup.match(/(\?|\.|!|\?"|\."|!")$/g) || []).length === 1;

    // //Remove all content within quotations, and check for 1 or less terminal punctuation
    const oneTerminal = (wordGroupTestValue.replace(/".*"/g, '').match(/[\?\.!]/g) || []).length <= 1;

    // If the PSP ends in a terminal, and has only terminal, after stripping quotation terminals and approved abbrvs, it is a sentence
    if (endsInPunc && oneTerminal) {
      return this.wordGroupType.sentence;
    }
    if (endsInPunc && !oneTerminal) {
      return this.wordGroupType.passage;

    }
  }

  confirmType(content: any) {
    const wordGroupType = this.validateWordGroup(content.content, content.type);
    if (wordGroupType === content.type) {
      this.uploadAndAdd(content.content, wordGroupType);
      this[`${wordGroupType.toLowerCase()}Child`].closeNewCard();
    } else {
      this[`${content.type.toLowerCase()}Mismatch`] = wordGroupType;
    }
  }

  clearMismatch(type: string) {
    this[`${type.toLowerCase()}Mismatch`] = '';
    this[`${type.toLowerCase()}Child`].closeNewCard();
  }
}


@Component({
  selector: 'app-alert-dialog',
  templateUrl: './html/alert-dialog.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
    './sentences-and-more.scss'
  ]
})


export class AlertDialogComponent implements OnInit {
  public title: string;

  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>) {

  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
}
