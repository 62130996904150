import { Store } from '@ngrx/store';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import {AuthGuard} from '../../services/auth-guard.service';
import { CourseListDialogComponent } from '../workbooks/course-list-dialog.component';
import {CourseWorkbookMenuComponent} from '../workbooks/course-workbooks.component';
import { ActivitiesMenuComponent } from '../../../activities/side-navs/activities-menu.component';
import {take, takeUntil} from 'rxjs/operators';
import {ActivityTypeService} from '../../services/activity-type.service';
import {MatDialog} from '@angular/material/dialog';
import {combineLatest, Subject} from 'rxjs';
import {CourseWorkbooksService} from '../../services/course-workbooks.service';
import {ActivityHeaderService} from '../../services/activity-header.service';
import { selectGetAllCourseWorkbook, selectGetAllLessons } from '../../store';


@Component({
  selector: 'app-header-element',
  templateUrl: './html/header.html',
  styleUrls: [
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss',
    './../../../activities/activity-header/activity-header.scss'
  ]
})

export class HeaderComponent implements OnInit, OnDestroy {
  @Input() headerType: string;
  @Input() courseWorkbook: any;
  @Input() lessonPlanId: string;
  @Input() lessonPlanStepId: string;
  @Input() activityName: string;
  @Input() workbookId: string;
  @Output() toggleShowActivitiesMenuEvent: EventEmitter<any> = new EventEmitter<any>();
  // not used
  // @Output() toggleShowCourseWorkbooksMenuEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleShowLessonPlansMenuEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleShowLessonPlanStepsMenuEvent: EventEmitter<any> = new EventEmitter<any>();
  public name: string;
  public email: string;
  activities: any[];
  courseWorkbooks: any[] = [];
  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private dialog: MatDialog,
    private _user: UserService,
    private _auth: AuthService,
    private _guard: AuthGuard,
    private _api: ApiService,
    private activityTypeService: ActivityTypeService,
    private courseWorkbooksService: CourseWorkbooksService,
    private activityHeaderService: ActivityHeaderService,
    private store: Store
    ) {}

  ngOnInit() {
    this.name = JSON.parse(localStorage.profile).user_metadata.nickname;
    this.email = JSON.parse(localStorage.profile).user_metadata.email;

    combineLatest([
      this.activityTypeService.getAll(),
      // this.store.select(selectGetAllLessons),
      // this.courseWorkbooksService.getAll(this.courseWorkbook.courseId)
      this.store.select(selectGetAllCourseWorkbook)
    ])
      // .pipe(
      //   take(1)
      // )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((results: any[]) => {
        if (!results[0] || !results[1]) {
          this.courseWorkbooks = [];
          this.activities = [];
          return;
        }
        this.courseWorkbooks = JSON.parse(JSON.stringify(results[1]));

        // @ts-ignore
        this.activities = results[0].map(activity => {
          if (activity.name.toLowerCase() === 'blackboard') {
            activity.url = '//activity/blackboard/';
          } else if (activity.name.toLowerCase() === 'workbook tiles') {
            activity.url = '//activity/workbook-tiles/';
          }

          return activity;
        });
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public deleteAccount() {
    this._api.removeUser(this._user.auth0UserInfo.user_metadata.uid).subscribe();
  }

  public logout() {
    // this._guard.loggedIn = false;
    this._guard.updateLoginStatus(false);
    AuthService.logout();
  }

  public toggleShowActivitiesMenu() {
    // if(this.courseWorkbook.name){
    //   let courseWorkbook = this.courseWorkbooks.filter(courseWorkbook => courseWorkbook._id === this.courseWorkbook._id)
    //   this.courseWorkbook = courseWorkbook[0]
    // }

    const activityDialog = this.dialog.open(CourseWorkbookMenuComponent, {
      panelClass: 'activity-modalbox',
      data: this.courseWorkbook,
      width: '350px'
    });

    activityDialog.componentInstance.functions = {
      closeDialog: () => activityDialog.close()
    };
  }

  public toggleShowCourseWorkbooksMenu() {
    this.dialog.open(CourseListDialogComponent, {
      width: '1700px',
      height: '350px',
      panelClass: 'course-list-modalbox',
      data: {
        courseWorkbooksList: this.courseWorkbooks,
        activities: this.activities
      }
    });

  }

  public toggleShowLessonPlansMenu() {
    this.toggleShowLessonPlansMenuEvent.emit();
  }

  public toggleShowLessonPlanStepsMenu() {
    this.toggleShowLessonPlanStepsMenuEvent.emit();
  }

  toggleHeaderHover(isHovering: boolean) {
    ActivityHeaderService.updateOverDeadzone(isHovering);
  }
}
