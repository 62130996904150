import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_DIR } from '../../environments/environment';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()

export class TilesService {
  public sightTiles: any = {
    r0: {
      c0: "tiles.bv.a",
      c1: "tiles.bc.b",
      c2: "tiles.bc.c",
      c3: "tiles.bc.d",
      c4: "tiles.bv.e",
      c5: "tiles.bc.f",
      c6: "tiles.bc.g",
      c7: "tiles.bc.h",
      c8: "tiles.bv.i",
      c9: "tiles.bc.j",
      c10: "tiles.bc.k",
      c11: "tiles.bc.l",
      c12: "tiles.bc.m",
      c13: "tiles.bc.n"
    },
    r1: {
      c0: "tiles.bv.o",
      c1: "tiles.bc.p",
      c2: "tiles.bc.q",
      c3: "tiles.bc.r",
      c4: "tiles.bc.s",
      c5: "tiles.bc.t",
      c6: "tiles.bv.u",
      c7: "tiles.bc.v",
      c8: "tiles.bc.w",
      c9: "tiles.bc.x",
    },
    r2: {
      c0: "tiles.bc.y",
      c1: "tiles.bc.z",
    },
    r3: {
      c0: "tiles.sym.'",
      c1: "tiles.sym2.period",
      c2: "tiles.sym.-"
    }
  };
  constructor(private http: HttpClient) {}

  getAllTiles() {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/tiles`)
        .pipe(
          take(1)
        ).subscribe(
        (tiles: any) => observer.next(tiles.data),
        (error: any) => {
          throw error;
        }
      );
    });
  }

  searchTiles(query: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/tiles/${query}`)
        .pipe(
          take(1)
        )
        .subscribe(
          (tiles: any) => observer.next(tiles.data),
          error => {
            throw error;
          });
    });
  }

  getTiles(id: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/tilebank/${id}`)
        .pipe(
          take(1)
        )
        .subscribe(
          (tileBank: any) => observer.next(tileBank.data),
          error => {
            throw error;
          }
        );
    });
  }

  getUserTiles(userId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/tilebank/user/${userId}`)
        .pipe(
          take(1)
        )
        .subscribe(
          (tileBank: any) => observer.next(tileBank.data),
          error => {
            throw error;
          }
        );
    });
  }

  putTileBank(tileBankId: string, updatedTileBank: any) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/tileBank/${tileBankId}`, updatedTileBank)
        .pipe(
          take(1)
        )
        .subscribe(
          () => observer.next(),
          error => {
            throw error;
          }
        );
    });
  }
}
