// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-save-setting .mat-dialog-content .mat-form-field .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/core/components/add-collection-dialog/add-collection-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;AACJ","sourcesContent":[".add-save-setting .mat-dialog-content .mat-form-field .mat-form-field-wrapper {\n    padding-bottom: 0 !important;\n    \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
