// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-select-panel {
  /* background: #c32828; */
  margin-top: 38px !important;
}
::ng-deep .mat-select-panel .name-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  display: inline-block;
}

:host ::ng-deep .setting-type-dropdown .mdc-text-field {
  padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/core/components/settings-header/settings-header.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EAEA,2BAAA;AAAJ;AAEI;EACI,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,YAAA;EACA,qBAAA;AAAR;;AAKI;EACI,qBAAA;AAFR","sourcesContent":["::ng-deep .mat-select-panel {\n    /* background: #c32828; */\n    // margin-top: 52.5px !important;\n    margin-top: 38px !important;\n\n    .name-text { \n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        width: 200px;\n        display: inline-block;\n    }\n}\n\n:host ::ng-deep {\n    .setting-type-dropdown .mdc-text-field {\n        padding: 0 !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
