import { Action } from "@ngrx/store";

export enum UserInterfaceTypes {
  SetActivityOrLessonFixStatus = '[Activity or Lesson Page] Set as Fix Status in Workbook Activity or Lesson Step page',

}

export class SetActivityOrLessonFixStatus implements Action {
  readonly type = UserInterfaceTypes.SetActivityOrLessonFixStatus;

  constructor(public payload: 'outdated' | 'updated') {}
}

export type UserInterfaceActions
= SetActivityOrLessonFixStatus;
