import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsRoutingModule } from '../../Settings/settings-routing.module';
import { Observable, BehaviorSubject } from 'rxjs';
import { API_DIR } from '../../environments/environment';
import { UserService } from '../services/user.service';
import { switchMap, take } from 'rxjs/operators';
import { isArray } from 'util';
import { DefaultLayoutAlignDirective } from '@angular/flex-layout';

@Injectable({providedIn: 'root'})
export class SettingsService {
  public activitySettings: any;
  public activitySettingsSubjet$: BehaviorSubject<any>;
  public updateActivitySettings: any;
  public activitySettingsDefault: any;
  constructor(private http: HttpClient, userService: UserService) {


  }


  get(activitySettingsId: string) {
    return new Observable(observer => {
      this.http
      .get(`${API_DIR}/userActivitySettings/${activitySettingsId}`)
      .subscribe((settings: any) => {
        observer.next(settings);
      },
      error => {
        throw error;
      });
    });
  }
  getAll(userId: string) {
    return new Observable(observer => {
      this.http
      .get(`${API_DIR}/userActivitySettings/user/${userId}`)
      .subscribe((settings: any) => {
        observer.next(settings);
      },
      error => {
        throw error;
      });
    });
  }

  updateSettings(settings: any) {
    this.activitySettingsSubjet$.next(settings);
    localStorage.setItem('activitySettings', JSON.stringify(settings));
  }

  update(activitySettingsId: string, updates: any) {
    return this.http.put(`${API_DIR}/userActivitySettings/update/${activitySettingsId}`, updates);
  }
  updateCurrent(activitySettingsId: string, userId: string) {
    return this.http
    .put(`${API_DIR}/userActivitySettings/updateCurrent/${activitySettingsId}/${userId}`, {});
    
  }
  create(userId: string, setting: any) {
    return this.http.post(`${API_DIR}/userActivitySettings/${userId}`, setting);
  }

  copy(activitySettingsId: string, ownerKey: string) {
    const object: any = {
      ownerKey: ownerKey
    };
    return this.http.post(`${API_DIR}/userActivitySettings/copy/${activitySettingsId}`, object);
  }

  delete(activitySettingsId: string) {
    return this.http.delete(`${API_DIR}/userActivitySettings/${activitySettingsId}`);

  }
  updateCompoundSounds(compoundSound: any, settingsId: string) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/settings/compoundSounds/${settingsId}`, compoundSound)
        .pipe(
          take(1)
        )
        .subscribe(
          () => {
                  observer.next();
          },
          error => {
            throw error;
          }
        );
    });
  }
}
