import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import { GetMyLibraryWorkbooks } from "../store/workbook/workbook.actions";
import { getWorkbook } from "../store";


@Injectable()
export class WorkbookResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const workbookId = route.params.workbookId;
    return this.store.pipe(
      select(getWorkbook, { id: workbookId }),
      // tap(isLoaded => {
      //   if (!isLoaded) {
      //     this.store.dispatch(new GetMyLibraryWorkbooks(''));
      //   }
      // }),
      filter(isLoaded => {
        if (workbookId === 'new') { return true; }
        return !!isLoaded;
      }),
      first(),
    );
  }
}
