import { Component, OnInit } from "@angular/core";
import { WordlistService } from "../core/services/wordlist.service";

@Component({
  selector: "app-wordlist",
  templateUrl: "./html/wordlist.html",
  styleUrls: [
    "../assets/css/main.css",
    "../assets/scss/fontawesome.scss",
    "../assets/scss/brands.scss",
    "../assets/scss/regular.scss",
    "../assets/scss/solid.scss"
  ]
})
export class WordlistComponent implements OnInit {
  public links: Array<Object>;
  constructor(private Wordlist: WordlistService) {
    this.links = [
      { path: "Words", name: 'Words'},
      { path: "Sentences", name: 'Sentences'},
      { path: "Files", name: 'Files'}
    ];
  }
  ngOnInit() {}
}
