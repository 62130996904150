import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LessonPlansComponent } from './lesson-plans.component';
import { EditLessonPlanComponent } from './Edit-Lesson-Plan/edit-lesson-plan.component';
import { SetCourseResolver } from '../core/resolvers/set-course.resolver';
import { AllCoursesResolver } from '../core/resolvers/all-courses.resolver';
import { MainCourseLessonsResolver } from '../core/resolvers/main-course-lessons.resolver';
import { AllLessonStepsResolver } from '../core/resolvers/all-lesson-steps.resolver';
import { AllMyLibraryWorkbooksResolver } from '../core/resolvers/all-my-library-workbooks.resolver';
import { AllSubscribedCoursesResolver } from '../core/resolvers/all-subscribed-courses.resolver';
import { AllCourseWorkbookResolver } from '../core/resolvers/all-course-workbook.resolver';
import { LessonPlanResolver } from '../core/resolvers/lesson-plan.resolver';
import { SettingsResolver } from '../core/resolvers/settings.resolver';
import { AuthGuard } from '../core/services/auth-guard.service';

const LessonPlanRoutes: Routes = [
  {
    path: 'lesson-plans/:courseId',
    component: LessonPlansComponent,
    data: {},
    resolve: {
      allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
      allCoursesLoaded: AllCoursesResolver
    }
  },
  {
    path: 'edit-lesson-plan/:lessonPlanId/:courseId/:order',
    canActivate: [AuthGuard],
    component: EditLessonPlanComponent,
    data: {},
    resolve: {
      allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
      allCoursesLoaded: AllCoursesResolver,
      // isCourseLoaded: SetCourseResolver,
      isLessonStepsLoaded: AllLessonStepsResolver,
      allMyLibraryWorkbooksLoaded: AllMyLibraryWorkbooksResolver,
      // isCourseLessonsLoaded: MainCourseLessonsResolver,
      allcourseWorkbooksLoaded: AllCourseWorkbookResolver,
      isLessonPlanLoaded: LessonPlanResolver,
      allSettingsLoaded: SettingsResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(LessonPlanRoutes)],
  exports: [RouterModule]
})
export class LessonPlansRoutingModule {}
