import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { forkJoin, from, of } from 'rxjs';
import { combineLatest, concatMap, filter, finalize, first, switchMap, tap } from "rxjs/operators";
import * as CoursesActions from '../store/courses/courses.actions';
import * as CoursesSelectors from '../store';


import * as SubscriptionsActions from '../store/subscriptions/subscriptions.actions';
import * as SubscriptionsSelectors from '../store';

@Injectable()
export class MainCourseResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(CoursesSelectors.selectCoursesState),
      tap(courses => {
        if (!courses.id && courses.ids.length) {
          this.store.dispatch(new CoursesActions.GetCourse(courses.ids[0]));
        }
      }),
      filter(courses => !!courses.id),
      first()
    );
  }
}
