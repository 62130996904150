import { CreateManyFiles, CreateWorkbookFile, DeleteFile, DeleteWorkbookFile, DeleteWorkbookFileSuccess, ReorderWorkbookFilesSuccess, RenameFileSuccess, DeleteManyFiles, DeleteManyWorkbookFile, CreateManyWorkbookFiles, DeleteManyWorkbookFileSuccess, RenameFile } from './../../../../store/file/file.actions';
import { Store } from '@ngrx/store';
import { AsyncPipe, DatePipe } from '@angular/common';
import { fromEvent, Observable, combineLatest, Subscription, Subject, BehaviorSubject } from 'rxjs';
import { debounceTime, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Inject,
  ViewChild,
  ElementRef, OnDestroy, ChangeDetectionStrategy, ViewChildren
} from '@angular/core';
import { ViewPDFComponent } from './view-pdf.component';
import {
  faFilePdf,
  faPlus,
  faTrash,
  faMinus,
  faRulerHorizontal
} from '@fortawesome/free-solid-svg-icons';
import { FilesService } from '../../../../services/files.service';
import { UserService } from '../../../../services/user.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';
import { CreateFile, GetManyFiles, GetManyWorkbookFiles } from '../../../../store/file/file.actions';
import * as reducers from '../../../../store';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-file-cards',
  templateUrl: './html/file-cards.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    './files.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FileCardsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('fileSelect', { static: false }) fileSelect: ElementRef;
  // @Input() files: any[];
  files: any[];
  // @Input() otherItems: any[];
  otherItems: any[];

  // Determines if the file is stored in the account's filebank or the workbook's filebank (+ account filebank)
  @Input() isAccountFile: boolean;
  @Input() uploadNew: boolean;
  @Input() isBank = false;
  @Input() searchTermObservable: Observable<any>;
  @Input() orderable: boolean;
  @Input() inSelectionMode: boolean;
  @Input() selectionModeObservable: Observable<boolean>;
  @Input() toggleSelectionMode!: () => void;
  @Output() selectedFilesCountChange = new EventEmitter<number>();
  @Input() inWorkbookFilesColor: string;
  // @Input() otherFilesUpdatedObservable: Observable<any>;
  // @Output() moveFile: EventEmitter<any> = new EventEmitter<any>(false);
  // @Output() addFile: EventEmitter<any> = new EventEmitter<any>(false);
  // @Output() removeWorkbookFile: EventEmitter<any> = new EventEmitter<any>(false);
  // @Output() addToWorkbook: EventEmitter<any> = new EventEmitter<any>(false);
  // @Output() removeFromWorkbook: EventEmitter<any> = new EventEmitter<any>(false);
  // @Output() remove: EventEmitter<any> = new EventEmitter<any>(false);
  @Output() updateWorkbookFiles: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateWorkbookFileName: EventEmitter<any> = new EventEmitter<any>();
  private newFile: any;
  private subscriptions: Subscription[] = [];
  public renaming = false;
  public renameControl: string;
  public searchTerm = "";
  public loadingFile = false;
  public combinedSizeTooLarge = false;
  public wrongType = false;
  public fileNum = 0;
  public tooMany = false;
  public FILE_LIMIT = 25;
  public FILE_SIZE_LIMIT = 5000000;
  public time: number = Date.now();
  public filteredFiles: any[] = [];
  public workbookFiles: any;
  public filteredFilesSubject: any = new BehaviorSubject<any>(this.filterFiles);

  public filteredFiles$ = this.filteredFilesSubject.asObservable();

  workbookId: string;

  faFile = faFilePdf;
  faPlus = faPlus;
  faTrash = faTrash;
  faMinus = faMinus;

  private unsubscribe$: Subject<void> = new Subject();
  @ViewChildren('contentCard') contentCards;
  isDuplicateName = false;
  duplicateCount = 0;
  duplicateNames = '';
  otherFiles: any;

  constructor(
    private filesService: FilesService,
    private userService: UserService,
    public dialog: MatDialog,
    private pdf: ViewPDFComponent,
    private store: Store,
    private route: ActivatedRoute,
    private async: AsyncPipe,
    private date: DatePipe
  ) {
  }

  ngOnInit(): void {
    this.workbookId = this.route.snapshot.paramMap.get('workbookId');

    this.init();

    this.subscriptions.push(this.searchTermObservable.subscribe(searchTerm => {
      this.searchTerm = searchTerm;

      this.clearSelection();
      this.filterFiles();
    }));


    this.subscriptions.push(this.selectionModeObservable.subscribe(inSelectionMode => {
      if (!inSelectionMode) {
        this.clearSelection();
      }
    }));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkCardMultiline();
    }, 1000);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  init(): void {
    combineLatest([
      this.store.select(reducers.selectGetFiles),
      this.store.select(reducers.selectGetWorkbookFiles)
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([files, workbookFiles]) => {
        if (!files || !workbookFiles) {
          this.files = [];
          this.otherItems = [];
          return;
        }
        if (workbookFiles[0]?.files) {
          this.workbookFiles = workbookFiles;
          this.updateWorkbookFiles.emit(workbookFiles);
        }
        if (this.isAccountFile) {
          this.files = JSON.parse(JSON.stringify(files));
          this.otherItems = JSON.parse(JSON.stringify(workbookFiles[0]?.files || []));

        } else {
          this.files = JSON.parse(JSON.stringify(workbookFiles[0]?.files || []));
          this.otherItems = JSON.parse(JSON.stringify(files));
        }

        this.otherFiles = JSON.parse(JSON.stringify(files));

        if (this.isDuplicateName) {
          // Check to see if otherFiles contains the duplicate files
          const duplicates = this.otherFiles.filter(file => this.duplicateNames.includes(file.name));
          if (duplicates.length > 0) {
            this.isDuplicateName = true;
            this.duplicateCount = duplicates.length;
            this.duplicateNames = duplicates.map(d => d.name).join(', ');
          } else {
            this.isDuplicateName = false;
            this.duplicateCount = 0;
            this.duplicateNames = '';
          }
        }

        // this.filteredFiles = this.files.slice();
        this.filteredFilesSubject.next(this.files);

        if (this.isBank) {
          this.files = this.files
            .sort((a: any, b: any) => {
              if (a.name?.toLowerCase() < b.name?.toLowerCase()) { return -1; }
              if (a.name?.toLowerCase() > b.name?.toLowerCase()) { return 1; }
              return 0;

            });
        }
        this.itemFilter = this.itemFilter.bind(this);
        this.updateFiles();

      });


  }

  updateFilterFilesSubject() {
    if (!this.isBank) {
      this.filesService.getManyWorkbookFiles(this.workbookId).pipe(take(1)).subscribe(workbookFiles => {
        if (workbookFiles[0]?.files) {
          this.workbookFiles = workbookFiles;
          // this.updateWorkbookFiles.emit(workbookFiles);
        }
        this.files = JSON.parse(JSON.stringify(workbookFiles[0]?.files || []));
        this.filteredFilesSubject.next(this.files);
      });
    }
  }

  filterFiles() {
    if (this.searchTerm !== "") {
      let filteredFiles = this.files.filter(f => {
        if (f.name) {
          return f.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0;
        }
        return f.file.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0;
      });
      this.filteredFilesSubject.next(filteredFiles);
    } else {
      this.filteredFilesSubject.next(this.files.slice());
    }
  }

  updateFiles() {
    this.files = this.files?.map(file => {
      if (this.isAccountFile) {
        file.isAdded = this.otherItems.findIndex(oi => file._id === oi._id) >= 0;
      } else {
        file.isAdded = this.otherItems.findIndex(oi => file._id === oi._id) >= 0;
      }

      file.selected = false;

      return file;
    });

    this.filterFiles();
  }

  addToWorkbookEvent(itemId: string) {
    // this.addToWorkbook.emit(itemId);
    let ownerKey = this.userService.getUserInfo().user_metadata.uid;

    let isDuplicate = this.otherItems.find(item => item._id === itemId);

    if(isDuplicate) {
      const dialogRef = this.dialog.open(DuplicatesDialogComponent, {
        panelClass: 'custom-confirm-dialog',
      });
      dialogRef.componentInstance.functions = {
        close: () => {
          dialogRef.close();
        }
      };
      return;
    }

    const workbookFile = {
      fileId: itemId,
      workbookId: this.workbookId,
      ownerKey: ownerKey
    };
    this.store.dispatch(new CreateWorkbookFile(workbookFile));
  }

  addManyToWorkbook() {
    let filteredFiles: any = this.async.transform(this.filteredFiles$);

    let selectedFiles = filteredFiles.filter(file => file.selected);

    let selectedFilesCount = selectedFiles.length;

    // Remove duplicates from selected files. Some files in the database may have different formats due to older versions of the app, we have to check for both
    let duplicates = this.otherItems?.filter(item => selectedFiles.find(item2 => (typeof item2.file === 'object' ? item2.file.name : item2.name) === (typeof item.file === 'object' ? item.file.name : item.name)));
    if (duplicates.length > 0) {
      selectedFiles = selectedFiles.filter(item => !duplicates.find(item2 => (typeof item2.file === 'object' ? item2.file.name : item2.name) === (typeof item.file === 'object' ? item.file.name : item.name)));
    }

    let existingFiles = selectedFilesCount - selectedFiles.length;

    if (selectedFiles.length === 0 && existingFiles === 0) {
      return;
    }

    const dialogRef = this.dialog.open(AddManyFilesDialogComponent, {
      panelClass: 'custom-confirm-dialog',
      height: '250px',
      width: '328px',
      data: {
        selectedFiles: selectedFiles,
        existingFiles: existingFiles,
      }
    });
    dialogRef.componentInstance.functions = {
      addToWorkbook: () => {
        // selectedFiles.forEach(file => {
        //   this.addToWorkbookEvent(file._id);
        // });

        let ownerKey = this.userService.getUserInfo().user_metadata.uid;

        const workbookFiles = selectedFiles.map(file => {
          return {
            fileId: file._id,
            workbookId: this.workbookId,
            ownerKey: ownerKey
          };
        });

        this.store.dispatch(new CreateManyWorkbookFiles(workbookFiles));
        this.clearSelection();
      }
    };
  }

  uploadStatus(status: boolean) {
    if (this.uploadNew) {
      this.uploadFile();
    }
    return this.uploadNew;
  }

  openPDF(file: any): void {
    if (!file.fileId) {
      const dialogRef = this.dialog.open(ViewPDFComponent, {
        panelClass: 'type-edit-modalbox',
        width: '800px',
        height: 'calc(100% - 75px)',
        data: { source: file._id }
      });

    } else {
      const dialogRef = this.dialog.open(ViewPDFComponent, {
        panelClass: 'type-edit-modalbox',
        width: '800px',
        height: 'calc(100% - 75px)',
        data: { source: file.fileId }
      });

    }
  }

  removeEvent(itemId: string) {
    let filteredFiles: any = this.async.transform(this.filteredFiles$);

    let deleteFileItem = filteredFiles.find(item => {
      return item._id === itemId;
    });

    let identifier = (typeof deleteFileItem.file === 'object' ? deleteFileItem.fileId : deleteFileItem._id)
9
    console.log(deleteFileItem)
    this.filesService.getManyWorkbookFilesByFileId(identifier).pipe(take(1)).subscribe((matches: any[]) => {

      const dialogRef = this.dialog.open(DeleteFileDialogComponent, {
        width: '328px',
        data: {
          inAWorkbook: matches && matches.length > 0,
          isBank: this.isBank,
          deletion: this.isBank ? true : false,
          title: this.isBank ? 'Delete File(s)' : 'Remove File(s) From Workbook',
          location: this.isBank ? 'your account' : 'the workbook',
        },
        panelClass: 'custom-confirm-dialog',
      });
      dialogRef.componentInstance.functions = {
        deleteContent: () => {
          // // this.remove.emit(itemId);
  
          if (this.isAccountFile) {
            // this.removeWorkbookFile.emit(itemId);
            let deleteFileItem = filteredFiles.find(item => {
              return item._id === itemId;
            });
            let deleteWorkbookFileItem = this.otherItems.find(item => {
              return item.fileId === itemId;
            });
  
            if (deleteFileItem) { this.store.dispatch(new DeleteFile(deleteFileItem._id)); }
            if (deleteWorkbookFileItem) { this.store.dispatch(new DeleteWorkbookFileSuccess(deleteWorkbookFileItem._id)); }
  
          } else {
            this.store.dispatch(new DeleteWorkbookFile({ itemId: itemId, collectionId: this.workbookId }));
          }
        }
  
      };
    });
  }

  removeManyEvent() {
    let filteredFiles: any = this.async.transform(this.filteredFiles$);
    let selectedFiles = filteredFiles.filter(file => file.selected);

    if (selectedFiles.length === 0) {
      // Nothing is selected, so do nothing
      return;
    }

    // Check through file service to see if the selected files are in multiple workbooks
    let fileIds = selectedFiles.map(file => (typeof file.file === 'object' ? file.fileId : file._id));
    this.filesService.getManyWorkbookFilesByFileIds(fileIds).pipe(take(1)).subscribe(matches => {

      let keys;
      let inAWorkbook = [];

      // Get the object keys and check if each value is an array that contains more than 1 value
      if(matches) {
        keys = Object.keys(matches);
        inAWorkbook = keys.filter(key => matches[key].length > 0);
      } 

      const dialogRef = this.dialog.open(DeleteManyFilesDialogComponent, {
        panelClass: 'custom-confirm-dialog',
        // height: '170px',
        width: '328px',
        data: {
          inAWorkbook: inAWorkbook.length > 0,
          selectedFiles: selectedFiles,
          isBank: this.isBank,
          deletion: this.isBank ? true : false,
          title: this.isBank ? 'Delete File(s)' : 'Remove File(s) From Workbook',
          location: this.isBank ? 'your account' : 'the workbook',
        }
      });
      dialogRef.componentInstance.functions = {
        deleteContent: () => {
          // this.remove.emit(itemId);

          if (this.isBank && this.isAccountFile) {
            let deleteFileIds = selectedFiles.map(file => file._id);
            let deleteWorkbookFileIds = this.otherItems?.filter(item => selectedFiles.find(item2 => item2._id === item.fileId)).map(item => item._id);

            if (deleteFileIds.length > 0) {
              this.store.dispatch(new DeleteManyFiles(deleteFileIds));
            }

            // if (deleteWorkbookFileIds.length > 0) {
            //   // this.store.dispatch(new DeleteManyWorkbookFileSuccess(deleteWorkbookFileIds));
            // }

            this.clearSelection();
          } else {
            let deleteFileIds = selectedFiles.map(file => file._id);

            if (deleteFileIds.length > 0) {
              this.store.dispatch(new DeleteManyWorkbookFile({ itemIds: deleteFileIds, collectionId: this.workbookId }));
              this.clearSelection();
            }
          }
        }
      };
    });
  }

  itemFilter(file: any) {
    if (!this.searchTerm || this.searchTerm === '') {
      return true;
    }

    return file.name.indexOf(this.searchTerm) >= 0;
  }
  selectFile(event: any) {
    this.fileNum = 0;
    const files: any = Array.from(event.target.files);
    this.combinedSizeTooLarge = (files.reduce((acc, file) => acc + file.size, 0)) > this.FILE_SIZE_LIMIT;
    this.wrongType = !files.every((file: any) => file.type === 'application/pdf');

    if (!this.combinedSizeTooLarge || !this.wrongType) {
      this.newFile = files;
      this.fileNum = files.length;
    }
    if (files.length > this.FILE_LIMIT) {
      this.tooMany = true;
    } else {
      this.tooMany = false;
    }

    // Find duplicate files
    const duplicates = this.otherFiles?.filter(item => files.find(item2 => (typeof item2.file === 'object' ? item2.file.name : item2.name) === (typeof item.file === 'object' ? item.file.name : item.name)));

    // const exist = !!this.otherFiles?.find(file => file?.name === files[0]?.name);

    // if (exist) {
    //   this.isDuplicateName = true;
    // } else {
    //   this.isDuplicateName = false;
    // }

    if (duplicates.length > 0) {
      this.isDuplicateName = true;
      this.duplicateCount = duplicates.length;
      this.duplicateNames = [...new Set(duplicates.map(d => typeof d === "object" ? d.file.name : d.name))].join(', ');
    } else {
      this.isDuplicateName = false;
      this.duplicateCount = 0;
      this.duplicateNames = '';
    }
  }

  // TODO: Make sure this can handle multiple files
  uploadFile() {
    let files = [];

    // if (this.newFile.length > 1 && this.isAccountFile) {
    //   this.tooMany = true;
    //   return;
    // } else 
    if (this.newFile.length > this.FILE_LIMIT) {
      this.tooMany = true;
      return;
    }

    if (this.fileNum === this.duplicateCount) {
      // All files are duplicates, so nothing new should be uploaded
      return;
    }

    if (this.inSelectionMode) {
      // If in selection mode, you can not upload files
      return;
    }

    if (this.newFile.length > 1) {
      this.tooMany = false;
      return new Observable(observer => {
        // this.newFile = this.newFile.map((newFile: any) => JSON.parse(JSON.stringify(newFile)))
        if (this.newFile.length <= this.FILE_LIMIT) {

          // Remove duplicates from files that will be uploaded
          this.newFile = this.newFile.filter(f => !this.duplicateNames.includes(f.name));
          this.fileNum = this.newFile.length;

          for (let i = 0; i < this.newFile.length; i++) {
            this.loadingFile = true;
            this.toBase64(this.newFile[i])
              .pipe(
                take(1),
                switchMap((fileBase64: any) => {
                  const file: any = {};
                  file.name = this.newFile[i].name;
                  file.file = fileBase64.split('base64,').pop();
                  if (i === (this.newFile.length - 1)) {
                    this.newFile = null;
                    this.fileSelect.nativeElement.value = null;
                    this.isDuplicateName = false;
                    this.duplicateCount = 0;
                    this.duplicateNames = '';
                  }
                  return new Observable(observer => observer.next(file));
                  // return this.filesService.createFile(file);
                })
              )
              .subscribe((file: any) => {
                //  if (!this.isAccountFile) {
                //  this.addToWorkbook.emit(file._id);
                //  this.addFile.emit(file);
                //  } else {
                //  this.files.push(file);
                //  }
                files.push(file);

                // Subtracts from the current file count after every file is added to the array
                this.fileNum = this.fileNum - 1;
              });

          }
          setTimeout(() => {
            observer.next(files);

          }, 1000);
        }
      })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((files: any) => {
          this.store.dispatch(new CreateManyFiles({
            files: files,
            workbookId: this.workbookId,
            isWorkbookFile: !this.isAccountFile
          }));
          setTimeout(() => {
            const uid = JSON.parse(localStorage.profile).user_metadata.uid;
            this.store.dispatch(new GetManyFiles(uid));
            this.store.dispatch(new GetManyWorkbookFiles(this.workbookId));

            // this.init();
          }, 1000);
          setTimeout(() => {
            // const uid = JSON.parse(localStorage.profile).user_metadata.uid;
            // this.store.dispatch(new GetManyFiles(uid));
            this.loadingFile = false;
            this.init();
          }, 1000);

        });

    } else if (this.newFile.length === 1) {
      this.tooMany = false;
      const file: any = {};

      // This should prevent the user from uploading a file with a duplicate name already in their account
      if (this.isDuplicateName) {
        return;
      }

      this.newFile.forEach((newFile, index, array) => {
        this.loadingFile = true;
        this.toBase64(newFile)
          .pipe(
            take(1),
            tap((fileBase64: any) => {
              file.name = this.newFile[index].name + `(${this.date.transform(this.time, 'medium')})`;
              file.file = fileBase64.split('base64,').pop();
              if (index === (this.newFile.length - 1)) {
                this.newFile = null;
                this.fileSelect.nativeElement.value = null;
                this.isDuplicateName = false;
                this.duplicateCount = 0;
                this.duplicateNames = '';
              }
              // return this.filesService.createFile(file);
              this.store.dispatch(new CreateFile({
                fileData: file,
                workbookId: this.workbookId,
                isWorkbookFile: !this.isAccountFile
              }));

            })
          )
          .subscribe((file: any) => {
            //  if (!this.isAccountFile) {
            //  this.addToWorkbook.emit(file._id);
            //  this.addFile.emit(file);
            //  } else {
            //  this.files.push(file);
            //  }
            this.fileNum = 0;

            setTimeout(() => {
              const uid = JSON.parse(localStorage.profile).user_metadata.uid;
              // this.store.dispatch(new GetManyFiles(uid));
              // this.store.dispatch(new GetManyWorkbookFiles(this.workbookId));

              // this.init();
            }, 1000);
            setTimeout(() => {
              // const uid = JSON.parse(localStorage.profile).user_metadata.uid;
              // this.store.dispatch(new GetManyFiles(uid));
              this.loadingFile = false;
              this.init();
            }, 1000);


          });

      });
    }
  }

  renameFileInput(index: number) {
    this.files[index]['renaming'] = true;
  }

  renameFile(index: number) {
    const filteredFiles = this.async.transform(this.filteredFiles$);
    const currFile = filteredFiles[index];
    const dialogRef = this.dialog.open(EditFileDialogComponent, {
      width: '450px',
      panelClass: 'type-edit-modalbox',
      data: {
        file: currFile
      }

    });

    dialogRef.componentInstance.functions = {
      editContent: (newName: string) => {


        // Find the file by id in this.files
        let fileIndex = this.files.findIndex(file => file._id === currFile._id);
        let file = this.files[fileIndex];

        if (file === undefined) {
          console.log("File not found to rename.")
          return;
        }

        this.files[fileIndex].name = newName;

        this.filesService.renameFile(file._id, newName).subscribe();
        let fileName = (typeof file.file === 'object') ? file.file.file : file.file;
        this.updateWorkbookFileName.emit({ fileName, name: newName });

        this.store.dispatch(new RenameFileSuccess({
          id: file._id,
          name: newName
        }));

        // this.files[index].name = newName;

        // this.store.dispatch(new RenameFile({
        //   id: file._id,
        //   name: newName
        // }));

        // // We have to do this because of how files changed over time
        // let fileName = (typeof this.files[index].file === 'object') ? this.files[index].file.file : this.files[index].file;

        // this.updateWorkbookFileName.emit({ fileName, name: newName });

      }
    };

    currFile['renaming'] = false;
    this.renameControl = '';
  }

  private toBase64(file: any) {
    return new Observable(observer => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => observer.next(reader.result);
      reader.onerror = error => observer.error(error);
    });
  }
  reorderFiles($event) {
    let moveFiles = (arr, fromIndex, toIndex) => {
      let element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
      return arr;
    };
    if ($event.container.id === $event.previousContainer.id) {
      // moveItemInArray($event.container.data, $event.previousIndex, $event.currentIndex);
      let files = moveFiles($event.container.data, $event.previousIndex, $event.currentIndex);
      files = JSON.parse(JSON.stringify(files));
      let requests = [];

      for (let i = 0; i < this.files.length; i++) {
        files[i]["sortOrder"] = i;
      }
      requests.push(this.filesService.updateWorkbookFile(this.workbookId, files));

      this.filteredFilesSubject.next(files);

      combineLatest(requests)
        .pipe(take(1))
        .subscribe();

    } else {
      transferArrayItem(
        $event.previousContainer.data,
        $event.container.data,
        $event.previousIndex,
        $event.currentIndex);
    }
  }
  sortBy(prop: string): any[] {
    let filteredFiles: any = this.async.transform(this.filteredFiles$);
    return filteredFiles.sort((a, b) => {
      if (a[prop] < b[prop]) {
        return -1;
      }

      if (a[prop] > b[prop]) {
        return 1;
      }

      return 0;
    });
  }
  checkCardMultiline() {
    this.contentCards._results.forEach(card => {
      if (card.nativeElement.offsetHeight > 64) {
        card.nativeElement.style.display = 'flex';
      } else {
        card.nativeElement.style.display = 'inline-block';
      }
    });
  }

  toggleSelection(file: any, event, index) {
    // console.log(file);
    // console.log(event);
    // console.log(index);

    let filteredFiles: any = this.async.transform(this.filteredFiles$);
    let selectedFile = filteredFiles[index];

    if (event.checked) {
      filteredFiles[index] = { ...selectedFile, selected: true };
    } else {
      filteredFiles[index] = { ...selectedFile, selected: false };
    }

    this.filteredFilesSubject.next(filteredFiles);
    this.selectedFilesCountChange.emit(filteredFiles.filter(file => file.selected).length);
  }

  selectAll() {
    let filteredFiles: any = this.async.transform(this.filteredFiles$);
    filteredFiles = filteredFiles.map(file => {
      return { ...file, selected: true };
    });

    this.filteredFilesSubject.next(filteredFiles);
    this.selectedFilesCountChange.emit(filteredFiles.length);
  }

  clearSelection() {
    let filteredFiles: any = this.async.transform(this.filteredFiles$);
    filteredFiles = filteredFiles.map(file => {
      return { ...file, selected: false };
    });

    this.filteredFilesSubject.next(filteredFiles);
    this.selectedFilesCountChange.emit(0);
  }

  get selectdFilesLength(): number {
    let filteredFiles: any = this.async.transform(this.filteredFiles$);
    return filteredFiles.filter(file => file.selected).length;
  }

  get isEverySelected(): boolean {
    let filteredFiles: any = this.async.transform(this.filteredFiles$);
    return filteredFiles.every(file => file.selected);
  }
}


@Component({
  selector: 'app-delete-file-dialog',
  templateUrl: './html/delete-file-dialog.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
    './files.scss'

  ],
  styles: [
    `:host {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }`
  ]
})

export class DeleteFileDialogComponent {
  functions;

  constructor(public dialogRef: MatDialogRef<DeleteFileDialogComponent>,
    private route: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
  save() {
    this.functions.deleteContent();
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
    // this.route.navigate([`/workbook-builder/${this.data.workbookId}`, {isFromLibrary: false, selectedTabIndex: 1}])

  }

}

@Component({
  selector: 'app-delete-many-files-dialog',
  templateUrl: './html/delete-many-files-dialog.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
    './files.scss',

  ],
  styles: [
    `:host {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }`
  ]
})

export class DeleteManyFilesDialogComponent {
  functions;

  constructor(public dialogRef: MatDialogRef<DeleteManyFilesDialogComponent>,
    private route: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
  deleteFiles() {
    this.functions.deleteContent();
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();

  }

}

@Component({
  selector: 'app-add-many-files-dialog',
  templateUrl: './html/add-many-files-dialog.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
    './files.scss',

  ],
  styles: [
    `:host {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }`
  ]
})

export class AddManyFilesDialogComponent {
  functions;

  constructor(public dialogRef: MatDialogRef<AddManyFilesDialogComponent>,
    private route: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
  save() {
    if (this.data.selectedFiles.length > 0) {
      this.functions.addToWorkbook();
    }
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();

  }

}

@Component({
  selector: 'app-duplicates-dialog',
  templateUrl: './html/duplicates-dialog.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
    './files.scss',

  ],
  styles: [
    `:host {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }`
  ]
})

export class DuplicatesDialogComponent {
  functions;

  constructor(public dialogRef: MatDialogRef<DuplicatesDialogComponent>,
    private route: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  close() {
    this.dialogRef.close();
  }

}


@Component({
  selector: 'app-edit-file-dialog',
  templateUrl: './html/rename-file-dialog.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
  ]
})

export class EditFileDialogComponent implements OnInit, OnDestroy {
  functions;
  public keys: any;
  public value: string;
  private unsubscribe$: Subject<void> = new Subject();
  private files;
  renameFileForm: UntypedFormGroup;
  constructor(public dialogRef: MatDialogRef<EditFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private formBuilder: UntypedFormBuilder
  ) {
    this.value = this.data.name;
    this.renameFileForm = this.formBuilder.group({
      name: [this.data.file.name, [Validators.required, (control: AbstractControl) => {
        const exist = !!this.files?.find(file => file.name === control.value && file._id !== this.data?.file._id);
        if (exist) {
          return { duplicate: true };
        }
        return false;
      }]]
    });
  }

  ngOnInit() {
    this.store.select(reducers.selectGetFiles)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(files => {
        this.files = files;
      });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  // edit(event: any) {
  //   this.value = event.target.value;
  // }

  save() {
    this.functions.editContent(this.renameFileForm.value.name);
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
}
