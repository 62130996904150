import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum SubscriptionActionTypes {
    GetSubscription = '[SubscriptionsService] Get Subscribed Course',
    GetSubscriptionSuccess = '[SubscriptionsService] Get Subscribed Course Success',
    GetSubscriptionFail = '[SubscriptionsService] Get Subscribed Course Fail',

    GetAllSubscribedCourses = '[SubscriptionsService] Get All Subscribed Courses',
    GetAllSubscribedCoursesSuccess = '[SubscriptionsService] Get All Subscribed Courses Success',
    GetAllSubscribedCoursesFail = '[SubscriptionsService] Get All Subscribed Courses Fail',

    ClearSubscriptions = '[SubscriptionsService] Clear Subscribed Courses',

    CreateSubscription = '[SubscriptionsService] Create Subscribed Course',
    CreateSubscriptionSuccess = '[SubscriptionsService] Create Subscribed Course Success',
    CreateSubscriptionFail = '[SubscriptionsService] Create Subscribed Course Fail',


    DeleteSubscription = '[SubscriptionsService] Delete Subscribed Course',
    DeleteSubscriptionSuccess = '[SubscriptionsService] Delete Subscribed Course Success',
    DeleteSubscriptionFail = '[SubscriptionsService] Delete Subscribed Course Fail',


}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class GetSubscription implements Action {
    readonly type = SubscriptionActionTypes.GetSubscription;

    constructor(public payload: any) {}
}

export class GetSubscriptionSuccess implements Action {
    readonly type = SubscriptionActionTypes.GetSubscriptionSuccess;

    constructor(public payload: any) {}
}

export class GetSubscriptionFail implements Action {
    readonly type = SubscriptionActionTypes.GetSubscriptionFail;

    constructor(public payload: any) {}
}

export class GetAllSubscribedCourses implements Action {
    readonly type = SubscriptionActionTypes.GetAllSubscribedCourses;

    constructor(public payload: any) {}
}

export class GetAllSubscribedCoursesSuccess implements Action {
    readonly type = SubscriptionActionTypes.GetAllSubscribedCoursesSuccess;

    constructor(public payload: any) {}
}

export class GetAllSubscribedCoursesFail implements Action {
    readonly type = SubscriptionActionTypes.GetAllSubscribedCoursesFail;

    constructor(public payload: any) {}
}


export class CreateSubscription implements Action {
    readonly type = SubscriptionActionTypes.CreateSubscription;

    constructor(public payload: any) {}
}
export class CreateSubscriptionSuccess implements Action {
    readonly type = SubscriptionActionTypes.CreateSubscriptionSuccess;

    constructor(public payload: any) {}
}
export class CreateSubscriptionFail implements Action {
    readonly type = SubscriptionActionTypes.CreateSubscriptionFail;

    constructor(public payload: any) {}
}

export class DeleteSubscription implements Action {
    readonly type = SubscriptionActionTypes.DeleteSubscription;

    constructor(public payload: any) {}
}
export class DeleteSubscriptionSuccess implements Action {
    readonly type = SubscriptionActionTypes.DeleteSubscriptionSuccess;

    constructor(public payload: any) {}
}
export class DeleteSubscriptionFail implements Action {
    readonly type = SubscriptionActionTypes.DeleteSubscriptionFail;

    constructor(public payload: any) {}
}

export class ClearSubscriptions implements Action {
    readonly type = SubscriptionActionTypes.ClearSubscriptions;
}


export type SubscriptionsActions
= GetSubscription
| GetSubscriptionSuccess
| GetSubscriptionFail
| GetAllSubscribedCourses
| GetAllSubscribedCoursesSuccess
| GetAllSubscribedCoursesFail
| ClearSubscriptions
| CreateSubscription
| CreateSubscriptionSuccess
| CreateSubscriptionFail
| DeleteSubscription
| DeleteSubscriptionSuccess
| DeleteSubscriptionFail;
