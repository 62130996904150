import * as CoursesActions from '../../store/courses/courses.actions';
import * as SubscriptionAction from '../../store/subscriptions/subscriptions.actions';
import { Store } from '@ngrx/store';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CourseService } from '../../services/courses.service';
import { take, takeUntil } from 'rxjs/operators';
import { ListenerService } from '../../services/listener.service';
import {SubscriptionService} from '../../services/subscription.service';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { selectGetAllCourses, selectGetAllSubscription } from '../../store';
import {combineLatest, Subject} from 'rxjs';

@Component({
  selector: 'app-add-course-dialog',
  templateUrl: './add-course-dialog.component.html',
  styleUrls: ['./add-course-dialog.component.scss']
})
export class AddCourseDialogComponent implements OnInit, OnDestroy {
  userId: string;
  functions;
  public formData = {
    courseName: '',
    teacherKey: JSON.parse(localStorage.getItem('profile')).user_metadata.uid,
    classDefinition: 'students',
    notes: '',
    courseType: 'Default/Practice Student',
    subscriptionCode: ''
  };
  // public maxlengthDes: boolean;
  // public maxlengthName: boolean;
  public alreadyExist: boolean;
  allSubscribedCourses: any[];
  private unsubscribe$: Subject<void> = new Subject();

  // addCourseForm = new FormGroup({
  //     name: new FormControl('', [Validators.maxLength(40)]),
  //     description: new FormControl('', [Validators.maxLength(160)]),
  //     subscription: new FormControl('')
  // });
  addCourseForm: UntypedFormGroup;

  duplicateMessage = '';

  private courses: any;

  constructor(public dialogRef: MatDialogRef<AddCourseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _courseService: CourseService,
    private listnerService: ListenerService, private _subscriptionService: SubscriptionService,
    private store: Store, private formBuilder: UntypedFormBuilder) {
      this.addCourseForm = this.formBuilder.group({
        form1: this.formBuilder.group({
          courseName: ['', [Validators.maxLength(40), Validators.required, (control: AbstractControl) => {
            const exist = !!this.courses?.find(({courseName}) => courseName === control.value);
            if (exist) {
              return { duplicate: true };
            }
            return false;
          }
          ]],
          teacherKey: JSON.parse(localStorage.getItem('profile')).user_metadata.uid,
          classDefinition: 'students',
          notes: ['', [Validators.maxLength(160)]],
          courseType: 'Default/Practice Student',
        }),
        form2: this.formBuilder.group({
          subscriptionCode: ['', [Validators.required, (control: AbstractControl) => {
            const exist = !!this.allSubscribedCourses?.find(({_id}) => _id === control.value);
            if (exist) {
              return { duplicate: true };
            }
            return false;
          }]]
        })
      });
    }

  ngOnInit() {
    this.userId = JSON.parse(localStorage.profile).user_metadata.uid;
    combineLatest([this.store.select(selectGetAllCourses), this.store.select(selectGetAllSubscription)])
    .pipe(take(1))
    .subscribe(([workbooks, courseWorkbooks]) => {
      this.allSubscribedCourses = [...(workbooks || []), ...(courseWorkbooks || [])];
    });

    this.store.select(selectGetAllCourses)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(courses => this.courses = courses);

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  // onkeyName(event: any): void {
  //   const name = this.addCourseForm.get('name');
  //   this.maxlengthName = name.status === "INVALID"  ? true : false;
  //   this.formData.courseName = name.value;
  // }

  // onkeyDes(event: any): void {
  //   const name = this.addCourseForm.get('description');
  //   this.maxlengthDes = name.status === "INVALID"  ? true : false;
  //   this.formData.notes = name.value;
  // }

  isExistingSubId(subId: String): boolean {
    return !!this.allSubscribedCourses.find(({_id}) => _id === subId);
  }

  // onkeySub() {
  //   // const field = this.addCourseForm.get('subscription');
  //   // this.formData.subscriptionCode = field.value;

  //   // const subId = field.value;
  //   const subId = this.addCourseForm.controls.form2.get('subscriptionCode').value;
  //   this.alreadyExist = this.isExistingSubId(subId);
  // }

  onSave() {
    // if (this.formData.courseName.length === 0) {
    //   this.formData.courseName = 'Untitled Course';
    // }
    // if (this.formData.subscriptionCode && this.formData.subscriptionCode !== '') {
    //   this.store.dispatch(new SubscriptionAction.CreateSubscription(
    //     {
    //       userId: this.userId,
    //       courseId: this.formData.subscriptionCode
    //     }
    //   ));
    //   setTimeout(() => {
    //     this.functions.reload();
    //   }, 500);
    // } else {
    //   delete this.formData.subscriptionCode;
    //   this.store.dispatch(new CoursesActions.CreateCourse(this.formData));
    //   this.dialogRef.close('course added');
    // }
    const form1 = this.addCourseForm.controls.form1;
    const form2 = this.addCourseForm.controls.form2;
    if (form2.valid) {
      this.store.dispatch(new SubscriptionAction.CreateSubscription(
        {
          userId: this.userId,
          courseId: form2.value.subscriptionCode
        }
      ));
    } else if (form1.valid) {
      this.store.dispatch(new CoursesActions.CreateCourse(form1.value));
      this.dialogRef.close('course added');
    }
  }
}
