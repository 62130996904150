import { Component, OnInit } from '@angular/core';
import { LoginService } from '../core/services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from './../core/services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './html/login.html',
  styleUrls: [
    '../assets/css/main.css',
    '../assets/scss/fontawesome.scss',
    '../assets/scss/brands.scss',
    '../assets/scss/regular.scss',
    '../assets/scss/solid.scss'
  ]
})
export class LoginComponent implements OnInit {
  constructor(
    private _log: LoginService,
    public dialog: MatDialog,
    public _auth: AuthService,
    private router: Router
  ) {
 }

  ngOnInit() {
    this.login()  ;
  }
  login() {
    const chromeVersion = this.getChromeVersion();
    if (chromeVersion && chromeVersion < 99) {
      // show discontinued modal
      const dialogRef = this.dialog.open(DiscontinuedModalComponent);
    } else {
      this._auth.login();
    }
  }
  // openDialog(): void {
  //   const dialogRef = this.dialog.open(LoginFormDialog);
  //   dialogRef.afterClosed().subscribe(result => {
  //   })
  // }

  getChromeVersion () {
    let raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    return raw ? parseInt(raw[2], 10) : false;
  }

  // create a function that navigates to the #/registration route
  register() {
    const chromeVersion = this.getChromeVersion();
    if (chromeVersion && chromeVersion < 99) {
      // show discontinued modal
      const dialogRef = this.dialog.open(DiscontinuedModalComponent);
    } else {
      this.router.navigate(['/registration']);
    }
  }

}

@Component({
  selector: 'app-login-form',
  templateUrl: './html/login-form.html'
})
export class LoginFormDialogComponent {
  constructor() {}
}

@Component({
  selector: 'app-discontinued-modal',
  templateUrl: './html/app-warning.html'
})
export class DiscontinuedModalComponent {
  constructor() {

  }


}
