// import { HomeComponent } from './components/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountComponent } from '../Settings/Account/account.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LoginComponent } from '../Login/login.component';
import { SettingsResolver } from '../core/resolvers/settings.resolver';
import { AllMyLibraryWorkbooksResolver } from '../core/resolvers/all-my-library-workbooks.resolver';
import { AllSubscribedCoursesResolver } from '../core/resolvers/all-subscribed-courses.resolver';
import { AllCoursesResolver } from '../core/resolvers/all-courses.resolver';
import { PlanResolver } from '../core/resolvers/plan.resolver';
import { AuthGuard } from '../core/services/auth-guard.service';
const routes: Routes = [
  { 
    path: '', 
    redirectTo: 'my-curriculum', 
    pathMatch: 'full',
    resolve: {
      planLoaded: PlanResolver 
    }
    // loadChildren: () => import('../Curriculum/curriculum.module').then(m => m.CurriculumModule),
  },

  { 
    path: 'account', 
    canActivate: [AuthGuard],
    loadChildren: () => import('../Settings/Account/account.module').then(m => m.AccountModule),
    // component: AccountComponent 
    resolve: {
      allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
      allCoursesLoaded: AllCoursesResolver,
    }
  },
  { 
    path: 'sharing', 
    canActivate: [AuthGuard],
    loadChildren: () => import('../sharing/sharing.module').then(m => m.SharingModule),
    // component: AccountComponent 
    resolve: {
      allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
      allCoursesLoaded: AllCoursesResolver,
    }
  },
  { 
    path: 'tutorials', 
    canActivate: [AuthGuard],
    loadChildren: () => import('../tutorials/tutorials.module').then(m => m.TutorialsModule),
    // component: AccountComponent 
    resolve: {
      allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
      allCoursesLoaded: AllCoursesResolver,
    }
  },
  { 
    path: 'activity', 
    canActivate: [AuthGuard],
    loadChildren: () => import('../activities/activity.module').then(m => m.ActivityModule),
    resolve: {
      allSettingsLoaded: SettingsResolver,
      allMyLibraryWorkbooksLoaded: AllMyLibraryWorkbooksResolver,
      allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
      allCoursesLoaded: AllCoursesResolver,
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
