import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_DIR } from '../../environments/environment';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()

export class WordsService {
  workbooks: any[] = [];

  constructor(private http: HttpClient) {}

  post(query: any) {
    return  new Observable(observer => {
      const url = `${API_DIR}/word/customer/${JSON.parse(localStorage.profile).user_metadata.cusID}`;
      this.http
        .post(url, query)
        .pipe(
          take(1)
        )
        .subscribe(
          (words: any) => observer.next(words.data),
          error => {
            observer.error(error);
          });
    });
  }


  getTilesByWordIds(userId: string, wordIds: any[]) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/word/tiles/wordIds/${userId}`, wordIds)
        .pipe(
          take(1)
        )
        .subscribe(
          (words: any) => observer.next(words.data),
          error => {
            throw error;
          });
    });
  }

  getWordsByWordIds(wordIds: any[]) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/word/wordIds`, wordIds)
        .pipe(
          take(1)
        )
        .subscribe(
          (words: any) => observer.next(words.data),
          error => {
            throw error;
          });
    });
  }

  getPairings() {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/word/sound-letter-pairings`)
        .pipe(
          take(1)
        )
        .subscribe(
          (pairings: any) => observer.next(pairings.data),
          error => {
            throw error;
          });
    });
  }
}
