import { reducers } from '../store/index';
import * as CourseWorkbookActions from '../store/course-workbook/course-workbook.actions';
import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import * as selectors from "../store";

@Injectable()
export class CourseWorkbookResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const courseWorkbookId = route.params.courseWorkbookId;
    return this.store.pipe(
      select(selectors.isCourseWorkbookLoaded, { id: courseWorkbookId }),
      tap(isLoaded => {
        if (!isLoaded) {
          this.store.dispatch(new CourseWorkbookActions.GetCourseWorkbook(courseWorkbookId));
          this.store.dispatch(new CourseWorkbookActions.GetCourseWorkbook(courseWorkbookId));
        }
      }),
      filter(isLoaded => !!isLoaded),
      first(),
    );
  }
}
