import { ActivationStart } from '@angular/router';
import * as PassageActions from './passage.actions';

export interface State {
    pending: boolean;
    error: any;
    succeeded: boolean;
    Passages: any;
    workbookPassages?: any;
    workbookId: any;
}

const initialState: State = {
    pending: false,
    error: null,
    succeeded: false,
    Passages: null,
    workbookPassages: null,
    workbookId: null
};

export function reducer(state = initialState, action: PassageActions.PassageActions): State {
    switch (action.type) {
        case PassageActions.PassageActionTypes.GetManyPassages: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case PassageActions.PassageActionTypes.GetManyPassagesSuccess: {
            return {
                ...state,
                Passages: action.payload,
                error: null
            };
        }
        case PassageActions.PassageActionTypes.GetManyPassagesFail: {
            return {
                ...state,
                Passages: null,
                error: action.payload
            };
        }
        case PassageActions.PassageActionTypes.GetManyWorkbookPassages: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case PassageActions.PassageActionTypes.ClearWorkbookPassages: {
            return {
                ...state,
                workbookPassages: [],
                error: null
            };
        }

        case PassageActions.PassageActionTypes.GetManyWorkbookPassagesSuccess: {
            return {
                ...state,
                workbookPassages: action.payload.data,
                workbookId: action.payload.id,
                error: null
            };
        }
        case PassageActions.PassageActionTypes.GetManyWorkbookPassagesFail: {
            return {
                ...state,
                workbookPassages: null,
                error: action.payload
            };
        }


        case PassageActions.PassageActionTypes.CreatePassage: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case PassageActions.PassageActionTypes.CreatePassageSuccess: {
            return {
                ...state,
                Passages: [...state.Passages, ...[action.payload]],
                error: null
            };
        }

        case PassageActions.PassageActionTypes.CreateWorkbookPassage: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case PassageActions.PassageActionTypes.CreateWorkbookPassageSuccess: {
            return {
                ...state,
                workbookPassages: [action.payload],
                error: null
            };
        }
        case PassageActions.PassageActionTypes.UpdateWorkbookPassageSuccess: {
            let passages = JSON.parse(JSON.stringify(action.payload));
            let newWorkbookPassages = JSON.parse(JSON.stringify(state.workbookPassages));
            newWorkbookPassages[0].passages = passages;
            return {
                ...state,
                workbookPassages: newWorkbookPassages,
                error: null
            };
        }


        case PassageActions.PassageActionTypes.DeleteWorkbookPassage: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }

        case PassageActions.PassageActionTypes.DeleteWorkbookPassageSuccess: {
            let workbookPassages = JSON.parse(JSON.stringify(state.workbookPassages));
            workbookPassages[0].passages = workbookPassages[0].passages.filter((passage) => passage._id !== action.payload.itemId);
            workbookPassages[0].numPassage = workbookPassages[0].passages.length;
            return {
                ...state,
                workbookPassages,
                error: null
            };
        }

        case PassageActions.PassageActionTypes.DeletePassage: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }

        case PassageActions.PassageActionTypes.DeletePassageSuccess: {
            let Passages = [...state.Passages];
            let workbookPassages = [...state.workbookPassages];
            const index1 = Passages.findIndex(passage => passage._id === action.payload);
            const index2 = workbookPassages.findIndex(workbookPassaage => workbookPassaage.passageId === action.payload);
            if (index1 > -1) {
                Passages.splice(index1, 1);
            }
            if (index2 > -1) {
                workbookPassages.splice(index2, 1);
            }

            return {
                ...state,
                Passages,
                workbookPassages,
                error: null
            };
        }

        case PassageActions.PassageActionTypes.UpdatePassageSuccess: {
            let Passages = state.Passages?.map(passage => {
                if (passage._id === action.payload.id) {
                    return {
                        ...passage,
                        passage: action.payload.Passage
                    };
                }
                return passage;
            });
            const workbookPassages = JSON.parse(JSON.stringify(state.workbookPassages));

            workbookPassages[0].passages.map((passage) => {
                if (passage._id === action.payload.id) {
                    passage.passage = action.payload.Passage;
                }
            });
            return {
                ...state,
                Passages,
                workbookPassages,
                error: null
            };
        }
        default:
            return state;
    }
}

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.succeeded;
export const getError = (state: State) => state.error;
export const getPassages = (state: State) => {
    if (state.Passages) {
        const passages = JSON.parse(JSON.stringify(state.Passages));
        return passages.sort((a, b) => {
                return a.passage.localeCompare(b.passage);
        });
    }
};

export const getWorkbookPassages = (state: State) => state.workbookPassages;

