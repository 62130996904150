import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_DIR} from '../../environments/environment';
import {take} from 'rxjs/operators';

@Injectable()

export class LessonPlansService {

  constructor(private http: HttpClient) {

  }

  get(lessonPlanId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/lessonPlan/${lessonPlanId}`)
        .pipe(
          take(1)
        )
        .subscribe(
          (lessonPlan: any) => {
            observer.next(lessonPlan.data);
          },
          error => {
            throw error;
          });
    });
  }

  getFirstStep(lessonPlanId: any) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/lessonPlan/firstStep/${lessonPlanId}`)
        .pipe(
          take(1)
        )
        .subscribe(
          (firstLessonPlanStepId: any) => {
            observer.next(firstLessonPlanStepId.data);
          },
          error => {
            throw error;
          });
    });
  }

  getAll(courseId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/lessonPlan/course/${courseId}`)
        .pipe(
          take(1)
        )
        .subscribe(
          (lessonPlans: any) => {
            observer.next(lessonPlans.data);
          },
          error => {
            throw error;
          });
    });
  }

  getAllWithSteps(courseId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/lessonPlan/course/steps/${courseId}`)
        .pipe(
          take(1)
        )
        .subscribe(
          (lessonPlans: any) => {
            observer.next(lessonPlans.data);
          },
          error => {
            throw error;
          });
    });
  }

  create(lessonPlan: any) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/lessonPlan`, lessonPlan)
        .pipe(
          take(1)
        )
        .subscribe(
          (newLessonPlan: any) => {
            observer.next(newLessonPlan.data);
          },
          error => {
            throw error;
          });
    });
  }

  update(id: string, lessonPlan: any) {
    return this.http.put(`${API_DIR}/lessonPlan/${id}`, lessonPlan);
  }

  delete(lessonPlanId: string) {
    return this.http.delete(`${API_DIR}/lessonPlan/${lessonPlanId}`);
  }

    copy(lessonPlanId: string) {
    return new Observable(observer => {
      return this.http
        .post(`${API_DIR}/lessonPlan/copy/${lessonPlanId}`, {})
        .pipe(
          take(1)
        )
        .subscribe(
          (newLessonPlan: any) => {
            observer.next(newLessonPlan.data);
          },
          error => {
            throw error;
          });
    });
  }

}
