import { Action } from "@ngrx/store";

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */
export enum FeedbackActionTypes {
    createFeedback = "[Feedback] Create Feedback",
    createFeedbackSuccess = "[Feedback] Create Feedback Success",
    createFeedbackFailure = "[Feedback] Create Feedback Failure"
}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class CreateFeedback implements Action {
    readonly type = FeedbackActionTypes.createFeedback;

    constructor(public payload: any) { }
}

export class CreateFeedbackSuccess implements Action {
    readonly type = FeedbackActionTypes.createFeedbackSuccess;

    constructor(public payload: any) { }
}

export class CreateFeedbackFailure implements Action {
    readonly type = FeedbackActionTypes.createFeedbackFailure;

    constructor(public payload: any) { }
}

export type FeedbackActions 
= CreateFeedback 
| CreateFeedbackSuccess 
| CreateFeedbackFailure;