// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container button {
  margin: 2px;
  border-radius: 15px;
  background-color: #866dff;
  width: 20vw;
}

.exit-button {
  display: flex;
  justify-content: flex-end;
}

.exit-button span {
  cursor: pointer;
  color: white;
}

::ng-deep .mat-bottom-sheet-container {
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/core/components/workbooks/workbook-builder/word-bottom-sheet/word-bottom-sheet.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AACA;EAEE,WAAA;EACA,mBAAA;EACA,yBAAA;EAEA,WAAA;AAAF;;AAEA;EACE,aAAA;EACA,yBAAA;AACF;;AACA;EACE,eAAA;EACA,YAAA;AAEF;;AACE;EACE,gBAAA;AAEJ","sourcesContent":[".container{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.container button{\n\n  margin: 2px;\n  border-radius: 15px;\n  background-color: #866dff;\n  // border: 3px solid black;\n  width: 20vw;\n}\n.exit-button{\n  display: flex;\n  justify-content: flex-end;\n}\n.exit-button span{\n  cursor: pointer;\n  color: white;\n}\n::ng-deep {\n  .mat-bottom-sheet-container{\n    overflow: hidden;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
