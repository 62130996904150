import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-word-adjust-filter',
  templateUrl: './html/word-filter-adjust.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    './word-filter.scss',
    '../workbook-builder.scss'

  ]
})

export class WordFilterAdjustComponent implements OnInit, OnDestroy {
  @Input() filter: any;
  @Output() updateQuery: EventEmitter<any> = new EventEmitter<any>();

  public faArrowUp = faArrowCircleUp;
  public faArrowDown = faArrowCircleDown;
  myCheckbox: UntypedFormControl = new UntypedFormControl();
  private subscriptions = new Subscription();

  ngOnInit() {
    // this.filter = {...{}, ...this.filter}
    if (!this.filter.currentVal) {
      this.filter["currentVal"] = {lte: 4, gte: 1};
    }
    this.queryUpdateEvent(this);

    this.subscriptions.add(
      this.myCheckbox.valueChanges.subscribe(value => {
      })
    );
  }

  ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
  }

  numUp(num) {
    const opposingNumberPredicate = num === 'gte'
      ? this.filter.currentVal[num] + 1 <= this.filter.currentVal.lte
      : this.filter.currentVal[num] + 1 >= this.filter.currentVal.gte;

    if (this.filter.currentVal[num] < this.filter.maxNum && opposingNumberPredicate) {
      this.filter.currentVal[num]++;
      this.queryUpdateEvent(this);
    }
  }

  numDown(num) {
    const opposingNumberPredicate = num === 'gte'
      ? this.filter.currentVal[num] - 1 <= this.filter.currentVal.lte
      : this.filter.currentVal[num] - 1 >= this.filter.currentVal.gte;

    if (this.filter.currentVal[num] > 1 && opposingNumberPredicate) {
      this.filter.currentVal[num]--;
      this.queryUpdateEvent(this);
    }
  }

  queryUpdateEvent(context) {
    if (context) {
      context.updateQuery.emit({
        [context.filter.name]: {gte: context.filter.currentVal.gte, lte: context.filter.currentVal.lte}
      });
    }
  }

  changeLte(event: any) {
    let obj = {
      lte: JSON.parse(JSON.stringify(this.filter.currentVal.lte)), 
      gte: JSON.parse(JSON.stringify(this.filter.currentVal.gte))
    };
    if (event.target.value >= this.filter.currentVal.gte) {
      obj.lte = parseInt(event.target.value, 10);
    } else {
      obj.lte = parseInt(event.target.value, 10);
      obj.gte = parseInt(event.target.value, 10);
    }
    this.filter.currentVal = obj;
    this.queryUpdateEvent(this);

  }
  changeGte(event: any) {
    // this.filter.currentVal = Object.assign({}, this.filter.currentValue)
    // this.filter.currentVal.gte = JSON.parse(JSON.stringify(this.filter.currentVal.gte))
    let obj = {
      lte: JSON.parse(JSON.stringify(this.filter.currentVal.lte)), 
      gte: JSON.parse(JSON.stringify(this.filter.currentVal.gte))
    };
    if (event.target.value <= this.filter.currentVal.lte) {
      obj.gte = parseInt(event.target.value, 10);
    } else {
      obj.lte = parseInt(event.target.value, 10);
      obj.gte = parseInt(event.target.value, 10);
    }
    this.filter.currentVal = obj;
    this.queryUpdateEvent(this);

  }
}
