import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import { GetWhizzimoLibraryWorkbooks } from "../store/workbook/workbook.actions";
import { selectGetMyWhizzimoWorkbooks } from "../store";

@Injectable()
export class LibraryWorkbooksResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(selectGetMyWhizzimoWorkbooks),
      tap(areLoaded => {
        if (!areLoaded) {
          this.store.dispatch(new GetWhizzimoLibraryWorkbooks(''));
        }
      }),
      filter(areLoaded => areLoaded),
      first(),
    );
  }
}
