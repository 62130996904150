import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import './polyfills';
import { AppModule } from './app/app.module';
import { AppConfig, isAlt } from './environments/environment';
import "hammerjs";

if (AppConfig.production) {
  enableProdMode();
  if (window) {
    window.console.log = function() {};
    window.console.warn = function() {};
    window.console.error = function() {};
    window.console.time = function() {};
    window.console.timeEnd = function() {};
  }
}

// window.addEventListener('touchmove', ev => {
//   ev.preventDefault();
//   ev.stopImmediatePropagation();
// }, { passive: false });


platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    preserveWhitespaces: false
  });
  // .catch(err => console.error(err));
