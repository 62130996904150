import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-word-radio-filter',
  templateUrl: './html/word-filter-radio.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    './word-filter.scss',
    '../workbook-builder.scss'

  ]
})

export class WordFilterRadioComponent implements OnInit {
  @Input() filter: any;
  @Output() updateQuery: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    this.updateQueryEvent({value: this.filter.currentVal});
  }

  updateQueryEvent(event: any) {
    this.updateQuery.emit({[this.filter.name]: event.value});
  }
}
