import * as PhraseActions from './phrase.actions';

export interface State {
    pending: boolean;
    error: any;
    succeeded: boolean;
    Phrases: any;
    workbookPhrases?: any;
    workbookId: any;
}

const initialState: State = {
    pending: false,
    error: null,
    succeeded: false,
    Phrases: null,
    workbookPhrases: null,
    workbookId: null
};

export function reducer(state = initialState, action: PhraseActions.PhraseActions): State {
    switch (action.type) {
        case PhraseActions.PhraseActionTypes.GetManyPhrases: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case PhraseActions.PhraseActionTypes.GetManyPhrasesSuccess: {
            return {
                ...state,
                Phrases: action.payload,
                error: null
            };
        }
        case PhraseActions.PhraseActionTypes.GetManyPhrasesFail: {
            return {
                ...state,
                Phrases: null,
                error: action.payload
            };
        }

        case PhraseActions.PhraseActionTypes.ClearWorkbookPhrases: {
            return {
                ...state,
                pending: true,
                workbookPhrases: [],
                error: null
            };
        }


        case PhraseActions.PhraseActionTypes.GetManyWorkbookPhrases: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case PhraseActions.PhraseActionTypes.GetManyWorkbookPhrasesSuccess: {
            return {
                ...state,
                workbookPhrases: action.payload.data,
                workbookId: action.payload.id,
                error: null
            };
        }
        case PhraseActions.PhraseActionTypes.UpdateWorkbookPhraseSuccess: {
            let phrases = JSON.parse(JSON.stringify(action.payload));
            let newWorkbookPhrases = JSON.parse(JSON.stringify(state.workbookPhrases));
            newWorkbookPhrases[0].phrases = phrases;
            return {
                ...state,
                workbookPhrases: newWorkbookPhrases,
                error: null
            };
        }

        case PhraseActions.PhraseActionTypes.GetManyWorkbookPhrasesFail: {
            return {
                ...state,
                workbookPhrases: null,
                error: action.payload
            };
        }

        case PhraseActions.PhraseActionTypes.CreatePhrase: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case PhraseActions.PhraseActionTypes.CreatePhraseSuccess: {
            return {
                ...state,
                Phrases: [...state.Phrases, ...[action.payload]],
                error: null
            };
        }

        case PhraseActions.PhraseActionTypes.CreateWorkbookPhrase: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case PhraseActions.PhraseActionTypes.CreateWorkbookPhraseSuccess: {
            return {
                ...state,
                workbookPhrases: [...action.payload],
                error: null
            };
        }

        case PhraseActions.PhraseActionTypes.DeleteWorkbookPhrase: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }

        case PhraseActions.PhraseActionTypes.DeleteWorkbookPhraseSuccess: {
            let workbookPhrases = JSON.parse(JSON.stringify(state.workbookPhrases));

            workbookPhrases[0].phrases = workbookPhrases[0].phrases.filter((phrase) => phrase._id !== action.payload.itemId);
            workbookPhrases[0].numPhrase = workbookPhrases[0].phrases.length;

            return {
                ...state,
                workbookPhrases,
                error: null
            };
        }

        case PhraseActions.PhraseActionTypes.DeletePhrase: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }

        case PhraseActions.PhraseActionTypes.DeletePhraseSuccess: {
            let Phrases = [...state.Phrases];
            let workbookPhrases = [...state.workbookPhrases];
            const index1 = Phrases.findIndex(phrase => phrase._id === action.payload);
            const index2 = workbookPhrases.findIndex(workbookPhrase => workbookPhrase.phraseId === action.payload);

            if (index1 > -1) {
                Phrases.splice(index1, 1);
            }
            if (index2 > -1) {
                workbookPhrases.splice(index2, 1);
            }
            return {
                ...state,
                Phrases,
                workbookPhrases,
                error: null
            };
        }

        case PhraseActions.PhraseActionTypes.UpdatePhrase: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case PhraseActions.PhraseActionTypes.UpdatePhraseSuccess: {
            let Phrases = state.Phrases?.map(phrase => {
                if (phrase._id === action.payload.id) {
                    return {
                        ...phrase,
                        phrase: action.payload.Phrase
                    };
                }
                return phrase;
            });
            const workbookPhrases = JSON.parse(JSON.stringify(state.workbookPhrases));

            workbookPhrases[0].phrases.map((phrase) => {
                if (phrase._id === action.payload.id) {
                    phrase.phrase = action.payload.Phrase;
                }
            });

            return {
                ...state,
                Phrases,
                workbookPhrases,
                error: null
            };
        }
        

        default:
            return state;
    }
}

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.succeeded;
export const getError = (state: State) => state.error;
export const getPhrases = (state: State) => {
    if (state.Phrases) {
        const phrases = JSON.parse(JSON.stringify(state.Phrases));
        return phrases.sort((a, b) => {
                return a.phrase.localeCompare(b.phrase);
        });
    }
   
};
export const getWorkbookPhrases = (state: State) => state.workbookPhrases;

