// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

:host ::ng-deep app-course-workbook-edit:first-child > mat-card {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.dark-theme :host ::ng-deep app-course-workbook-edit:first-child > mat-card {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
:host ::ng-deep .whizzimo-mat-checkbox {
  margin-right: 24px;
}`, "",{"version":3,"sources":["webpack://./src/LessonPlans/Edit-Lesson-Plan/edit-lesson-plan.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AACF;;AAGE;EACE,2BAAA;EACA,4BAAA;AAAJ;AACI;EACE,2BAAA;EACA,4BAAA;AACN;AAEE;EACE,kBAAA;AAAJ","sourcesContent":[".loader {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n}\n\n:host ::ng-deep {\n  app-course-workbook-edit:first-child > mat-card {\n    border-top-left-radius: 8px;\n    border-top-right-radius: 8px;\n    .dark-theme & {\n      border-top-left-radius: 8px;\n      border-top-right-radius: 8px;\n    }\n  }\n  .whizzimo-mat-checkbox {\n    margin-right: 24px;\n  }\n  .mat-button {\n    // margin-right: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
