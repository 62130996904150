import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { CoursesComponent } from './courses.component';
import { CourseService } from '../core/services/courses.service';
import { CourseWorkbooksService } from '../core/services/course-workbooks.service';
import { CoursesRoutingModule } from './courses-routing.module';
import { WorkbooksService } from '../core/services/workbooks.service';
import {CourseListComponent} from './course-list.component';
import {SubscriptionService} from '../core/services/subscription.service';

@NgModule({
    declarations: [CoursesComponent, CourseListComponent],
    imports: [
        CoreModule,
        CoursesRoutingModule,
    ],
    providers: [CourseService, CourseWorkbooksService, WorkbooksService, SubscriptionService]
})

export class CoursesModule {}
