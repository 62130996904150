import * as settingsActions from './settings.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface State extends EntityState<any> {
    selectedId: string;
    id: string;
    pending: boolean;
    error: any;
    succeeded: boolean;
    all: boolean;
    allSettings: any;
}

export const adapter: EntityAdapter<string> = createEntityAdapter({
    selectId: (setting: any) => setting._id
});
const initialState: State = adapter.getInitialState({
    selectedId: null,
    id: null,
    pending: false,
    error: null,
    succeeded: false,
    all: false,
    allSettings: []
});


export function reducer(state = initialState, action: settingsActions.SettingsActions): State {
    switch (action.type) {
        case settingsActions.SettingsActionTypes.CreateSettingSuccess: {
            return {
                ...state,
                pending: false,
                succeeded: true,
                error: null,
                allSettings: [...state.allSettings, action.payload]
            };
        }

        case settingsActions.SettingsActionTypes.UpdateSetting: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };

        }

        case settingsActions.SettingsActionTypes.UpdateSettingSuccess: {
            const updated = adapter.updateOne({id: action.payload._id, changes: action.payload}, state);
            return {
                ...state,
                pending: false,
                succeeded: true,
                error: null,
                allSettings: state.allSettings.map((setting) => {
                    if (setting._id === action.payload._id) {
                        return {...setting, ...action.payload};
                    }
                    return setting;
                })
            };

        }

        case settingsActions.SettingsActionTypes.SetCurrentSetting: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
                selectedId: null
            };
        }

        case settingsActions.SettingsActionTypes.SetCurrentSettingSuccess: {
            let entities = JSON.parse(JSON.stringify(state.entities));
            for (let key in entities) {
                if (entities.hasOwnProperty(key)) {
                    entities[key].isCurrent = false;
                    if (key === action.payload._id) {
                        entities[key].isCurrent = true;
                    }
                }
            }
            localStorage.setItem("userSettings", JSON.stringify({...action.payload, isCurrent: true}));

            return {
                ...state,
                pending: false,
                succeeded: true,
                error: null,
                selectedId: action.payload._id
            };

        }

        case settingsActions.SettingsActionTypes.DeleteSetting: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
                all: state.all
            };
        }
        case settingsActions.SettingsActionTypes.DeleteSettingSuccess: {
            const removed = adapter.removeOne(action.payload, state);
            let entities = JSON.parse(JSON.stringify(removed.entities));
            for (let key in entities) {
                if (entities.hasOwnProperty(key)) {
                    entities[key].isCurrent = false;
                    if (key === action.payload._id) {
                        entities[key].isCurrent = true;
                    }
                }
            }

            return {
                ...state,
                entities: entities,
                pending: false,
                succeeded: true,
                error: null,
                allSettings: state.allSettings.filter(({_id}) => _id !== action.payload),
                selectedId: state.selectedId === action.payload ? state.allSettings[0]._id : state.selectedId
            };
        }

        case settingsActions.SettingsActionTypes.DeleteSettingFail: {
            return {
                ...state,
                pending: false,
                succeeded: false,
                error: action.payload,
            };
        }
        case settingsActions.SettingsActionTypes.GetAllSettings: {
            return {
                ...state,
                // selectedId: action.payload,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case settingsActions.SettingsActionTypes.GetAllSettingsSuccess: {
            // const courses = action.payload.slice();
            // courses.sort((a, b) => a.order !== b.order ? a.order < b.order ? -1 : 1 : 0);
            // const currentSetting = action.payload.find(setting => setting.isCurrent) || action.payload[0];

            return {
                ...adapter.addMany(action.payload, state),
                succeeded: true,
                pending: false,
                all: true,
                error: null,
                allSettings: action.payload
            };
        }
        case settingsActions.SettingsActionTypes.GetAllSettingsFail: {
            return {
                ...state,
                succeeded: false,
                pending: false,
                selectedId: null,
                error: action.payload
            };
        }
        // case settingsActions.SettingsActionTypes.ClearCourses: {
        //     return {
        //         ...adapter.removeAll(state),
        //         succeeded: false,
        //         pending: false,
        //         error: null
        //     }
        // }
        case settingsActions.SettingsActionTypes.GetSetting: {
            return {
                ...state,
                id: action.payload,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case settingsActions.SettingsActionTypes.GetSettingSuccess: {
            return {
                ...state,
                succeeded: true,
                pending: false,
                id: state.id,
                selectedId: action.payload._id,
                error: null
            };
        }
        case settingsActions.SettingsActionTypes.GetSettingFail: {
            return {
                ...state,
                succeeded: false,
                pending: false,
                id: null,
                error: action.payload
            };
        }
        case settingsActions.SettingsActionTypes.CopySettingSuccess: {
            return {
                ...adapter.addOne(action.payload, state),
                succeeded: true,
                pending: false,
                id: null,
                allSettings: [...state.allSettings, action.payload]
            };
        }
        default:
            return state;
    }
}

export const {
    selectIds: SelectAllIds,
    selectEntities: SelectAllEntities,
    selectAll: SelectAllSettings,
} = adapter.getSelectors();

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.succeeded;
export const getAllSucceeded = (state: State) => state.all;
export const getError = (state: State) => state.error;
export const getSelectedSetting = (state: State) => {
    return state.entities[state.selectedId];
};
// export const getSetting = (state: State) => state.setting;
export const getAllSettings = (state: State) => {
    let settings = state.allSettings;
    return [...settings];
};
