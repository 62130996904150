import { NgModule } from '@angular/core';

import { CoreModule } from '../core/core.module';

import {PasswordresetComponent, PasswordresetInfoDialogComponent} from './passwordreset.component';

import { PasswordresetService } from '../core/services/passwordreset.service';

import { PasswordresetRoutingModule } from './passwordreset-routing.module';

@NgModule({
    declarations: [PasswordresetComponent, PasswordresetInfoDialogComponent],
    imports: [
        CoreModule,
        PasswordresetRoutingModule
    ],
    providers: [PasswordresetService]
})

export class PasswordresetModule {}
