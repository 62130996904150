import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PhraseService } from '../../services/phrase.service';
import * as actions from './phrase.actions';

@Injectable()

export class PhrasesEffect {
    constructor(
        private actions$: Actions,
        private store$: Store,
        private phraseService: PhraseService
    ) {}
    getManyPhrases$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PhraseActionTypes.GetManyPhrases),
            map((action: actions.GetManyPhrases) => action.payload),
            switchMap((payload: any) => {
                return this.phraseService.getManyPhrases(payload).pipe(
                    map((res: any) => new actions.GetManyPhrasesSuccess(res)),
                    catchError((error) => of(new actions.GetManyPhrasesFail(error.message)))
                );
            })
        );
    });

    getManyWorkbookPhrases$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PhraseActionTypes.GetManyWorkbookPhrases),
            map((action: actions.GetManyWorkbookPhrases) => action.payload),
            switchMap((payload: any) => {
                return this.phraseService.getManyWorkbookPhrases(payload).pipe(
                    map((res: any) => new actions.GetManyWorkbookPhrasesSuccess({
                        id: payload,
                        data: res
                    })),
                    catchError((error) => of(new actions.GetManyWorkbookPhrasesFail(error.message)))
                );
            })
        );
    });

    createPhrase$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PhraseActionTypes.CreatePhrase),
            map((action: actions.CreatePhrase) => action.payload),
            switchMap((payload: any) => {
                return this.phraseService.createPhrase(payload.phraseData).pipe(
                    switchMap((res: any) => {
                        const workbookPhrase = {
                            workbookId: payload.workbookId,
                            phraseId: res._id,
                            ownerKey: payload.phraseData.ownerKey
                        };
                        return [
                            new actions.CreatePhraseSuccess(res),
                            new actions.CreateWorkbookPhrase(workbookPhrase)
                        ];

                    }),
                    catchError((error) => of(new actions.CreatePhraseFail(error.message)))
                );
            })
        );
    });

    createWorkbookPhrase$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PhraseActionTypes.CreateWorkbookPhrase),
            map((action: actions.CreateWorkbookPhrase) => action.payload),
            switchMap((payload: any) => {
                return this.phraseService.createWorkbookPhrase(payload).pipe(
                    map((res: any) => new actions.CreateWorkbookPhraseSuccess(res)),
                    catchError((error) => of(new actions.CreateWorkbookPhraseFail(error.message)))
                );
            })
        );
    });

    updatePhrase$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PhraseActionTypes.UpdatePhrase),
            map((action: actions.UpdatePhrase) => action.payload),
            switchMap((payload: any) => {
                return this.phraseService.updatePhrase(payload.id, payload.Phrase).pipe(
                    map((res: any) => new actions.UpdatePhraseSuccess(payload)),
                    catchError((error) => of(new actions.UpdatePhraseFail(error.message)))
                );
            })
        );
    });

    updateWorkbookPhrase$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PhraseActionTypes.UpdateWorkbookPhrase),
            map((action: actions.UpdateWorkbookPhrase) => action.payload),
            switchMap((payload: any) => {
                return this.phraseService.updateWorkbookPhrase(payload.id, payload.phrases).pipe(
                    switchMap((res: any) => [
                        new actions.UpdateWorkbookPhraseSuccess(payload.phrases),
                        // new actions.ClearWorkbookPhrases(),
                        // new actions.GetManyWorkbookPhrases(payload.id)
                    ]),
                    catchError((error) => of(new actions.UpdateWorkbookPhraseFail(error.message)))
                );
            })
        );
    });

    deletePhrase$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PhraseActionTypes.DeletePhrase),
            map((action: actions.DeletePhrase) => action.payload),
            switchMap((payload: any) => {
                return this.phraseService.deletePhrase(payload).pipe(
                    map((res: any) => new actions.DeletePhraseSuccess(payload)),
                    catchError((error) => of(new actions.DeletePhraseFail(error.message)))
                );
            })
        );
    });

    deleteWorkbookPhrase$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PhraseActionTypes.DeleteWorkbookPhrase),
            map((action: actions.DeleteWorkbookPhrase) => action.payload),
            switchMap((payload: any) => {
                return this.phraseService.deleteWorkbookPhrase(payload.collectionId, payload.itemId).pipe(
                    map((res: any) => new actions.DeleteWorkbookPhraseSuccess(payload)),
                    catchError((error) => of(new actions.DeleteWorkbookPhraseFail(error.message)))
                );
            })
        );
    });

    deleteManyWorkbookPhrase$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.PhraseActionTypes.DeleteManyWorkbookPhrase),
            map((action: actions.DeleteManyWorkbookPhrase) => action.payload),
            switchMap((payload: any) => {
                return this.phraseService.deleteManyWorkbookPhrases(payload).pipe(
                    map((res: any) => new actions.DeleteManyWorkbookPhraseSuccess(res)),
                    catchError((error) => of(new actions.DeleteManyWorkbookPhraseFail(error.message)))
                );
            })
        );
    });

}
