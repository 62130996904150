import { Actions } from '@ngrx/effects';
import * as FileActions from './file.actions';

export interface State {
    pending: boolean;
    error: any;
    succeeded: boolean;
    file: any;
    files: any;
    workbookFiles?: any;
    workbookId: string;
}

const initialState: State = {
    pending: false,
    error: null,
    succeeded: false,
    file: null,
    files: null,
    workbookFiles: null,
    workbookId: null
};

export function reducer(state = initialState, action: FileActions.FileActions): State {
    switch (action.type) {
        case FileActions.FileActionTypes.GetFile: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case FileActions.FileActionTypes.GetFileSuccess: {
            return {
                ...state,
                file: action.payload,
                error: null
            };
        }
        case FileActions.FileActionTypes.GetFileFail: {
            return {
                ...state,
                files: null,
                error: action.payload
            };
        }

        case FileActions.FileActionTypes.GetManyFiles: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case FileActions.FileActionTypes.GetManyFilesSuccess: {
            return {
                ...state,
                files: action.payload,
                error: null
            };
        }
        case FileActions.FileActionTypes.GetManyFilesFail: {
            return {
                ...state,
                files: null,
                error: action.payload
            };
        }
        case FileActions.FileActionTypes.GetManyWorkbookFiles: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case FileActions.FileActionTypes.GetManyWorkbookFilesSuccess: {
            return {
                ...state,
                workbookFiles: action.payload.data,
                workbookId: action.payload.id,
                error: null
            };
        }
        case FileActions.FileActionTypes.GetManyWorkbookFilesFail: {
            return {
                ...state,
                workbookFiles: null,
                error: action.payload
            };
        }

        case FileActions.FileActionTypes.CreateFile: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case FileActions.FileActionTypes.CreateFileSuccess: {
            return {
                ...state,
                files: [...state.files, ...[action.payload]],
                error: null
            };
        }

        case FileActions.FileActionTypes.CreateManyFiles: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }

        // case FileActions.FileActionTypes.CreateManyFilesSuccess: {
        //     const files = [...state.files, ...action.payload];
        //     const newState = JSON.parse(JSON.stringify(state));
        //     newState.files = files;
        //     newState.error = null;
        //     return newState;
        // }

        case FileActions.FileActionTypes.CreateManyFilesSuccess: {
            return {
                ...state,
                files: [...state.files, ...action.payload],
                error: null
            };
        }

        case FileActions.FileActionTypes.CreateWorkbookFile: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case FileActions.FileActionTypes.CreateWorkbookFileSuccess: {
            return {
                ...state,
                workbookFiles: [...state.workbookFiles, ...[action.payload]],
                error: null
            };
        }

        // case FileActions.FileActionTypes.CreateManyWorkbookFilesSuccess: {
        //     return {
        //         ...state,
        //         workbookFiles: [...state.workbookFiles, ...action.payload],
        //         error: null
        //     };
        // }

        case FileActions.FileActionTypes.ClearFile: {
            return {
                ...state,
                file: null,
                error: null
            };
        }

        case FileActions.FileActionTypes.RenameFile: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case FileActions.FileActionTypes.RenameFileSuccess: {
            const unfilteredFiles = JSON.parse(JSON.stringify(state.files));
            const files = unfilteredFiles.map(item => {
                if (item._id === action.payload.id) {
                    item.name = action.payload.name;
                }
                return item;
            });

            const workbookFile = JSON.parse(JSON.stringify(state.workbookFiles));
            workbookFile[0].files.map((file) => {
                if (file._id === action.payload.id) {
                    file.name = action.payload.name;
                }
            });
            // files.splice(index, 1);
            return {
                ...state,
                files,
                workbookFiles: workbookFile,
                error: null
            };
        }

        case FileActions.FileActionTypes.UpdateFile: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case FileActions.FileActionTypes.UpdateFileSuccess: {
            const unfilteredFiles = JSON.parse(JSON.stringify(state.files));
            const files = unfilteredFiles.map(item => {
                if (item._id === action.payload.id) {
                    item = action.payload.file;
                }
                return item;
            });

            const workbookFile = JSON.parse(JSON.stringify(state.workbookFiles));
            workbookFile[0].files.map((file) => {
                if (file._id === action.payload.id) {
                    file = action.payload.file;
                }
            });
            return {
                ...state,
                files,
                workbookFiles: workbookFile,
                error: null
            };
        }


        case FileActions.FileActionTypes.DeleteFile: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case FileActions.FileActionTypes.DeleteFileSuccess: {
            const unfilteredFiles = JSON.parse(JSON.stringify(state.files));
            const files = unfilteredFiles.filter(item => item._id !== action.payload);
            // files.splice(index, 1);
            return {
                ...state,
                files,
                error: null
            };
        }

        case FileActions.FileActionTypes.DeleteManyFiles: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }

        case FileActions.FileActionTypes.DeleteManyFilesSuccess: {
            const unfilteredFiles = JSON.parse(JSON.stringify(state.files));
            const files = unfilteredFiles.filter(item => !action.payload.includes(item._id));
            return {
                ...state,
                files,
                error: null
            };
        }

        case FileActions.FileActionTypes.DeleteWorkbookFile: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }

        case FileActions.FileActionTypes.DeleteWorkbookFileSuccess: {
            let newWorkbookFiles = JSON.parse(JSON.stringify(state.workbookFiles));
            let workbookFiles = newWorkbookFiles[0].files.filter(item => item._id !== action.payload);

            console.log(newWorkbookFiles);
            console.log(workbookFiles);
            // workbookFiles.splice(index, 1);
            return {
                ...state,
                workbookFiles,
                error: null
            };
        }

        case FileActions.FileActionTypes.DeleteManyWorkbookFileSuccess: {
            let newWorkbookFiles = JSON.parse(JSON.stringify(state.workbookFiles));
            let workbookFiles = newWorkbookFiles[0].files.filter(item => !action.payload.includes(item._id));

            return {
                ...state,
                workbookFiles,
                error: null
            };
        }

        case FileActions.FileActionTypes.ReorderWorkbookFilesSuccess: {
            let files = JSON.parse(JSON.stringify(action.payload));
            let newWorkbookFiles = JSON.parse(JSON.stringify(state.workbookFiles));
            newWorkbookFiles[0].files = files;

          return {
              ...state,
              workbookFiles: newWorkbookFiles,
          };
        }

        default:
            return state;
    }
}

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.succeeded;
export const getError = (state: State) => state.error;
export const getFile = (state: State) => state.file;

export const getFiles = (state: State) => state.files;
export const getWorkbookFiles = (state: State) => state.workbookFiles;

