import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_DIR } from '../../environments/environment';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class WorkbooksService {
  workbooks: any[] = [];

  constructor(private http: HttpClient) {}

  get(id: string) {
    return  new Observable(observer => {
      this.http
        .get(`${API_DIR}/workbook/${id}`)
        .pipe(
          take(1)
        )
        .subscribe(
          (workbook: any) => {
            observer.next(workbook.data);
          },
          error => {
            throw error;
          });
    });
  }

  getAll() {
    const whizzimoId = JSON.parse(localStorage.getItem('profile')).user_metadata.uid;
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/workbook/user/${whizzimoId}`)
        .pipe(
          take(1)
        )
        .subscribe(
          (workbooks: any) => observer.next(workbooks.data),
          error => {
            throw error;
          });
    });
  }

  getLibraryWorkbooks() {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/workbook/user/libraryUser`)
        .pipe(
          take(1)
        )
        .subscribe(
          (workbooks: any) => observer.next(workbooks.data),
          error => {
            throw error;
          });
    });
  }

  create(workbook: any) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/workbook`, workbook)
        .pipe(
          take(1)
        )
        .subscribe(
          (newWorkbook: any) => observer.next(newWorkbook.data),
          error => {
            throw error;
          });
    });
  }

  update(workbook: any, workbookId: string) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/workbook/${workbookId}`, workbook)
        .pipe(
          take(1)
        )
        .subscribe(
          (courseWorkbook: any) => observer.next(courseWorkbook.data),
          error => {
            throw error;
          });
    });
  }

  delete(id: string) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/workbook/${id}`)
        .subscribe(() => {
          observer.next();
        });
    });
  }

  copy(id: string, ownerKey: string, changeName: boolean) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/workbook/copy/`, {workbookId: id, ownerKey: ownerKey, changeName})
        .pipe(
          take(1)
        )
        .subscribe(
          (copiedWorkbook: any) => observer.next(copiedWorkbook.data),
    (error: any) => { throw error; }
        );
    });
  }

  getManyByIds(ids: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/workbook/ids/${ids}`)
        .pipe(
          take(1)
        )
        .subscribe(
          (tiles: any) => {
            observer.next(tiles.data);
          },
          (error: any) => {
            throw error;
          }
        );
    });
  }

  getTiles(words: any[]) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/word/tiles`, words)
        .pipe(
          take(1)
        )
        .subscribe(
          (tiles: any) => {
            observer.next(tiles.data);
          },
          (error: any) => {
            throw error;
          }
        );
    });
  }
}
