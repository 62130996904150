import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum ActivityActionTypes {
    BlackboardInit = '[ActivityService] Blackboard Init',
    BlackboardInitSuccess = '[ActivityService] Blackboard Init Success',
    BlackboardInitFail = '[ActivityService] Blackboard Init Fail',

    MiniTilesInit = '[ActivityService] Mini Tiles Init',
    MiniTilesInitSuccess = '[ActivityService] Mini Tiles Init Success',
    MiniTilesInitFail = '[ActivityService] Mini Tiles Init Fail',

    WorkbookTilesInit = '[ActivityService] Workbook Tiles Init',
    WorkbookTilesInitSuccess = '[ActivityService] Workbook Tiles Init Success',
    WorkbookTilesInitFail = '[ActivityService] Workbook Tiles Init Fail',

    GetAllActivityTypes = '[ActivityService] Get All Activity Types',
    GetAllActivityTypesSuccess = '[ActivityService] Get All Activity Types Success',

    setActivitySelectedTool = '[ActivityService] Set Activity selectedTool',

    UpdateInitDataCourseWorkbookActivitySuccess = '[Workbook Builder] Update CourseWorkbook Activity of the Init Value Success',
    ClearActivityInitData = 'Clear Activity Init Data'
}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class BlackboardInit implements Action {
    readonly type = ActivityActionTypes.BlackboardInit;

    constructor(public payload: any) {}
}
export class BlackboardInitSuccess implements Action {
    readonly type = ActivityActionTypes.BlackboardInitSuccess;

    constructor(public payload: any) {}
}
export class BlackboardInitFail implements Action {
    readonly type = ActivityActionTypes.BlackboardInitFail;

    constructor(public payload: any) {}
}

export class MiniTilesInit implements Action {
    readonly type = ActivityActionTypes.MiniTilesInit;

    constructor(public payload: any) {}
}
export class MiniTilesInitSuccess implements Action {
    readonly type = ActivityActionTypes.MiniTilesInitSuccess;

    constructor(public payload: any) {}
}
export class MiniTilesInitFail implements Action {
    readonly type = ActivityActionTypes.MiniTilesInitFail;

    constructor(public payload: any) {}
}

export class WorkbookTilesInit implements Action {
    readonly type = ActivityActionTypes.WorkbookTilesInit;

    constructor(public payload: any) {}
}
export class WorkbookTilesInitSuccess implements Action {
    readonly type = ActivityActionTypes.WorkbookTilesInitSuccess;

    constructor(public payload: any) {}
}
export class WorkbookTilesInitFail implements Action {
    readonly type = ActivityActionTypes.WorkbookTilesInitFail;

    constructor(public payload: any) {}
}

export class GetAllActivityTypes implements Action {
    readonly type = ActivityActionTypes.GetAllActivityTypes;

    constructor(public payload: any) {}
}
export class GetAllActivityTypesSuccess implements Action {
    readonly type = ActivityActionTypes.GetAllActivityTypesSuccess;

    constructor(public payload: any) {}
}

export class SetActivitySelectedTool implements Action {
    readonly type = ActivityActionTypes.setActivitySelectedTool;

    constructor(public payload: any) {}
}

export class UpdateInitDataCourseWorkbookActivitySuccess implements Action {
  readonly type = ActivityActionTypes.UpdateInitDataCourseWorkbookActivitySuccess;

  constructor(public payload: any) {}
}

export class ClearActivityInitData implements Action {
  readonly type = ActivityActionTypes.ClearActivityInitData;

  constructor() {}
}

export type ActivityActions
= BlackboardInit
| BlackboardInitSuccess
| BlackboardInitFail
| MiniTilesInit
| MiniTilesInitSuccess
| MiniTilesInitFail
| WorkbookTilesInit
| WorkbookTilesInitSuccess
| WorkbookTilesInitFail
| GetAllActivityTypes
| GetAllActivityTypesSuccess
| SetActivitySelectedTool
| UpdateInitDataCourseWorkbookActivitySuccess
| ClearActivityInitData;
