import { combineLatest } from 'rxjs';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { SettingsService } from "../core/services/settings.service";
import { getAllNonAndSubscriptionCourses, isSetCourseLoaded } from '../core/store';
import { SetCurriculumCourse } from '../core/store/set-course/set-course.actions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UtilityService } from '../core/services/utility.service';

@Component({
  selector: "app-settings",
  templateUrl: "./html/settings.html",
  styleUrls: [
    "../assets/css/main.css",
    "../assets/scss/fontawesome.scss",
    "../assets/scss/brands.scss",
    "../assets/scss/regular.scss",
    "../assets/scss/solid.scss"
  ]
})
export class SettingsComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject();

  constructor(private settings: SettingsService, private store: Store, private utilityService: UtilityService) {
  }

  ngOnInit() {
    combineLatest([
      this.store.select(isSetCourseLoaded),
      this.store.select(getAllNonAndSubscriptionCourses)
    ])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([isDefaultCourseLoaded, allCourses]) => {
      if (!isDefaultCourseLoaded && allCourses?.length) {
        let course = allCourses[0];
        if (this.utilityService.mobileCheck()) {
          course = allCourses.find((course: any) => !course.subscriptionId);
        }
        this.store.dispatch(new SetCurriculumCourse({
          course,
          redirect: false
        }));
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
