import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum LessonActionTypes {
    GetLesson = '[LessonService] Get Lesson',
    GetLessonSuccess = '[LessonService] Get Lesson Success',
    GetLessonFail = '[LessonService] Get Lesson Fail',

    ClearAllLessons = '[LessonService] Clear All Lessons',

    GetAllLessons = '[LessonService] Get All Lessons',
    GetAllLessonsSuccess = '[LessonService] Get All Lessons Success',
    GetAllLessonsFail = '[LessonService] Get All Lessons Fail',

    GetFirstLessonStep = '[LessonService] Get First Lesson Step',
    GetFirstLessonStepSuccess = '[LessonService] Get First Lesson Step Success',
    GetFirstLessonStepFail = '[LessonService] Get First Lesson Step Fail',

    GetAllLessonSteps = '[LessonService] Get All Lesson Steps',
    GetAllLessonStepsSuccess = '[LessonService] Get All Lesson Steps Success',
    GetAllLessonStepsFail = '[LessonService] Get All Lesson Steps Fail',

    CreateLesson = '[LessonService] Create Lesson',
    CreateLessonSuccess = '[LessonService] Create Lesson Success',
    CreateLessonFail = '[LessonService] Create Lesson Fail',

    CopyLesson = '[LessonService] Copy Lesson',
    CopyLessonSuccess = '[LessonService] Copy Lesson Success',
    CopyLessonFail = '[LessonService] Copy Lesson Fail',

    UpdateLesson = '[LessonService] Update Lesson',
    UpdateLessonSuccess = '[LessonService] Update Lesson Success',
    UpdateLessonFail = '[LessonService] Update Lesson Fail',

    DeleteLesson = '[LessonService] Delete Lesson',
    DeleteLessonSuccess = '[LessonService] Delete Lesson Success',
    DeleteLessonFail = '[LessonService] Delete Lesson Fail',

    AppendLessonStepToStore = '[LessonService] Append Lesson Step To Store',
    RemoveLessonStepFromStore = '[LessonService] Remove Lesson Step From Store'
}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class GetLesson implements Action {
    readonly type = LessonActionTypes.GetLesson;

    constructor(public payload: any) {}
}

export class GetLessonSuccess implements Action {
    readonly type = LessonActionTypes.GetLessonSuccess;

    constructor(public payload: string) {}
}
export class GetLessonFail implements Action {
    readonly type = LessonActionTypes.GetLessonFail;

    constructor(public payload: string) {}
}

export class CreateLesson implements Action {
    readonly type = LessonActionTypes.CreateLesson;

    constructor(public payload: any) {}
}

export class CreateLessonSuccess implements Action {
    readonly type = LessonActionTypes.CreateLessonSuccess;

    constructor(public payload: string) {}
}
export class CreateLessonFail implements Action {
    readonly type = LessonActionTypes.CreateLessonFail;

    constructor(public payload: string) {}
}

export class UpdateLesson implements Action {
    readonly type = LessonActionTypes.UpdateLesson;

    constructor(public payload: any) {}
}

export class UpdateLessonSuccess implements Action {
    readonly type = LessonActionTypes.UpdateLessonSuccess;

    constructor(public payload: any) {}
}
export class UpdateLessonFail implements Action {
    readonly type = LessonActionTypes.UpdateLessonFail;

    constructor(public payload: string) {}
}

export class CopyLesson implements Action {
    readonly type = LessonActionTypes.CopyLesson;

    constructor(public payload: any) {}
}

export class CopyLessonSuccess implements Action {
    readonly type = LessonActionTypes.CopyLessonSuccess;

    constructor(public payload: string) {}
}
export class CopyLessonFail implements Action {
    readonly type = LessonActionTypes.CopyLessonFail;

    constructor(public payload: string) {}
}

export class DeleteLesson implements Action {
    readonly type = LessonActionTypes.DeleteLesson;

    constructor(public payload: any) {}
}

export class DeleteLessonSuccess implements Action {
    readonly type = LessonActionTypes.DeleteLessonSuccess;

    constructor(public payload: string) {}
}
export class DeleteLessonFail implements Action {
    readonly type = LessonActionTypes.DeleteLessonFail;

    constructor(public payload: string) {}
}

export class ClearAllLessons implements Action {
    readonly type = LessonActionTypes.ClearAllLessons;

    constructor(public payload: any) {}
}

export class GetAllLessons implements Action {
    readonly type = LessonActionTypes.GetAllLessons;

    constructor(public payload: any) {}
}

export class GetAllLessonsSuccess implements Action {
    readonly type = LessonActionTypes.GetAllLessonsSuccess;

    constructor(public payload: any) {}
}
export class GetAllLessonsFail implements Action {
    readonly type = LessonActionTypes.GetAllLessonsFail;

    constructor(public payload: string) {}
}

export class GetFirstLessonStep implements Action {
    readonly type = LessonActionTypes.GetFirstLessonStep;

    constructor(public payload: any) {}
}

export class GetFirstLessonStepSuccess implements Action {
    readonly type = LessonActionTypes.GetFirstLessonStepSuccess;

    constructor(public payload: string) {}
}
export class GetFirstLessonStepFail implements Action {
    readonly type = LessonActionTypes.GetFirstLessonStepFail;

    constructor(public payload: string) {}
}

export class GetAllLessonSteps implements Action {
    readonly type = LessonActionTypes.GetAllLessonSteps;

    constructor(public payload: any) {}
}

export class GetAllLessonStepsSuccess implements Action {
    readonly type = LessonActionTypes.GetAllLessonStepsSuccess;

    constructor(public payload: any) {}
}
export class GetAllLessonStepsFail implements Action {
    readonly type = LessonActionTypes.GetAllLessonStepsFail;

    constructor(public payload: string) {}
}

export class AppendLessonStepToStore implements Action {
    readonly type = LessonActionTypes.AppendLessonStepToStore;

    constructor(public payload: any) {}
}

export class RemoveLessonStepFromStore implements Action {
    readonly type = LessonActionTypes.RemoveLessonStepFromStore;

    constructor(public payload: any) {}
}

export type LessonActions
= GetLesson
| GetLessonSuccess
| GetLessonFail
| ClearAllLessons
| GetAllLessons
| GetAllLessonsSuccess
| GetAllLessonsFail
| GetFirstLessonStep
| GetFirstLessonStepSuccess
| GetFirstLessonStepFail
| GetAllLessonSteps
| GetAllLessonStepsSuccess
| GetAllLessonStepsFail
| CreateLesson
| CreateLessonSuccess
| CreateLessonFail
| CopyLesson
| CopyLessonSuccess
| CopyLessonFail
| DeleteLesson
| DeleteLessonSuccess
| DeleteLessonFail
| UpdateLesson
| UpdateLessonSuccess
| UpdateLessonFail
| AppendLessonStepToStore
| RemoveLessonStepFromStore;
