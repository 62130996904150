// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container, .item-row {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.item-row {
  flex-direction: row;
}

.toggle {
  width: 100%;
}

.help_icon {
  margin-left: 10px;
  cursor: pointer;
}

:host ::ng-deep .mat-mdc-text-field-wrapper {
  padding: 0 24px !important;
}`, "",{"version":3,"sources":["webpack://./src/Settings/new-advanced/new-advanced.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EAEE,mBAAA;AAAF;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,iBAAA;EACA,eAAA;AAAF;;AAIE;EACE,0BAAA;AADJ","sourcesContent":[".container {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.item-row {\n  @extend .container;\n  flex-direction: row;\n}\n\n.toggle {\n  width: 100%;\n}\n\n.help_icon {\n  margin-left: 10px;\n  cursor: pointer;\n}\n\n:host ::ng-deep {\n  .mat-mdc-text-field-wrapper {\n    padding: 0 24px !important;\n  } \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
