import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_DIR} from '../../environments/environment';

@Injectable()
export class PlanService {
  constructor(private http: HttpClient) {}

  getAllPlans() {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/plan`)
        .subscribe(
          (plans: any) => observer.next(plans.data),
            error => {
              throw error;
            }
        );
    });
  }

  getPlan(customerId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/payment/customer/${customerId}`)
        .subscribe(
          (customer: any) => {
            if (customer.data.subscriptions.count > 0) {
              observer.next(customer.data.subscriptions.data[0].plan);
            } else {
              observer.next(null);
            }
          },
          error => {
            throw error;
          }
        );
    });
  }
}
