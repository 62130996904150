// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .mat-form-field-label-wrapper {
  top: -1em;
}`, "",{"version":3,"sources":["webpack://./src/core/components/add-import-collection-dialog/add-import-collection-dialog.component.scss"],"names":[],"mappings":"AACE;EACE,SAAA;AAAJ","sourcesContent":[":host ::ng-deep {\n  .mat-form-field-label-wrapper {\n    top: -1em;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
