import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {WordFilterComponent} from './word-filter.component';

@Component({
  selector: 'app-word-multiple-checkbox-filter',
  templateUrl: './html/word-filter-multiple-checkbox.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
    './word-filter.scss'

  ]
})

export class WordFilterMultipleCheckboxComponent implements OnInit {
  @Input() filter: any;
  @Input() syllableCount: number;
  @Output() updateQuery: EventEmitter<any> = new EventEmitter<any>();

  selectedValues: any[] = [];

  ngOnInit(): void {
    if (this.filter.valProperty) {
      this.selectedValues = this.filter.currentVal[this.filter.valProperty];
    } else {
      this.selectedValues = this.filter.currentVal;
    }

  }

  updateQueryEvent($event, value: any) {
    this.updateQuery.emit({[value.name]: [value.value]});
  }
}
