import { GetLessonStep } from '../store/edit-lesson/edit-lesson.actions';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import * as selectors from "../store";
import { SetActivityOrLessonFixStatus } from '../store/user-interface/user-interface.actions';

@Injectable()
export class LessonPlanStepResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const lessonPlanStepId = route.url[3].path;
        return this.store.pipe(
          // select(selectors.selectGetLessonStep),
          select(selectors.selectIsExistLessonStepById, { id: lessonPlanStepId }),
          tap(isLoaded => {
            this.store.dispatch(new SetActivityOrLessonFixStatus('outdated'));
            if (!isLoaded) {
              this.store.dispatch(new GetLessonStep(lessonPlanStepId));
            }
          }),
          filter(isLoaded => !!isLoaded),
          first()
        );

  }
}
