import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { CurriculumComponent } from './curriculum.component';
import { CurriculumService } from '../core/services/curriculum.service';

import { CurriculumRoutingModule } from './curriculum-routing.module';
import { AuthGuard } from '../core/services/auth-guard.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { NgsContenteditableModule } from '@ng-stack/contenteditable';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [CurriculumComponent],
  imports: [
    CoreModule,
    CurriculumRoutingModule,
    FontAwesomeModule,
    NgsContenteditableModule,
    NgxSpinnerModule
  ],
  providers: [CurriculumService, AuthGuard]
})

export class CurriculumModule {}
