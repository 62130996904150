  import { Component, OnInit } from "@angular/core";
import { WordlistService } from "../../core/services/wordlist.service";

@Component({
  selector: "app-settings-basic",
  templateUrl: "./html/files.html",
  styleUrls: [
    "../../assets/css/main.css",
    "../../assets/scss/fontawesome.scss",
    "../../assets/scss/brands.scss",
    "../../assets/scss/regular.scss",
    "../../assets/scss/solid.scss"
  ]
})
export class FilesComponent implements OnInit {
  constructor(private Wordlist: WordlistService) {}
  ngOnInit() {}
}
