import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of,  Observable} from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';

import * as actions from './words.actions';
import { WordsService } from '../../services/words.service';
@Injectable()

export class WordsEffect {
    constructor(
        private actions$: Actions,
        private store$: Store,
        private wordsService: WordsService
    ) {}
    postWords$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.WordsActionTypes.PostWords),
            map((action: actions.PostWords) => action.payload),
            // tap(courseId => {
            //     this.store$.dispatch(new CourseWorkbookActions.GetAllCourseWorkbooks(courseId));
            // }),
            mergeMap((payload: any) => {
                return this.wordsService.post(payload.filter).pipe(
                    map((res: string) => new actions.PostWordsSuccess({
                        id: payload.id,
                        response: res
                    })),
                    catchError((error) => of(new actions.PostWordsFail(error.message)))
                );
            })
        );
    });
    getWordsByWordIds$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.WordsActionTypes.GetWordsByWordId),
            map((action: actions.GetWordsByWordId) => action.payload),
            // tap(courseId => {
            //     this.store$.dispatch(new CourseWorkbookActions.GetAllCourseWorkbooks(courseId));
            // }),
            switchMap((payload: any) => {
                return this.wordsService.getWordsByWordIds( payload.wordIds).pipe(
                    map((res: string) => new actions.GetWordsByWordIdSuccess(res)),
                    catchError((error) => of(new actions.GetWordsByWordIdFail(error.message)))
                );
            })
        );
    });
    getTilesByWordId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.WordsActionTypes.GetTilesByWordId),
            map((action: actions.GetTilesByWordId) => action.payload),
            // tap(courseId => {
            //     this.store$.dispatch(new CourseWorkbookActions.GetAllCourseWorkbooks(courseId));
            // }),
            switchMap((payload: any) => {
                return this.wordsService.getTilesByWordIds(payload.id, payload.wordIds).pipe(
                    map((res: string) => new actions.GetTilesByWordIdSuccess(res)),
                    catchError((error) => of(new actions.GetTilesByWordIdFail(error.message)))
                );
            })
        );
    });
    getPairings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.WordsActionTypes.GetPairings),
            map((action: actions.GetPairings) => action.payload),
            // tap(courseId => {
            //     this.store$.dispatch(new CourseWorkbookActions.GetAllCourseWorkbooks(courseId));
            // }),
            switchMap((payload: any) => {
                return this.wordsService.getPairings().pipe(
                    map((res: string) => new actions.GetPairingsSuccess(res)),
                    catchError((error) => of(new actions.GetPairingsFail(error.message)))
                );
            })
        );
    });

}
