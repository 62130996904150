import {Component, OnDestroy, OnInit, Inject} from '@angular/core';
import { CourseService } from '../core/services/courses.service';
import {combineLatest, Subscribable, Subscription} from 'rxjs';
import { take } from 'rxjs/operators';
import {Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {UserService} from '../core/services/user.service';
import {SharingService} from '../core/services/sharing.service';
import {SubscriptionService} from '../core/services/subscription.service';

@Component({
  selector: 'app-courses',
  templateUrl: './html/courses.html',
  styleUrls: ['../assets/css/main.css',
        '../assets/scss/fontawesome.scss',
        '../assets/scss/brands.scss',
        '../assets/scss/regular.scss',
        '../assets/scss/solid.scss',
        './courses.scss'
]
})

export class CoursesComponent implements OnInit {
  courses: any[] = [];
  subscribedCourses: any[] = [];

  currentCourse: any;
  newCourse = {
    courseName: '',
    teacherKey: JSON.parse(localStorage.getItem('profile')).user_metadata.uid,
    classDefinition: 'students',
    notes: '',
    courseType: 'Default/Practice Student'
  };

  newCourses = [];
  userId: string;
  subscribedCourseKey: string;

  constructor(
    private _courseService: CourseService,
    private _router: Router,
    private userService: UserService,
    private subscriptionService: SubscriptionService
    ) {}

  ngOnInit() {
    this.userId = JSON.parse(localStorage.profile).user_metadata.uid;
    this.getCourses();
  }

  getCourses() {
    combineLatest([this._courseService.getAll(), this.subscriptionService.getMany(this.userId)])
      .pipe(
        take(1)
      )
      .subscribe((courses: any) => {
        this.courses = courses[0];
        this.subscribedCourses = courses[1].map(sharedCourse => sharedCourse.courses).flat();
      });
  }

  deleteCourse(courseId: string) {
    if (this.courses.length > 1) {
      this._courseService
        .delete(courseId)
        .pipe(
          take(1)
        )
        .subscribe(
          () => this.getCourses(),
          error => {}
        );
    }
  }

  copyCourse(courseId) {
    this._courseService
      .copy(courseId)
      .pipe(
        take(1)
      )
      .subscribe(
        () => this.getCourses(),
        error => {}
      );
  }

  updateCourse(index) {
    const course = this.courses[index];
    delete course.editing;

    this._courseService
      .update(course)
      .pipe(
        take(1)
      )
      .subscribe(
        () => {},
        error => {}
      );
  }

  editCourse(index) {
    this.courses[index].editing = true;
  }
  finishEdit(event) {
    if (event.save) {
      this.updateCourse(event.index);
    }
    this.courses[event.index].editing = false;
  }

  addNewCourse() {
    this.newCourses.push({...{}, ...this.newCourse});
  }

  goToCourse(course) {
    this._courseService.currentCourse = course;
    // localStorage.setItem('currentCourseId', course._id);
    this._router.navigateByUrl('my-curriculum');
  }

  createCourse(index) {
    const course = this.newCourses[index];

    this._courseService
      .create(course)
      .pipe(
        take(1)
      )
      .subscribe(
        () => {
          this.getCourses();
          this.resetNewCourse(index);
        },
        error => {}
      );
  }

  resetNewCourse(index) {
    this.newCourses.splice(index, 1);
  }

  shareCourse(_id: any) {
    alert('Sharing Id: ' + _id);
  }

  addSharedCourse() {
      this.subscriptionService
      .create({userId: this.userId, courseId: this.subscribedCourseKey})
      .subscribe(() => window.location.reload());
  }
}


