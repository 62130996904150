// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#email .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/passwordreset/passwordreset.component.scss"],"names":[],"mappings":"AACI;EACI,4BAAA;AAAR","sourcesContent":["#email{\n    .mat-form-field-wrapper {\n        padding-bottom: 0 !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
