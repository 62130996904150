// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.for-internal-container {
  border-top: 1px solid rgba(13, 2, 35, 0.08);
  margin: 2px 0 0;
}
.for-internal-container .add_cousre_modal_subscription {
  font-size: 16px;
  font-weight: bold;
  opacity: 0.32;
  line-height: 1;
  letter-spacing: normal;
}
.for-internal-container .input-container {
  margin: 0 24px;
}
.for-internal-container .input-container > mat-form-field {
  width: 100%;
}
.for-internal-container .input-container > mat-form-field .mat-form-field-wrapper {
  margin: 0;
}

.add_cousre_modal_subscription {
  margin: 24px;
}`, "",{"version":3,"sources":["webpack://./src/core/components/add-course-dialog/add-course-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,2CAAA;EACA,eAAA;AACF;AACE;EACE,eAAA;EACA,iBAAA;EACA,aAAA;EACA,cAAA;EACA,sBAAA;AACJ;AAEE;EACE,cAAA;AAAJ;AACI;EACE,WAAA;AACN;AACM;EACE,SAAA;AACR;;AAIA;EACE,YAAA;AADF","sourcesContent":[".for-internal-container {\n  border-top: 1px solid rgba(13, 2, 35, 0.08);\n  margin: 2px 0 0;\n\n  .add_cousre_modal_subscription {\n    font-size: 16px;\n    font-weight: bold;\n    opacity: 0.32;\n    line-height: 1;\n    letter-spacing: normal;\n  }\n\n  .input-container {\n    margin: 0 24px;\n    > mat-form-field {\n      width: 100%;\n\n      .mat-form-field-wrapper {\n        margin: 0;\n      }\n    }\n  }\n}\n.add_cousre_modal_subscription {\n  margin: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
