import { Router } from '@angular/router';
import { CourseService } from '../../services/courses.service';
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import * as actions from './set-course.actions';
import * as CourseWorkbookActions from '../course-workbook/course-workbook.actions';

import { SubscriptionService } from '../../services/subscription.service';
import { isAllCourseWorkbooksLoaded, selectCourseData } from '..';
import { GetAllLessons, GetAllLessonSteps } from '../lesson/lesson.actions';

@Injectable()
export class SetCourseEffect {
  constructor(
      private actions$: Actions,
      private store$: Store,
      private courseService: CourseService,
      private subscriptionService: SubscriptionService,
      private router: Router,
      private store: Store
  ) {}

  setCurriculumCourse$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(actions.SetCourseActionTypes.SetCurriculumCourse),
        switchMap((action: any) =>
          of(action).pipe(
            withLatestFrom(
              this.store.select(isAllCourseWorkbooksLoaded, { id: action?.payload?.course?._id })
            ),
            tap(([action, isLoaded]) => {
              if (!isLoaded) {
                this.store$.dispatch(new CourseWorkbookActions.GetAllCourseWorkbooks(action?.payload?.course?._id));
                this.store$.dispatch(new GetAllLessonSteps(action.payload.course?._id));
                this.store$.dispatch(new GetAllLessons(action.payload.course?._id));
              }
            }),
            map(([action, latest]) => {
              // localStorage.setItem('currentCourseId', action.payload.course._id);
              return new actions.SetCurriculumCourseSuccess(action.payload);
            })
          )
        )

    );
  });

  SetCurriculumCourseSuccess$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(actions.SetCourseActionTypes.SetCurriculumCourseSuccess),
        map((action: actions.SetCurriculumCourseSuccess) => action.payload),
        tap((payload) => {
          localStorage.setItem('previousSelectedCourse', JSON.stringify(payload.course));
          if (payload.redirect) {
            this.router.navigateByUrl('/my-curriculum');
          }
        }),
    );
  }, { dispatch: false });


  setCurriculumCourseById$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(actions.SetCourseActionTypes.SetCurriculumCourseById),
        switchMap((action: any) =>
          of(action).pipe(
            withLatestFrom(
              this.store.select(selectCourseData, { id: action.payload })
            ),
            tap(([action, courseData]) => {
              if (courseData) {
                this.store.dispatch(new actions.SetCurriculumCourse({
                  course: courseData
                }));
              }
            })
          ),
        )
    );
  }, {dispatch: false});
}
