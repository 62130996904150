import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_DIR} from '../../environments/environment';
import {take} from 'rxjs/operators';

@Injectable()

export class LessonPlansStepsService {

  constructor(private http: HttpClient) {

  }

  get(lessonPlanStepId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/lessonPlanStep/${lessonPlanStepId}`)
        .pipe(
          take(1)
        )
        .subscribe(
          (lessonPlan: any) => {
            observer.next(lessonPlan.data);
          },
          error => {
            throw error;
          });
    });
  }

  getCountByWorkbookId(workbookId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/lessonPlanStep/count/workbook/${workbookId}`)
        .pipe(take(1))
        .subscribe(
          (count: any) => {
            observer.next(count.data);
          },
          error => {
            throw error;
          });
    });
  }

  getCountByCourseWorkbookId(courseWorkbookId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/lessonPlanStep/count/courseWorkbook/${courseWorkbookId}`)
        .pipe(take(1))
        .subscribe(
          (count: any) => {
            observer.next(count.data);
          },
          error => {
            throw error;
          });
    });
  }

  getAll(lessonPlanId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/lessonPlanStep/lessonPlan/${lessonPlanId}`)
        .pipe(
          take(1)
        )
        .subscribe(
          (lessonPlanSteps: any) => {
            observer.next(lessonPlanSteps.data);
          },
          error => {
            throw error;
          });
    });
  }

  create(lessonPlanStep: any) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/lessonPlanStep`, lessonPlanStep)
        .pipe(
          take(1)
        )
        .subscribe(
          (newLessonPlan: any) => {
            observer.next(newLessonPlan.data);
          },
          error => {
            throw error;
          });
    });
  }

  update(id: string, lessonPlanStep: any) {
    return new Observable(observer => {
      this.http.put(`${API_DIR}/lessonPlanStep/${id}`, lessonPlanStep)
      .pipe(take(1))
      .subscribe(
      (res: any) => observer.next(res.data), 
      error => {throw error; });
    });
  }

  updateMany(lessonPlanSteps: any[]) {

    return new Observable(observer => {
      // fetch(`${API_DIR}/lessonPlanStep/update/multi`, {
      //   method: 'PUT',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     body: JSON.stringify(lessonPlanSteps)
      //   }
      // })
      //   .then(response => response.json)
      //   .then(data => {
      //     observer.next(data);
      //   })
      //   .catch(err => {
      //     throw err;
      //   });
      this.http.put(`${API_DIR}/lessonPlanStep/update/multi`, lessonPlanSteps)
        .pipe(take(1))
        .subscribe(
          (res: any) => observer.next(res.data),
          error => {throw error; });
    });
  }

  delete(lessonPlanStepId: string) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/lessonPlanStep/${lessonPlanStepId}`)
        .pipe(take(1))
        .subscribe(
          () => observer.next(),
          error => {
            throw error;
          }
        );
    });
  }

  copy(lessonPlanStepId: string) {
    return new Observable(observer => {
      return this.http
        .post(`${API_DIR}/lessonPlanStep/copy/${lessonPlanStepId}`, {})
        .pipe(
          take(1)
        )
        .subscribe(
          (newLessonPlan: any) => {
            observer.next(newLessonPlan.data);
          },
          error => {
            throw error;
          });
    });
  }

}
