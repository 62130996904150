import { NodeStringDecoder } from 'string_decoder';
import * as sentenceActions from './sentence.actions';

export interface State {
    pending: boolean;
    error: any;
    succeeded: boolean;
    sentences: any;
    workbookSentences?: any;
    workbookId: string;
}

const initialState: State = {
    pending: false,
    error: null,
    succeeded: false,
    sentences: null,
    workbookSentences: null,
    workbookId: null
};

export function reducer(state = initialState, action: sentenceActions.SentenceActions): State {
    switch (action.type) {
        case sentenceActions.SentenceActionTypes.GetManySentences: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case sentenceActions.SentenceActionTypes.GetManySentencesSuccess: {
            return {
                ...state,
                sentences: action.payload,
                error: null
            };
        }
        case sentenceActions.SentenceActionTypes.ClearWorkbookSentences: {
            return {
                ...state,
                workbookSentences: [],
                error: null
            };
        }


        case sentenceActions.SentenceActionTypes.GetManySentencesFail: {
            return {
                ...state,
                sentences: null,
                error: action.payload
            };
        }
        case sentenceActions.SentenceActionTypes.GetManyWorkbookSentences: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case sentenceActions.SentenceActionTypes.GetManyWorkbookSentencesSuccess: {
            return {
                ...state,
                workbookSentences: action.payload.data,
                workbookId: action.payload.id,
                error: null
            };
        }
        case sentenceActions.SentenceActionTypes.GetManyWorkbookSentencesFail: {
            return {
                ...state,
                workbookSentences: null,
                error: action.payload
            };
        }


        case sentenceActions.SentenceActionTypes.CreateSentence: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case sentenceActions.SentenceActionTypes.CreateSentenceSuccess: {
            return {
                ...state,
                sentences: [...state.sentences, ...[action.payload]],
                error: null
            };
        }

        case sentenceActions.SentenceActionTypes.CreateWorkbookSentence: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case sentenceActions.SentenceActionTypes.CreateWorkbookSentenceSuccess: {
            return {
                ...state,
                workbookSentences: [action.payload],
                error: null
            };
        }
        case sentenceActions.SentenceActionTypes.UpdateWorkbookSentenceSuccess: {
            let sentences = JSON.parse(JSON.stringify(action.payload));
            let newWorkbookSentences = JSON.parse(JSON.stringify(state.workbookSentences));
            newWorkbookSentences[0].sentences = sentences;

            return {
                ...state,
                workbookSentences: newWorkbookSentences,
                error: null
            };
        }

        case sentenceActions.SentenceActionTypes.DeleteWorkbookSentence: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }

        case sentenceActions.SentenceActionTypes.DeleteWorkbookSentenceSuccess: {
            let workbookSentences = JSON.parse(JSON.stringify(state.workbookSentences));
            workbookSentences[0].sentences = workbookSentences[0].sentences.filter((sentence) => sentence._id !== action.payload.itemId);
            workbookSentences[0].numSentence = workbookSentences[0].sentences.length;
            return {
                ...state,
                workbookSentences,
                error: null
            };
        }

        case sentenceActions.SentenceActionTypes.DeleteSentence: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }

        case sentenceActions.SentenceActionTypes.DeleteSentenceSuccess: {
            let sentences = [...state.sentences];
            let workbookSentences = [...state.workbookSentences];

            const index1 = sentences.findIndex(sentence => sentence._id === action.payload);
            const index2 = workbookSentences.findIndex(workbookSentence => workbookSentence.sentenceId === action.payload);

            if (index1 > -1) {
                sentences.splice(index1, 1);
            }
            if (index2 > -1) {
                workbookSentences.splice(index2, 1);
            }

            return {
                ...state,
                sentences,
                workbookSentences,
                error: null
            };
        }

        case sentenceActions.SentenceActionTypes.UpdateSentenceSuccess: {
            let sentences = state.sentences?.map(sentence => {
                if (sentence._id === action.payload.id) {
                    return {
                        ...sentence,
                        sentence: action.payload.sentence
                    };
                }
                return sentence;
            });
            const workbookSentences = JSON.parse(JSON.stringify(state.workbookSentences));

            workbookSentences[0].sentences.map((sentence) => {
                if (sentence._id === action.payload.id) {
                    sentence.sentence = action.payload.sentence;
                }
            });

            return {
                ...state,
                sentences,
                workbookSentences,
                error: null
            };
        }

        default:
            return state;
    }
}

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.succeeded;
export const getError = (state: State) => state.error;
export const getSentences = (state: State) => {
    if (state.sentences) {
        const sentences = JSON.parse(JSON.stringify(state.sentences));
        return sentences.sort((a, b) => {
                return a.sentence.localeCompare(b.sentence);
        });
    }
};

export const getWorkbookSentences = (state: State) => state.workbookSentences;

