import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first, tap } from "rxjs/operators";
import { GetManyPhrases } from "../store/phrase/phrase.actions";
import { selectGetPhrases } from "../store";

@Injectable()
export class AllPhrasesResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(selectGetPhrases),
      tap(areLoaded => {
        if (!areLoaded) {
          const uid = JSON.parse(localStorage.profile).user_metadata.uid;
          this.store.dispatch(new GetManyPhrases(uid));
        }
      }),
      filter(areLoaded => areLoaded),
      first(),
    );
  }
}
