import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_DIR } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()

export class SentenceService {

  constructor(private http: HttpClient) {}

  getManySentences(userId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/sentence/user/${userId}`)
        .subscribe(
          (sentences: any) => {
            observer.next(sentences.data);
          },
          error => {
            throw error;
          }
        );
    });
  }

  getManyWorkbookSentences(workbookId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/workbookSentence/workbook/${workbookId}`)
        .subscribe(
          (workbookSentences: any) => {
            observer.next(workbookSentences.data);
          },
          error => {
            throw error;
          }
        );
    });
  }

  createSentence(newSentence: any) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/sentence`, newSentence)
        .subscribe(
          (sentence: any) => observer.next(sentence.data),
          error => {
            throw error;
          }
        );
    });
  }

  createWorkbookSentence(newWorkbookSentence: any) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/workbookSentence`, newWorkbookSentence)
        .subscribe(
          (workbookSentence: any) => observer.next(workbookSentence.data),
          error => {
            throw error;
          }
        );
    });
  }

  updateSentence(sentenceId: string, updatedSentence: any) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/sentence/${sentenceId}`, {sentence: updatedSentence})
        .subscribe(
          (sentence: any) => observer.next(sentence.data),
          error => {
            throw error;
          }
        );
    });
  }

  updateWorkbookSentence(sentenceId: string, data: any) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/workbookSentence/${sentenceId}`, data)
        .subscribe(
          (sentence: any) => observer.next(sentence.data),
          error => {
            throw error;
          }
        );
    });
  }

  deleteSentence(sentenceId: string) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/sentence/${sentenceId}`)
        .subscribe(
          () => observer.next(),
          error => {
            throw error;
          }
        );
    });
  }

  deleteWorkbookSentence(collectionId: string, itemId: string) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/workbookSentence/${collectionId}/${itemId}`)
        .subscribe(
          () => observer.next(),
          error => {
            throw error;
          }
        );
    });
  }
  deleteManyWorkbookSentences(sentenceId: string) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/workbookSentence/sentence/${sentenceId}`)
        .subscribe(
          () => observer.next(),
          error => {
            throw error;
          }
        );
    });
  }

}
