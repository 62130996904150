import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject} from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class ListenerService {
   private _curriculumTabListner = new BehaviorSubject<any>('workbook');
   private _listners = new Subject<any>();
   private _navBarListner = new BehaviorSubject<any>(false);
   private _selectedPlan = new BehaviorSubject<any>('demo');
   _previousUrlListener =  new Subject<string>();
   timeStamp: string;

   constructor() {
     this.timeStamp = (new Date()).toString();
     let test = this.timeStamp;
   }

    listener(): Observable<any> {
     return this._listners.asObservable();
    }

    callback(callback: any) {
       this._listners.next(callback);
    }

   listenPreviousUrl(): Observable<string> {
      return this._previousUrlListener.asObservable();
   }

   setPreviousUrl(url: string) {
      setTimeout(() => {
         this._previousUrlListener.next(url);
      }, 300);
   }

   setCurriculumTab(tabName) {
      this._curriculumTabListner.next(tabName);
   }

   curriculumCallback() {
      return this._curriculumTabListner.asObservable();
   }

   setPlan(plan) {
      this._selectedPlan.next(plan);
   }

   getPlan() {
      return this._selectedPlan.asObservable();
   }

}
