import { UserInterfaceActions, UserInterfaceTypes } from './user-interface.actions';
export interface State {
  myCurriculumActiveTab: string;
  activityOrLessonFixStatus: 'outdated' | 'updated';

}

const initialState: State = {
  myCurriculumActiveTab: 'workbook',
  activityOrLessonFixStatus: 'updated'
};

export function reducer(state = initialState, action: UserInterfaceActions): State {
  switch (action.type) {
    case UserInterfaceTypes.SetActivityOrLessonFixStatus: {
      return {
          ...state,
          activityOrLessonFixStatus: action.payload
      };
    }
    default:
      return state;
  }
}
