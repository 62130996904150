import { concatMap, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of,  Observable} from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { LessonPlansStepsService } from '../../services/lesson-plans-steps.service';
import * as actions from './edit-lesson.actions';
import { AppendLessonStepToStore, RemoveLessonStepFromStore } from '../lesson/lesson.actions';

@Injectable()

export class EditLessonPlansEffect {
    constructor(
        private actions$: Actions,
        private store$: Store,
        private lessonPlansStepsService: LessonPlansStepsService
    ) {}
    getLessonPlanStep$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.LessonStepActionTypes.GetLessonStep),
            map((action: actions.GetLessonStep) => action.payload),
            switchMap((payload: any) => {
                return this.lessonPlansStepsService.get(payload).pipe(
                    map((res: any) => new actions.GetLessonStepSuccess(res)),
                    catchError((error) => of(new actions.GetLessonStepFail(error.message)))
                );
            })
        );
    });

    getAllLessonPlanSteps$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.LessonStepActionTypes.EditGetAllLessonSteps),
            map((action: actions.EditGetAllLessonSteps) => action.payload),
            switchMap((payload: any) => {
                return this.lessonPlansStepsService.getAll(payload).pipe(
                    map((res: any) => new actions.EditGetAllLessonStepsSuccess({
                        id: payload,
                        items: res
                    })),
                    catchError((error) => of(new actions.EditGetAllLessonStepsFail(error.message)))
                );
            })
        );
    });
    getCountByWorkbookId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.LessonStepActionTypes.GetWorkbookIdCount),
            map((action: actions.GetWorkbookIdCount) => action.payload),
            switchMap((payload: any) => {
                return this.lessonPlansStepsService.get(payload).pipe(
                    map((res: any) => new actions.GetWorkbookIdCountSuccess(res)),
                    catchError((error) => of(new actions.GetWorkbookIdCountFail(error.message)))
                );
            })
        );
    });

    // getAllLessonSteps$ = createEffect(() => {
    //     return this.actions$.pipe(
    //         ofType(actions.LessonStepActionTypes.GetAllLessonSteps),
    //         map((action: actions.GetAllLessonSteps) => action.payload),
    //         switchMap((payload: any) => {
    //             return this.lessonPlansStepsService.getAll(payload).pipe(
    //                 map((res: any) => new actions.GetAllLessonStepsSuccess(res)),
    //                 catchError((error) => of(new actions.GetAllLessonStepsFail(error.message)))
    //             );
    //         })
    //     );
    // });

    createLessonPlans$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.LessonStepActionTypes.CreateLessonStep),
            map((action: actions.CreateLessonStep) => action.payload),
            mergeMap((payload: any) => {
                return this.lessonPlansStepsService.create(payload).pipe(
                    switchMap((res: any) => {
                        return [
                            new actions.CreateLessonStepSuccess(res),
                            new AppendLessonStepToStore({
                                lessonId: res.lessonPlanId,
                                lessonStepId: res._id
                            })
                        ];
                    }),
                    catchError((error) => of(new actions.CreateLessonStepFail(error.message)))
                );
            })
        );
    });
    copyLessonPlans$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.LessonStepActionTypes.CopyLessonStep),
            map((action: actions.CopyLessonStep) => action.payload),
            switchMap((payload: any) => {
                return this.lessonPlansStepsService.copy(payload).pipe(
                    map((res: any) => new actions.CopyLessonStepSuccess(res)),
                    catchError((error) => of(new actions.CopyLessonStepFail(error.message)))
                );
            })
        );
    });
    updateLessonPlan$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.LessonStepActionTypes.UpdateLessonStep),
            map((action: actions.UpdateLessonStep) => action.payload),
            mergeMap((payload: any) => {
                const id = payload._id || payload.id;
                return this.lessonPlansStepsService.update(id, payload.lessonPlanStep).pipe(
                    map((res: any) => {
                        return new actions.UpdateLessonStepSuccess(payload);
                    }),
                    catchError((error) => of(new actions.UpdateLessonStepFail(error.message)))
                );
            })
        );
    });
    deleteLessonPlans$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.LessonStepActionTypes.DeleteLessonStep),
            map((action: actions.DeleteLessonStep) => action.payload),
            switchMap((payload: any) => {
                return this.lessonPlansStepsService.delete(payload.lessonStepId).pipe(
                    switchMap((res: any) => {
                        return [
                            new actions.DeleteLessonStepSuccess(payload.lessonStepId),
                            new RemoveLessonStepFromStore(payload)
                        ];
                    }),
                    catchError((error) => of(new actions.DeleteLessonStepFail(error.message)))
                );
            })
        );
    });


}
