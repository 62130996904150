import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, first } from "rxjs/operators";
import { selectGetAllLessonsLoaded } from "../store/";

@Injectable()
export class MainCourseLessonsResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(selectGetAllLessonsLoaded),
      filter(areLoaded => !!areLoaded),
      first(),
    );
  }
}
