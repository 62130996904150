import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, finalize, first, tap } from "rxjs/operators";
import * as PlanActions from '../store/plan/plan.actions';
import * as reducers from '../store';



@Injectable()
export class PlanResolver  {
  constructor(private store: Store<any>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(reducers.selectIsPlanLoaded),
      tap(areLoaded => {
        if (!areLoaded) {
          this.store.dispatch(new PlanActions.GetPlan(JSON.parse(localStorage.profile).user_metadata.cusID));
        }
      }),
      filter(areLoaded => areLoaded),
      first(),
    );
  }
}
