export enum FilterComponentTypes {
  checkbox,
  radio,
  textarea,
  dropdown,
  tiles,
  adjustable,
  pairings,
  multicheckbox,
  quickCheckbox,
  textareaLarge
}
