import { Store } from '@ngrx/store';
import { ViewWordsDialogComponent } from '../view-words-dialog/view-words-dialog.component';
import { Component, EventEmitter, ViewChild, ElementRef, Renderer2, Inject, Input, OnDestroy, OnInit, Output, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray, CdkDragEnter } from '@angular/cdk/drag-drop';
import { _variable_images } from '../../../environments/environment';
import { LessonPlanWarningDialogComponent } from '../lesson-plan-warning-dialog/lesson-plan-warning-dialog.component';
import { DataService } from '../../services/data.service';
import { Swappable, Sortable, Plugins } from '@shopify/draggable';
import { fromEvent, Subject } from 'rxjs';
import { selectCurrentTheme } from '../../store';
import { takeUntil } from 'rxjs/operators';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Component({
  selector: 'app-lesson-plan-card',
  templateUrl: './html/lesson-plan-card.html',
  styleUrls: [
    '../../styles/workbook-cards.scss',
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss'

  ]
})
export class LessonPlanCardsComponent implements OnInit, OnDestroy {
  // '././././assets/svgs/solid/add-circle-curriculum.svg';
  @ViewChild('reorderTable', { static: false }) reorderTable: ElementRef;

  @Input() set canReorder(reorder: boolean) {
    this.toggleReorder = reorder;
    if (reorder === true) {
      setTimeout(() => {
        this.makeReorder();

      }, 1000);
    }
  }
  @Input() lessonPlans: any[] = [];
  @Input() hasSettings = false;
  @Input() hasNew = false;
  @Input() course: any;
  @Input() userId: string;
  @Output() addNew: EventEmitter<any> = new EventEmitter();
  @Output() settingsClick: EventEmitter<any> = new EventEmitter(false);
  @Output() cardClick: EventEmitter<any> = new EventEmitter(false);
  @Output() deleteLessonPlan: EventEmitter<any> = new EventEmitter();
  @Output() addLessonPlan: EventEmitter<any> = new EventEmitter();
  @Output() copyLessonPlan: EventEmitter<any> = new EventEmitter();
  @Output() editLessonPlan: EventEmitter<any> = new EventEmitter();
  @Output() begin: EventEmitter<any> = new EventEmitter();
  @Output() changeOrder: EventEmitter<any> = new EventEmitter();

  addCircleCurriculumImage: any;

  dragPosition = { x: 0, y: 0 };
  public containers: any;
  public draggingIndex: any;
  public swappingIndex: any;
  public toggleReorder: boolean;
  private unsubscribe$: Subject<void> = new Subject();
  public emailVerified: boolean = JSON.parse(localStorage.profile).email_verified;

  constructor(private dialog: MatDialog,
    private dataService: DataService,
    private r2: Renderer2,
    private store: Store
  ) {
    // this.addCircleCurriculumImage = _variable_images + 'add-circle-1.png';
  }

  ngOnInit(): void {
    this.dataService._variable_images.pipe(takeUntil(this.unsubscribe$)).subscribe(path => {
      this.addCircleCurriculumImage = path + 'add-circle-1.png';
    });
    const i = this.lessonPlans;
    const j = i;

    this.store.select(selectCurrentTheme)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(theme => {
        if (theme === 'theme-dark') {
          this.addCircleCurriculumImage = 'assets/svgs/regular/add-circle-2.svg';
        } else {
          this.addCircleCurriculumImage = 'assets/svgs/regular/add-circle-1.svg';
        }
      });
  }

  // ngAfterViewInit(): void {
  //   setTimeout(() => {
  //     this.makeReorder();

  //   }, 1000);
  // }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  lessonPlanWarning(lessonPlanId) {
    // if (this.userId !== this.course.teacherKey) {
    //   const dialogRef = this.dialog.open(LessonPlanWarningDialogComponent, {
    //     panelClass: 'small-dialog',
    //     data: {
    //       title: 'Lesson Plan Error',
    //       message:  'This lesson plan cannot be loaded. Please contact the lesson\'s owner.',
    //       subscribed: true
    //     },

    //   }).afterClosed().subscribe((value) => {
    //     if (value) {
    //       this.editLessonPlan.emit(lessonPlanId);
    //     }
    //   });

    // } else {
    //   const dialogRef = this.dialog.open(LessonPlanWarningDialogComponent, {
    //     panelClass: 'small-dialog',
    //     data: {
    //       title: 'Lesson Plan Error',
    //       message:  'This lesson plan cannot be loaded because it does not have any content.',
    //       subscribed: false
    //     },

    //   }).afterClosed().subscribe((value) => {
    //     if (value) {
    //       this.editLessonPlan.emit(lessonPlanId);
    //     }
    //   });

    // }
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      panelClass: 'custom-confirm-dialog',
      data: {
        title: 'Lesson Plan Content Missing',
        content: "Please click on the three dots on this lesson and then select 'Edit' to add content to this lesson before beginning it."
      }
    });
    dialogRef.afterClosed().subscribe();

  }
  public beginEvent(lessonPlan) {
    if (lessonPlan.lessonPlanCount < 1 || !lessonPlan.lessonPlanSteps?.length) {
      this.lessonPlanWarning(lessonPlan._id);
    } else {
      this.begin.emit(lessonPlan);
    }
  }
  public trigEditLessonPlan(lessonPlanId) {
    this.editLessonPlan.emit(lessonPlanId);
  }
  public trigCopyLessonPlan(lessonPlanId) {
    this.copyLessonPlan.emit(lessonPlanId);
  }
  public trigDeleteLessonPlan(lessonPlanId) {
    this.deleteLessonPlan.emit(lessonPlanId);
  }

  cardClickEvent(workbook) {
    this.cardClick.emit(workbook);
  }

  settingsClickEvent($event, workbook) {
    $event.stopPropagation();
    this.settingsClick.emit(workbook);
  }

  createNewLessonPlan() {
    this.addNew.emit();
  }

  makeReorder() {

    const tableElement = this.reorderTable.nativeElement;
    tableElement.innerHTML = '';
    
    // grab table container
    // for each workbook of workbooks
    this.dataService._variable_images.pipe(takeUntil(this.unsubscribe$)).subscribe((path: any) => {
      let tr = this.r2.createElement('tr');
      this.r2.addClass(tr, 'reorderContainer');
      let first = this.r2.createElement('td');
      // this.r2.addClass(first, 'workbook');
      this.r2.addClass(first, 'filler');

      let filler = this.r2.createElement('div');
      this.r2.addClass(filler, 'workbook');
      first.appendChild(filler);
      // if ('assets/img/theme-dark/' === path) {
      //   this.r2.addClass(first, 'add-success-dark');
      // } else {
      //   this.r2.addClass(first, 'add-success-light');
      // }
      this.r2.appendChild(tr, first);
      this.lessonPlans.forEach((workbook, index, array) => {
        // create parent div
        let div = this.r2.createElement('td');
        this.r2.setProperty(div, 'id', workbook._id);

        this.r2.addClass(div, 'dropzone');
        this.r2.addClass(div, 'drag_box');

        // create child div with material card class
        let matDiv = this.r2.createElement('div');
        this.r2.addClass(matDiv, 'mat-card');
        this.r2.addClass(matDiv, 'workbook');
        this.r2.addClass(matDiv, 'content');
        this.r2.addClass(matDiv, 'reorder-workbook');
        this.r2.setProperty(matDiv, 'id', index);
        // add font color class "primary-light"
        this.r2.addClass(matDiv, 'primary-light');
        // on 'click' event fire cardClickEvent function
        let matDivClick = fromEvent(matDiv, 'click');
        matDivClick.pipe(takeUntil(this.unsubscribe$)).subscribe((event: any) => {
          this.cardClickEvent(workbook);
        });
        // create child of child div
        let subDiv = this.r2.createElement('div');

        // Create div to hold the ellipsis button and "Remove" button
        let actionDiv = this.r2.createElement('div');
        this.r2.addClass(actionDiv, 'action-div');

        // create mat-button
        let button = this.r2.createElement('button');
        this.r2.addClass(subDiv, 'example-handle');
        this.r2.addClass(button, 'mat-icon-button');
        // add class 'ellipsis' to button
        this.r2.addClass(button, 'ellipsis');

        // add i tag with classes 'fas' 'fa-ellipsis-v'
        let el1 = this.r2.createElement('img');

        this.r2.setAttribute(el1, 'src', path + 'move.png');
        this.r2.appendChild(button, el1);

        // create a delete button for deleting workbook from course
        let removeButton = this.r2.createElement('button');
        this.r2.setProperty(removeButton, 'textContent', "Delete");
        this.r2.addClass(removeButton, 'remove-btn');
        this.r2.addClass(removeButton, 'bg_transparent_dark_salmon');
        this.r2.listen(removeButton, 'mousedown', (event) => {
          event.stopPropagation();
          this.trigDeleteLessonPlan(workbook._id);
        });

        let action = this.r2.createElement('div');
        // create div with mat-card-actions classes attached
        this.r2.addClass(action, 'mat-card-actions');
        this.r2.addClass(action, 'actions');
        // add style 'padding' of '10px' to actions div
        this.r2.setStyle(action, 'padding', '10px');
        let text = this.r2.createText(workbook.name);
        this.containers = [];
        this.containers.push(matDiv);

        // append respective children to their parents
        this.r2.appendChild(action, text);

        this.r2.appendChild(actionDiv, button);
        this.r2.appendChild(actionDiv, removeButton);

        this.r2.appendChild(subDiv, actionDiv);
        this.r2.appendChild(subDiv, action);
        this.r2.appendChild(matDiv, subDiv);
        this.r2.appendChild(div, matDiv);
        this.r2.appendChild(tr, div);

      });
      if (this.reorderTable) {
        this.r2.appendChild(this.reorderTable.nativeElement, tr);
      }

      let newIndex = -1;
      let oldIndex = -1;

      // add Draggablejs swappable object to child element
      const sortAble = new Sortable(tr, {
        draggable: '.drag_box',
        // @ts-ignore
        dropzone: '.dropzone',
        swapAnimation: { duration: 500, easingFunction: "ease-in-out", horizontal: true },
        plugins: [Plugins.SwapAnimation]
      });

      sortAble.on('drag:start', (event: any) => {
        if (event.originalEvent.target.closest('.remove-btn')) {
          event.cancel();
        }
      });

      sortAble.on('drag:stop', (event: any) => {
        if (!event.data.source) { return; }

        setTimeout(() => {
          let newOrderIds = Array.from(this.reorderTable.nativeElement.childNodes[0].childNodes)
            .filter((node: any) => node?.id && node.cellIndex > -1)
            .sort((a: any, b: any) => {
              return a.cellIndex - b.cellIndex;
            })
            .map((node: any) => node.id);

          this.lessonPlans = this.lessonPlans.sort((a: any, b: any) => {
            return newOrderIds.indexOf(a._id) - newOrderIds.indexOf(b._id);
          }).map((lessonPlan, index) => {
            lessonPlan.order = index;
            return lessonPlan;
          });
          this.changeOrder.emit(this.lessonPlans);
        }, 100);
      });

      // sortAble.on('sortable:sorted', (evt) => {
      //   newIndex = evt.data.newIndex;
      //   oldIndex = evt.data.oldIndex;
      // });

      // const swappable = new Swappable(tr, {
      //   draggable: '.reorder-workbook',
      //   mirror: {
      //     appendTo: this.reorderTable.nativeElement,
      //     constrainDimensions: true
      //   },
      //   plugins: [Plugins.ResizeMirror]
      // });

      //   swappable.on('swappable:start', (event: any) => {

      //     this.draggingIndex = event.data.dragEvent.data.originalSource;

      //   });
      //   swappable.on('swappable:swapped', (event: any) => {
      //     // this.swappingIndex = parseInt(event.data.swappedElement.id, 10)
      //     this.swappingIndex = event.data.swappedElement;

      //   });
      //   swappable.on('swappable:stop', (event: any) => {
      //     const swappingIndex = parseInt(this.swappingIndex.id, 10);
      //     const currentIndex = parseInt(this.draggingIndex.id, 10);
      //     const temp = this.lessonPlans[currentIndex];
      //     this.lessonPlans[currentIndex] = this.lessonPlans[swappingIndex];
      //     this.lessonPlans[swappingIndex] = temp;
      //     // @ts-ignore
      //     // [this.workbooks[currentIndex], this.workbooks[swappingIndex]] = [this.workbooks[swappingIndex], this.workbooks[currentIndex]]
      //     this.r2.removeAttribute(this.swappingIndex, 'id');
      //     this.r2.removeAttribute(this.draggingIndex, 'id');
      //     this.r2.setAttribute(this.draggingIndex, 'id', `${swappingIndex}`);
      //     this.r2.setAttribute(this.swappingIndex, 'id', `${currentIndex}`);


      //     this.changeOrder.emit(this.lessonPlans);
      // });

    });


    // -- end -- //
  }

  entered(event: CdkDragEnter) {
    moveItemInArray(this.lessonPlans, event.item.data, event.container.data);
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.lessonPlans, event.previousIndex, event.currentIndex);
  }

  dropped(event: any) {
    this.changeOrder.emit(this.lessonPlans);
  }

  sortedLessons() {
    return this.lessonPlans.sort((a, b) => {
      if (!a.order || !b.order) {
        return 0;
      }

      if (a.order > b.order) {
        return 1;
      }

      if (a.order < b.order) {
        return -1;
      }

      return 0;
    });
  }
}
