import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum PhraseActionTypes {
    GetManyPhrases = '[PhraseService] Get Many Phrases',
    GetManyPhrasesSuccess = '[PhraseService] Get Many Phrases Success',
    GetManyPhrasesFail = '[PhraseService] Get Many Phrases Fail',

    ClearWorkbookPhrases = '[PhraseService] CLear Workbook Phrases',
    ClearWorkbookPhrasesSuccess = '[PhraseService] CLear Workbook Phrases Success',
    ClearWorkbookPhrasesFail = '[PhraseService] CLear Workbook Phrases Fail',


    GetManyWorkbookPhrases = '[PhraseService] Get Many Workbook Phrases',
    GetManyWorkbookPhrasesSuccess = '[PhraseService] Get Many Workbook Phrases Success',
    GetManyWorkbookPhrasesFail = '[PhraseService] Get Many Workbook Phrases Fail',

    CreatePhrase = '[PhraseService] Create Phrase',
    CreatePhraseSuccess = '[PhraseService] Create Phrase Success',
    CreatePhraseFail = '[PhraseService] Create Phrase Fail',

    CreateWorkbookPhrase = '[PhraseService] Create Workbook Phrase',
    CreateWorkbookPhraseSuccess = '[PhraseService] Create Workbook Phrase Success',
    CreateWorkbookPhraseFail = '[PhraseService] Create Workbook Phrase Fail',

    UpdatePhrase = '[PhraseService] Update Phrase',
    UpdatePhraseSuccess = '[PhraseService] Update Phrase Success',
    UpdatePhraseFail = '[PhraseService] Update Phrase Fail',

    UpdateWorkbookPhrase = '[PhraseService] Update Workbook Phrase',
    UpdateWorkbookPhraseSuccess = '[PhraseService] Update Workbook Phrase Success',
    UpdateWorkbookPhraseFail = '[PhraseService] Update Workbook Phrase Fail',

    DeletePhrase = '[PhraseService] Delete Phrase',
    DeletePhraseSuccess = '[PhraseService] Delete Phrase Success',
    DeletePhraseFail = '[PhraseService] Delete Phrase Fail',

    DeleteWorkbookPhrase = '[PhraseService] Delete Workbook Phrase',
    DeleteWorkbookPhraseSuccess = '[PhraseService] Delete Workbook Phrase Success',
    DeleteWorkbookPhraseFail = '[PhraseService] Delete Workbook Phrase Fail',

    DeleteManyWorkbookPhrase = '[PhraseService] Delete Many Workbook Phrase',
    DeleteManyWorkbookPhraseSuccess = '[PhraseService] Delete Many Workbook Phrase Success',
    DeleteManyWorkbookPhraseFail = '[PhraseService] Delete Many Workbook Phrase Fail',

}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class ClearWorkbookPhrases implements Action {
    readonly type = PhraseActionTypes.ClearWorkbookPhrases;

}
export class ClearWorkbookPhrasesSuccess implements Action {
    readonly type = PhraseActionTypes.ClearWorkbookPhrasesSuccess;

    constructor(public payload: any) {}
}
export class ClearWorkbookPhrasesFail implements Action {
    readonly type = PhraseActionTypes.ClearWorkbookPhrasesFail;

    constructor(public payload: any) {}
}

export class GetManyPhrases implements Action {
    readonly type = PhraseActionTypes.GetManyPhrases;

    constructor(public payload: any) {}
}
export class GetManyPhrasesSuccess implements Action {
    readonly type = PhraseActionTypes.GetManyPhrasesSuccess;

    constructor(public payload: any) {}
}
export class GetManyPhrasesFail implements Action {
    readonly type = PhraseActionTypes.GetManyPhrasesFail;

    constructor(public payload: any) {}
}


export class GetManyWorkbookPhrases implements Action {
    readonly type = PhraseActionTypes.GetManyWorkbookPhrases;

    constructor(public payload: any) {}
}
export class GetManyWorkbookPhrasesSuccess implements Action {
    readonly type = PhraseActionTypes.GetManyWorkbookPhrasesSuccess;

    constructor(public payload: any) {}
}
export class GetManyWorkbookPhrasesFail implements Action {
    readonly type = PhraseActionTypes.GetManyWorkbookPhrasesFail;

    constructor(public payload: any) {}
}

export class CreatePhrase implements Action {
    readonly type = PhraseActionTypes.CreatePhrase;

    constructor(public payload: any) {}
}
export class CreatePhraseSuccess implements Action {
    readonly type = PhraseActionTypes.CreatePhraseSuccess;

    constructor(public payload: any) {}
}
export class CreatePhraseFail implements Action {
    readonly type = PhraseActionTypes.CreatePhraseFail;

    constructor(public payload: any) {}
}

export class CreateWorkbookPhrase implements Action {
    readonly type = PhraseActionTypes.CreateWorkbookPhrase;

    constructor(public payload: any) {}
}
export class CreateWorkbookPhraseSuccess implements Action {
    readonly type = PhraseActionTypes.CreateWorkbookPhraseSuccess;

    constructor(public payload: any) {}
}
export class CreateWorkbookPhraseFail implements Action {
    readonly type = PhraseActionTypes.CreateWorkbookPhraseFail;

    constructor(public payload: any) {}
}

export class UpdatePhrase implements Action {
    readonly type = PhraseActionTypes.UpdatePhrase;

    constructor(public payload: any) {}
}
export class UpdatePhraseSuccess implements Action {
    readonly type = PhraseActionTypes.UpdatePhraseSuccess;

    constructor(public payload: any) {}
}
export class UpdatePhraseFail implements Action {
    readonly type = PhraseActionTypes.UpdatePhraseFail;

    constructor(public payload: any) {}
}

export class UpdateWorkbookPhrase implements Action {
    readonly type = PhraseActionTypes.UpdateWorkbookPhrase;

    constructor(public payload: any) {}
}
export class UpdateWorkbookPhraseSuccess implements Action {
    readonly type = PhraseActionTypes.UpdateWorkbookPhraseSuccess;

    constructor(public payload: any) {}
}
export class UpdateWorkbookPhraseFail implements Action {
    readonly type = PhraseActionTypes.UpdateWorkbookPhraseFail;

    constructor(public payload: any) {}
}

export class DeletePhrase implements Action {
    readonly type = PhraseActionTypes.DeletePhrase;

    constructor(public payload: any) {}
}
export class DeletePhraseSuccess implements Action {
    readonly type = PhraseActionTypes.DeletePhraseSuccess;

    constructor(public payload: any) {}
}
export class DeletePhraseFail implements Action {
    readonly type = PhraseActionTypes.DeletePhraseFail;

    constructor(public payload: any) {}
}

export class DeleteWorkbookPhrase implements Action {
    readonly type = PhraseActionTypes.DeleteWorkbookPhrase;

    constructor(public payload: any) {}
}
export class DeleteWorkbookPhraseSuccess implements Action {
    readonly type = PhraseActionTypes.DeleteWorkbookPhraseSuccess;

    constructor(public payload: any) {}
}
export class DeleteWorkbookPhraseFail implements Action {
    readonly type = PhraseActionTypes.DeleteWorkbookPhraseFail;

    constructor(public payload: any) {}
}

export class DeleteManyWorkbookPhrase implements Action {
    readonly type = PhraseActionTypes.DeleteManyWorkbookPhrase;

    constructor(public payload: any) {}
}
export class DeleteManyWorkbookPhraseSuccess implements Action {
    readonly type = PhraseActionTypes.DeleteManyWorkbookPhraseSuccess;

    constructor(public payload: any) {}
}
export class DeleteManyWorkbookPhraseFail implements Action {
    readonly type = PhraseActionTypes.DeleteManyWorkbookPhraseFail;

    constructor(public payload: any) {}
}

export type PhraseActions
= ClearWorkbookPhrases
| ClearWorkbookPhrasesSuccess
| ClearWorkbookPhrasesFail
| GetManyPhrases
| GetManyPhrasesFail
| GetManyPhrasesSuccess
| GetManyWorkbookPhrases
| GetManyWorkbookPhrasesFail
| GetManyWorkbookPhrasesSuccess
| CreatePhrase
| CreatePhraseFail
| CreatePhraseSuccess
| CreateWorkbookPhrase
| CreateWorkbookPhraseFail
| CreateWorkbookPhraseSuccess
| UpdatePhrase
| UpdatePhraseSuccess
| UpdatePhraseFail
| UpdateWorkbookPhrase
| UpdateWorkbookPhraseFail
| UpdateWorkbookPhraseSuccess
| DeletePhrase
| DeletePhraseFail
| DeletePhraseSuccess
| DeleteWorkbookPhrase
| DeleteWorkbookPhraseFail
| DeleteWorkbookPhraseSuccess
| DeleteManyWorkbookPhrase
| DeleteManyWorkbookPhraseFail
| DeleteManyWorkbookPhraseSuccess;
