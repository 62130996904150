
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Component,
  EventEmitter,
  Renderer2,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  RendererFactory2,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  ElementRef,
  QueryList,
  HostListener,
  OnDestroy
} from '@angular/core';
import { UtilityService } from '../../services/utility.service';
import { first, takeUntil } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import {SettingsService} from '../../services/new-settings.service';
import {UserService} from '../../services/user.service';
import {DragDrop} from '@angular/cdk/drag-drop';
import {Droppable} from '@shopify/draggable';
import {ListenerService} from '../../services/listener.service';
import {Subject, Subscription, interval} from 'rxjs';
import { getCurrentSetting, selectCurrentTheme } from '../../store';
import { delay, throttle } from 'rxjs/operators';

@Component({
  selector: 'app-tile-bank',
  templateUrl: './html/tile-bank.html',
  styleUrls: [
    './tile-bank.scss',
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss'
  ]
})

export class TileBankComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('table', { static: false }) public table: ElementRef;
  @Input() cols: number;
  @Input() rowHeight: string;
  // @ts-ignore
  // @Input() rows = [];
  @Input() tileSize = 2;

  @Input() userSettings: any;
  @Input() dropZone: any;
  @Input() set getRows(rows: any[]) {
    if (rows.length > 0 && rows.every((row) => "") !== true) {
      if (this.containers.length > 0) {
        // this.containers.forEach((tr, index) => this.r2.removeChild(this.table.nativeElement, tr.sourceElement));
        this.tileElements = [];
        this.makeTable(rows);
      } else {
        this.rows = rows;
        setTimeout(() => {
          this.makeTable(rows);
          this.setTilePositions();
        }, 500)

      }
    }
  }
  @Output() draggingFromDrawerEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() sight: boolean;
  @Input() freezeRows: any[] = [];
  @Input() bgcolor: string;
  @Input() lowerCase: boolean;
  @Input() showGrid = false;
  @Input() tileWidth: number;
  @Input() height = 60;
  @Input() padding = 0;
  @Output() simpleDropEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() draggingEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() dropzoneIdsUpdatedEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChildren("grid") grid: QueryList<any>;
  @Input() type: string;
  private r2: Renderer2;
  @Input() isBlankTiles = false;
  private tableDrop: any;
  private tileClassList = [
    'x-small-tile',
    'small-tile',
    'medium-tile',
    'large-tile',
    'x-large-tile'
  ];

  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';
  public rows = [];
  public dropZoneIds: any[] = [];
  public containers = [];
  public isLoading = true;
  public tileElements = [];
  public currentObject: any;
  public currentObject2: any;
  public currentTileDragId: string;
  public currentTileDragId2: string;
  public pickUpPosition: any;
  public tiles = [];
  public tileBounds: any;
  public canDrop: boolean;
  public currentDropId: any;
  public prevParent: any;
  private tileProperties: any = [];
  private subscriptions = new Subscription();
  private screenActive = false;
  private currentTileSize = 2;
  private unsubscribe$: Subject<void> = new Subject();
  private theme;
  public tableBuilt = false;

  constructor(public utilityService: UtilityService,
    private userService: UserService,
    private settingsService: SettingsService,
    rendererFactory: RendererFactory2,
    private dragDrop: DragDrop,
    private listener: ListenerService,
    private store: Store,
    private router: Router
    ) {
      this.r2 = rendererFactory.createRenderer(null, null);

    }
  ngOnInit() {
    const userId = JSON.parse(localStorage.profile).user_metadata.uid;
    this.isLoading = false;
    this.lowerCase = true;
    // const interval = setInterval(() => {
    //   if (this.settingsService.activitySettings) {
    //     this.userSettings = this.settingsService.activitySettings;
    //     this.makeTable(this.rows);
    //     this.rows.forEach((row, i) => row.forEach((col, j) => this.dropZoneIds.push(`containerr${i} c${j}`)));
    //     this.dropzoneIdsUpdatedEvent.emit(this.dropZoneIds);

    //     clearInterval(interval);
    //   }
    // }, 300);

    // setTimeout(() => {
    //   this.makeTable(this.rows);
    //   this.rows.forEach((row, i) => row.forEach((col, j) => this.dropZoneIds.push(`containerr${i} c${j}`)));
    //   this.dropzoneIdsUpdatedEvent.emit(this.dropZoneIds);
    // }, 300);


    this.store.select(selectCurrentTheme)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(theme => {
        this.theme = theme;
      });
  }

  ngAfterViewInit() {

  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.router.url.includes('settings/tiles')) {
      return;
    }
    if (changes.tileSize) {
      this.toggleSize(changes.tileSize.currentValue);
    } else if (changes?.lowerCase?.previousValue !== 'undefined') {
      let tiles = document.getElementsByClassName('text-holder');
      if (this.lowerCase) {
        Array.from(tiles).forEach((tile, index, array) => {
          let text = tile.innerHTML;
          tile.innerHTML = text.charAt(0).toLowerCase() + text.slice(1);

        });
      } else {
        Array.from(tiles).forEach((tile, index, array) => {
          let text = tile.innerHTML;
          tile.innerHTML = text.charAt(0).toUpperCase() + text.slice(1);

        });

      }

    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setTilePositions() {
    this.tileBounds = {};
    setTimeout(() => {
      if (this.tileElements.length === 0) {
        return;
      }

      this.tileElements
        .filter(key => key !== '')
        .forEach(key => {
          if (document.getElementById(key)) {
            this.tileBounds[key] = document.getElementById(key).getBoundingClientRect();
          }
        });

      let tileWidths = window.screen.width === UtilityService.baseResolutionWidth
        ? this.utilityService.tileBaseWidths
        : this.utilityService.tileHDWidths;

      if (this.tileProperties.length > 0) {
        this.tileProperties.forEach((tile, i) => {
          if (tileWidths[tile.name] && tileWidths[tile.name][this.currentTileSize]) {
            this.tileProperties[i].width = tileWidths[tile.name][this.currentTileSize];
          }
        });
      }

      this.screenActive = true;
    }, 100);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
  }

  // getTileClasses(col: string) {
  //   if (!col) {
  //   }
  //   let obj = {
  //     'x-small-tile': this.tileSize === 0,
  //     'small-tile': this.tileSize === 1,
  //     'medium-tile': this.tileSize === 2,
  //     'large-tile': this.tileSize === 3,
  //     'x-large-tile': this.tileSize === 4,
  //       };

  //   if (this.sight) {
  //     const sight = this.userSettings.tiles.sight;
  //     const fontColor = sight.tilefontcolor;
  //     const bgColor = sight.tilebgcolor;


  //     obj[fontColor] = true;
  //     obj[bgColor] = true;
  //     return obj;
  //   } else {
  //     obj[this.utilityService.getTileClasses(col, this.userSettings)] = true;
  //     obj[this.userSettings.tilefont] = true;
  //     return obj;
  //   }
  // }

  getTileClasses(col: string) {
    if (this.sight) {
      const sight = this.userSettings.tiles.sight;
      const fontColor = sight.tilefontcolor;
      const bgColor = sight.tilebgcolor;

      return `${bgColor} ${fontColor}`;
    } else {
      return `${this.utilityService.getTileClasses(col, this.userSettings)} ${this.userSettings?.tilefont}`;
    }
  }

  shadow(event) {
  }
  getTilePreview(col: string) {
    if (this.sight) {
      const sight = this.userSettings.tiles.sight;
      const fontColor = sight.tilefontcolor;
      const bgColor = sight.tilebgcolor;

      return `${bgColor} ${fontColor}`;
    } else {
      return `${this.utilityService.getTileClasses(col, this.userSettings)} ${this.userSettings.tilefont} ${this.userSettings.tilehighlightcolor}`;
    }
  }

  allowDrop(value: any) {
    return () => value === '';
  }

  toggleSize(size: number) {
    this.containers.forEach((container, index, array) => {
      let classList = Array.from(container.tile.classList);
      this.tileClassList.forEach(tileClass => {
        if (classList.includes(tileClass)) {
          this.r2.removeClass(container.tile, tileClass);
          this.r2.removeStyle(container.sourceContainer, 'min-width');
          this.r2.removeStyle(container.sourceContainer, 'height');

        }
      });
    });
    this.currentTileSize = size;
    switch (size) {
      case 0:
        // xs
        this.containers.forEach((container, index, array) => {
          this.r2.addClass(container.tile, this.tileClassList[0]);
          this.r2.setStyle(container.sourceContainer, 'min-width', '32px');
          this.r2.setStyle(container.sourceContainer, 'height', '30px');


        });
        break;

      case 1:
        // s
        this.containers.forEach((container, index, array) => {
          this.r2.addClass(container.tile, this.tileClassList[1]);
          this.r2.setStyle(container.sourceContainer, 'min-width', '42px');
          this.r2.setStyle(container.sourceContainer, 'height', '40px');


        });

        break;

      case 3:
        // L
        this.containers.forEach((container, index, array) => {
          this.r2.addClass(container.tile, this.tileClassList[3]);
          this.r2.setStyle(container.sourceContainer, 'min-width', '82px');
          this.r2.setStyle(container.sourceContainer, 'height', '80px');

        });

        break;

      case 4:
        // XL
        this.containers.forEach((container, index, array) => {
          this.r2.addClass(container.tile, this.tileClassList[4]);
          this.r2.setStyle(container.sourceContainer, 'min-width', '102px');
          this.r2.setStyle(container.sourceContainer, 'height', '100px');

        });

        break;

      case 2:
      default:
        // M
        this.containers.forEach((container, index, array) => {
          this.r2.addClass(container.tile, this.tileClassList[2]);
          this.r2.setStyle(container.sourceContainer, 'min-width', '62px');
          this.r2.setStyle(container.sourceContainer, 'height', '60px');

        });

        break;
    }

    this.setTilePositions();
    setTimeout(() => this.listener.callback('resize'));
  }

makeTable(allRows) {
  let rows = [];
  // check if the current route is /activity/spelling
  if (this.tableBuilt && this.router.url.includes('activity/spelling')) {
    return;
  }

  const childElements = this.table.nativeElement.children;
  for (let child of childElements) {
    this.r2.removeChild(this.table.nativeElement, child);
  }
  this.tableBuilt = true;
  if (this.type === 'activity') {

    let firstCol = allRows[0]?.length;
    let lastCol = 0;

    allRows.forEach((row, i) => {
      const col1 = row.findIndex(item => item);
      if (col1 > -1 && col1 < firstCol) {
        firstCol = col1;
      }
      row.forEach((item, j) => {
        if (item && j > lastCol) {
          lastCol = j;
        }
      });
    });

    allRows.forEach(col => {
      rows.push(col.slice(firstCol, lastCol + 1));
    });
  } else {
    rows = allRows;
  }
  rows.forEach((row, i) => {
    // create rows
    let tr = this.r2.createElement('tr');
    row.forEach((col, j) => {
      // create coloumns
      let td = this.r2.createElement('td');
      this.r2.setAttribute(td, 'id', `containerr${i} c${j}`);
      this.r2.addClass(td, 'dropzone');
      this.r2.setStyle(td, 'min-width', '62px');
      this.r2.setStyle(td, 'height', '60px');
      if (this.showGrid) {
        this.r2.addClass(td, 'shadow');
      }
      // create draggable container
      let tile = this.r2.createElement('div');
      let span = this.r2.createElement('span');
      let cdkTile = this.dragDrop.createDrag(tile);
      tile.id = col;
      this.tileElements.push(col);
      // cdkTile.
      let cdkDropList = this.dragDrop.createDropList(td);
      cdkDropList.withItems([cdkTile]);
      if (col !== '') {
        this.r2.addClass(tile, 'inside-tile');
        this.r2.addClass(tile, 'bank-tile');
        // if (this.type !== 'activity') {
        //   let img = this.r2.createElement('img')
        //   img.src = `assets/img/theme1/move-modal.png`;
        //   this.r2.appendChild(tile, img)
        // }
      } else {
        this.r2.setStyle(tile, 'height', '100%');
        this.r2.setStyle(tile, 'width', '100%');
      }
      // this.r2.setAttribute(tile, 'id', `container r${i} c${j}`)
      const tileType = col.substring(col.indexOf('.') + 1, col.lastIndexOf('.'));
      if (tileType !== 'blank') {
        let string = this.utilityService.convertSymbolTiles(this.returnLetters(col), tileType);
        // string = this.lowerCase || this.lowerCase === undefined ? string : string.charAt(0).toUpperCase() + text.slice(1)
        let text = this.r2.createText(string);
        this.r2.appendChild(span, text);
        this.r2.addClass(span, 'text-holder');
        this.r2.setStyle(span, 'padding', '0 15px');
      }
      this.r2.appendChild(tile, span);

      // get settings classes & split class string into array...
      let classes = this.getTileClasses(col).split(' ');
      // add necessary classes...
      classes.forEach((styleClass, j) => styleClass !== "" ? this.r2.addClass(tile, styleClass) : false);
      this.dropZoneIds.push(`containerr${i} c${j}`);

      // if (!col) {
      //   tile = this.r2.createElement('div');
      // }
      this.r2.appendChild(td, tile);
      this.r2.appendChild(tr, td);

      // events are fired below...

      // instantiate the droppable class

      // move event
     this.subscriptions.add(cdkTile.started.subscribe((event: any) => {
        this.currentTileDragId = this.utilityService.randomString(8);
        let part = col.split('.')[1];
        let value = col.split('.')[2];
        let width = this.tileBounds[col]?.width;
        this.currentObject = {
          value: this.lowerCase || this.lowerCase === undefined ? part === 'blank' ? '' : value : value.charAt(0).toUpperCase() + value.slice(1),
          tileClass: `${event.source._rootElement.classList.value}`,
          dragId: this.currentTileDragId,
          width: width,
          lockAxis: '',
          name: col
        };
        this.pickUpPosition = event.source._pickupPositionOnPage;
        this.tileProperties.push(this.currentObject);

        this.tiles.push({x: -10000, y: -10000});

      }));
     this.subscriptions.add(cdkTile.moved.subscribe((event: any) => {
       if (Array.isArray(this.dropZone)) {
        // this.dropZone.forEach((dropzone, i) => {
        //   let bounds = document.getElementById(col).getBoundingClientRect();
        //   let stableDiv = dropzone.getBoundingClientRect();
        //   if (this.currentObject) {
        //     this.currentObject.position = {x: event.pointerPosition.x , y: event.pointerPosition.y};
        //     this.dragging(event, col, dropzone.id);
        //   }
        //   let verticalPredicate = event.pointerPosition.y + bounds.height >= stableDiv.y
        //   && event.pointerPosition.y <= stableDiv.y + stableDiv.height;
        //   if (verticalPredicate) {
        //     this.canDrop = true;
        //     this.currentDropId = parseInt(dropzone.id, 10)
        //   } else {
        //     this.canDrop = false;
        //   }

        // })

        if (parseInt(event.event.target.id, 10) || event.event.target.id === '0') {
          this.canDrop = true;
          this.currentDropId = parseInt(event.event.target.id, 10);
        } else  {
          this.canDrop = false;
        }
       } else {
        this.currentTileDragId2  = event.event?.target?.parentElement?.id.split('container');
        if (this.currentObject2) {

          let id = `container${this.currentObject2.id}`;
          if (id !== event.event.target.parentElement.id && (this.currentTileDragId2[0] !== '' || !this.currentTileDragId2.includes('testing-table'))) {
            let target = document.getElementById(id);
          }
        }

        if (this.currentTileDragId2.length > 1 && (this.currentTileDragId2[0] !== '' || !this.currentTileDragId2.includes('testing-table') && this.currentTileDragId2[1] !== '' )) {

          let nextIds = this.parseId(this.currentTileDragId2[1]);
          // if target cell is empty, make drop able it with green border
          let target: any = document.getElementById(`container${this.currentTileDragId2[1]}`).childNodes[0];

          if ((!this.rows[nextIds.rowId][nextIds.colId] || target.id === "") || this.rows[nextIds.rowId][nextIds.colId] === this.currentObject.name) {
            let obj = {
              id: this.currentTileDragId2[1],
              previousId: '',
              tile: col
            };
            this.currentObject2 = obj;
            this.canDrop = true;
            // if (event.event.target.parentElement.id !== 'container') {
            //   this.r2.setStyle(event.event.target.parentElement, 'border', '3px solid #6dd378');
            // }
          }
        } else {
          if (this.currentObject) {
            this.currentObject.position = {x: event.pointerPosition.x , y: event.pointerPosition.y};
            this.dragging(event, col, `containerr${i} c${j}`);
          }

          let bounds = document.getElementById(col)?.getBoundingClientRect();
          let stableDiv = this.dropZone.nativeElement?.getBoundingClientRect();
          if (!(bounds && stableDiv)) return;
          let verticalPredicate = event.pointerPosition.y + bounds.height >= stableDiv.y
          && event.pointerPosition.y <= stableDiv.y + stableDiv.height;
          if (verticalPredicate) {
            this.canDrop = true;
          } else {
            this.canDrop = false;
          }

        }

       }

      }));

      this.subscriptions.add(cdkDropList
        .dropped
        .pipe(throttle(ev => interval(100)))

        .subscribe((event: any) => {
        if (Array.isArray(this.dropZone)) {

        if (this.canDrop) {
          let obj = {
            event: event,
            index: this.currentDropId
          };
          this.simpleDrop(obj);
        }
      } else {
        if (this.canDrop && (this.currentTileDragId2.length > 1 )) {
          this.currentObject2.previousId = event.container.element.id.split('container')[1];
          this.draggingFromDrawer(this.currentObject2);

        } else if (this.canDrop) {
          this.simpleDrop(event);
        }

      }

      }));
      this.containers.push({ sourceElement: tr, cdkdrag: cdkTile, tile: tile, cdkDropList: cdkDropList, sourceContainer: td});
    });
    if (this.table?.nativeElement) {
      this.r2.appendChild(this.table.nativeElement, tr);
    }
  });

  this.setTilePositions();

}

  simpleDrop($event) {
    this.simpleDropEvent.emit($event);
  }

  ondrag($event) {
    $event.event.srcElement.classList.add(this.userSettings.tilehighlightcolor);

  }
  draggingFromDrawer(event) {
    this.draggingFromDrawerEventEmitter.emit(event);
  }

  dragging($event, value, indexOf) {
    this.draggingEvent.emit({ $event: $event.source, index: indexOf, value: value, position: $event.pointerPosition });

  }

  returnLetters(letters) {
    letters = letters.substring(letters.lastIndexOf('.') + 1);
    if (letters === 'period') {
      return '.';
    }
    if (this.lowerCase) {
      return letters;
    } else {
      return letters.charAt(0).toUpperCase() + letters.slice(1);
    }
  }

  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }

  addId(i: number, j: number) {
    this.dropZoneIds.push(`${i}-${j}`);
  }

  test($event: any) {
  }

  private parseId(id: string) {
    const rowId = id.split(' ')[0].split('r')[1];
    const colId = id.split(' ')[1].split('c')[1];
    if (rowId === '') {
      return;
    }
    return { rowId: +rowId, colId: +colId };
  }
}
