import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {WordFilterComponent} from './word-filter.component';

@Component({
  selector: 'app-word-checkbox-filter',
  templateUrl: './html/word-filter-checkbox.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    './word-filter.scss',
    '../workbook-builder.scss'

  ]
})

export class WordFilterCheckboxComponent implements OnInit {
  @Input() filter: any;
  @Output() updateQuery: EventEmitter<any> = new EventEmitter<any>();

  selectedValues: any[] = [];

  ngOnInit(): void {
    if (this.filter.valProperty) {
      this.selectedValues = this.filter.currentVal[this.filter.valProperty];
    } else {
      this.selectedValues = this.filter.currentVal;
    }

  }

  updateQueryEvent() {
    this.updateQuery.emit({[this.filter.name]: this.selectedValues});
  }
}
