import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum LessonStepActionTypes {
    GetLessonStep = '[LessonStepService] Get Lesson Step',
    GetLessonStepSuccess = '[LessonStepService] Get Lesson Step Success',
    GetLessonStepFail = '[LessonStepService] Get Lesson Step Fail',

    ClearLessonStep = '[LessonStepService] Clear Lesson Step',

    EditGetAllLessonSteps = '[DummyService] Edit - Get All Lesson Steps',
    EditGetAllLessonStepsSuccess = '[DummyService] Edit - Get All Lesson Steps Success',
    EditGetAllLessonStepsFail = '[DummyService] Edit - Get All Lesson Steps Fail',

    GetWorkbookIdCount = '[LessonStepService] Get Count By Workbook Id',
    GetWorkbookIdCountSuccess = '[LessonStepService] Get Count By Workbook Id Success',
    GetWorkbookIdCountFail = '[LessonStepService] Get Count By Workbook Id Fail',

    GetCourseWorkbookIdCount = '[LessonStepService] Get Count By Course Workbook Id',
    GetCourseWorkbookIdCountSuccess = '[LessonStepService] Get Count By Course Workbook Id Success',
    GetCourseWorkbookIdCountFail = '[LessonStepService] Get Count By Course Workbook Id Fail',

    CreateLessonStep = '[LessonStepService] Create Lesson Step',
    CreateLessonStepSuccess = '[LessonStepService] Create Lesson Step Success',
    CreateLessonStepFail = '[LessonStepService] Create Lesson Step Fail',

    CopyLessonStep = '[LessonStepService] Copy Lesson Step',
    CopyLessonStepSuccess = '[LessonStepService] Copy Lesson Step Success',
    CopyLessonStepFail = '[LessonStepService] Copy Lesson Step Fail',

    UpdateLessonStep = '[LessonStepService] Update Lesson Step',
    UpdateLessonStepSuccess = '[LessonStepService] Update Lesson Step Success',
    UpdateLessonStepFail = '[LessonStepService] Update Lesson Step Fail',

    DeleteLessonStep = '[LessonStepService] Delete Lesson Step',
    DeleteLessonStepSuccess = '[LessonStepService] Delete Lesson Step Success',
    DeleteLessonStepFail = '[LessonStepService] Delete Lesson Step Fail',

}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class GetLessonStep implements Action {
    readonly type = LessonStepActionTypes.GetLessonStep;

    constructor(public payload: any) {}
}

export class GetLessonStepSuccess implements Action {
    readonly type = LessonStepActionTypes.GetLessonStepSuccess;

    constructor(public payload: string) {}
}
export class GetLessonStepFail implements Action {
    readonly type = LessonStepActionTypes.GetLessonStepFail;

    constructor(public payload: string) {}
}

export class ClearLessonStep implements Action {
    readonly type = LessonStepActionTypes.ClearLessonStep;
}


export class CreateLessonStep implements Action {
    readonly type = LessonStepActionTypes.CreateLessonStep;

    constructor(public payload: any) {}
}

export class CreateLessonStepSuccess implements Action {
    readonly type = LessonStepActionTypes.CreateLessonStepSuccess;

    constructor(public payload: any) {}
}
export class CreateLessonStepFail implements Action {
    readonly type = LessonStepActionTypes.CreateLessonStepFail;

    constructor(public payload: string) {}
}

export class UpdateLessonStep implements Action {
    readonly type = LessonStepActionTypes.UpdateLessonStep;

    constructor(public payload: any) {}
}

export class UpdateLessonStepSuccess implements Action {
    readonly type = LessonStepActionTypes.UpdateLessonStepSuccess;

    constructor(public payload: any) {}
}
export class UpdateLessonStepFail implements Action {
    readonly type = LessonStepActionTypes.UpdateLessonStepFail;

    constructor(public payload: string) {}
}

export class CopyLessonStep implements Action {
    readonly type = LessonStepActionTypes.CopyLessonStep;

    constructor(public payload: any) {}
}

export class CopyLessonStepSuccess implements Action {
    readonly type = LessonStepActionTypes.CopyLessonStepSuccess;

    constructor(public payload: string) {}
}
export class CopyLessonStepFail implements Action {
    readonly type = LessonStepActionTypes.CopyLessonStepFail;

    constructor(public payload: string) {}
}

export class DeleteLessonStep implements Action {
    readonly type = LessonStepActionTypes.DeleteLessonStep;

    constructor(public payload: any) {}
}

export class DeleteLessonStepSuccess implements Action {
    readonly type = LessonStepActionTypes.DeleteLessonStepSuccess;

    constructor(public payload: string) {}
}
export class DeleteLessonStepFail implements Action {
    readonly type = LessonStepActionTypes.DeleteLessonStepFail;

    constructor(public payload: string) {}
}


export class EditGetAllLessonSteps implements Action {
    readonly type = LessonStepActionTypes.EditGetAllLessonSteps;

    constructor(public payload: any) {}
}

export class EditGetAllLessonStepsSuccess implements Action {
    readonly type = LessonStepActionTypes.EditGetAllLessonStepsSuccess;

    constructor(public payload: any) {}
}
export class EditGetAllLessonStepsFail implements Action {
    readonly type = LessonStepActionTypes.EditGetAllLessonStepsFail;

    constructor(public payload: string) {}
}

export class GetWorkbookIdCount implements Action {
    readonly type = LessonStepActionTypes.GetWorkbookIdCount;

    constructor(public payload: any) {}
}

export class GetWorkbookIdCountSuccess implements Action {
    readonly type = LessonStepActionTypes.GetWorkbookIdCountSuccess;

    constructor(public payload: string) {}
}
export class GetWorkbookIdCountFail implements Action {
    readonly type = LessonStepActionTypes.GetWorkbookIdCountFail;

    constructor(public payload: string) {}
}

export class GetCourseWorkbookIdCount implements Action {
    readonly type = LessonStepActionTypes.GetCourseWorkbookIdCount;

    constructor(public payload: any) {}
}

export class GetCourseWorkbookIdCountSuccess implements Action {
    readonly type = LessonStepActionTypes.GetCourseWorkbookIdCountSuccess;

    constructor(public payload: string) {}
}
export class GetCourseWorkbookIdCountFail implements Action {
    readonly type = LessonStepActionTypes.GetCourseWorkbookIdCountFail;

    constructor(public payload: string) {}
}

export type LessonStepActions
= GetLessonStep
| GetLessonStepSuccess
| GetLessonStepFail
| ClearLessonStep
| EditGetAllLessonSteps
| EditGetAllLessonStepsSuccess
| EditGetAllLessonStepsFail
| GetWorkbookIdCount
| GetWorkbookIdCountSuccess
| GetWorkbookIdCountFail
| GetCourseWorkbookIdCount
| GetCourseWorkbookIdCountSuccess
| GetCourseWorkbookIdCountFail
| CreateLessonStep
| CreateLessonStepSuccess
| CreateLessonStepFail
| CopyLessonStep
| CopyLessonStepSuccess
| CopyLessonStepFail
| DeleteLessonStep
| DeleteLessonStepSuccess
| DeleteLessonStepFail
| UpdateLessonStep
| UpdateLessonStepSuccess
| UpdateLessonStepFail;
