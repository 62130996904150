import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-course-list',
  templateUrl: './html/course-list.html',
  styleUrls: ['../assets/css/main.css',
    '../assets/scss/fontawesome.scss',
    '../assets/scss/brands.scss',
    '../assets/scss/regular.scss',
    '../assets/scss/solid.scss',
    './courses.scss'
  ]
})

export class CourseListComponent implements OnInit {
  @Input() courses: any;
  @Input() userId: string;
  @Output() editCourseEvent: EventEmitter<any> = new  EventEmitter<any>();
  @Output() goToCourseEvent: EventEmitter<any> = new  EventEmitter<any>();
  @Output() copyCourseEvent: EventEmitter<any> = new  EventEmitter<any>();
  @Output() shareCourseEvent: EventEmitter<any> = new  EventEmitter<any>();
  @Output() deleteCourseEvent: EventEmitter<any> = new  EventEmitter<any>();
  @Output() finishEditEvent: EventEmitter<any> = new  EventEmitter<any>();

  ngOnInit() {
    const a = 'tets';
  }

  editCourse(index: number) {
    this.editCourseEvent.emit(index);
  }

  goToCourse(course: any) {
    this.goToCourseEvent.emit(course);
  }

  copyCourse(id: string) {
    this.copyCourseEvent.emit(id);
  }

  shareCourse(id: any) {
    this.shareCourseEvent.emit(id);
  }

  deleteCourse(id: any) {
    this.deleteCourseEvent.emit(id);
  }

  finishEdit(isEditing: boolean, index: number) {
    this.finishEditEvent.emit({isEditing: isEditing, index: index});
  }
}
