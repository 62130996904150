import { Action } from '@ngrx/store';

/***
 * For each type in an action group, make a simple
 * enum object for all of this group's action types.
 */

export enum SharingActionTypes {
    GetSharingData = '[SharingService] Get Sharing Data',
    GetSharingDataSuccess = '[SharingService] Get Sharing Data Success',
    GetSharingDataFail = '[SharingService] Get Sharing Data Fail',

    SharingApply = '[SharingService] Apply Sharing Data',
    SharingApplySuccess = '[SharingService] Apply Sharing Data Success',
    SharingApplyFail = '[SharingService] Apply Sharing Data Fail',
    SharingApplyReset = '[SharingService] Apply Sharing Data Reset',

    SharingDelete = '[SharingService] Delete Sharing Data',
    SharingDeleteSuccess = '[SharingService] Delete Sharing Data Success',
    SharingDeleteFail = '[SharingService] Delete Sharing Data Fail',

    SharingRename = '[SharingService] Rename Sharing Data',
    SharingRenameSuccess = '[SharingService] Rename Sharing Data Success',
    SharingRenameFail = '[SharingService] Rename Sharing Data Fail',

    SharingCreate = '[SharingService] Create Sharing Data',
    SharingCreateSuccess = '[SharingService] Create Sharing Data Success',
    SharingCreateFail = '[SharingService] Create Sharing Data Fail'

}

/***
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions
 */
export class GetSharingData implements Action {
    readonly type = SharingActionTypes.GetSharingData;

    constructor(public payload: any) {}
}

export class GetSharingDataSuccess implements Action {
    readonly type = SharingActionTypes.GetSharingDataSuccess;

    constructor(public payload: string) {}
}
export class GetSharingDataFail implements Action {
    readonly type = SharingActionTypes.GetSharingDataFail;

    constructor(public payload: string) {}
}

export class SharingApply implements Action {
    readonly type = SharingActionTypes.SharingApply;

    constructor(public payload: any) {}
}

export class SharingApplySuccess implements Action {
    readonly type = SharingActionTypes.SharingApplySuccess;

    constructor(public payload: string) {}
}
export class SharingApplyFail implements Action {
    readonly type = SharingActionTypes.SharingApplyFail;

    constructor(public payload: string) {}
}

export class SharingApplyReset implements Action {
    readonly type = SharingActionTypes.SharingApplyReset;

    constructor(public payload: string) {}
}

export class SharingDelete implements Action {
    readonly type = SharingActionTypes.SharingDelete;

    constructor(public payload: any) {}
}

export class SharingDeleteSuccess implements Action {
    readonly type = SharingActionTypes.SharingDeleteSuccess;

    constructor(public payload: any) {}
}
export class SharingDeleteFail implements Action {
    readonly type = SharingActionTypes.SharingDeleteFail;

    constructor(public payload: string) {}
}

export class SharingRename implements Action {
    readonly type = SharingActionTypes.SharingRename;

    constructor(public payload: any) {}
}

export class SharingRenameSuccess implements Action {
    readonly type = SharingActionTypes.SharingRenameSuccess;

    constructor(public payload: any) {}
}
export class SharingRenameFail implements Action {
    readonly type = SharingActionTypes.SharingRenameFail;

    constructor(public payload: string) {}
}

export class SharingCreate implements Action {
    readonly type = SharingActionTypes.SharingCreate;

    constructor(public payload: any) {}
}

export class SharingCreateSuccess implements Action {
    readonly type = SharingActionTypes.SharingCreateSuccess;

    constructor(public payload: string) {}
}
export class SharingCreateFail implements Action {
    readonly type = SharingActionTypes.SharingCreateFail;

    constructor(public payload: string) {}
}



export type SharingDataActions
= GetSharingData
| GetSharingDataSuccess
| GetSharingDataFail
| SharingApply
| SharingApplySuccess
| SharingApplyFail
| SharingApplyReset
| SharingDelete
| SharingDeleteSuccess
| SharingDeleteFail
| SharingRename
| SharingRenameSuccess
| SharingRenameFail
| SharingCreate
| SharingCreateSuccess
| SharingCreateFail;
