import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, first, take, tap } from 'rxjs/operators';
import { TilesUpdatingOptionEnum, WordsUpdatingOptionEnum } from '../components/course-workbook-edit/course-workbook-edit.component';
import { WorkbookActivityLessonStepService } from '../services/workbook-activity-lesson-step.service';
import { getCurrentSetting, selectGetAllCourseWorkbook, selectGetWorkbookActivity, selectWorkbookActivityUpdated } from '../store';
import { GetWorkbookActivity, UpdateWorkbookActivity } from '../store/workbook-activity/workbook-activity.actions';

@Injectable()
export class WorkbookActivityDataFixResolver  {
  constructor(private store: Store<any>, private workbookActivityLessonStepService: WorkbookActivityLessonStepService) {}

  updateWorkbookActivity(workbookActivity, activityType) {
    const userId = JSON.parse(localStorage.profile).user_metadata.uid;
    const customerId = JSON.parse(localStorage.profile).user_metadata.cusID;

    this.store.dispatch(new UpdateWorkbookActivity({
      courseWorkbookActivity: workbookActivity,
      initData: {
        activityType,
        courseWorkbookId: workbookActivity.courseWorkbookId,
        lessonPlanStepId: null,
        userId,
        customerId
      }
    }));
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // return this.store.pipe(
    //   select(selectGetWorkbookActivity),
    //   tap(isLoaded => {
    //     if (!isLoaded) {

    //     }
    //   }),
    //   filter(isLoaded => isLoaded),
    //   first(),
    // );
    const courseWorkbookId = route.params.courseWorkbookId;
    const activityType = route.url[0].path;
    const activityTypeId = route.url[0].path.replace( /-([a-z])/ig, ( all, letter ) => {
      return letter.toUpperCase();
    });

    return combineLatest([
      this.store.select(selectGetWorkbookActivity),
      this.store.select(getCurrentSetting),
      this.store.select(selectGetAllCourseWorkbook),
      this.store.select(selectWorkbookActivityUpdated)
    ]).pipe(
      tap(([workbookActivity, setting, courseWorkbooks, isWorkbookActivityUpdated]) => {
        if (!workbookActivity) {
          this.store.dispatch(new GetWorkbookActivity({
            courseWorkbookId: courseWorkbookId,
            activityTypeId: activityTypeId
          }));
        } else {
          const courseWorkbook: any = courseWorkbooks?.find((item: any) => item._id === workbookActivity?.courseWorkbookId);
          if (!courseWorkbook) {
            return;
          }
          let updatedWorkbookActivity = JSON.parse(JSON.stringify(workbookActivity));
          if (!(updatedWorkbookActivity.wordUpdating && updatedWorkbookActivity.tileUpdating)) {
            const { wordUpdating, tileUpdating } = this.workbookActivityLessonStepService.getDefaultUpdatingValues();
            updatedWorkbookActivity.wordUpdating = wordUpdating;
            updatedWorkbookActivity.tileUpdating = tileUpdating;
            if ((updatedWorkbookActivity.tileUpdating === TilesUpdatingOptionEnum.ExactTilesFilterBased && !courseWorkbook.workbook.filters.exactTiles?.length) ||
            !updatedWorkbookActivity.words?.length) {
              // tslint:disable-next-line:no-unused-expression
              updatedWorkbookActivity.tileUpdating === TilesUpdatingOptionEnum.Manual;
            }
            if (updatedWorkbookActivity.tileUpdating === TilesUpdatingOptionEnum.Manual) {
              updatedWorkbookActivity.tiles = this.workbookActivityLessonStepService.applyTilesFromTileLayout(updatedWorkbookActivity.tiles);
            }
          }

          if (updatedWorkbookActivity.wordUpdating === WordsUpdatingOptionEnum.RemoveWords) {
            updatedWorkbookActivity.words = this.workbookActivityLessonStepService.applyRemoveWordsOption(updatedWorkbookActivity, courseWorkbook);
          } else if (updatedWorkbookActivity.wordUpdating === WordsUpdatingOptionEnum.ReplaceWords) {
            updatedWorkbookActivity.words = this.workbookActivityLessonStepService.applyReplaceWordsOption(updatedWorkbookActivity, courseWorkbook);
          } else if (updatedWorkbookActivity.wordUpdating === WordsUpdatingOptionEnum.UseWords) {
            updatedWorkbookActivity.words = this.workbookActivityLessonStepService.applyUseWordsOption(courseWorkbook);
          }

          if (updatedWorkbookActivity.words.find(word => !word?.displayWord)) {
            updatedWorkbookActivity.words = this.workbookActivityLessonStepService.fixWordsData(updatedWorkbookActivity, courseWorkbook);
          }

          if (updatedWorkbookActivity.tileUpdating === TilesUpdatingOptionEnum.ExactTilesFilterBased) {
            if (courseWorkbook.workbook.filters.exactTiles?.length) {
              updatedWorkbookActivity.tiles = courseWorkbook.workbook.filters.exactTiles.map(item => item?.tile ? item.tile : item);

            } else {
              updatedWorkbookActivity.tileUpdating = TilesUpdatingOptionEnum.Manual;
            }
          } else if (updatedWorkbookActivity.tileUpdating === TilesUpdatingOptionEnum.ActiveWordsBased) {
            this.workbookActivityLessonStepService.applyActiveWordsTilesOption(updatedWorkbookActivity).pipe(take(1)).subscribe(tiles => {
              updatedWorkbookActivity.tiles = tiles;
              this.updateWorkbookActivity(updatedWorkbookActivity, activityType);
            });
            return;

          }

          this.updateWorkbookActivity(updatedWorkbookActivity, activityType);
        }

      }),
      filter(([workbookActivity, setting, courseWorkbooks, isWorkbookActivityUpdated]) => {
        // return workbookActivity;
        const courseWorkbook: any = courseWorkbooks?.find((item: any) => item._id === workbookActivity?.courseWorkbookId);
        if (setting && courseWorkbooks && courseWorkbook && isWorkbookActivityUpdated && workbookActivity?.wordUpdating && workbookActivity?.tileUpdating) {
          return true;
        }
      }),
      first()
    );
  }
}
