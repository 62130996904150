// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  font-weight: bold;
  padding-top: 24px;
}

.body {
  color: gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-holder {
  display: flex;
  justify-content: flex-end;
  padding: 0 24px 24px;
}

::ng-deep .modal-container mat-mdc-dialog-container {
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/select-plan-modal/select-plan.modal.scss"],"names":[],"mappings":"AAEA;EAEI,iBAAA;EACA,iBAAA;AAFJ;;AAIA;EACI,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AADJ;;AAGA;EACI,aAAA;EACA,yBAAA;EACA,oBAAA;AAAJ;;AAGI;EACI,kBAAA;AAAR","sourcesContent":["// @import '../../styles.scss';\n// @import \"../../component_variable.scss\";\n.header{\n    // color: $primary-dark;\n    font-weight: bold;\n    padding-top: 24px;\n}\n.body{\n    color: gray;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n.button-holder{\n    display: flex;\n    justify-content: flex-end;\n    padding: 0 24px 24px;\n}\n::ng-deep .modal-container {\n    mat-mdc-dialog-container {\n        border-radius: 8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
