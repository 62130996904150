import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_DIR } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ListenerService } from './listener.service';
import { Store } from '@ngrx/store';

@Injectable()

export class CourseService {
  courses: any[] = [];
  currentCourse: any;

  constructor(
    private http: HttpClient,
    private listenerService: ListenerService,
    private store: Store
    ) {
      // TODO: need to unsubscribe
      this.listenerService.listener().subscribe((callback) => {
        const payload = callback.split(':');
        if (payload.length > 1 && payload[0] === 'course')  {
          this.currentCourse = payload[1];
          // localStorage.setItem('currentCourseId', payload[1]);
          this.listenerService.callback(`selectedCourse:${payload[1]}`);
        }
      });

      // if (localStorage.getItem('currentCourseId') !== 'undefined' && localStorage.getItem('currentCourseId')) {
      //   this.currentCourse = localStorage.getItem('currentCourseId');
      // }
  }

  get(courseId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/course/${courseId}`)
        .subscribe(
          (course: any) => {
            this.currentCourse = course.data;
            observer.next(this.currentCourse);
          },
          error => {
            throw error;
          });
    });
  }

  getAll() {
    const whizzimoId = JSON.parse(localStorage.getItem('profile')).user_metadata.uid;

    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/course/user/${whizzimoId}`)
        .subscribe(
          (courses: any) => {
            this.courses = courses.data;
            observer.next(this.courses);
          },
          error => {
            throw error;
          });
    });
  }

  create(course: any) {
    return this.http.post(`${API_DIR}/course`, course);
  }

  update(course: any) {
    return this.http.put(`${API_DIR}/course/${course._id}`, course);
  }

  delete(courseId: string) {
    return this.http.delete(`${API_DIR}/course/${courseId}`);
  }

  copy(courseId: string) {
    return this.http.post(`${API_DIR}/course/copy/${courseId}`, {});
  }
}
